import { mergeClasses } from "@twire/utility/helpers";
import React from "react";
import classes from "./ComponentError.module.scss";

export interface ComponentErrorInterface {
  message: string;
  svg: any;
  suggestion?: string;
  onActionClicked?: (arg?: any) => void;
  actionText?: string;
}

interface Props extends ComponentErrorInterface {
  className?: string;
}

const ComponentError: React.FC<Props> = (props) => (
  <div
    className={mergeClasses(
      classes.container,
      props.className ? props.className : ""
    )}
  >
    <div className="flex flex-col items-center">
      <div className={classes.svgWrapper}>
        <props.svg />
      </div>
      <p className={classes.errorMessage}>{props.message}</p>
      {props.suggestion && (
        <p className={classes.suggestion}>{props.suggestion}</p>
      )}
      {props.onActionClicked && props.actionText && (
        <button
          onClick={props.onActionClicked}
          className={classes.actionButton}
        >
          {props.actionText}
        </button>
      )}
    </div>
  </div>
);

export default ComponentError;
