import { useState } from "react";
import { RootReducer } from "@twire/redux/types";
import { useDispatch, useSelector } from "react-redux";
import TournamentInfo from "./TournamentInfo";
import Placeholder from "./Placeholder";
import { saveUserAction } from "@twire/redux/track/actions";
import { tournamentInfoView, tournamentLinkOpen } from "@twire/redux/track/userActions/userActions";

const TournamentInfoContainer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  
  const {
    tournament: { tournament, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const toggle = () => {
    setOpen(!open);
    dispatch(saveUserAction(tournamentInfoView(
      tournament?.friendlyName,
      tournament?.eventType,
      tournament?.region,
    )));
  };

  const onSocialMediaOpen = (type: string) => {
    dispatch(saveUserAction(tournamentLinkOpen(
      tournament?.friendlyName,
      tournament?.eventType,
      tournament?.region,
      type,
    )));
  }

  return (
    <>
      {!requestStates.tournament.requesting && (
        <TournamentInfo
          open={open}
          toggle={toggle}
          tournamentData={tournament}
          onSocialMediaOpen={onSocialMediaOpen}
          game={game}
        />
      )}
      {requestStates.tournament.requesting && <Placeholder />}
    </>
  );
};

export default TournamentInfoContainer;
