import React from "react";
import UnopDropdown, { DropDowndirections } from "unop-react-dropdown";
import { TwireScoreChartItem } from "@twire/common/TwireScoreChart/types";
import TwireScoreChart from "@twire/common/TwireScoreChart";
import { mergeClasses } from "@twire/utility/helpers";
import { ReactComponent as SearchSvg } from "../../../../../../../assets/svgs/search.svg";
import { ReactComponent as ArrowTip } from "../../../../../../../assets/svgs/arrowTip.svg";
import { PlayerStatData } from "./types";
import classes from "./PlayerStat.module.scss";

const PlayerStat: React.FC<{
  data: TwireScoreChartItem[];
  twrScore: number;
  playerName: string;
  teamName: string;
  onChange: (v: string) => void;
  collection: number[];
  platformLeaderboard: PlayerStatData[];
  onButtonClick: (v: number, callBack: () => void) => void;
  playerIndex: number;
}> = ({
  data,
  twrScore,
  playerName,
  teamName,
  onChange,
  collection,
  platformLeaderboard,
  onButtonClick,
  playerIndex,
}) => (
  <div className={classes.box}>
    <UnopDropdown
      align={DropDowndirections.CENTER}
      closeOnClickOut
      trigger={
        <div className={classes.playerName}>
          {playerName}
          <ArrowTip className={classes.arrowTip} />
        </div>
      }
    >
      {({ hide }: { hide: (e?: any) => void }) => (
        <ul className={classes.dropdown}>
          <div className={classes.searchBox}>
            <div className={classes.searchSvgBox}>
              <SearchSvg />
            </div>
            <input
              type="search"
              className={classes.search}
              onChange={(e) => onChange(e.target.value.toLowerCase())}
            />
          </div>
          <div className={classes.predictions}>
            {collection.map((i, index) => (
              <button
                key={index}
                onClick={() => onButtonClick(i, hide)}
                className={mergeClasses(classes.button, [
                  i === playerIndex,
                  classes.active,
                ])}
              >
                {platformLeaderboard[i].username}
              </button>
            ))}
          </div>
        </ul>
      )}
    </UnopDropdown>
    <div
      className={mergeClasses(
        "open-sans opacity-70 text-twire-light text-center text-xs",
        classes.teamName
      )}
    >
      {teamName}
    </div>
    <TwireScoreChart data={data} />

    <div
      className={mergeClasses(
        "text-twire-light rift-bold flex items-baseline justify-center",
        classes.caption
      )}
    >
      <div className={mergeClasses(classes.bigger, "rift-bold")}>
        {Math.round(twrScore)}
      </div>{" "}
      <div
        className={mergeClasses("text-twire-orange rift-bold", classes.smaller)}
      >
        TWR
      </div>
    </div>
  </div>
);

export default PlayerStat;
