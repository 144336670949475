import React from "react";
import Articles from "@twire/common/Articles";
import Text from "@twire/common/Text";
import SubscriptionForm from "./SubscriptionForm";
import classes from "./Settings.module.scss";

const Subscription: React.FC = () => {
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <h1 className={classes.heading}>
          <Text i18nKey="words.settings">Settings</Text>
        </h1>
        <div className={classes.content}>
          <div className={classes.form}>
            <h1 className={classes.smallHeading}>
              <Text i18nKey="words.subscription">Subscription</Text>
            </h1>
            <SubscriptionForm />
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <Articles config={{ visibleCount: 3 }} />
      </div>
    </div>
  );
};

export default Subscription;
