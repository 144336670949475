import { LeaderboardTableViewProps } from "./types";
import {
  mergeClasses,
  setTeamLogo,
  setDefaultTeamLogo,
} from "@twire/utility/helpers";
import { Link } from "react-router-dom";
import RankChange from "@twire/common/RankChange";
import classes from "./LeaderboardTable.module.scss";
import Text from "@twire/common/Text";
import { GeneralTournamentFormat, LanguagePath, Path } from "@twire/utility/constants";

const Table: React.FC<LeaderboardTableViewProps> = ({
  leaderboard,
  headers,
  game,
  onMouseEnterTeamName,
  onMouseLeaveTeamName,
  indexStart,
  getTeamUrl,
  ruleset,
  started,
  numberOfAdvanceTeams,
}) => {
  return (
    <div className={classes.wrapper}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className="px-2">rank</th>
            <th className={mergeClasses(classes.team, "text-left pl-3")}>
              team
            </th>

            {headers.map((header) => (
              <th key={header.key} className="px-2 whitespace-nowrap">
                <Text i18nKey={`words.${header.key}` as Path<LanguagePath>}>
                  {header.value}
                </Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {leaderboard.map(
            ({ team, positionChange, positionDiff, players, id, advance, numberOfMatches }, index) => (
              <tr
                key={id}
                className={mergeClasses(
                  "mb-1",
                  [
                    ruleset === GeneralTournamentFormat.WWCD && started && numberOfMatches === 0,
                    classes.fade,
                  ],
                )}
              >
                <td className={mergeClasses(
                  "text-center text-twire-orange text-xl font-bold background-twire-table-tile",
                  [advance || ((indexStart || 0) + index) < (numberOfAdvanceTeams || 0), classes.advance],
                )}>
                  {`#${(indexStart || 0) + index + 1}`}
                </td>
                <td className="background-twire-table-tile">
                  <Link className="block" to={getTeamUrl(id, team)} key={index}>
                    <div
                      className={mergeClasses(
                        classes.rank,
                        "flex items-center text-xl whitespace-nowrap overflow-ellipsis pl-4 cursor-pointer pr-4",
                        classes.team,
                        classes.row
                      )}
                      onMouseEnter={(e) => {
                        onMouseEnterTeamName(e, players);
                      }}
                      onMouseLeave={onMouseLeaveTeamName}
                    >
                      <img
                        src={setTeamLogo(game, team)}
                        className={classes.logo}
                        alt="logo"
                        onError={setDefaultTeamLogo}
                      />{" "}
                      <div className={classes.teamName}>{team}</div>
                      <RankChange
                        positionChange={positionChange}
                        positionDiff={positionDiff}
                        className="ml-auto pl-4"
                      />
                    </div>
                  </Link>
                </td>
                {headers.map((header) => (
                  <td
                    key={header.key}
                    className="text-xl text-center background-twire-grey"
                  >
                    {(leaderboard[index] as any)[header.key] as any}
                  </td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
