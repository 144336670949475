import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { TeamRankingInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as topTeamsHandlers from "./handlers/getTeamRanking";

const INITIAL_STATE: TeamRankingInterface = {
  teams: [],
  updatedAt: "",
  requestStates: {
    teams: { ...defaultRequestState },
  },
};

const teamsReducer: Reducer<TeamRankingInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TEAM_RANKING_GET_TEAM_RANKING_REQUESTED:
      return topTeamsHandlers.requested(state);
    case actionTypes.TEAM_RANKING_GET_TEAM_RANKING_FAILED:
      return topTeamsHandlers.failed(state, action);
    case actionTypes.TEAM_RANKING_GET_TEAM_RANKING_SUCCESS:
      return topTeamsHandlers.success(state, action);
    case actionTypes.TEAM_RANKING_GET_TEAM_RANKING_CLEAR:
      return topTeamsHandlers.clear(state);
    default:
      return state;
  }
};

export default teamsReducer;
