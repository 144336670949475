import { mergeClasses } from "@twire/utility/helpers";
import React from "react";
import classes from "./Progress.module.scss";

const ProgressContainer: React.FC<{
  stroke?: number;
  progress: number;
  radius?: number;
  containerClassName?: string;
  textClassName?: string;
}> = ({
  radius = 20,
  stroke = 2,
  progress,
  containerClassName,
  textClassName,
}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const fullStrokeDashoffset = circumference - 1 * circumference;

  return (
    <div
      style={{
        height: `${radius * 2}px`,
        width: `${radius * 2}px`,
      }}
      className={mergeClasses(
        classes.container,
        containerClassName ? containerClassName : ""
      )}
    >
      <svg height={radius * 2} width={radius * 2} className={classes.svg}>
        <circle
          stroke="#ffffff1a"
          fill="transparent"
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset: fullStrokeDashoffset }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          className={classes.circle}
        />
        <circle
          stroke="#f0ab2e"
          fill="transparent"
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          className={classes.circle}
        />
      </svg>
      <div
        className={mergeClasses("text-twire-light", classes.percentage, [
          !!textClassName,
          textClassName,
        ])}
      >
        {progress}
      </div>
    </div>
  );
};

export default ProgressContainer;
