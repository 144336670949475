import { isDesktop, isMobile, isTablet } from "react-device-detect";
import i18n from 'i18next';

import { SaveUserActionParams } from "../types";

/**
 * 
 * ARTICLE PAGE ACTIONS
 * 
 */

// Post comment on article action
const ARTICLE_POST_COMMENT = 'Article Post Comment';
/**
 * Returns an object that represents user
 * posting a comment to article
 * 
 * @param {*} articleTitle article title
 */
export const articlePostComment = (
  articleTitle?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: ARTICLE_POST_COMMENT,
    properties: {
      "article title": articleTitle,
    },
  });
}

// Share article action
const ARTICLE_SHARE = 'Article Share';
/**
 * Returns an object that represents user's
 * sharing of the article
 * 
 * @param {*} articleTitle article title
 */
export const articleShare = (
  articleTitle?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: ARTICLE_SHARE,
    properties: {
      "article title": articleTitle,
    },
  });
}

// Article view action
const ARTICLE_VIEW = 'Article View';
/**
 * Returns an object that represents user
 * viewing an article
 * 
 * @param {*} articleTitle article title
 */
export const articleView = (
  articleTitle?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: ARTICLE_VIEW,
    properties: {
      "article title": articleTitle,
    },
  });
}

/**
 * 
 * FEATURED TOURNAMENTS ACTIONS
 * 
 */

// Featured tournament stream open action
const FEATURED_TOURNAMENT_OPEN_STREAM = 'Featured Tournament Open Stream';
/**
 * Returns an object that represents user
 * opening featured tournament stream
 * 
 * @param {*} tournamentName tournament name
 */
export const featuredTournamentOpenStream = (
  tournamentName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FEATURED_TOURNAMENT_OPEN_STREAM,
    properties: {
      "tournament name": tournamentName,
    },
  });
}

// Featured tournament page view
const FEATURED_TOURNAMENT_PAGE_VIEW = 'Featured Tournament Page View';
/**
 * Returns an object that represents user
 * opening featured tournament page
 * 
 * @param {*} tournamentName tournament name
 */
export const featuredTournamentPageView = (
  tournamentName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FEATURED_TOURNAMENT_PAGE_VIEW,
    properties: {
      "tournament name": tournamentName,
    },
  });
}

/**
 * 
 * HOME PAGE ACTIONS
 * 
 */

// Home page page view
const HOME_PAGE_VIEW = 'Home Page View';
/**
 * Returns an object that represents user
 * opening home tournament page
 */
export const homePageView = (): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: HOME_PAGE_VIEW,
    properties: {},
  });
}

/**
 * 
 * NEWS PAGE ACTIONS
 * 
 */

// News page page view
const NEWS_PAGE_VIEW = 'News Page View';
/**
 * Returns an object that represents user
 * opening News tournament page
 */
export const newsPageView = (
  pageNumber?: number,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: NEWS_PAGE_VIEW,
    properties: {
      "page number": pageNumber,
    },
  });
}

/**
 * 
 * RANKING PAGE ACTIONS
 * 
 */

// Team ranking page view
const RANKING_TEAM_PAGE_VIEW = 'Ranking Team Page View';
/**
 * Returns an object that represents user
 * opening team ranking page
 */
export const rankingTeamPageView = (
  region?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: RANKING_TEAM_PAGE_VIEW,
    properties: {
      "ranking region": region,
    },
  });
}

/**
 * 
 * SCRIMS PAGE ACTIONS
 * 
 */

// Scrims page view
const SCRIMS_PAGE_VIEW = 'Scrims Page View';
/**
 * Returns an object that represents user
 * opening scrim tournament page
 */
export const scrimsPageView = (): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: SCRIMS_PAGE_VIEW,
    properties: {},
  });
}

/**
 * 
 * SEARCH ACTIONS
 * 
 */

// Main search triggered
const SEARCH_MAIN_TRIGGERED = 'Search Main Triggered';
/**
 * Returns an object that represents user
 * triggering main search in top search bar
 * 
 * @param {*} tournament tournament name
 */
 export const searchMainTriggered = (
  query?: string,
  numOfResults?: number,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: SEARCH_MAIN_TRIGGERED,
    properties: {
      "searching query": query,
      "number of results": numOfResults,
    },
  });
}

/**
 * 
 * TEAM PAGE ACTIONS
 * 
 */

// Team's matches viewed
const TEAM_MATCHES_VIEW = 'Team Matches View';
/**
 * Returns an object that represents user
 * viewing team's matches
 * 
 * @param {*} teamName team name
 */
 export const teamMatchesView = (
  teamName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TEAM_MATCHES_VIEW,
    properties: {
      "team name": teamName,
    },
  });
}

// Team's page viewed
const TEAM_PAGE_VIEW = 'Team Page View';
/**
 * Returns an object that represents user
 * viewing team's page
 * 
 * @param {*} teamName team name
 */
 export const teamPageView = (
  teamName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TEAM_PAGE_VIEW,
    properties: {
      "team name": teamName,
    },
  });
}

// Team's roster viewed
const TEAM_ROSTER_VIEW = 'Team Roster View';
/**
 * Returns an object that represents user
 * viewing team's roster
 * 
 * @param {*} teamName team name
 */
 export const teamRosterView = (
  teamName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TEAM_ROSTER_VIEW,
    properties: {
      "team name": teamName,
    },
  });
}

// Team's statistics viewed
const TEAM_STATISTICS_VIEW = 'Team Statistics View';
/**
 * Returns an object that represents user
 * viewing team's statistics
 * 
 * @param {*} teamName team name
 */
 export const teamStatisticsView = (
  teamName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TEAM_STATISTICS_VIEW,
    properties: {
      "team name": teamName,
    },
  });
}

// Team's tournaments viewed
const TEAM_TOURNAMENTS_VIEW = 'Team Tournaments View';
/**
 * Returns an object that represents user
 * viewing team's tournaments
 * 
 * @param {*} teamName team name
 */
 export const teamTournamentsView = (
  teamName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TEAM_TOURNAMENTS_VIEW,
    properties: {
      "team name": teamName,
    },
  });
}

/**
 * 
 * TOURNAMENT PAGE ACTIONS
 * 
 */

// Graph filter used on tournament page
const TOURNAMENT_GRAPH_FILTER_TRIGGERED = 'Tournament Graph Filter Triggered';
/**
 * Returns an object that represents user
 * using graph filter on tournament page
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 * @param {*} filterOption selected option
 * @param {*} type graph type (player, team)
 */
 export const tournamentGraphFilterTriggered = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
  filterOption?: string,
  type?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_GRAPH_FILTER_TRIGGERED,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
      "filter option": filterOption,
      "graph type": type,
    },
  });
}

// Tournament info viewed
const TOURNAMENT_INFO_VIEW = 'Tournament Info View';
/**
 * Returns an object that represents user
 * viewing tournament's info
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentRegion tournament region
 */
 export const tournamentInfoView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentRegion?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_INFO_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament region": tournamentRegion,
    },
  });
}

// Tournament leaderboard viewed
const TOURNAMENT_LEADERBOARD_VIEW = 'Tournament Leaderboard View';
/**
 * Returns an object that represents user
 * viewing tournament's leaderboard
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 */
 export const tournamentLeaderboardView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_LEADERBOARD_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
    },
  });
}

// Tournament link opened
const TOURNAMENT_LINK_OPEN = 'Tournament Link Open';
/**
 * Returns an object that represents user
 * opening social media or any other tournament link
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentRegion tournament region
 * @param {*} linkType link type
 */
 export const tournamentLinkOpen = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentRegion?: string,
  linkType?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_LINK_OPEN,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament region": tournamentRegion,
      "link type": linkType,
    },
  });
}

// Tournament match replay view
const TOURNAMENT_MATCH_REPLAY_VIEW = 'Tournament Match Replay View';
/**
 * Returns an object that represents user
 * viewing match replay
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 */
 export const tournamentMatchReplayView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_MATCH_REPLAY_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
    },
  });
}

// Tournament matches view
const TOURNAMENT_MATCHES_VIEW = 'Tournament Matches View';
/**
 * Returns an object that represents user
 * viewing tournament's matches
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 * @param {*} matchesType matches type
 */
 export const tournamentMatchesView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
  matchesType?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_MATCHES_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
      "matches type": matchesType,
    },
  });
}

// Tournament page viewed
const TOURNAMENT_PAGE_VIEW = 'Tournament Page View';
/**
 * Returns an object that represents user
 * viewing tournament's page
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentRegion tournament region
 */
 export const tournamentPageView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentRegion?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_PAGE_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament region": tournamentRegion,
    },
  });
}

// Tournament participants viewed
const TOURNAMENT_PARTICIPANTS_VIEW = 'Tournament Participants View';
/**
 * Returns an object that represents user
 * viewing tournament's participants
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 */
 export const tournamentParticipantsView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_PARTICIPANTS_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
    },
  });
}

// Tournament player stats viewed
const TOURNAMENT_PLAYER_STATS_VIEW = 'Tournament Player Stats View';
/**
 * Returns an object that represents user
 * viewing tournament's player stats
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 * @param {*} statisticsType type of the statistcs view
 * @param {*} fullscreen full screen option
 */
 export const tournamentPlayerStatsView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
  statisticsType?: string,
  fullscreen?: boolean,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_PLAYER_STATS_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
      "statistics type": statisticsType,
      "fullscreen": fullscreen,
    },
  });
}

// Tournament team stats viewed
const TOURNAMENT_TEAM_STATS_VIEW = 'Tournament Team Stats View';
/**
 * Returns an object that represents user
 * viewing tournament's team stats
 * 
 * @param {*} tournamentName tournament name
 * @param {*} tournamentType tournament type
 * @param {*} tournamentGroup selected group name
 * @param {*} tournamentRegion tournament region
 * @param {*} statisticsType type of the statistcs view
 * @param {*} fullscreen full screen option
 */
 export const tournamentTeamStatsView = (
  tournamentName?: string,
  tournamentType?: string,
  tournamentGroup?: string,
  tournamentRegion?: string,
  statisticsType?: string,
  fullscreen?: boolean,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_TEAM_STATS_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament type": tournamentType,
      "tournament group": tournamentGroup,
      "tournament region": tournamentRegion,
      "statistics type": statisticsType,
      "fullscreen": fullscreen,
    },
  });
}

/**
 * 
 * TOURNAMENT LIST PAGE ACTIONS
 * 
 */

// Tournament list page viewed
const TOURNAMENT_LIST_PAGE_VIEW = 'Tournament List Page View';
/**
 * Returns an object that represents user
 * viewing tournament list page
 * 
 * @param {*} tournamentsType tournament type
 */
 export const tournamentListPageView = (
  tournamentsType?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_LIST_PAGE_VIEW,
    properties: {
      "tournaments type": tournamentsType,
    },
  });
}

/**
 * 
 * TOURNAMENT STREAM ACTIONS
 * 
 */

// Tournament stream viewed
const TOURNAMENT_STREAM_VIEW = 'Tournament Stream View';
/**
 * Returns an object that represents user
 * viewing tournament stream
 * 
 * @param {*} tournamentName tournament name
 */
 export const tournamentStreamView = (
  tournamentName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TOURNAMENT_STREAM_VIEW,
    properties: {
      "tournament name": tournamentName,
    },
  });
}

/**
 * 
 * TWIRE SOCIAL MEDIA ACTIONS
 * 
 */

// Twire social media open
const TWIRE_SOCIAL_MEDIA_OPEN = 'Twire Social Media Open';
/**
 * Returns an object that represents user
 * opening Twire's social media 
 * 
 * @param {*} linkType link type
 */
 export const twireSocialMediaOpen = (
  linkType?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: TWIRE_SOCIAL_MEDIA_OPEN,
    properties: {
      "link type": linkType,
    },
  });
}

// Fantasy tournaments list
const FANTASY_TOURNAMENT_LIST_PAGE_VIEW = 'Fantasy Tournament List Page View';
/**
 * Returns an object that represents user
 * opening fantasy tournaments list 
 */
 export const fantasyTournamentListPageView = (): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FANTASY_TOURNAMENT_LIST_PAGE_VIEW,
    properties: {},
  });
}

// Fantasy Tournament page viewed
const FANTASY_TOURNAMENT_PAGE_VIEW = 'Fantasy Tournament Page View';
/**
 * Returns an object that represents user
 * viewing fantasy tournament's page
 * 
 * @param {*} tournamentName tournament name
 */
 export const fantasyTournamentPageView = (
  tournamentName?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FANTASY_TOURNAMENT_PAGE_VIEW,
    properties: {
      "tournament name": tournamentName,
    },
  });
}

// Fantasy Leaderboard page viewed
const FANTASY_LEADERBOARD_PAGE_VIEW = 'Fantasy Leaderboard Page View';
/**
 * Returns an object that represents user
 * viewing fantasy leaderboard's page
 * 
 * @param {*} tournamentName tournament name
 */
 export const fantasyLeaderboardPageView = (
  tournamentName?: string,
  tournamentGroup?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FANTASY_LEADERBOARD_PAGE_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament group": tournamentGroup,
    },
  });
}

// Fantasy My Team page viewed
const FANTASY_MY_TEAM_PAGE_VIEW = 'Fantasy My Team Page View';
/**
 * Returns an object that represents user
 * viewing fantasy my team page
 * 
 * @param {*} tournamentName tournament name
 */
 export const fantasyMyTeamPageView = (
  tournamentName?: string,
  tournamentGroup?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FANTASY_MY_TEAM_PAGE_VIEW,
    properties: {
      "tournament name": tournamentName,
      "tournament group": tournamentGroup,
    },
  });
}

// Fantasy My Team save
const FANTASY_MY_TEAM_SAVE = 'Fantasy My Team Save';
/**
 * Returns an object that represents user
 * saving fantasy team
 * 
 * @param {*} tournamentName tournament name
 */
 export const fantasyMyTeamSave = (
  tournamentName?: string,
  tournamentGroup?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: FANTASY_MY_TEAM_SAVE,
    properties: {
      "tournament name": tournamentName,
      "tournament group": tournamentGroup,
    },
  });
}

// Ad Shown
const AD_SHOWN = 'Ad Shown';
/**
 * Returns an object that represents user
 * that saw an ad
 * 
 * @param {*} adProvider name of the ad provider
 */
 export const adShown = (
  adProvider?: string,
  country?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: AD_SHOWN,
    properties: {
      "ad provider": adProvider,
      "user country": country,
    },
  });
}

// Ad Click
const AD_CLICK = 'Ad Click';
/**
 * Returns an object that represents user
 * clicking on an ad
 * 
 * @param {*} adProvider name of the ad provider
 */
 export const adClick = (
  adProvider?: string,
  country?: string,
): SaveUserActionParams => {
  return addGeneralPropertiesToAction({
    event: AD_CLICK,
    properties: {
      "ad provider": adProvider,
      "user country": country,
    },
  });
}

/**
 * Adds general properties to action.
 * 
 * @param {*} event 
 */
const addGeneralPropertiesToAction = (event: any): SaveUserActionParams => {
  event.timestamp = new Date();
  event.device = getDeviceType();
  event.language = i18n.language;
  return event;
}

/**
 * Returns type of the device
 * 
 * @returns 
 */
const getDeviceType = (): string => {
  if (isDesktop) {
    return 'desktop';
  } else if (isTablet) {
    return 'tablet';
  } else if (isMobile) {
    return 'mobile';
  } else {
    return 'other';
  }
}