import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTextArea } from "@twire/common/TextArea/hooks";
import { clearArticle, getArticle, postComment } from "@twire/redux/article/actions";
import { RootReducer } from "@twire/redux/types";
import { useLanguageChange } from "@twire/utility/hooks";
import { urlFriendlyString } from "@twire/utility/helpers";
import { useDispatch, useSelector } from "react-redux";
import Article from "./Article";
import { saveUserAction } from "../../../../../redux/track/actions";
import { articleView } from "@twire/redux/track/userActions/userActions";

const ArticleContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    article: { author, content, title, comments, requestStates, updatedAt },
    user: { signedIn },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const history = useHistory();

  const instance = useTextArea();

  const params = useParams<{ id: string, title: string }>();

  const lang = useLanguageChange();

  useEffect(() => {
    dispatch(getArticle(parseInt(params.id)));
    return () => {
      dispatch(clearArticle());
    };
  }, []);

  useEffect(() => {
    if (requestStates.article.success) {
      dispatch(saveUserAction(articleView(title)));
      if (!params.title) {
        history.replace(
          `${history.location.pathname}/${urlFriendlyString(title)}`
        );
      } else if (params.title !== urlFriendlyString(title)) {
        history.replace(
          history.location.pathname.replace(
            params.title,
            urlFriendlyString(title)
          )
        );
      }
    }
  }, [requestStates.article, history.location]);

  const buildUrl = (url: string) => {
    return `/${lang}/${game}${url}`;
  };

  const handlePostComment = () => {
    if (signedIn) {
      dispatch(postComment(instance.value));
      instance.setValue("");
    }
  };

  return (
    <Article
      date={updatedAt}
      title={title}
      content={content}
      buildUrl={buildUrl}
      comments={comments?.filter((comment) => !comment.parentId) || []}
      allComments={comments || []}
      textAreaInstance={instance}
      loadingArticle={requestStates.article.requesting}
      articleError={!!requestStates.article.failed}
      postingComment={
        requestStates.comment.requesting && !requestStates.comment.id
      }
      handlePostComment={handlePostComment}
      signedIn={signedIn}
      author={author}
    />
  );
};

export default ArticleContainer;
