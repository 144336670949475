import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTeamRanking } from "@twire/redux/teamRanking/actions";
import { RootReducer } from "@twire/redux/types";
import { useGetTeamUrl } from "@twire/utility/hooks";

import Placeholder from "./Placeholder";
import TopTeams from "./TopTeams";
import { sortAndTrim } from "./functions";

const TopTeamsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    teamRanking: { teams, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const getTeamUrl = useGetTeamUrl();

  useEffect(() => {
    dispatch(getTeamRanking());
  }, [game]);

  return requestStates.teams.requesting && teams.length === 0 ? (
    <Placeholder />
  ) : (
    <TopTeams
      topTeams={sortAndTrim(teams, 10)}
      game={game}
      getTeamUrl={getTeamUrl}
    />
  );
};

export default TopTeamsContainer;
