import InfiniteScroll from "react-infinite-scroll-component";
import TournamentTile from "../TournamentTile";
import { mergeClasses, urlFriendlyString } from "@twire/utility/helpers";
import { TournamentsViewProps } from "./types";
import { Link } from "react-router-dom";
import { AdsContext } from "../../pages/Dashboard/context";

const Tournaments: React.FC<TournamentsViewProps> = ({
  tournaments,
  url,
  onScroll,
  infinite,
}) => (
  <>
    <InfiniteScroll
      dataLength={tournaments.length}
      next={onScroll}
      hasMore={!!infinite}
      loader={
        <div className="flex justify-center mt-5">
          <img
            src="/images/animated/loader.gif"
            style={{ height: "30px" }}
            alt="loading"
          />
        </div>
      }
    >
      {tournaments.map((tournament, index) => (
        <>
          <div
            key={tournament.id}
            className={mergeClasses("my-1", [
              index === tournaments.length - 1,
              "mb-0",
            ])}
          >
            <Link
              to={`${url}${tournament.id}/${urlFriendlyString(
                tournament.name
              )}`}
            >
              <TournamentTile {...tournament} />
            </Link>
          </div>
          {index !== 0 && index === 2 && <AdsContext.Consumer>
            {({ showAds }) => (
              showAds && <div className="iframe-center" data-onpage="true" data-adpath="/339474670,22606297850/Twire/InContent"></div>
            )}
          </AdsContext.Consumer>}
        </>
      ))}
    </InfiniteScroll>
  </>
);

export default Tournaments;
