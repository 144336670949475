import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { resetPassword } from "@twire/redux/user/actions";
import { t } from "@twire/utility/helpers";
import { ResetPasswordFields } from "./types";
import ResetPassword from "./ResetPassword";

const ResetPasswordContainer: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ username: string; code: string }>();
  const [error, setError] = useState(false);
  const {
    user: { requestStates },
  } = useSelector((state: RootReducer) => state);
  const placeholders: ResetPasswordFields = {
    password: t("words.password"),
    confirmPassword: t("words.passwordConfirmation"),
  };

  const handleSubmit = (data: ResetPasswordFields) => {
    if (params.username && params.code) {
      dispatch(resetPassword(params.username, params.code, data.password));
    } else {
      setError(true);
    }
  };
  return (
    <ResetPassword
      error={requestStates.resetPassword.failed || error}
      success={requestStates.resetPassword.success}
      loading={requestStates.resetPassword.requesting}
      handleSubmit={handleSubmit}
      placeholders={placeholders}
    />
  );
};

export default ResetPasswordContainer;
