import React from "react";
import PlayerRankingTile from "../../../common/PlayerRankingTile";
import classes from "./PlayerRanking.module.scss";
import {
  PlayerStats,
  RankedPlayermap,
  PlayerId,
} from "@twire/redux/playerRanking/types";
import Toggler from "@twire/common/Toggler";
import { getTheme, t } from "@twire/utility/helpers";
import ComponentError from "@twire/common/ComponentError";
import Placeholder from "./Placeholder";
import { ReactComponent as Trophy } from "../../../../assets/svgs/trophy.svg";
import { mergeClasses } from "@twire/utility/helpers";
import { Games } from "@twire/utility/constants";
import { useGetTeamUrl } from "@twire/utility/hooks";
import "@twire/common/RankingTile/RankingTile.module.scss";
import PlayerRankingHeader from "./PlayerRankingHeader";
import PlayerRankingFilter from "./PlayerRankingFilter";
import { PlayerStatKey, PlayerRankingSortType, PlayersTrie } from "./types";
import RankedPlayerStat from "@twire/common/RankedPlayerStat";

const Ranking: React.FC<{
  loading: boolean;
  updatedAt: string;
  showError: boolean;
  game: Games;
  players: PlayerId[];
  setSortby: (key: PlayerStatKey) => void;
  getTeamUrl: ReturnType<typeof useGetTeamUrl>;
  sortedBy: PlayerRankingSortType;
  playersTrie: PlayersTrie;
  rankedPlayermap: RankedPlayermap;
  averageStats: PlayerStats | null;
  showAverageStats: boolean;
  averageStatsToggle: () => void;
}> = ({
  players,
  loading,
  updatedAt,
  showError,
  game,
  setSortby,
  sortedBy,
  playersTrie,
  rankedPlayermap,
  averageStats,
  showAverageStats,
  averageStatsToggle,
}) => (
  <div className={classes.container}>
    <div className={mergeClasses("w-full", classes.left)}>
      <div className={classes.top}>
        <h2>power rankings</h2>
        <p>
          Last updated on:{" "}
          {updatedAt
            ? new Date(Date.parse(updatedAt)).toLocaleDateString()
            : "-- : -- : --"}
        </p>
      </div>

      <div className="mb-4">
        <PlayerRankingFilter />
      </div>
      {loading && (
        <div
          className={mergeClasses(
            "background-twire-grey mb-8 appear animate-pulse",
            classes.statsRow
          )}
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {!loading && !showError && players && players.length > 3 && averageStats && (
        <>
          <div className="flex items-center font-semibold mb-2">
            <span className="ml-auto mr-2 text-twire-opaque-white">
              Show average scores
            </span>
            <Toggler
              onToggleOn={averageStatsToggle}
              onToggleOff={averageStatsToggle}
              on={showAverageStats}
            />
          </div>
          <div
            className={mergeClasses(
              "reverse-rank-tile-orange mb-8 appear",
              classes.statsRow
            )}
          >
            {[0, 1, 2, 3].map((_, i) => (
              <React.Fragment key={i}>
                <div
                  className={mergeClasses(classes.rankedPlayerCard, "flex-1")}
                >
                  <RankedPlayerStat
                    rankedPlayermap={rankedPlayermap}
                    playersTrie={playersTrie}
                    player={rankedPlayermap[players[i]]}
                    averageStats={averageStats}
                    showAverageStats={showAverageStats}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </>
      )}

      {loading ? (
        <Placeholder />
      ) : showError ? (
        <ComponentError
          message={t("sentences.rankingLoadFailed")}
          svg={Trophy}
          suggestion={t("sentences.refreshThePage")}
          className="h-96 mt-4"
        />
      ) : (
        <div className={mergeClasses("w-full overflow-x-auto")}>
          <PlayerRankingHeader sortedBy={sortedBy} setSortby={setSortby} />
          {players.map((playerId) => (
            <React.Fragment key={playerId}>
              <div className="block my-1">
                <PlayerRankingTile
                  playerData={rankedPlayermap[playerId]}
                  theme={getTheme(rankedPlayermap[playerId].rank)}
                  game={game}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      <div className={classes.bottom}>
        <h3>How ranking is calculated?</h3>
        <p>
          Power ranking takes into account Tier S tournaments starting with PCS
          4. Regional tournaments are appropriately weighted based on where they
          are held. Rankings are based on each player&apos;s performance with
          each statistic being weighed according to their team placement. A
          player who has played in more tournaments has a higher weight in the
          All-Time ranking.
        </p>
      </div>
      <div className={classes.bottom}>
        <h3>Attacker score</h3>
        <p>
          The attacker score represents how good a player is when attacking. It
          takes into account statistics such as damage dealt, assists, and kill
          streaks.
        </p>
      </div>
      <div className={classes.bottom}>
        <h3>Utility score</h3>
        <p>
          The utility score represents how good a player is with their utility.
          It takes into account statistics such as utility picked, utility
          dropped, and utility threw.
        </p>
      </div>
      <div className={classes.bottom}>
        <h3>Survivor score</h3>
        <p>
          The survivor score represents how good a player is at surviving as
          long as possible. It takes into account statistics such as boosts,
          death position on a team, time survived, and distance traveled.
        </p>
      </div>
      <div className={classes.bottom}>
        <h3>Teammate score</h3>
        <p>
          The teammate score represents how good of a teammate a player is. It
          takes into account statistics such as revives, team kills and smokes
          used to cover your team.
        </p>
      </div>
      <div className={classes.bottom}>
        <h3>Finisher score</h3>
        <p>
          The finisher score represents how good a player is at finishing their
          kills. It takes into account statistics such as knocks, stolen kills,
          and lost kills.
        </p>
      </div>
    </div>
  </div>
);

export default Ranking;
