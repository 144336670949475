import { createContext } from "react";

export type MatchTime = "upcoming" | "ongoing";

export type ContextType = {
  time: MatchTime;
  setTime: (time: MatchTime) => void;
};

const defaultValue: ContextType = {
  time: "upcoming",
  setTime: () => {
    1 + 1;
  },
};

export const MatchesContext = createContext(defaultValue);
