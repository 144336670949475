import React from "react";
import Chart from "./Chart";
import { ChartsData } from "./types";
import classes from "./Charts.module.scss";

const Charts: React.FC<{ data: ChartsData[] }> = ({ data }) => (
  <div className={classes.container}>
    {data.map((chartsData, index) => (
      <Chart key={index} data={chartsData} />
    ))}
  </div>
);

export default Charts;
