import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { urlFriendlyString, mergeClasses } from "@twire/utility/helpers";
import ComponentError from "@twire/common/ComponentError";
import { Link } from "react-router-dom";
import TournamentTile from "@twire/common/TournamentTile";
import { getRankClassName } from "./functions";
import { TournamentsViewProps } from "./types";
import classes from "./Tournaments.module.scss";

const Tournaments: React.FC<TournamentsViewProps> = ({
  data,
  url,
  error,
  onScroll,
  hasMore,
}) =>
  error ? (
    <ComponentError className={classes.error} {...error} />
  ) : (
    <div className={mergeClasses(classes.container, "appear")}>
      <InfiniteScroll
        dataLength={data.length}
        next={onScroll}
        hasMore={hasMore}
        className={classes.scrollContainer}
        loader={
          <div className="flex justify-center mt-5">
            <img
              src="/images/animated/loader.gif"
              style={{ height: "30px" }}
              alt="loading"
            />
          </div>
        }
      >
        {data.map(({ rank, tournament: tournamentData }, i) => (
          <Link
            className="block"
            key={i}
            to={`${url}${tournamentData.id}/${urlFriendlyString(
              tournamentData.name
            )}`}
          >
            <TournamentTile
              {...tournamentData}
              externalClassName={getRankClassName(rank || 0)}
            />
          </Link>
        ))}
      </InfiniteScroll>
    </div>
  );

export default Tournaments;
