// import { Table as TableClass, Column, Row } from "@twire/utility/classes";
import { mergeClasses } from "@twire/utility/helpers";

import { MatchesContext } from "../../context";
import classes from "../MatchesTable.module.scss";
import placeholderClasses from "./Placeholder.module.scss";

const Placeholder: React.FC = () => {
  const items = Array(10).fill("");
  return (
    <MatchesContext.Consumer>
      {({ time }) => (
        <div className={mergeClasses(classes.container, "appear")}>
          <div
            className={classes.wrapperGrid}
            style={{
              gridTemplateColumns:
                time === "ongoing"
                  ? "max-content auto auto auto"
                  : "max-content auto auto auto auto",
            }}
          >
            <div className="w-full">
              <div
                className={mergeClasses(
                  classes.grey1,
                  classes.row,
                  "mb-1 flex items-center font-bold",
                  classes.p30
                )}
              >
                Match Name
              </div>
              <div>
                {items.map((_, index) => (
                  <div
                    className={mergeClasses(
                      classes.rank,
                      classes.grey2,
                      "mb-1 flex items-center font-bold whitespace-nowrap",
                      classes.team,
                      classes.row,
                      classes.p30
                    )}
                    key={index}
                  >
                    {/* <span className="opacity-30"> */}
                    <div className={placeholderClasses.placeholder} />
                    {/* </span> */}
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full">
              <div
                className={mergeClasses(
                  classes.grey1,
                  classes.row,
                  "mb-1 flex items-center font-bold",
                  classes.p30
                )}
              >
                Map
              </div>
              {items.map((_, index) => (
                <div
                  className={mergeClasses(
                    classes.rank,
                    classes.grey3,
                    classes.damage,
                    "mb-1 flex items-center font-bold whitespace-nowrap",
                    classes.row,
                    classes.p30
                  )}
                  key={index}
                >
                  <div className={placeholderClasses.placeholder} />
                </div>
              ))}
            </div>

            {time === "upcoming" && (
              <div className="w-full">
                <div
                  className={mergeClasses(
                    classes.grey1,
                    classes.row,
                    "mb-1 flex items-center font-bold",
                    classes.p30
                  )}
                >
                  start
                </div>
                {items.map((_, index) => (
                  <div
                    className={mergeClasses(
                      classes.rank,
                      classes.grey3,
                      classes.damage,
                      "mb-1 flex items-center font-bold whitespace-nowrap",
                      classes.row,
                      classes.p30
                    )}
                    key={index}
                  >
                    <div className={placeholderClasses.placeholder} />
                  </div>
                ))}
              </div>
            )}

            {time === "upcoming" && (
              <div className="w-full">
                <div
                  className={mergeClasses(
                    classes.grey1,
                    classes.row,
                    "mb-1 flex items-center font-bold",
                    classes.p30
                  )}
                >
                  end
                </div>
                {items.map((_, index) => (
                  <div
                    className={mergeClasses(
                      classes.rank,
                      classes.grey3,
                      classes.damage,
                      "mb-1 flex items-center font-bold whitespace-nowrap",
                      classes.row,
                      classes.p30
                    )}
                    key={index}
                  >
                    <div className={placeholderClasses.placeholder} />
                  </div>
                ))}
              </div>
            )}

            {time === "ongoing" && (
              <div className="w-full">
                <div
                  className={mergeClasses(
                    classes.grey1,
                    classes.row,
                    "mb-1 flex items-center font-bold justify-center px-3"
                  )}
                >
                  Winning team
                </div>
                {items.map((_, index) => (
                  <div
                    className={mergeClasses(
                      classes.rank,
                      classes.grey3,
                      classes.kills,
                      "mb-1 flex items-center font-bold whitespace-nowrap justify-center",
                      classes.row
                    )}
                    key={index}
                  >
                    <div
                      className={mergeClasses(
                        classes.logo,
                        placeholderClasses.placeholder
                      )}
                    />{" "}
                    <div className={placeholderClasses.placeholder} />
                  </div>
                ))}
              </div>
            )}
            <div className="w-full">
              <div
                className={mergeClasses(
                  classes.grey1,
                  classes.row,
                  "mb-1 flex items-center font-bold justify-center text-twire-orange px-3"
                )}
              >
                {/* Points */}
              </div>
              {items.map((_, index) => (
                <div
                  className={mergeClasses(
                    classes.rank,
                    classes.grey3,
                    "mb-1 flex items-center font-bold text-twire-orange-i whitespace-nowrap justify-center",
                    classes.row,
                    classes.points
                  )}
                  key={index}
                >
                  <div className={classes.actionsRow}>
                    <div
                      className={mergeClasses(
                        placeholderClasses.placeholder,
                        placeholderClasses.actionButton
                      )}
                    />
                    <div
                      className={mergeClasses(
                        placeholderClasses.placeholder,
                        placeholderClasses.actionButton
                      )}
                    />
                    <div
                      className={mergeClasses(
                        placeholderClasses.placeholder,
                        placeholderClasses.actionButton
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </MatchesContext.Consumer>
  );
};

export default Placeholder;
