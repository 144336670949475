import { mergeClasses } from "../../../utility/helpers";
import { ReactComponent as CircleLoader } from "../../../assets/svgs/circle-loader.svg";
import { TwireButtonProps } from "./types";
import classes from "./TwireButton.module.scss";

const TwireButton: React.FC<TwireButtonProps> = ({
  loading,
  children,
  alternate,
  className,
  blue,
  red,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={mergeClasses(
        className || "",
        alternate ? "button-grey" : " button-orange",
        blue ? classes.blue : "",
        red ? classes.red : "",
        classes.button
      )}
      disabled={loading || rest.disabled}
    >
      {loading ? <CircleLoader className={classes.loadingSvg} /> : children}
    </button>
  );
};

export default TwireButton;
