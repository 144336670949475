import { useSelector } from "react-redux";
import {
  PlayerStats,
  PlayerId,
  RankedPlayermap,
  RankedPlayermapValue,
} from "@twire/redux/playerRanking/types";
import { RootReducer } from "@twire/redux/types";
import { formatPlayerDataForChart } from "./functions";
import RankedPlayerStat from "./RankedPlayerStat";
import { useState, useEffect } from "react";
import { PlayersTrie } from "../../pages/Dashboard/PlayerRanking/types";

const RankedPlayerStatContainer: React.FC<{
  player: RankedPlayermapValue;
  playersTrie: PlayersTrie;
  rankedPlayermap: RankedPlayermap;
  averageStats: PlayerStats;
  showAverageStats: boolean;
}> = ({
  player,
  playersTrie,
  rankedPlayermap,
  averageStats,
  showAverageStats,
}) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const [selectedPlayer, setSelectedPlayer] =
    useState<RankedPlayermapValue>(player);
  const [collection, setCollection] = useState<PlayerId[]>([]);

  useEffect(() => {
    setSelectedPlayer(player);
    setCollection(playersTrie.search(""));
  }, [player]);

  const onInputChange = (val: string) => {
    setCollection(playersTrie.search(val));
  };

  return (
    <RankedPlayerStat
      rankedPlayermap={rankedPlayermap}
      game={game}
      player={selectedPlayer.player}
      onButtonClick={(i: PlayerId, callBack: () => void) => {
        setSelectedPlayer(rankedPlayermap[i]);
        callBack();
      }}
      collection={collection}
      onChange={onInputChange}
      showAverageStats={showAverageStats}
      data={formatPlayerDataForChart(selectedPlayer.player, averageStats)}
    />
  );
};

export default RankedPlayerStatContainer;
