import React from "react";
import Charts from "./Charts/Charts";
import { formatStatisitcsToChartData } from "./functions";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";

const PerTournament: React.FC = () => {
  const {
    // app: { game },
    team: { requestStates, statistics },
  } = useSelector((state: RootReducer) => state);
  return !requestStates.statistics.requesting ? (
    !requestStates.statistics.failed ? (
      <Charts data={formatStatisitcsToChartData(statistics)} />
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

export default PerTournament;
