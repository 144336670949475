import { useState, useEffect } from "react";
import { useTextArea } from "@twire/common/TextArea/hooks";
import { postComment, clearComment } from "@twire/redux/article/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { CommentProps, Mode } from "./types";
import Comment from "./Comment";

const CommentContainer: React.FC<CommentProps> = (props) => {
  const [mode, setMode] = useState<Mode>("VIEW");
  const dispatch = useDispatch();
  const instance = useTextArea();
  const {
    article: { requestStates },
    user: { signedIn },
  } = useSelector((state: RootReducer) => state);

  const postReply = () => {
    if (instance.value && signedIn) {
      dispatch(postComment(instance.value, props.id));
    }
  };

  const onCancelClick = () => {
    instance.setValue("");
    setMode("VIEW");
    dispatch(clearComment());
  };

  useEffect(() => {
    if (
      requestStates.comment.success &&
      requestStates.comment.id === props.id &&
      mode === "REPLY"
    ) {
      onCancelClick();
    }
  }, [requestStates.comment]);

  return (
    <Comment
      {...props}
      instance={instance}
      mode={mode}
      setMode={setMode}
      comments={props.comments}
      postReply={postReply}
      postingReply={
        requestStates.comment.requesting &&
        requestStates.comment.id === props.id
      }
      error={
        requestStates.comment.failed && requestStates.comment.id === props.id
      }
      onCancelClick={onCancelClick}
      signedIn={signedIn}
    />
  );
};

export default CommentContainer;
