import { createContext } from "react";
import { FeaturedTournament } from "@twire/redux/featured/types";

export type ContextType = {
  isTransitioning: boolean;
  currentindex: number;
  isAppearing: boolean;
  featuredTournaments: FeaturedTournament[];
  transitionDuration: number;
  setIndex: (index: number) => void;
};

const defaultValue: ContextType = {
  isTransitioning: false,
  isAppearing: false,
  currentindex: 0,
  featuredTournaments: [],
  transitionDuration: 0.3,
  setIndex: (index: number) => {
    index + 1;
  },
};

export const FeaturedTournamentsContext = createContext(defaultValue);
