import { useTranslation } from "react-i18next";
import { supportedLangsAndName, languageIcon } from "@twire/utility/constants";
import {
  //  useSelector,
  useDispatch,
} from "react-redux";
import { changeLanguage } from "@twire/redux/app/actions";
// import { RootReducer } from "@twire/redux/types";

import Dropdown from "../../DropDown";
import DropdownTrigger from "../../DropDown/DropdownTrigger";
import DropdownItem from "../../DropDown/DropdownItem";

import classes from "./LanguageSelect.module.scss";

const LanguageSelectContainer: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  // const {
  //   articles: { articles, requestStates },
  //   app: { game },
  // } = useSelector((state: RootReducer) => state);
  return (
    <Dropdown
      className={classes.dropdown}
      dropdownTrigger={(props: any) => (
        <DropdownTrigger className={classes.dropdownTrigger} {...props}>
          <span
            className={`flag-icon flag-icon-${languageIcon[i18n.language]} ${
              classes.flagIcon
            }`}
          ></span>
        </DropdownTrigger>
      )}
    >
      {supportedLangsAndName.map((language) => (
        <div key={language.lang} className="w-full">
          <DropdownItem
            className={classes.dropdownItem}
            active={i18n.language === language.lang}
            onClick={() => {
              // i18n.changeLanguage(language)
              dispatch(changeLanguage(language.lang));
            }}
          >
            <span
              className={`flag-icon flag-icon-${languageIcon[language.lang]} ${classes.flagIcon}`}
            ></span>
            <span className="ml-2">{language.name}</span>
          </DropdownItem>
        </div>
      ))}
    </Dropdown>
  );
};

export default LanguageSelectContainer;
