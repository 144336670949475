// TOURNAMENT action types
export const TOURNAMENT_GET_TOURNAMENT = "TOURNAMENT_GET_TOURNAMENT";
export const TOURNAMENT_GET_TOURNAMENT_REQUESTED =
  "TOURNAMENT_GET_TOURNAMENT_REQUESTED";
export const TOURNAMENT_GET_TOURNAMENT_SUCCESS =
  "TOURNAMENT_GET_TOURNAMENT_SUCCESS";
export const TOURNAMENT_GET_TOURNAMENT_FAILED =
  "TOURNAMENT_GET_TOURNAMENT_FAILED";
export const TOURNAMENT_TOURNAMENT_CLEAR = "TOURNAMENT_GET_TOURNAMENT_CLEAR";

// export const TOURNAMENT_FILTER_TOURNAMENT = "TOURNAMENT_FILTER_TOURNAMENT";
// export const TOURNAMENT_FILTER_TOURNAMENT_REQUESTED =
//   "TOURNAMENT_FILTER_TOURNAMENT_REQUESTED";
// export const TOURNAMENT_FILTER_TOURNAMENT_SUCCESS =
//   "TOURNAMENT_FILTER_TOURNAMENT_SUCCESS";
// export const TOURNAMENT_FILTER_TOURNAMENT_FAILED =
//   "TOURNAMENT_FILTER_TOURNAMENT_FAILED";
// export const TOURNAMENT_FILTER_TOURNAMENT_CLEAR =
//   "TOURNAMENT_FILTER_TOURNAMENT_CLEAR";

export const TOURNAMENT_SET_FILTER = "TOURNAMENT_SET_FILTER";

export const TOURNAMENT_SET_FILTER_CLEAR = "TOURNAMENT_SET_FILTER_CLEAR";

export const TOURNAMENT_SET_STATS_FILTER = "TOURNAMENT_SET_STATS_FILTER";

export const TOURNAMENT_UNSET_STATS_FILTER = "TOURNAMENT_UNSET_STATS_FILTER";

export const TOURNAMENT_SET_STATS_FILTER_CLEAR = 
  "TOURNAMENT_SET_STATS_FILTER_CLEAR";

export const TOURNAMENT_GET_LEADERBOARD = "TOURNAMENT_GET_LEADERBOARD";
export const TOURNAMENT_GET_LEADERBOARD_REQUESTED =
  "TOURNAMENT_GET_LEADERBOARD_REQUESTED";
export const TOURNAMENT_GET_LEADERBOARD_SUCCESS =
  "TOURNAMENT_GET_LEADERBOARD_SUCCESS";
export const TOURNAMENT_GET_LEADERBOARD_FAILED =
  "TOURNAMENT_GET_LEADERBOARD_FAILED";
export const TOURNAMENT_GET_LEADERBOARD_CLEAR =
  "TOURNAMENT_GET_LEADERBOARD_CLEAR";

export const TOURNAMENT_GET_MATCHES = "TOURNAMENT_GET_MATCHES";
export const TOURNAMENT_GET_MATCHES_REQUESTED =
  "TOURNAMENT_GET_MATCHES_REQUESTED";
export const TOURNAMENT_GET_MATCHES_SUCCESS = "TOURNAMENT_GET_MATCHES_SUCCESS";
export const TOURNAMENT_GET_MATCHES_FAILED = "TOURNAMENT_GET_MATCHES_FAILED";
export const TOURNAMENT_GET_MATCHES_CLEAR = "TOURNAMENT_GET_MATCHES_CLEAR";

export const TOURNAMENT_GET_TEAM_STATS = "TOURNAMENT_GET_TEAM_STATS";
export const TOURNAMENT_GET_TEAM_STATS_REQUESTED =
  "TOURNAMENT_GET_TEAM_STATS_REQUESTED";
export const TOURNAMENT_GET_TEAM_STATS_FAILED =
  "TOURNAMENT_GET_TEAM_STATS_FAILED";
export const TOURNAMENT_GET_TEAM_STATS_SUCCESSFUL =
  "TOURNAMENT_GET_TEAM_STATS_SUCCESSFUL";
export const TOURNAMENT_GET_TEAM_STATS_CLEAR =
  "TOURNAMENT_GET_TEAM_STATS_CLEAR";

export const TOURNAMENT_GET_PLATFORM_STATS = "TOURNAMENT_GET_PLATFORM_STATS";
export const TOURNAMENT_GET_PLATFORM_STATS_REQUESTED =
  "TOURNAMENT_GET_PLATFORM_STATS_REQUESTED";
export const TOURNAMENT_GET_PLATFORM_STATS_FAILED =
  "TOURNAMENT_GET_PLATFORM_STATS_FAILED";
export const TOURNAMENT_GET_PLATFORM_STATS_SUCCESSFUL =
  "TOURNAMENT_GET_PLATFORM_STATS_SUCCESSFUL";
export const TOURNAMENT_GET_PLATFORM_STATS_CLEAR =
  "TOURNAMENT_GET_PLATFORM_STATS_CLEAR";

  export const TOURNAMENT_GET_STATS_FILTERS = "TOURNAMENT_GET_STATS_FILTERS";
  export const TOURNAMENT_GET_STATS_FILTERS_REQUESTED =
    "TOURNAMENT_GET_STATS_FILTERS_REQUESTED";
  export const TOURNAMENT_GET_STATS_FILTERS_FAILED =
    "TOURNAMENT_GET_STATS_FILTERS_FAILED";
  export const TOURNAMENT_GET_STATS_FILTERS_SUCCESSFUL =
    "TOURNAMENT_GET_STATS_FILTERS_SUCCESSFUL";
  export const TOURNAMENT_GET_STATS_FILTERS_CLEAR =
    "TOURNAMENT_GET_STATS_FILTERS_CLEAR";
