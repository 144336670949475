import { useRef, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { useUrl } from "@twire/utility/hooks";

import {
  getSearchResults,
  clearSearchResults,
} from "@twire/redux/search/actions";
import Search from "./Search";
import { saveUserAction } from "@twire/redux/track/actions";
import { searchMainTriggered } from "@twire/redux/track/userActions/userActions";

type Timeout = ReturnType<typeof setTimeout>;

const SearchContainer: React.FC = () => {
  const dispatch = useDispatch();
  const delayRef = useRef<Timeout | null>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const params = useParams<{ query?: string }>();
  const {
    search: { requestStates, tournaments, teams },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (searchValue) {
      dispatch(saveUserAction(searchMainTriggered(
        searchValue,
        (teams?.length || 0) + (tournaments?.length || 0),
      )));
    }
  }, [teams, tournaments]);

  const url = useUrl(`/${game}/search`);
  const onChange = (value: string) => {
    if (delayRef.current) {
      clearTimeout(delayRef.current);
    }
    if (value) {
      delayRef.current = setTimeout(() => {
        dispatch(getSearchResults(value));
        setSearchValue(value);
        history.replace(`${url}/${value}`);
      }, 700);
    } else {
      dispatch(clearSearchResults());
      history.replace(`${url}`);
    }
  };

  useEffect(() => {
    if (params.query && searchRef.current) {
      searchRef.current.value = params.query;
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        dispatch(getSearchResults(params.query!));
      }, 0);
    }
    return () => {
      dispatch(clearSearchResults());
    };
  }, []);

  const clearValue = () => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  return (
    <Search
      count={(tournaments?.length || 0) + (teams?.length || 0)}
      onChange={onChange}
      clearValue={clearValue}
      searchRequestStates={requestStates}
      searchRef={searchRef}
    />
  );
};

export default SearchContainer;
