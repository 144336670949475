import React from "react";
import TextArea from "@twire/common/TextArea";
import ComponentError from "@twire/common/ComponentError";
import TwireLink from "@twire/common/TwireLink";
import LastMatches from "@twire/common/LastMatches";
import PremiumBanner from "@twire/common/PremiumBanner";
import Placeholder from "./Placeholder";
import { mergeClasses, t, formatTime } from "@twire/utility/helpers";
import TopTeams from "@twire/common/TopTeams";
import { Link } from "react-router-dom";
import ArrowSpan from "@twire/common/ArrowSpan";
import TwireButton from "@twire/common/TwireButton";
import Comment from "./Comment";
import { ArticleViewProps } from "./types";
import classes from "./Article.module.scss";
import { ReactComponent as Trophy } from "../../../../../assets/svgs/trophy.svg";
import { ReactComponent as ChatBubble } from "../../../../../assets/svgs/chat-bubble.svg";
import { URLS } from "@twire/utility/constants";
import { AdsContext } from "../../context";

const Article: React.FC<ArticleViewProps> = ({
  content,
  buildUrl,
  title,
  comments,
  textAreaInstance,
  loadingArticle,
  articleError,
  postingComment,
  handlePostComment,
  allComments,
  signedIn,
  date,
  author,
}) => (
  <div className={mergeClasses(classes.container, "appear")}>
    <div className={classes.left}>
      {loadingArticle ? (
        <Placeholder />
      ) : articleError ? (
        <ComponentError
          message={t("sentences.articleLoadFailed")}
          svg={Trophy}
          suggestion={t("sentences.refreshThePage")}
          className="h-96 appear"
        />
      ) : (
        <article className={classes.article}>
          {!!date && (
            <p className={classes.timeWrapper}>
              {formatTime(date as unknown as string)}
            </p>
          )}
          <h1 className={classes.title}>{title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={classes.content}
          />
          <hr className="my-3 border-twire-ash" />
          <div className="text-twire-light rift-bold">
            <span className="text-twire-light rift-bold opacity-30 mr-1">
              author
            </span>
            : {author}
          </div>
        </article>
      )}
      <AdsContext.Consumer>
        {({ showAds }) => (
          showAds && <div className="iframe-center" data-onpage="true" data-adpath="/339474670,22606297850/Twire/InContent"></div>
        )}
      </AdsContext.Consumer>
      {!loadingArticle && !articleError && (
        <div className="mt-12">
          <>
            <div className="flex text-twire-light items-center rift-bold text-xl mb-2">
              <ChatBubble className="opacity-30" />
              <span className="ml-2 mr-1 rift-bold">
                {allComments.length}
              </span>{" "}
              Comments
            </div>
            <div
              className={mergeClasses("flex flex-col", classes.textAreaWrapper)}
            >
              {!signedIn && (
                <div className={classes.mask}>
                  You have to{" "}
                  <TwireLink to="/login" className="mx-1 text-twire-orange">
                    login
                  </TwireLink>{" "}
                  or{" "}
                  <TwireLink to="/register" className="mx-1 text-twire-orange">
                    register
                  </TwireLink>{" "}
                  to post comments.
                </div>
              )}
              <TextArea
                instance={textAreaInstance}
                placeholder="Add your reply"
              />
              <TwireButton
                className={mergeClasses("mt-2 ml-auto", classes.postButton)}
                loading={postingComment}
                disabled={postingComment}
                onClick={handlePostComment}
              >
                post
              </TwireButton>
            </div>
          </>
          {comments.map((data) => (
            <div className="my-4" key={data.id}>
              <Comment
                comments={allComments.filter(
                  (comment) => comment.parentId === data.id
                )}
                iteration={0}
                {...data}
                allComments={allComments}
              />
            </div>
          ))}
        </div>
      )}
    </div>
    <div className={classes.right}>
      <div className="mb-4">
        <PremiumBanner />
      </div>
      <div>
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="text-xl bold mr-3 text-twire-light">
              {t("words.topTeams")}
            </span>
          </div>
          <Link to={buildUrl(URLS.TEAM_RANKING)} className="ml-auto">
            <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
          </Link>
        </div>
        <TopTeams />
      </div>
      <div className="mt-4">
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="text-xl bold mr-3 text-twire-light">
              {t("words.lastMatches")}
            </span>
          </div>
          {/* <Link to="/" className="ml-auto">
            <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
          </Link> */}
        </div>
        <LastMatches />
      </div>
    </div>
  </div>
);

export default Article;
