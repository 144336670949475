export const CACHE_TIME = {
  MINUTE: 1,
  HOUR: 60,
  DAY: 26 * 60,
};

/**
 * Returns cache key
 *
 * @param variables
 * @returns
 */
export const getCacheKey = (query: string, variables: any): string => {
  if (typeof variables !== 'object' || variables === null) {
    return '';
  }

  // Sort keys alphabetically
  const keys = Object.keys(variables).sort();

  // Generate key by joining sorted keys and corresponding values
  const key: string = keys
    .map((key) => {
      const value = variables[key];
      if (typeof value === 'object') {
        // Recursively generate key for nested object
        return getCacheKey(query, value);
      } else {
        return String(value);
      }
    })
    .join('-');

  return `${getQueryName(query)}-${key}`;
};

/**
 * Returns cached data if it exists
 *
 * @param key
 * @returns
 */
export const getCachedData = (query: string, variables: any): any | null => {
  const key = getCacheKey(query, variables);
  const item = localStorage.getItem(key);

  const cachedObject: CachedData | null = item ? JSON.parse(item) : null;
  if (cachedObject && new Date(cachedObject.expire) > new Date()) {
    return cachedObject.data;
  } else {
    localStorage.removeItem(key);
  }
  return null;
};

/**
 * Sets cached data
 *
 * @param query
 * @param variables
 * @param data
 * @param cache - in minutes
 */
export const setCachedData = (query: string, variables: any, data: any, cache: number): void => {
  const key = getCacheKey(query, variables);
  const cachedData: CachedData = {
    data: data,
    expire: new Date(new Date().getTime() + cache * 60 * 1000),
  };

  localStorage.removeItem(key);
  localStorage.setItem(key, JSON.stringify(cachedData));
};

/**
 * Invalidates cached data
 *
 * @param query
 * @param varaibles
 */
export const invalidateCachedData = (invalidateCache: InvalidateCache): void => {
  const key = getCacheKey(invalidateCache.query, invalidateCache.varaibles);

  if (key) {
    for (let i = 0; i < localStorage.length; i++) {
      const localStorageKey = localStorage.key(i);

      if (localStorageKey && localStorageKey.startsWith(key)) {
        localStorage.removeItem(localStorageKey);
      }
    }
  }
};

/**
 * Returns name of the query from string
 *
 * @param query
 * @returns
 */
const getQueryName = (query: string) => {
  const regex = /^\s*query\s+(\w+)\b/;
  const match = query.match(regex);

  if (match && match.length > 1) {
    return match[1];
  } else {
    return null;
  }
};

export interface CachedData {
  data: any;
  expire: Date;
}

export interface InvalidateCache {
  query: string;
  varaibles: any;
}
