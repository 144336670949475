import React from "react";
import { mergeClasses } from "../../../../../../utility/helpers";
import cardClasses from "./Card/Card.module.scss";
import classes from "./Cards.module.scss";

const Cards: React.FC = () => (
  <div className={mergeClasses(classes.container, "appear")}>
    {Array(6)
      .fill("")
      .map((_, index) => (
        <React.Fragment key={index}>
          <div
            className={mergeClasses(cardClasses.container, "animate-pulse")}
            style={{ height: "329.54px" }}
          />
        </React.Fragment>
      ))}
  </div>
);

export default Cards;
