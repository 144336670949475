import {ActionCreator, AnyAction} from "redux";
import {FrogotPassword, ResendVerifyEmail, ResetPassword, SignIn, SignUp, VerifyEmail} from "./types";
import * as actionTypes from "./actionTypes";

export const signIn: ActionCreator<SignIn> = (
    username: string,
    password: string,
    recaptchaToken: string
) => ({
    type: actionTypes.AUTH_SIGN_IN,
    payload: {
        username,
        password,
        recaptchaToken,
    },
});

export const signOut = (): AnyAction => ({
    type: actionTypes.AUTH_SIGN_OUT,
});

export const signUp: ActionCreator<SignUp> = (
    username: string,
    password: string,
    email: string,
    newsletter: boolean,
    recaptchaToken: string
) => ({
    type: actionTypes.AUTH_SIGN_UP,
    payload: {
        username,
        password,
        email,
        newsletter,
        recaptchaToken,
    },
});

export const forgotPassword: ActionCreator<FrogotPassword> = (
    username: string
) => ({
    type: actionTypes.AUTH_FORGOT_PASSWORD,
    payload: {
        username,
    },
});

export const resetPassword: ActionCreator<ResetPassword> = (
    username: string,
    code: string,
    password: string,
) => ({
    type: actionTypes.AUTH_RESET_PASSWORD,
    payload: {
        username,
        code,
        password,
    },
});

export const verifyEmail: ActionCreator<VerifyEmail> = (
    username: string,
    code: string,
) => ({
    type: actionTypes.AUTH_VERIFY_EMAIL,
    payload: {
        username,
        code,
    },
});

export const resendVerifyEmail: ActionCreator<ResendVerifyEmail> = (
    username: string,
) => ({
    type: actionTypes.AUTH_RESEND_VERIFY_EMAIL,
    payload: {
        username,
    },
});

export const getSignInUser = (): AnyAction => ({
    type: actionTypes.AUTH_GET_SIGN_IN_USER,
});

export const clearSignIn = (): AnyAction => ({
    type: actionTypes.AUTH_SIGN_IN_CLEAR,
});

export const clearForgotPassword = (): AnyAction => ({
    type: actionTypes.AUTH_FORGOT_PASSWORD_CLEAR,
});

export const clearResetPassword = (): AnyAction => ({
    type: actionTypes.AUTH_RESET_PASSWORD_CLEAR,
});

export const clearVerifyEmail = (): AnyAction => ({
    type: actionTypes.AUTH_VERIFY_EMAIL_CLEAR,
});
