import classes from "./PlayerStats.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const PlayerStatsPlaceholder: React.FC = () => (
  <div className={mergeClasses(classes.placeholder)}>
    <div className="animate-pulse" />
    <div className="mx-3 animate-pulse" />
    <div className="mr-3 animate-pulse" />
    <div className="animate-pulse" />
  </div>
);

export default PlayerStatsPlaceholder;
