import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import TournamentsTile from "@twire/common/TournamentTile";
import { useUrl } from "@twire/utility/hooks";
import { urlFriendlyString } from "@twire/utility/helpers";
import LineText from "@twire/common/LineText";
import Text from "@twire/common/Text";
import classes from "./Tournaments.module.scss";

const Tournaments: React.FC = () => {
  const {
    search: { tournaments },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const [reveal, setReveal] = useState(false);
  const url = useUrl(`/${game}/tournaments/tournament/`);
  return tournaments && tournaments?.length > 0 ? (
    <div>
      <p className="rift-bold text-twire-light my-4">
        <Text i18nKey="words.tournaments">tournaments</Text>
      </p>
      <div className={classes.resultsGrid}>
        {(reveal ? tournaments : tournaments.slice(0, 4))?.map((data, i) => (
          <Link to={`${url}${data.id}/${urlFriendlyString(data.name)}`} key={i}>
            <TournamentsTile {...data} />
          </Link>
        ))}
      </div>
      <LineText className="mt-3">
        <button
          className="whitespace-nowrap"
          onClick={() => {
            setReveal(!reveal);
          }}
        >
          {reveal ? "Show Less" : "Show More"}
        </button>
      </LineText>
    </div>
  ) : (
    <></>
  );
};

export default Tournaments;
