import { Link, Redirect, Switch, Route } from "react-router-dom";
import LastMatches from "@twire/common/LastMatches";
import { URLS } from "@twire/utility/constants";
import { t, mergeClasses, getFullRoute } from "@twire/utility/helpers";
import ArrowSpan from "@twire/common/ArrowSpan";
import TopTeams from "@twire/common/TopTeams";
import TeamInfo from "./TeamInfo";
import TeamDataNav from "./TeamDataNav";
import Tournaments from "./Tournaments";
import Matches from "./Matches";
import Statistics from "./Statistics";
import classes from "./Team.module.scss";

const Team: React.FC<{ buildUrl: (url: string) => string }> = ({
  buildUrl,
}) => (
  <div className={mergeClasses(classes.container, "appear")}>
    <div className={classes.left}>
      <TeamInfo />
      <div className="my-4">
        <TeamDataNav />
      </div>
      <div>
        <Switch>
          <Route
            path={getFullRoute(URLS.TEAM_TOURNAMENTS)}
            component={Tournaments}
          />
          <Route path={getFullRoute(URLS.TEAM_MATCHES)} component={Matches} />
          <Route
            path={getFullRoute(URLS.TEAM_STATISTICS)}
            component={Statistics}
          />
          <Redirect to={getFullRoute(URLS.TEAM_STATISTICS)} />
        </Switch>
      </div>
    </div>
    <div className={classes.right}>
      <div>
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="text-xl bold mr-3 text-twire-light">
              {t("words.topTeams")}
            </span>
          </div>
          <Link to={buildUrl(URLS.TEAM_RANKING)} className="ml-auto">
            <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
          </Link>
        </div>
        <TopTeams />
      </div>
      <div className="mt-4">
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="text-xl bold mr-3 text-twire-light">
              {t("words.lastMatches")}
            </span>
          </div>
          {/* <Link to="/" className="ml-auto">
            <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
          </Link> */}
        </div>
        <LastMatches />
      </div>
    </div>
  </div>
);

export default Team;
