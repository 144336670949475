import {
  mergeClasses,
  setDefaultTournamentLogo,
  setTournamentLogo,
} from "@twire/utility/helpers";
import { t } from "@twire/utility/helpers";
// import { Link } from "react-router-dom";
import ArrowSpan from "@twire/common/ArrowSpan";
import { formatter } from "@twire/utility/helpers";
import { TournamentTileData } from "./types";

import { ReactComponent as RankSvg } from "../../../assets/svgs/arena.svg";
// import { ReactComponent as LeaderboardSvg } from "../../../assets/svgs/leaderboard.svg";
import { ReactComponent as Aim } from "../../../assets/svgs/aim.svg";
import { ReactComponent as Dollar } from "../../../assets/svgs/dollar.svg";

import classes from "./TournamentTile.module.scss";

const TournamentTile: React.FC<TournamentTileData> = ({
  name,
  location,
  tier,
  prize,
  currency,
  badge,
  shard,
  game,
  externalClassName,
}) => (
  <div
    className={mergeClasses(
      classes.container,
      "flex background-twire-grey p-5",
      "cursor-pointer",
      externalClassName || ""
    )}
  >
    <div
      className={mergeClasses("flex items-center w-full", classes.tileContent)}
    >
      <div className={mergeClasses("flex", classes.tileCol1)}>
        <img
          src={setTournamentLogo(game, badge || shard)}
          alt="vietnam"
          className={classes.tournamentImg}
          onError={setDefaultTournamentLogo}
        />
        <div className={mergeClasses("ml-3", classes.colInner)}>
          <p className="text-2xl text-twire-light font-bold">{name}</p>
          <div className={mergeClasses("flex", classes.tournamentDetail)}>
            <div className="flex items-center">
              <RankSvg />
              <span className="ml-2 text-twire-light open-sans capitalize">
                {t("words.tier")}{" "}
                <span className="uppercase open-sans">{tier || "N/A"}</span>
              </span>
            </div>
            <div className="flex items-center">
              <Dollar />
              <span className="ml-2 text-twire-light open-sans">
                {prize > 0
                  ? formatter(currency).format(prize).replace(/\.00/g, "")
                  : "N/A"}
              </span>
            </div>
            <div className="flex items-center">
              <Aim />
              <span className="ml-2 text-twire-light open-sans uppercase">
                {location}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={mergeClasses(
          "ml-auto flex items-center",
          classes.detailContent
        )}
      >
        {/* <Link to="/" className="mr-4">
          <LeaderboardSvg />
        </Link> */}
        {/* <Link to="/"> */}
        <ArrowSpan>{t("words.details")}</ArrowSpan>
        {/* </Link> */}
      </div>
    </div>
  </div>
);

export default TournamentTile;
