import { mergeClasses } from "@twire/utility/helpers";

const Line: React.FC<{ className?: string }> = (props) => (
  <hr
    className={mergeClasses("opacity-20", props.className || "")}
    {...props}
  />
);

export default Line;
