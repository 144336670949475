import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { urlFriendlyString } from "@twire/utility/helpers";
import { useUrl } from "@twire/utility/hooks";
import { RootReducer } from "@twire/redux/types";
import ArrowSpan from "@twire/common/ArrowSpan";
import Text from "@twire/common/Text";

import TournamentTile from "./TournamentTile";
import classes from "./Tournaments.module.scss";

const Tournaments: React.FC<{
  onTileClick: () => void;
  onViewMoreClicked: () => void;
}> = ({ onTileClick, onViewMoreClicked }) => {
  const {
    search: { tournaments, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}/tournaments/tournament/`);
  return requestStates.success && tournaments && tournaments.length === 0 ? (
    <>
      <span className="text-twire-light">
        <Text i18nKey="words.tournaments"></Text> ({tournaments.length})
      </span>
      <div className="text-twire-red open-sans font-bold text-xs mt-1">
        No tournaments found
      </div>
    </>
  ) : !tournaments ? (
    <></>
  ) : requestStates.success && tournaments && tournaments.length > 0 ? (
    <>
      <div className="flex">
        <span className="text-twire-light">
          <Text i18nKey="words.tournaments"></Text> ({tournaments.length})
        </span>
        {tournaments.length > 2 && (
          <div className="ml-auto rift-bold text-sm">
            <ArrowSpan onClick={onViewMoreClicked}>
              <Text i18nKey="words.viewMore"></Text>
            </ArrowSpan>
          </div>
        )}
      </div>
      <div className={classes.list}>
        {tournaments.slice(0, 2).map((data, i) => (
          <Link
            to={`${url}${data.id}/${urlFriendlyString(data.name)}`}
            key={i}
            onClick={onTileClick}
          >
            <TournamentTile {...data} game={game} />
          </Link>
        ))}
      </div>
    </>
  ) : (
    <div>Error when getting tournaments</div>
  );
};

export default Tournaments;
