export const TEAM_RANKING_GET_TEAM_RANKING = 
  "TEAM_RANKING_GET_TEAM_RANKING";
export const TEAM_RANKING_GET_TEAM_RANKING_REQUESTED = 
  "TEAM_RANKING_GET_TEAM_RANKING_REQUESTED";
export const TEAM_RANKING_GET_TEAM_RANKING_SUCCESS = 
  "TEAM_RANKING_GET_TEAM_RANKING_SUCCESS";
export const TEAM_RANKING_GET_TEAM_RANKING_FAILED = 
  "TEAM_RANKING_GET_TEAM_RANKING_FAILED";
export const TEAM_RANKING_GET_TEAM_RANKING_CLEAR = 
  "TEAM_RANKING_GET_TEAM_RANKING_CLEAR";
