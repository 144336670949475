// import Expandable from "@twire/common/Expandable";
import {
  mergeClasses,
  formatter,
  setDefaultTeamLogo,
  setTeamLogo,
} from "@twire/utility/helpers";
import { PrizeTableContext } from "./context";
import PrizeTableFilter from "./PrizeTableFilter";
// import { ReactComponent as ArrowTip } from "../../../../../../../assets/svgs/arrowTip.svg";
import classes from "./PrizeTable.module.scss";
import { Games } from "@twire/utility/constants";

const getMedal = (placement: number) => {
  switch (placement) {
    case 1:
      return "gold-coin";
    case 2:
      return "silver-coin";
    case 3:
      return "bronze-coin";
  }
};

const PrizeTable: React.FC<{
  game: Games
}> = ({
  game,
}) => (
  <PrizeTableContext.Consumer>
    {({
      // revealRestOfTable, onRevealTableClicked,
      totalPrize,
      table,
    }) => (
      <>
        <div
          className={mergeClasses(
            "mb-6 flex items-center",
            classes.prizeDetails
          )}
        >
          <div className="font-bold text-twire-orange">prize distribution</div>
          <div
            className={mergeClasses(
              "font-bold text-twire-light ml-12",
              classes.prize
            )}
          >
            {totalPrize}
          </div>
          <div className="text-xs text-twire-light open-sans-i opacity-70 ml-3 mr-auto mt-1">
            is spread among the teams as seen below
          </div>
          <PrizeTableFilter />
        </div>

        <div>
          <div className={classes.wrapper}>
            <table className={classes.container}>
              <thead>
                <tr className={classes.header}>
                  <th>place</th>
                  <th>$usd</th>
                  {/* {table.data.data && table.data.data[0].prizeCoefficient && ( */}
                  {/* <th>Total Money</th> */}

                  <th>team</th>
                </tr>
              </thead>
              <tbody>
                {table.data.data &&
                  table.data.data
                    .sort((a: any, b: any) =>
                      a.placement > b.placement ? 1 : -1
                    )
                    .map(
                      ({
                        placement,
                        prize,
                        teamName,
                      }: // prizeCoefficient,
                      {
                        placement: any;
                        prize: number;
                        teamName: string;
                        prizeCoefficient: number;
                      }) => (
                        <tr
                          key={placement}
                          className={mergeClasses(
                            classes.row
                            // classes.fourCols
                          )}
                        >
                          <td>
                            #{placement}{" "}
                            {placement < 4 && (
                              <img
                                src={`/images/icons/${getMedal(placement)}.png`}
                                alt="gold-medal"
                                className="ml-4"
                              />
                            )}
                          </td>
                          <td>
                            {prize
                              ? formatter("usd").format(prize)
                              : formatter("usd").format(0)}
                          </td>
                          {/* {table.data.data &&
                            table.data.data[0].prizeCoefficient && ( */}
                          {/* <td>
                            {prizeCoefficient
                              ? formatter("usd").format(prizeCoefficient)
                              : formatter("usd").format(0)}
                          </td> */}
                          {/* )} */}
                          {teamName ? (
                            <td className={classes.teamName}>
                              <img
                                src={setTeamLogo(game, teamName)}
                                onError={setDefaultTeamLogo}
                                alt="team"
                                className="mr-3 mb-1"
                              />
                              {teamName}
                            </td>
                          ) : (
                            <td>TBD</td>
                          )}
                        </tr>
                      )
                    )}
              </tbody>
            </table>
          </div>

          {/* <div className="text-center mt-6">
            <button
              onClick={onRevealTableClicked}
              className="flex items-center mx-auto font-bold"
            >
              <span className="mr-3 text-twire-light opacity-30">
                {!revealRestOfTable ? "place 4 to 16" : "place 1 to 3"}
              </span>
              <ArrowTip />
            </button>
          </div> */}
        </div>
      </>
    )}
  </PrizeTableContext.Consumer>
);

export default PrizeTable;
