import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { Subscription, TwireUrl } from "@twire/utility/helpers";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { subscribeToPlan } from "@twire/redux/subscriptions/actions";
// import { initialValues } from "./constants";
import SubscriptionForm from "./SubscriptionForm";

const SubscriptionFormContainer: React.FC = () => {
  const [requesting, setRequesting] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [cardError, setCardError] = useState<any>(null);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(
    TwireUrl.get("sub")
  );
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: { userSubscription, requestStates: userRequestStates, signedIn },
    subscriptions: { requestStates },
  } = useSelector((state: RootReducer) => state);

  const onCardChange = (e: StripeCardElementChangeEvent) => {
    if (e.complete) {
      setCardComplete(e.complete);
    } else {
      if (cardComplete) setCardComplete(false);
    }
    if (e.error) {
      setCardError(e.error);
    } else {
      if (cardError) setCardError(null);
    }
  };

  const onSubscribe = async () => {
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardComplete && cardElement) {
        setRequesting(true);
        await stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
          })
          .then((result) => {
            // console.log(result.paymentMethod);
            if (result.paymentMethod && subscriptionPlanId) {
              dispatch(
                subscribeToPlan(subscriptionPlanId, result.paymentMethod)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setRequesting(false));
      }
    }
  };

  useEffect(() => {
    setSubscriptionPlanId(TwireUrl.get("sub"));
  }, [history.location]);

  const unSubscribe = async () => {
    await Subscription.cancel();
  };

  useEffect(() => {
    if (requestStates.subscribeUser.success) {
      if (TwireUrl.get("sub")) {
        TwireUrl.delete("sub");
      }
    }
  }, [requestStates.subscribeUser]);

  return userRequestStates.userSubscription.requesting && signedIn ? (
    <div className="flex items-center justify-center h-36 appear">
      <img
        src="/images/animated/loader.gif"
        style={{ height: "25px" }}
        alt="loading"
      />
    </div>
  ) : (
    <SubscriptionForm
      requesting={
        requesting ||
        requestStates.subscribeUser.requesting ||
        requestStates.unsubscribeUser.requesting
      }
      onSubmit={onSubscribe}
      unSubscribe={unSubscribe}
      onCardChange={onCardChange}
      subscribed={!!userSubscription.status}
      signedIn={signedIn && !userRequestStates.signIn.requesting}
      startDate={userSubscription.validFrom || ""}
      endDate={userSubscription.validTo || ""}
      errorPaying={
        userSubscription.status
          ? requestStates.unsubscribeUser.failed
          : requestStates.subscribeUser.failed
      }
      subscriptionPlanId={subscriptionPlanId || ""}
      subscriptionId={userSubscription.subscriptionId || ""}
    />
  );
};

export default SubscriptionFormContainer;
