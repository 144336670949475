import { TogglerContainerProps } from "./types";
import Toggler from "./Toggler";

const TogglerContainer: React.FC<TogglerContainerProps> = ({
  onToggleOn,
  onToggleOff,
  on,
}) => {
  const onClick = () => {
    if (on) {
      onToggleOff();
    } else {
      onToggleOn();
    }
  };
  return <Toggler on={on} onClick={onClick} />;
};

export default TogglerContainer;
