import { PlayerStatKey, PlayerRankingSortType } from "../types";
import PlayerRankingHeader from "./PlayerRankingHeader";

const PlayerRankingHeaderContainer: React.FC<{
  setSortby: (key: PlayerStatKey) => void;
  sortedBy: PlayerRankingSortType;
}> = ({ setSortby, sortedBy }) => {
  return <PlayerRankingHeader setSortby={setSortby} sortedBy={sortedBy} />;
};

export default PlayerRankingHeaderContainer;
