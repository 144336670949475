import {
  mergeClasses,
  setDefaultTournamentLogo,
  setTournamentLogo,
  t,
  formatter,
} from "@twire/utility/helpers";
import { ReactComponent as ArrowTip } from "../../../../../assets/svgs/arrow-tip-right.svg";
import { TournamentTileData } from "../../../TournamentTile/types";
import classes from "./TournamentTile.module.scss";

const TournamentTile: React.FC<TournamentTileData> = ({
  name,
  location,
  tier,
  prize,
  currency,
  badge,
  shard,
  game,
}) => (
  <div className={mergeClasses(classes.container, "background-twire-grey")}>
    <img
      className={classes.logo}
      src={setTournamentLogo(game, badge || shard)}
      alt="tournament"
      onError={setDefaultTournamentLogo}
    />
    <div>
      <div className="text-twire-orange rift-bold text-xl">{name}</div>
      <div className="text-twire-light opacity-70 open-sans text-xs">
        {`${t("words.tier")} ${tier ? `${tier}` : ""}${
          prize > 0
            ? `, ${formatter(currency)
                .format(prize)
                .replace(/\.00/g, "")} prize pool`
            : ""
        }${location ? `, ${location}` : ""}`}
      </div>
    </div>
    <ArrowTip className="ml-auto" />
  </div>
);

export default TournamentTile;
