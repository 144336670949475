const isProd = process.env.REACT_APP_STAGE === "prod";

type Config = {
  url: string;
  public_key: string;
};

let recaptchaConfig: Config;

if (isProd) {
  recaptchaConfig = {
    url: "https://recaptcha.twire.gg",
    public_key: "6LepzcIkAAAAAFSQiXPEa1Z1IjK-rwAyp8E7c5u3",
  };
} else {
  recaptchaConfig = {
    url: "http://52.49.1.96:40002",
    public_key: "6Lcq_b0kAAAAACFIIsh4C_8dxI74A7r65Pqu5MCY",
  };
}

export default recaptchaConfig;
