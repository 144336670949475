import AuthPage from "@twire/common/AuthPage";
import { ReactComponent as SuccessSVG } from "../../../assets/svgs/success.svg";
import TwireButton from "@twire/common/TwireButton";
import TwireLink from "@twire/common/TwireLink";
import Text from "@twire/common/Text";

const Success: React.FC<{
  error: boolean;
  success: boolean;
  username: string;
  resendLoading: boolean;
  resendSuccess: boolean;
  resendFailed: any;
  onResendVerificationEmail: (username: string) => void;
}> = ({
  success,
  error,
  username,
  resendLoading,
  resendSuccess,
  resendFailed,
  onResendVerificationEmail,
}) => {
  console.log('status:')
  console.log(resendSuccess)
  console.log(resendFailed)
  return (
    <AuthPage>
      {success ? (
        <div className="flex flex-col items-center">
          <SuccessSVG className="mb-4" />
          <h1><Text i18nKey="words.success">Success</Text></h1>
          <h6 className="mb-0 open-sans text-sm text-center">
            <Text i18nKey="sentences.verificationSuccess">
              Verification completed! You may login now!
            </Text>
          </h6>
          <TwireLink to="/login" className="w-full">
            <TwireButton>
              <Text i18nKey="login.loginText">login</Text>
            </TwireButton>
          </TwireLink>
        </div>
      ) : error ? (
        <div>
          {
            resendSuccess ?
            <div className="success-message text-center">
              <Text i18nKey="sentences.resendVerificationEmailSuccess">
                Verification email successfully resend
              </Text>
            </div>
            :
            resendFailed ?
            <div className="error-message text-center">
              { resendFailed.message }
            </div>
            :
            <div className="error-message text-center">
              <Text i18nKey="sentences.verificationFailed">
                Verification failed! Your link is broken, expired or already been verified.
              </Text>
            </div>
          }
          <TwireButton
            className="mt-4"
            onClick={() => onResendVerificationEmail(username)}
            loading={resendLoading}
          >
            <Text i18nKey="sentences.resendVerificationEmail">
              Resend verification email
            </Text>
          </TwireButton>
        </div>
      ) : (
        <div className="text-center ml-auto flex items-center">
          <img
            src="/images/animated/loader.gif"
            style={{ height: "30px" }}
            alt="loading"
            className="mx-auto"
          />
        </div>
      )}
    </AuthPage>
  );
};

export default Success;
