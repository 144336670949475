import React from "react";
import classes from "./Placeholder.module.scss";
import mainClasses from "../../NewsTile/NewsTile.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const Placeholder: React.FC<{ short?: boolean }> = ({ short }) => (
  <div
    className={mergeClasses(
      classes.container,
      mainClasses.container,
      short ? `${mainClasses.short} ${classes.short}` : ""
    )}
  >
    <div className={mainClasses.top} />
    <div
      className={mergeClasses(
        "h-full flex flex-col justify-between",
        mainClasses.inner
      )}
    >
      <div className="mt-auto">
        <div className={classes.headline} />
        <div className={classes.caption} />
      </div>
      <div className={mergeClasses(mainClasses.base, classes.base)}>
        <span className={mergeClasses("flex items-center", classes.left)} />
        <span className={mergeClasses("ml-auto", classes.right)} />
      </div>
    </div>
  </div>
);

export default Placeholder;
