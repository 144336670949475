import { mergeClasses } from "@twire/utility/helpers";
import TournaentFilter from "../TournamentsFilter";
import TournamentsTemplate from "../TournamentsTemplate";
import LastMatches from "@twire/common/LastMatches";
import TopTeams from "@twire/common/TopTeams";
import { t } from "@twire/utility/helpers";
import classes from "./MobileMainSection.module.scss";

const MobileMainSection: React.FC<{
  index: number;
  onClick: (arg: number) => void;
  dropdownOpen: boolean;
  setDropdownOpen: (value: boolean) => void;
}> = ({ index, onClick, dropdownOpen, setDropdownOpen }) => (
  <div className={classes.container}>
    <div
      className={mergeClasses(
        "flex mb-4",
        dropdownOpen ? "" : "overflow-x-auto",
        classes.navWrapper
      )}
    >
      <div
        className={mergeClasses(
          "text-twire-light pr-7 font-bold text-xl flex items-center whitespace-nowrap",
          [index === 0, "text-twire-orange underline"]
        )}
      >
        <span className="mr-2" onClick={() => onClick(0)}>
          {t("words.ongoingTournaments")}
        </span>{" "}
        <TournaentFilter
          type="ongoing"
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
      </div>
      <div
        className={mergeClasses(
          "text-twire-light pr-7 font-bold text-xl flex items-center whitespace-nowrap",
          [index === 1, "text-twire-orange underline"]
        )}
        onClick={() => onClick(1)}
      >
        {t("words.lastMatches")}
      </div>
      <div
        className={mergeClasses(
          "text-twire-light pr-7 font-bold text-xl flex items-center whitespace-nowrap",
          [index === 2, "text-twire-orange underline"]
        )}
        onClick={() => onClick(2)}
      >
        {t("words.topTeams")}
      </div>
    </div>
    <>
      {index === 0 && <TournamentsTemplate type="ongoing" />}
      {index === 1 && <LastMatches />}
      {index === 2 && <TopTeams />}
    </>
  </div>
);

export default MobileMainSection;
