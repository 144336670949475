import Cookies from "js-cookie";
import { AnyAction } from "redux";
import { defaultUserSubscription, PREMIUM_USER_COOKIE } from "../constants";
import { UserStateInterface } from "../types";

export const requested = (state: UserStateInterface): UserStateInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    userSubscription: {
      failed: false,
      requesting: true,
      success: false,
    },
  },
});

export const success = (
  state: UserStateInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: AnyAction
): UserStateInterface => {
  if (action.premium && action.premium.status) {
    Cookies.set(PREMIUM_USER_COOKIE, "true");
  } else {
    Cookies.remove(PREMIUM_USER_COOKIE);
  }
  return {
    ...state,
    userSubscription: action.premium,
    requestStates: {
      ...state.requestStates,
      userSubscription: {
        failed: false,
        requesting: false,
        success: true,
      },
    },
  }
};

export const failed = (
  state: UserStateInterface,
  action: AnyAction
): UserStateInterface => ({
  ...state,
  // userSubscription: { ...defaultUserSubscription },
  requestStates: {
    ...state.requestStates,
    userSubscription: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: UserStateInterface): UserStateInterface => ({
  ...state,
  userSubscription: { ...defaultUserSubscription },
  requestStates: {
    ...state.requestStates,
    userSubscription: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
