import { DateTime } from "luxon";
import { DATE_PERIOD_IN_MONTHS } from "./constants";
import { TeamStatistic } from "../../../../../../PublicAPI";
import { ChartsData, ChartData, TimeTournamentMap } from "./Charts/types";

const calcMax = (value: number) => {
  const v = value;
  const vString = Math.trunc(v).toString();
  const factor = `1${Array(vString.length - 1)
    .fill("0")
    .join("")}`;
  return Math.ceil(v / Number(factor)) * Number(factor);
};

const getTicks = (start: number, end: number): number[] => {
  const final: number[] = [];
  const then = DateTime.fromMillis(start).toMillis();
  const now = DateTime.fromMillis(end).toMillis();
  let current = then;
  while (current < now) {
    final.push(current);
    current = DateTime.fromMillis(current).plus({ months: 1 }).toMillis();
  }
  return final;
};

export const formatStatisitcsToChartData = (
  statistics: TeamStatistic[]
): ChartsData[] => {
  let min = DateTime.now().toMillis();
  const max = DateTime.now().startOf("month").endOf("month").toMillis();
  const timeTournamentaMap: TimeTournamentMap = {};

  return statistics.map((data) => {
    let yMax = 0;
    const stats: ChartData[] = [];
    data.perTournamentValue.forEach((stat) => {
      if (stat.value > yMax) {
        yMax = stat.value;
      }

      if (stat.tournament.stopDate) {
        const time = DateTime.fromSQL(stat.tournament.stopDate);
        if (time.toMillis() < min) {
          min = time.startOf("month").toMillis();
        }
        const timeInMillis = time.toMillis();
        if (
          timeTournamentaMap[`${timeInMillis}-${data.statisticName}`] &&
          !timeTournamentaMap[`${timeInMillis}-${data.statisticName}`].find(
            (t) => t.tournament.id === stat.tournament.id
          )
        ) {
          timeTournamentaMap[`${timeInMillis}-${data.statisticName}`].push({
            value: stat.value,
            tournament: stat.tournament,
          });
        } else {
          timeTournamentaMap[`${timeInMillis}-${data.statisticName}`] = [
            { value: stat.value, tournament: stat.tournament },
          ];
        }

        stats.push({
          name: stat.tournament.friendlyName,
          tournament: stat.tournament,
          y: stat.value,
          x: stat.tournament.stopDate
            ? DateTime.fromSQL(stat.tournament.stopDate).toMillis()
            : max,
        });
      }
    });

    // Responsible for limiting the data to last X months and sorting it by start date
    const ticks = getTicks(min, max).slice(-1 * DATE_PERIOD_IN_MONTHS);
    const filteredStats = stats
      .filter((statValue) => statValue.x >= ticks[0])
      .sort((a, b) => (a.x > b.x ? 1 : -1));

    return {
      ticks,
      timeTournamentaMap,
      statisticName: data.statisticName,
      overall: data.overallValue,
      min: ticks[0],
      max: ticks[ticks.length - 1],
      stats: filteredStats,
      yMax: calcMax(yMax),
    };
  });
};
