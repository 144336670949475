import React from "react";

import { MapType } from "./types";
import AdvancedTable from "../Stats/AdvancedTable";
// import { mergeClasses } from "@twire/utility/helpers";
// import classes from "./TeamStats.module.scss";

const TeamStats: React.FC<{
  stats: any;
  mapTypes: MapType[];
  map: string;
  setMap: (m: string) => void;
  headers: any;
  onResizeClick: () => void;
  fullscreen: boolean;
}> = ({
  stats,
  // mapTypes,
  // map,
  // setMap,
  headers,
  onResizeClick,
  fullscreen,
}) => (
  <div className="mt-5 h-full">
    {/* <div className={classes.maps}>
      {mapTypes.map(({ key, name }) => (
        <button
          className={mergeClasses([map === key, classes.active])}
          key={key}
          onClick={() => setMap(key)}
        >
          {name}
        </button>
      ))}
    </div> */}
    <div className="mb-3" />
    <AdvancedTable
      headers={headers}
      stats={stats}
      colHeading={{ value: "Team Name", id: "", sortId: "teamName" }}
      onResizeClick={onResizeClick}
      fullscreen={fullscreen}
    />
  </div>
);

export default TeamStats;
