/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveFile = /* GraphQL */ `
  mutation SaveFile($file: FileInput, $game: String!) {
    saveFile(file: $file, game: $game)
  }
`;
export const saveHighlights = /* GraphQL */ `
  mutation SaveHighlights($fileId: Int!, $highlights: [HighlightInput!]!) {
    saveHighlights(fileId: $fileId, highlights: $highlights) {
      fileId
      isPublic
      filename
      highlights {
        time
        comment
        drawings
        sub
        username
      }
    }
  }
`;
export const savePubgUsername = /* GraphQL */ `
  mutation SavePubgUsername($pubgUsername: String, $pubgUsernameId: Int) {
    savePubgUsername(
      pubgUsername: $pubgUsername
      pubgUsernameId: $pubgUsernameId
    ) {
      id
      pubgUsername
      pubgUsernameDone
      pubgUsernameError
    }
  }
`;
export const saveEmailNotifications = /* GraphQL */ `
  mutation SaveEmailNotifications($emailNotifications: Boolean!) {
    saveEmailNotifications(emailNotifications: $emailNotifications)
  }
`;
export const renameFile = /* GraphQL */ `
  mutation RenameFile($fileId: Int!, $filename: String!) {
    renameFile(fileId: $fileId, filename: $filename)
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($fileId: Int!) {
    deleteFile(fileId: $fileId)
  }
`;
export const savePayment = /* GraphQL */ `
  mutation SavePayment($subscriptionId: String!) {
    savePayment(subscriptionId: $subscriptionId)
  }
`;
export const makeFilePublic = /* GraphQL */ `
  mutation MakeFilePublic($fileId: Int!) {
    makeFilePublic(fileId: $fileId)
  }
`;
export const collaboration = /* GraphQL */ `
  mutation Collaboration($fileId: Int!, $collaborationInput: AWSJSON!) {
    collaboration(fileId: $fileId, collaborationInput: $collaborationInput) {
      fileId
      settings
    }
  }
`;
export const usePromoCode = /* GraphQL */ `
  mutation UsePromoCode($code: String!) {
    usePromoCode(code: $code)
  }
`;
export const updateTournamentTeamName = /* GraphQL */ `
  mutation UpdateTournamentTeamName(
    $tournamentId: Int!
    $igns: [String!]!
    $teamName: String!
  ) {
    updateTournamentTeamName(
      tournamentId: $tournamentId
      igns: $igns
      teamName: $teamName
    )
  }
`;
export const addComment = /* GraphQL */ `
  mutation AddComment(
    $belongsTo: String
    $belongsToId: Int!
    $content: String!
    $game: String!
    $parentId: Int
  ) {
    addComment(
      belongsTo: $belongsTo
      belongsToId: $belongsToId
      content: $content
      game: $game
      parentId: $parentId
    ) {
      content
      id
      insertedAt
      parentId
      updatedAt
      username
    }
  }
`;
export const saveFantasyTeam = /* GraphQL */ `
  mutation SaveFantasyTeam(
    $game: String!
    $tournamentId: Int!
    $round: String
    $group: String
    $players: [FantasyPlayerInput!]!
  ) {
    saveFantasyTeam(
      game: $game
      tournamentId: $tournamentId
      round: $round
      group: $group
      players: $players
    ) {
      players {
        id
        playerName
        teamName
        price
        substitute
        totalPoints
        kills
        killPoints
        damage
        damagePoints
        survives
        survivePoints
        diedQuickly
        diedQuicklyPoints
        captainPoints
        captain
        active
      }
      totalPrice
      totalPoints
      placement
    }
  }
`;
export const createFantasyPrivateLeaderboard = /* GraphQL */ `
  mutation CreateFantasyPrivateLeaderboard(
    $game: String!
    $tournamentId: Int!
    $name: String!
    $password: String!
  ) {
    createFantasyPrivateLeaderboard(
      game: $game
      tournamentId: $tournamentId
      name: $name
      password: $password
    ) {
      id
      name
      fantasyUsers {
        userId
        placement
        nickname
        prize
        points
      }
    }
  }
`;
export const joinFantasyPrivateLeaderboard = /* GraphQL */ `
  mutation JoinFantasyPrivateLeaderboard(
    $game: String!
    $privateLeaderboardId: String!
    $password: String!
  ) {
    joinFantasyPrivateLeaderboard(
      game: $game
      privateLeaderboardId: $privateLeaderboardId
      password: $password
    ) {
      id
      name
      fantasyUsers {
        userId
        placement
        nickname
        prize
        points
      }
    }
  }
`;
