/**
 * This file is symlinked and its contents can be imported with
 * import { someFunction } from "@twire/helpers";
 */

import { DateTime } from "luxon";
import i18next, { TFunction } from "i18next";
// import { TOptions, StringMap } from "i18next";
import {
  Games,
  GeneralTournamentFormat,
  LanguagePath,
  Path,
  PositionChange,
  Theme,
  TWIRE_ASSETS_URL,
} from "@twire/utility/constants";
import * as Yup from "yup";
import i18n from "../../i18n";
import { ChildTournamentFilter } from "../../PublicAPI";
import {
  TournamentStatsFilter,
  TournamentStatsFilterType,
} from "../../redux/tournament/types";
import { isString } from "formik";

export type ConditionalClass = [
  boolean,
  ...(string | undefined | ConditionalClass)[]
];

/**
 * This function allows you to merge sevral classNames together.
 * For conditional classes, just pass in the condition (boolean)
 * as the first elemnt in the array and pass the classes you want
 * to merge as the other items in the array.
 */
export const mergeClasses = (
  ...args: (string | undefined | ConditionalClass)[]
): string => {
  return args.reduce((accumulator: string, currentValue) => {
    if (Array.isArray(currentValue)) {
      const bool = currentValue.shift();
      if (bool)
        return `${accumulator} ${mergeClasses(
          ...(currentValue as (string | undefined | ConditionalClass)[])
        )}`;
      return accumulator;
    }
    if (!currentValue) return accumulator;
    return `${accumulator ? `${accumulator} ` : ""}${currentValue}`;
  }, "");
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const t = (key: Path<LanguagePath>, options?: any): any =>
  i18n.t.call(i18n, key, options);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSchema = (t: TFunction) => ({
  username: Yup.string()
    .min(3, t("sentences.shortUsername"))
    .max(30, t("sentences.longUsername"))
    .required(t("sentences.requiredUsername")),
  password: Yup.string()
    .min(8, t("sentences.shortPassword"))
    .required(t("sentences.requiredPassword")),
});

export const url = (urlString: string): string =>
  `/${i18n.language}${urlString}`;

export const formatTime = (time: string): string => {
  const newTime = DateTime.fromISO(time, { locale: i18next.language });
  return `${
    newTime.toLocaleString(DateTime.DATE_MED).split(",")[0]
    } - ${newTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
};

export const formatDate = (date: string): string => {
  const newTime = DateTime.fromISO(date, { locale: i18next.language });
  return `${newTime.toLocaleString(DateTime.DATE_MED)}`;
};

export const formatter = (currency: string): Intl.NumberFormat =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });

export const fomratToDecimalPlaces = (value: number, decPlaces = 2): number => {
  let factor = "1";
  for (let i = 0; i < decPlaces; i++) {
    factor += "0";
  }
  return !(value % 1)
    ? value
    : Number((Math.round(value * Number(factor)) / Number(factor)).toFixed(2));
};

export const formatSecondsToTime = (seconds: number): string => {
  const time = new Date(0);
  time.setSeconds(seconds);
  return `${time.toISOString().substr(11, 8)}`;
};

export const shuffle = <T>(array: T[]): T[] => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const replaceEmpty = <T>(value: T): T | string => value || "N/A";

export const formatTournamentFilter = (
  shard: string | null,
  parent: string | null,
  child: string | null
): string => {
  return `${shard ? `${shard}` : ""}${parent ? `-${parent}` : ""}${
    child ? `-${child}` : ""
    }`;
};

export const truncate = (input: string, length: number): string =>
  input.length > length ? `${input.substring(0, length)}...` : input;

export const urlFriendlyString = (name: string): string => {
  try {
    return name
      .replaceAll("-", "")
      .replaceAll(" ", "-")
      .replace(/[^A-Za-z0-9\-_.]+/g, "")
      .toLowerCase();
  } catch (err) {
    return "";
  }
};

export class TwireUrl {
  static params = new URLSearchParams(
    new URL(window.location.href.replace("#!", "")).search
  );

  static appendParams(url: string): string {
    const params = new URLSearchParams(
      new URL(window.location.href.replace("#!", "")).search
    );
    return params ? `${url}?${params}` : url;
  }

  static set(key: string, value: string): void {
    TwireUrl.params.set(key, value);
    window.history.replaceState(
      { [key]: value },
      "",
      "?" + TwireUrl.params.toString()
    );
  }

  static append(key: string, value: string): void {
    const pre = TwireUrl.params.toString() ? "" : "?";
    TwireUrl.params.append(key, value);
    window.history.replaceState(null, "", pre + TwireUrl.params.toString());
  }

  static delete(key: string): void {
    TwireUrl.params.delete(key);
    window.history.replaceState(null, "", "?" + TwireUrl.params.toString());
  }

  static get(key: string, useUnreliableMethod?: boolean): string | null {
    const url = window.location.href;
    const match = url.match("[?&]" + key + "=([^&]+)");
    if (useUnreliableMethod) {
      if (match && match.length > 1) {
        return match[1];
      }
    }
    return match ? decodeURIComponent(match[1]) : null;
  }
}

export const getUrlStatsFilterParam = (
  filterType: TournamentStatsFilterType.MAPS | TournamentStatsFilterType.MATCHES
): TournamentStatsFilter[] => {
  return (
    TwireUrl.get(filterType)
      ?.split(",")
      .map((m) => {
        return {
          key:
            filterType === TournamentStatsFilterType.MATCHES ? parseInt(m) : m,
          value: "",
          selected: true,
        };
      }) || []
  );
};

export const roundToX = (num: number, X: number): number | string => {
  if (typeof num !== "number") return "N/A";
  return +(Math.round(Number(num.toString() + "e+" + X.toString())) + "e-" + X);
};

export const getTheme = (index: number): Theme | undefined => {
  switch (index) {
    case 0:
      return "ORANGE";
    case 1:
      return "BLUE";
    case 2:
      return "RED";
    default:
      return undefined;
  }
};

export const getPosVal = (
  positionChange: PositionChange,
  value: number
): string => {
  if (positionChange === "UP") {
    return `+ ${value}`;
  } else if (positionChange === "DOWN") {
    return `- ${value}`;
  } else {
    return "";
  }
};

export const getFullRoute = (route: string): string => `/:lang?/:game?${route}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getParent = (value: string, tournamentFilters: any) =>
  tournamentFilters?.find((a: any) => a?.value === value);

export const getFirstChild = (
  value: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tournamentFilters: any
): ChildTournamentFilter | null => {
  const parent = getParent(value, tournamentFilters);
  if (parent) {
    if (parent.children) {
      return parent.children[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getChild = (
  parentValue: string,
  childValue: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  tournamentFilters: any
): ChildTournamentFilter | null => {
  const parent = getParent(parentValue, tournamentFilters);
  if (parent) {
    if (parent.children) {
      return parent.children.find((c: ChildTournamentFilter) => {
        return c.value === childValue;
      });
    } else {
      return null;
    }
  } else {
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setTeamLogo = (game: Games, teamName: string) => {
  try {
    teamName = (isString(teamName) ? teamName : "default")
      .replaceAll(" ", "-")
      .replace(/[^A-Za-z0-9\-_.]+/g, "")
      .toLowerCase();
  } catch (err) {
    teamName = "default";
  }

  return `${TWIRE_ASSETS_URL}${game}/team-logos/${teamName}.png`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setDefaultTeamLogo = (evt: any) => {
  evt.target.src = `${TWIRE_ASSETS_URL}pubg/team-logos/default.png`;
};

export const setTournamentLogo = (
  game: Games,
  tournamentName: string
): string => {
  return `${TWIRE_ASSETS_URL}${game}/tournament-logos/${
    isString(tournamentName) ? tournamentName : "default"
    }.png`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setDefaultTournamentLogo = (evt: any) => {
  evt.target.src = `${TWIRE_ASSETS_URL}pubg/tournament-logos/default.png`;
};

export const getElementYOffset = (element: Element): number => {
  const scrollOnWindow =
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
  const rect = element.getBoundingClientRect();
  let distanceFromTopOfPage = rect.top;
  if (scrollOnWindow !== 0) {
    distanceFromTopOfPage = rect.top + scrollOnWindow;
  }

  return distanceFromTopOfPage;
};

export const getElementXOffset = (element: Element): number => {
  const scrollOnWindow =
    window.pageXOffset !== undefined
      ? window.pageXOffset
      : (document.documentElement || document.body.parentNode || document.body)
        .scrollLeft;
  const rect = element.getBoundingClientRect();
  let distanceFromTopOfPage = rect.left;
  if (scrollOnWindow !== 0) {
    distanceFromTopOfPage = rect.left + scrollOnWindow;
  }

  return distanceFromTopOfPage;
};

export const isProduction = (): boolean => {
  // return document.location.hostname === "twire.gg";
  return false;
};

/**
 * Loads Ad script
 */
export const loadAdScript = (): void => {
  const displayAds = true;
  if (displayAds && location.hostname === "twire.gg") {
    const script = document.createElement("script");
    script.src = "https://adncdnend.azureedge.net/adtags/twire.adn.js";
    script.id = "ad-script";
    document.getElementsByTagName("head")[0].append(script);

    const videoScript = document.createElement("script");
    videoScript.src =
      "https://adncdnend.azureedge.net/adn-video/Twire_ADN_Player.js";
    videoScript.id = "adnimationjs";
    videoScript.setAttribute("async", "");
    videoScript.setAttribute("data-info", "Twire_Player");
    videoScript.setAttribute("data-info-mobile", "Twire_Player");
    document.getElementsByTagName("head")[0].append(videoScript);
  }
};

/**
 * Unloads Ad script
 */
export const unloadAdScript = (): void => {
  const script = document.getElementById("ad-script");
  if (script) {
    script.remove();
  }
  const videoScript = document.getElementById("AV63d8dd51bfe299666e04c6b5");
  if (videoScript) {
    videoScript.remove();
  }
};

export const getRankClassName = (rank: number): string => {
  if (rank) {
    switch (rank) {
      case 1:
        return " rank-tile-orange";
      case 2:
        return " rank-tile-blue";
      case 3:
        return " rank-tile-red";
      default:
        return "";
    }
  } else {
    return "";
  }
};

export const generateDateInteger = (date: DateTime): number => {
  return Number(
    // `${date.year}${date.month < 10 ? `0${date.month}` : date.month}${
    //   date.day < 10 ? `0${date.day}` : date.day
    // }`
    date.year + date.month + date.day
  );
};

/**
 * Returns tournament format according to ruleset.
 * Default format is POINT_SYSTEM
 *
 * @param ruleset
 * @returns
 */
export const getGeneralTournamentFormat = (
  ruleset: string | null | undefined
): string => {
  switch (ruleset) {
    case "super22":
      return GeneralTournamentFormat.POINT_SYSTEM_22;
    case "super21":
      return GeneralTournamentFormat.MOST_CHICKEN;
    case "wwcd":
      return GeneralTournamentFormat.WWCD;
    default:
      return GeneralTournamentFormat.POINT_SYSTEM;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSlectedRound = (tournamentFilters: any) => {
  const selected = tournamentFilters.find((filter: any) => filter.selected);
  if (selected) {
    return selected;
  } else {
    return tournamentFilters[0];
  }
};

export const formatToCurrency = (value: number, currency: string): string =>
  formatter(currency).format(value).replace(/\.00/g, "");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPlayerAvatar = (name: string) => {
  return `https://twire-assets.s3.eu-west-1.amazonaws.com/pubg/players/${name}.png`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setDefaultPlayerLogo = (evt: any, filled?: boolean) => {
  const replacementImg = filled
    ? "/images/player/empty-avatar-filled.png"
    : "/images/player/empty-avatar.png";

  if (!evt.target.src.includes(replacementImg)) {
    evt.target.src = replacementImg;
  }
};

export * from "./Recaptcha";
export * from "./Subscription";
export * from './Assets';
export * from './DRKN';