import enTranslation from "@twire/locales/en/translation.json";
import { ReactComponent as pubgSvg } from "../assets/svgs/games/pubg.svg";
import { ReactComponent as pubgcSvg } from "../assets/svgs/games/pubgc.svg";

export const supportedLangsAndName = [
  { lang: "en", name: "EN" },
  { lang: "es", name: "ES" },
  { lang: "ja", name: "JA" },
  { lang: "ko", name: "KO" },
  { lang: "pt", name: "PT-BR" },
  { lang: "th", name: "TH" },
  { lang: "tr", name: "TR" },
  { lang: "vi", name: "VI" },
  { lang: "zh", name: "ZH" },
  { lang: "sl", name: "SL" },
];

export const DEFAULT_LANG = "en";

export const supportedLangs = supportedLangsAndName.map(({ lang }) => lang);

// Tournaments constants
export const TOURNAMENT_RECENT_DAYS = 14;

// Article Constants
export const ARTICLE_RECENT_DAYS = 14;

// Games constants
export const PUBG_GAME = "pubg";
export const PUBGC_GAME = "pubgc";

export const supportedGames = [
  { name: "pubg console", id: PUBGC_GAME, gameSvg: pubgSvg },
  { name: "pubg", id: PUBG_GAME, gameSvg: pubgcSvg },
];

// This ensures that when specifying language in the custom "t" function
// or Text component, there will be autocompete to prevent typos
type PathImpl<T, Key extends keyof T> = Key extends string
  ? T[Key] extends Record<string, unknown>
  ?
  | `${Key}.${PathImpl<T[Key], Exclude<keyof T[Key], keyof unknown[]>> &
  string}`
  | `${Key}.${Exclude<keyof T[Key], keyof unknown[]> & string}`
  : never
  : never;

type PathImpl2<T> = PathImpl<T, keyof T> | keyof T;

export type Path<T> = PathImpl2<T> extends string | keyof T
  ? PathImpl2<T>
  : keyof T;

export type LanguagePath = typeof enTranslation;

export const validGamesArray = [PUBG_GAME, PUBGC_GAME];

// Supported games
export enum Games {
  PUBG = "pubg",
  PUBGC = "pubgc",
}

// URL to Twire assets bucket
export const TWIRE_ASSETS_URL =
  "https://twire-assets.s3.eu-west-1.amazonaws.com/";

export interface ListConfig {
  adIndices?: number[];
  maxLength?: number;
}

export const regionMap = {
  global: "Global",
  asia: "Asia",
  eu: "Europe",
  la: "South America",
  na: "North America",
  oce: "Oceania",
  apac: "APAC",
  "": "All",
};

export const langRegionMap: { [key: string]: string } = {
  en: "eu",
  ko: "asia",
};

export const LOCAL_STORAGE_KEYS = {
  globalTournamentFilters: "global-tournament-filters",
};

export const tierMap = {
  a: "a",
  b: "b",
  c: "c",
  s: "s",
};

export type Theme = "ORANGE" | "BLUE" | "RED";

export type PositionChange = "UP" | "DOWN" | "SAME";

export const ROOT_URLS = {
  TOURNAMENTS: "/tournaments",
  TOURNAMENT_BARE: "/tournaments/tournament",
  TOURNAMENT: "/tournaments/tournament/:id/:name?",
  FANTANSY: "/fantasy",
  TEAM: "/team/:id/:team?",
  TEAM_BARE: "/team",
  RANKING: "/ranking",
};

export const URLS = {
  ...ROOT_URLS,

  // Dashboard
  ADS: "/ads.txt",
  SCRIMS: "/scrims",
  PGC_POINTS: "/pgc-points",
  NEWS: "/news",
  LOGIN: "/login",
  REGISTER: "/register",
  SEARCH: "/search/:query?",
  SEARCH_BARE: "/search",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:username/:code",
  RESET_PASSWORD_BARE: "/reset-password",
  VERIFICATION: "/verification/:username/:code",
  VERIFICATION_BARE: "/verification",
  SUBSCRIPTION: "/subscription/:planId",
  SETTINGS: "/settings",
  SUBSCRIPTION_BARE: "/subscription",
  PLANS: "/plans",

  // Tournaments
  TOURNAMENTS: ROOT_URLS.TOURNAMENTS,
  UPCOMING: `${ROOT_URLS.TOURNAMENTS}/upcoming`,
  ONGOING: `${ROOT_URLS.TOURNAMENTS}/ongoing`,
  PAST: `${ROOT_URLS.TOURNAMENTS}/past`,

  // Tournament
  TOURNAMENT: ROOT_URLS.TOURNAMENT,
  LEADERBOARD: `${ROOT_URLS.TOURNAMENT}/leaderboards`,
  MATCHES: `${ROOT_URLS.TOURNAMENT}/matches`,
  TEAM_STATS: `${ROOT_URLS.TOURNAMENT}/team-stats`,
  PLAYER_STATS: `${ROOT_URLS.TOURNAMENT}/player-stats`,

  // Fantasy
  FANTANSY_TPURNAMENTS: `${ROOT_URLS.FANTANSY}/tournaments`,
  FANTANSY_TOURNAMENT: `${ROOT_URLS.FANTANSY}/tournament/:id/:name?`,
  FANTANSY_TOURNAMENT_BARE: `${ROOT_URLS.FANTANSY}/tournament`,
  FANTANSY_TOURNAMENT_LEADERBOARD: `${ROOT_URLS.FANTANSY}/tournament/:id/:name?/leaderboard`,
  FANTANSY_TOURNAMENT_PRIVATE_LEADERBOARD: `${ROOT_URLS.FANTANSY}/tournament/:id/:name?/private-leaderboards/:id?`,
  FANTANSY_TOURNAMENT_STATISTICS: `${ROOT_URLS.FANTANSY}/tournament/:id/:name?/statistics`,
  FANTANSY_TOURNAMENT_MY_TEAM: `${ROOT_URLS.FANTANSY}/tournament/:id/:name?/my-team`,
  FANTANSY_HOW_TO_PLAY: `${ROOT_URLS.FANTANSY}/how-to-play`,

  // Team
  TEAM: ROOT_URLS.TEAM,
  TEAM_ROSTER: `${ROOT_URLS.TEAM}/roaster`,
  TEAM_TOURNAMENTS: `${ROOT_URLS.TEAM}/tournaments`,
  TEAM_MATCHES: `${ROOT_URLS.TEAM}/matches`,
  TEAM_STATISTICS: `${ROOT_URLS.TEAM}/statistics`,

  // Ranking
  TEAM_RANKING: `${ROOT_URLS.RANKING}/team-ranking`,
  POWER_RANKING: `${ROOT_URLS.RANKING}/power-ranking`,
};

export const TWIRE_SCORE_SKILLS = {
  attk: "attacker",
  fin: "finisher",
  util: "utility",
  team: "teammate",
  sur: "survivor",
  steal: "stealer",
};

export const MATCH_TYPES = {
  super21: "super21",
  super20: "super20",
  wwcd: "wwcd",
};

export const languageIcon: { [key: string]: string } = {
  en: "gb",
  es: "es",
  ja: "jp",
  ko: "kr",
  "pt": "br",
  sl: "si",
  th: "th",
  tr: "tr",
  vi: "vn",
  zh: "cn",
};

export enum GeneralTournamentFormat {
  MOST_CHICKEN = "MOST_CHICKEN",
  POINT_SYSTEM = "POINT_SYSTEM",
  POINT_SYSTEM_22 = "POINT_SYSTEM_22",
  WWCD = "WWCD",
}

export enum AdProviders {
  GGBET = "GG.BET",
  _1XBET = "1XBET",
  BC_GAME = "BG.GAME",
  STAKE = "STAKE",
}

export const URL_PARAMS_KEYS = {
  FLEADERBOARD: "fleaderboard",
  FOVERALL: "foverall",
  PAGE: "page",
};
