import React from "react";
import { CategorizedFilterProps } from "./types";
import CategorizedFilter from "./CategorizedFilter";

const CategorizedFilterContainer: React.FC<CategorizedFilterProps> = (
  props
) => {
  return <CategorizedFilter {...props} />;
};

export default CategorizedFilterContainer;
