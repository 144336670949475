import { useDispatch } from "react-redux";
import TournamentFilter from "./TournamentFilter";
import { unsetStatsFilters } from "@twire/redux/tournament/actions";

const TournamentFilterContainer: React.FC = () => {
  const dispatch = useDispatch();
  const onChange = () => {
    dispatch(unsetStatsFilters());
  }

  return <TournamentFilter onChange={onChange} />;
};

export default TournamentFilterContainer;
