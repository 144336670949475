import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  mergeClasses,
  setTournamentLogo,
  getRankClassName,
} from "@twire/utility/helpers";
import ComponentError from "@twire/common/ComponentError";
import { Games } from "@twire/utility/constants";
import MatchTile from "./MatchTile";
import { MatchesViewProps } from "./types";
import classes from "./Matches.module.scss";

const Matches: React.FC<MatchesViewProps> = ({
  data,
  game,
  error,
  onScroll,
  hasMore,
}) =>
  error ? (
    <ComponentError className={classes.error} {...error} />
  ) : (
    <div className={mergeClasses(classes.container, "appear")}>
      <InfiniteScroll
        dataLength={data.length}
        next={onScroll}
        hasMore={hasMore}
        className={classes.scrollContainer}
        loader={
          <div className="flex justify-center mt-5">
            <img
              src="/images/animated/loader.gif"
              style={{ height: "30px" }}
              alt="loading"
            />
          </div>
        }
      >
        {data.map((matchData) => (
          <React.Fragment key={matchData.match.id}>
            <MatchTile
              className={getRankClassName(matchData.teamRank)}
              tournamentLogo={setTournamentLogo(
                game as Games,
                matchData.tournament.imageName || ""
              )}
              matchId={matchData.match.id}
              tournamentId={matchData.tournament.id}
              matchName={matchData.match.name || ""}
              tournamentName={matchData.tournament.friendlyName}
            />
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );

export default Matches;
