import React from "react";
import FantasyTournamentTile from "@twire/common/FantasyTournamentTile";
import { Tournament } from "../../../FantasyPublicAPI";
import { urlFriendlyString } from "@twire/utility/helpers";
// import classes from "./FantasyTournaments.module.css";

const FantasyTournaments: React.FC<{
  tournaments: Tournament[];
}> = ({ tournaments }) => (
  <>
    {tournaments.map(t => (
      <a
        href={`https://fantasy.twire.gg/en/tournament/${t.id}/${urlFriendlyString(t.name)}/my-team`}
        className="block my-1"
        target="_blank"
        rel="noreferrer"
        key={t.id}
      >
        <FantasyTournamentTile
          tournament={t}
        />
      </a>
    ))}
  </>
);

export default FantasyTournaments;
