import { AnyAction } from "redux";
import { TwireUrl } from "../../../utility/helpers";
import { combineStatsFilters } from "../functions";
import { TournamentInterface, TournamentStatsFilter, TournamentStatsFilterType } from "../types";

export const requested = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    statsFilters: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => {
  const combinedFilters = {
    maps: combineStatsFilters(
      state.statsFilters.maps,
      action.statsFilters.maps as TournamentStatsFilter[],
    ),
    matches: combineStatsFilters(
      state.statsFilters.matches,
      action.statsFilters.matches as TournamentStatsFilter[],
    ),
  }
  return {
    ...state,
    statsFilters: {
      all: !combinedFilters.maps.some(m => m.selected)
        && !combinedFilters.matches.some(m => m.selected),
      ...combinedFilters
    },
    requestStates: {
      ...state.requestStates,
      statsFilters: { failed: false, requesting: false, success: true },
    },
  }
};

export const failed = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    statsFilters: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  statsFilters: {
    all: true,
    maps: [],
    matches: [],
  },
  requestStates: {
    ...state.requestStates,
    statsFilters: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});

export const unset = (state: TournamentInterface): TournamentInterface => {
  TwireUrl.delete(TournamentStatsFilterType.MAPS);
  TwireUrl.delete(TournamentStatsFilterType.MATCHES);
  return {
    ...state,
    statsFilters: {
      all: true,
      maps: state.statsFilters.maps.map(m => {
        return {
          ...m,
          selected: false,
        }
      }),
      matches: state.statsFilters.matches.map(m => {
        return {
          ...m,
          selected: false,
        }
      }),
    },
    requestStates: {
      ...state.requestStates,
    },
  }
};
