import Dropdown, { TriggerProps } from "unop-react-dropdown";
import { mergeClasses } from "@twire/utility/helpers";
import { useT } from "@twire/utility/hooks";
import { ReactComponent as SearchSVG } from "../../../assets/svgs/search.svg";
import { ReactComponent as CloseSvg } from "../../../assets/svgs/close.svg";
import Tournaments from "./Tournaments";
import Teams from "./Teams";
import classes from "./Search.module.scss";
import { RequestStates } from "../../../redux/types";

type OnchangeType = (value: string, dropdownProps: TriggerProps) => void;

interface SearchInputType extends Partial<TriggerProps> {
  onChange: OnchangeType;
  searchRef: React.RefObject<HTMLInputElement>;
}

const SearchInput: React.FC<SearchInputType> = ({
  onChange,
  searchRef,
  ...rest
}) => {
  return (
    <div
      className={mergeClasses(
        "flex w-full relative items-center",
        classes.top,
        [!!rest.open, "background-twire-menu-grey"]
      )}
    >
      <SearchSVG />
      <input
        type="search"
        className={mergeClasses("ml-2 text-xs", classes.search)}
        placeholder={useT("sentences.searchPlaceholder")}
        onChange={(e) => onChange(e.target.value, rest ? rest : ({} as any))}
        ref={searchRef}
      />
      {rest.open && (
        <button
          className={classes.close}
          onClick={() => rest.hide && rest.hide()}
        >
          <CloseSvg />
        </button>
      )}
    </div>
  );
};

const Search: React.FC<{
  onChange: OnchangeType;
  searchRef: React.RefObject<HTMLInputElement>;
  searchRequestStates: RequestStates;
  clearValue: () => void;
  onViewMoreClicked: (hide: () => void) => void;
}> = ({
  onChange,
  searchRequestStates,
  searchRef,
  clearValue,
  onViewMoreClicked,
}) => (
  <Dropdown
    dropdownWrapperClassName="w-full h-full cursor-auto-i"
    dropdownMenuClassName={mergeClasses(classes.dropdownMenu, "cursor-auto-i")}
    onDisappear={clearValue}
    trigger={<SearchInput onChange={onChange} searchRef={searchRef} />}
  >
    {({ hide }) => (
      <ul className={classes.list}>
        {searchRequestStates.requesting ? (
          <div>
            <img
              className="mx-auto"
              src="/images/animated/loader.gif"
              style={{ height: "25px" }}
              alt="loading"
            />
          </div>
        ) : searchRequestStates.failed ? (
          <div className="text-twire-red font-bold">
            Error getting search results
          </div>
        ) : (
          <>
            <div className={mergeClasses("appear")}>
              <div className="mb-4">
                <Tournaments
                  onTileClick={hide}
                  onViewMoreClicked={() => onViewMoreClicked(hide)}
                />
              </div>
              <div>
                <Teams
                  onTileClick={hide}
                  onViewMoreClicked={() => onViewMoreClicked(hide)}
                />
              </div>
            </div>
          </>
        )}
      </ul>
    )}
  </Dropdown>
);

export default Search;
