import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, clearForgotPassword } from "@twire/redux/user/actions";
import { RootReducer } from "@twire/redux/types";
import { useT } from "@twire/utility/hooks";

import ForgotPassword from "./ForgotPassword";
import { ForgotPasswordFormData } from "./types";

const ForgotPasswordContainer: FC = () => {
  const dispatch = useDispatch();
  const usernamePlaceholder = useT("words.username");
  const {
    user: { requestStates },
  } = useSelector((state: RootReducer) => state);
  useEffect(() => {
    return () => {
      dispatch(clearForgotPassword());
    };
  }, [dispatch]);

  const onSubmit = (values: ForgotPasswordFormData) => {
    dispatch(forgotPassword(values.username));
  };

  return (
    <ForgotPassword
      onSubmit={onSubmit}
      usernamePlaceholder={usernamePlaceholder}
      loading={requestStates.forgotPassword.requesting}
      error={requestStates.forgotPassword.failed}
      success={requestStates.forgotPassword.success}
    />
  );
};

export default ForgotPasswordContainer;
