import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { PrizeTableContext, ContextType } from "./context";
import { formatter } from "@twire/utility/helpers";
// import {} from "./types";
import PrizeTable from "./PrizeTable";

const PrizeTableContainer: React.FC = () => {
  const {
    app: { game },
    tournament: { tournament },
  } = useSelector((state: RootReducer) => state);
  const [revealRestOfTable, setRevealRestoFtable] = useState(false);
  const [totalPrize] = useState(
    tournament?.prizePool
      ? formatter("usd").format(tournament?.prizePool)
      : "N/A"
  );
  const [shard, setShard] = useState(
    tournament &&
      tournament.tournamentPrizePool &&
      tournament.tournamentPrizePool[0] &&
      tournament?.tournamentPrizePool[0].shardInfo
      ? tournament?.tournamentPrizePool[0].shardInfo
      : ""
  );
  const [tableData, setTableData] = useState<any>({
    headers: [],
    data: [],
  });
  const onRevealTableClicked = () => {
    setRevealRestoFtable(!revealRestOfTable);
  };

  useEffect(() => {
    setTableData({
      headers: tournament?.tournamentPrizePool?.map(
        ({ shardInfo, shardName }: any) => ({
          value: shardInfo,
          title: shardName,
        })
      ),
      data:
        tournament?.tournamentPrizePool?.find((t) => t?.shardInfo === shard) ||
        {},
    });
  }, []);

  const changeShard = (shard: string) => {
    setShard(shard);
    setTableData({
      ...tableData,
      data:
        tournament?.tournamentPrizePool?.find((t) => t?.shardInfo === shard) ||
        {},
    });
  };

  const state: ContextType = {
    shard,
    totalPrize,
    revealRestOfTable,
    onRevealTableClicked,
    table: tableData,
    setShard: changeShard,
  };

  return tournament?.tournamentPrizePool &&
    tournament?.tournamentPrizePool?.length > 0 ? (
    <PrizeTableContext.Provider value={state}>
      <PrizeTable game={game} />
    </PrizeTableContext.Provider>
  ) : (
    <></>
  );
  // <h1>Hello</h1>
};

export default PrizeTableContainer;
