import { useSelector } from "react-redux";
import { useUrl } from "@twire/utility/hooks";
import { URLS } from "@twire/utility/constants";
import { RootReducer } from "@twire/redux/types";
import { t } from "@twire/utility/helpers";

import { NavItem } from "./types";
import { ReactComponent as HomeSvg } from "../../../../../assets/svgs/house.svg";
import { ReactComponent as TrophySvg } from "../../../../../assets/svgs/trophy.svg";
import { ReactComponent as ArenaSvg } from "../../../../../assets/svgs/arena.svg";
import { ReactComponent as SwordsSvg } from "../../../../../assets/svgs/swords.svg";
import { ReactComponent as NewsSvg } from "../../../../../assets/svgs/news.svg";
import { ReactComponent as Shield } from "../../../../../assets/svgs/shield.svg";

export type NavLinksType = NavItem[];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNavLinks = () => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const homeUrl = useUrl(`/${game}`);
  const homeUrl2 = useUrl(`/${game}/`);

  const navLinks: NavItem[] = [
    {
      url: useUrl(`/${game}`),
      title: t("words.home"),
      svg: HomeSvg,
      exact: true,
      isActive: (_, location) => {
        if (
          location.pathname.includes(URLS.LOGIN) ||
          location.pathname.includes(URLS.FORGOT_PASSWORD) ||
          location.pathname.includes(URLS.REGISTER) ||
          location.pathname.includes(URLS.RESET_PASSWORD_BARE) ||
          location.pathname.includes(URLS.VERIFICATION_BARE) ||
          location.pathname.includes(URLS.SEARCH_BARE) ||
          location.pathname.includes(URLS.PLANS) ||
          location.pathname.includes(`${URLS.SETTINGS}`) ||
          location.pathname.includes(`${URLS.TEAM_BARE}/`)
        ) {
          return true;
        }
        if (location.pathname === homeUrl || location.pathname === homeUrl2)
          return true;
        return false;
      },
    },
    {
      url: useUrl(`/${game}/tournaments`),
      title: t("words.tournaments"),
      svg: TrophySvg,
    },
    {
      // url: useUrl(`/${game}/ranking`),
      url: useUrl(`/${game}/ranking`),
      title: t("words.ranking"),
      svg: ArenaSvg,
    },
    {
      // url: useUrl(`/${game}/scrims`),
      url: useUrl(`/${game}/scrims`),
      title: t("words.scrims"),
      svg: SwordsSvg,
      // external: true,
    },
    {
      // url: useUrl(`/${game}/news`),
      url: useUrl(`/${game}/news`),
      title: t("words.news"),
      svg: NewsSvg,
    },
    {
      url: `https://fantasy.twire.gg`,
      title: t("words.fantasy"),
      svg: Shield,
      blue: true,
      external: true,
    },
  ];
  return {
    navLinks,
  };
};
