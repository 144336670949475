import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { t } from "@twire/utility/helpers";
import { getTeamMatches } from "@twire/redux/team/actions";
import { ComponentErrorInterface } from "@twire/common/ComponentError";
import Placeholder from "./Placeholder";
import { ReactComponent as TrophySvg } from "../../../../../assets/svgs/trophy.svg";
import Matches from "./Matches";
import { saveUserAction } from "@twire/redux/track/actions";
import { teamMatchesView } from "@twire/redux/track/userActions/userActions";

const MatchesContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<ComponentErrorInterface | null>(null);
  const {
    app: { game },
    team: { matches, requestStates, team },
  } = useSelector((state: RootReducer) => state);
  // const [matchesData, setMatchesData] = useState<typeof matches | null>(
  //   matches
  // );

  // useEffect(() => {
  //   if (requestStates.matches.success) {
  //     setMatchesData(matches);
  //   }
  // }, [requestStates.matches]);

  useEffect(() => {
    if (requestStates.team.success && team && matches.data.length === 0) {
      dispatch(getTeamMatches(team?.id, 1));
      dispatch(saveUserAction(teamMatchesView(team.name)));
    }
  }, [requestStates.team]);

  useEffect(() => {
    if (requestStates.matches.failed && matches.data.length === 0) {
      setError({
        // change these to matches error message
        message: t("sentences.getTournamentsError"),
        suggestion: t("sentences.refreshThePage"),
        svg: TrophySvg,
        actionText: t("words.tryAgain"),
        onActionClicked: () => dispatch(getTeamMatches(team?.id, 0)),
      });
    } else if (requestStates.matches.success && matches.data.length === 0) {
      setError({
        message: t("sentences.noMatchesError"),
        suggestion: t("sentences.noTournamentsSuggestion"),
        svg: TrophySvg,
      });
    } else {
      setError(null);
    }
  }, [requestStates.matches, matches.data]);

  const onScroll = () => {
    dispatch(getTeamMatches(team?.id, matches.page + 1));
  };

  return requestStates.team.requesting ||
    (requestStates.matches.requesting && matches.data.length === 0) ? (
    <Placeholder />
  ) : (
    <Matches
      data={matches.data}
      error={error}
      game={game}
      onScroll={onScroll}
      hasMore={!matches.isLastPage}
    />
  );
};

export default MatchesContainer;
