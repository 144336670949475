import AdvancedTablePlaceholder from "../../Stats/AdvancedTable/Placeholder";
// import classes from "../TeamStats.module.scss";

const Placeholder: React.FC = () => {
  return (
    <div className="mt-5 appear">
      {/* <div className={classes.maps}>
        <button className="background-twire-placeholder-color-i opacity-60"></button>
        <button className="background-twire-placeholder-color-i opacity-60"></button>
        <button className="background-twire-placeholder-color-i opacity-60"></button>
      </div> */}
      <div>
        <AdvancedTablePlaceholder />
      </div>
    </div>
  );
};

export default Placeholder;
