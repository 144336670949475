export class DRKN {
  static URL = "https://drkn.com";

  // Every DRKN Related AD
  static G4A_ACTION_DRKN = "drkn_click";

  // DRKN AD
  static G4A_IMPRESSION_DRKN = "drkn_impression";

  // Every our Shop Related AD
  static G4A_ACTION_SHOP = "shop_click";

  static FANTASY_URL = "https://fantasy.twire.gg/shop?filter=drkn&page=1";
  static AD_CLASS = "g4a_banner_drkn";

  // Tournament page banner
  static AD_ID_TOURNAMENT = "drkn_top_banner";
}
