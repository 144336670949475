import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { filterTournaments } from "@twire/redux/tournaments/actions";
import { filterScrims } from "@twire/redux/scrims/actions";
import { TimeType } from "@twire/redux/tournaments/types";
import { useSelect } from "@twire/common/Select";
import { regionMap, tierMap } from "@twire/utility/constants";
import TournamentsFilter from "./TournamentsFilter";

const TournamentsFilterContainer: React.FC<{
  type: TimeType;
  scrims?: boolean;
  onOpen?: (arg?: any) => void;
  onClose?: (arg?: any) => void;
}> = ({ type, scrims, ...rest }) => {
  const {
    tournaments: {
      tournaments: {
        [type]: { filter },
      },
    },
    scrims: {
      scrims: { filter: scrimsFilter },
    },
  } = useSelector((state: RootReducer) => state);

  const dispatch = useDispatch();

  const regionItems = Object.entries(regionMap).map(([value, title]) => ({
    value,
    title,
  }));
  const tierItems = Object.values(tierMap).map((value) => ({
    value,
    title: `tier ${value}`,
  }));

  const regionInstance = useSelect(
    regionItems,
    scrims ? scrimsFilter.region : filter.region
  );
  const tierInstance = useSelect(
    [...tierItems, ...[{ value: "", title: "all" }]],
    scrims ? scrimsFilter.tier : filter.tier
  );

  const onApplyClicked = () => {
    dispatch(
      scrims
        ? filterScrims({
            region: regionInstance.value.value,
            tier: tierInstance.value.value,
          })
        : filterTournaments(
            {
              region: regionInstance.value.value,
              tier: tierInstance.value.value,
            },
            type
          )
    );
  };

  const onClearClicked = () => {
    regionInstance.updateValue({ title: "all", value: "" });
    tierInstance.updateValue({ title: "all", value: "" });
    dispatch(
      scrims
        ? filterScrims({
            region: "",
            tier: "",
          })
        : filterTournaments(
            {
              region: "",
              tier: "",
            },
            type
          )
    );
  };

  return (
    <TournamentsFilter
      regionInstance={regionInstance}
      tierInstance={tierInstance}
      onApplyClicked={onApplyClicked}
      onClearClicked={onClearClicked}
      {...rest}
    />
  );
};

export default TournamentsFilterContainer;
