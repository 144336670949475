import { createContext } from "react";
import { TwireScoreChartItem } from "./types";

export type ContextType = {
  data: TwireScoreChartItem[];
  moreData?: boolean;
};

const defaultValue: ContextType = {
  data: [],
};

export const TwireScoreChartContext = createContext(defaultValue);
