import { AnyAction, ActionCreator } from "redux";
import { SetFilter, GetPaginated } from "./types";
import { ScrimFilter } from "./types";
import * as actionTypes from "./actionTypes";

export const getPaginated: ActionCreator<GetPaginated> = (page: number) => ({
  type: actionTypes.SCRIMS_GET_SCRIMS_PAGINATED,
  page,
});

export const filterScrims: ActionCreator<SetFilter> = (
  filter: ScrimFilter
) => ({
  type: actionTypes.SCRIMS_FILTER_SCRIMS,
  filter,
});

export const getScrims = (): AnyAction => ({
  type: actionTypes.SCRIMS_GET_SCRIMS,
});
