import { all, takeLatest } from "@redux-saga/core/effects";

import * as actionTypes from "./actionTypes";

function* saveUserActionRequest() {
  // pass
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.SAVE_USER_ACTION, saveUserActionRequest),
  ]);
}
