import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  PolarRadiusAxis,
} from "recharts";
import { useDispatch } from "react-redux";
import { showTooltip, hideTooltip } from "@twire/redux/app/actions";
import { TwireScoreChartContext } from "./context";
import { ReactComponent as Grenade } from "../../../assets/svgs/grenade.svg";
import { ReactComponent as Sniper } from "../../../assets/svgs/fighting.svg";
import { ReactComponent as Aim } from "../../../assets/svgs/aim.svg";
import { ReactComponent as Timer } from "../../../assets/svgs/timer.svg";
import { ReactComponent as Multiple } from "../../../assets/svgs/multiple.svg";
import { ReactComponent as Thief } from "../../../assets/svgs/thief.svg";
import classes from "./TwireScoreChart.module.scss";
import { mergeClasses } from "../../../utility/helpers";

const width = 220;
const height = 180;

const getMainY = (value: number, index: number) => {
  switch (index) {
    case 0:
      return value - 10;
    case 2:
      return value - 10;
    case 3:
      return value - 5;
    case 4:
      return value - 10;
    case 5:
      return value - 3;
    default:
      return value - 4;
  }
};

const getMainX = (value: number, index: number, score: number) => {
  const adjustScore = (value: number, score: number): number => {
    if (score === 100) {
      return value + 5;
    }
    return value;
  };
  switch (index) {
    case 0:
      return value + 2;
    case 1:
      return value + adjustScore(10, score);
    case 2:
      return score === 100 ? value + 13 : value + 10;
    case 3:
      return value - 0;
    case 4:
      return score === 100 ? value - 30 : value - 26;
    case 5:
      return score === 100 ? value - 30 : value - 26;
    default:
      return value;
  }
};

const getY = (value: number, index: number) => {
  switch (index) {
    case 0:
      return value + 3;
    case 2:
      return value + 3;
    case 3:
      return value + 8;
    case 4:
      return value + 3;
    case 5:
      return value + 10;
    default:
      return value + 10;
  }
};

const getX = (value: number, index: number, score: number) => {
  const adjustScore = (value: number, score: number): number => {
    if (score === 100) {
      return value - 2;
    }
    return value;
  };
  switch (index) {
    case 0:
      return adjustScore(value - 5, score);
    case 1:
      return value + 0;
    case 2:
      return value;
    case 3:
      return adjustScore(value - 7, score);
    case 4:
      return value + 2;
    case 5:
      return value + 2;
    default:
      return value;
  }
};

const TickIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
    index: number;
  }
> = ({ index, ...rest }) => {
  switch (index) {
    case 0:
      return <Aim height={15} width={15} {...rest} />;
    case 1:
      return <Sniper {...rest} height={15} width={20} />;
    case 2:
      return <Grenade {...rest} height={15} width={20} />;
    case 3:
      return <Timer {...rest} height={15} width={15} />;
    case 4:
      return <Multiple {...rest} height={15} width={15} />;
    case 5:
      return <Thief {...rest} height={15} width={15} />;
    default:
      return <Grenade {...rest} />;
  }
};

const CustomTick = ({ payload, x, y, textAnchor, stroke, radius }: any) => {
  const dispatch = useDispatch();
  return (
    <TwireScoreChartContext.Consumer>
      {({ data }) => (
        <g
          width={35}
          height={17}
          className={mergeClasses(
            "recharts-layer recharts-polar-angle-axis-tick",
            classes.tick
          )}
          onMouseEnter={(e) => {
            dispatch(
              showTooltip(e.pageX, e.pageY - 30, data[payload.value].skill)
            );
          }}
          onMouseLeave={() => {
            dispatch(hideTooltip());
          }}
        >
          {/* <text
            radius={radius}
            stroke={stroke}
            x={getMainX(x, data[payload.value].id)}
            y={getMainY(y, data[payload.value].id)}
            className="recharts-text recharts-polar-angle-axis-tick-value"
            textAnchor={textAnchor}
            fill="#ffffff"
          >
            <tspan x={getMainX(x, data[payload.value].id)} dy="0em">
              {data[payload.value].skill}
            </tspan>
          </text> */}
          <TickIcon
            index={data[payload.value].id}
            className={classes.icon}
            x={getMainX(x, data[payload.value].id, data[payload.value].value)}
            y={getMainY(y, data[payload.value].id)}
          />
          <text
            radius={radius}
            stroke={stroke}
            x={getX(x, data[payload.value].id, data[payload.value].value)}
            y={getY(y, data[payload.value].id)}
            className="recharts-text recharts-polar-angle-axis-tick-value"
            textAnchor={textAnchor}
            fill="#ffffff80"
          >
            <tspan
              x={getX(x, data[payload.value].id, data[payload.value].value)}
              dy="0em"
            >
              {data[payload.value].text || data[payload.value].value}
            </tspan>
          </text>
        </g>
      )}
    </TwireScoreChartContext.Consumer>
  );
};

const TwireScoreChart: React.FC = () => (
  <TwireScoreChartContext.Consumer>
    {({ data, moreData }) => (
      <ResponsiveContainer
        className={classes.container}
        width="100%"
        height={height}
      >
        <RadarChart
          cx={`${50}%`}
          cy={`${50}%`}
          outerRadius={`${80}%`}
          width={width}
          height={height}
          data={data}
          className={classes.radarChart}
          //   strokeDasharray="20"
        >
          <PolarGrid
            strokeWidth={3}
            className={classes.ploarGrid}
            stroke="#ffffff1a"
            polarRadius={[0.075 * width, 0.185 * width, 0.295 * width]}
          />
          <PolarAngleAxis
            dataKey="id"
            tick={(props) => <CustomTick {...props} />}
          />
          <PolarRadiusAxis domain={[0, 100]} style={{ display: "none" }} />
          <Radar
            name="Mike"
            dataKey="value"
            stroke="#F0AB2E"
            fill="#ecb55133"
            fillOpacity={0.6}
            strokeWidth={2}
            className={classes.radar}
          />
          {moreData && (
            <Radar
              name="Mike"
              dataKey="secondValue"
              stroke="#FFFFFF"
              fill="#FFFFFF"
              fillOpacity={0.2}
              strokeWidth={2}
              className={classes.radar}
            />
          )}
        </RadarChart>
      </ResponsiveContainer>
    )}
  </TwireScoreChartContext.Consumer>
);

export default TwireScoreChart;
