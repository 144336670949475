import React from "react";
import Placeholder from "./Placeholder";

const PlaceholderContainer: React.FC = () => {
  return (
    <>
      <div className="my-1">
        <Placeholder theme="ORANGE" />
      </div>
      <div className="my-1">
        <Placeholder theme="BLUE" />
      </div>
      <div className="my-1">
        <Placeholder theme="RED" />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
      <div className="my-1">
        <Placeholder />
      </div>
    </>
  );
};

export default PlaceholderContainer;
