import { AnyAction } from "redux";
import { ScrimsInterface } from "../types";
import { defaultScrimstFilters } from "../constants";

export const requested = (
  state: ScrimsInterface,
  paginated?: boolean
): ScrimsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    data: { failed: false, requesting: true, success: false },
    paginated: paginated
      ? { failed: false, requesting: true, success: false }
      : state.requestStates.paginated,
  },
});

export const success = (
  state: ScrimsInterface,
  action: AnyAction,
  paginated?: boolean
): ScrimsInterface => ({
  ...state,
  scrims: {
    ...state.scrims,
    data: paginated
      ? [...(state.scrims.data || []), ...action.payload.scrims.data]
      : action.payload.scrims.data,
    page: paginated
      ? action.payload.scrims.data.length === 0
        ? action.payload.scrims.page - 1
        : action.payload.scrims.page
      : 1,
    isLastPage:
      action.payload.scrims.data.length === 0 ? true : state.scrims.isLastPage,
    game: action.payload.scrims.game,
  },
  requestStates: {
    ...state.requestStates,
    data: { failed: false, requesting: false, success: true },
    paginated: paginated
      ? { failed: false, requesting: false, success: true }
      : state.requestStates.paginated,
  },
});

export const failed = (
  state: ScrimsInterface,
  action: AnyAction,
  paginated?: boolean
): ScrimsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    data: { failed: action.error, requesting: false, success: false },
    paginated: paginated
      ? { failed: action.error, requesting: false, success: false }
      : state.requestStates.paginated,
  },
});

export const clear = (
  state: ScrimsInterface,
  paginated?: boolean
): ScrimsInterface => ({
  ...state,
  scrims: {
    ...state.scrims,

    game: null,
    data: [],
    filter: { ...defaultScrimstFilters },
  },
  requestStates: {
    ...state.requestStates,
    data: { failed: false, requesting: false, success: false },

    paginated: paginated
      ? { failed: false, requesting: false, success: false }
      : state.requestStates.paginated,
  },
});
