import { ReactComponent as SuccessSVG } from "../../../assets/svgs/success.svg";
import Text from "@twire/common/Text";
import TwireButton from "@twire/common/TwireButton";

const Success: React.FC<{
  showLoginButton: boolean;
  onCloseSuccessMessage: () => void;
}> = ({
  showLoginButton,
  onCloseSuccessMessage,
}) => {
  return (
    <div className="flex flex-col items-center">
      <SuccessSVG className="mb-4" />
      <h1><Text i18nKey="words.success">Success</Text></h1>
      {( showLoginButton &&
        <TwireButton
          onClick={onCloseSuccessMessage}
        >
          <Text i18nKey="login.loginText">login</Text>
        </TwireButton>
      )}
    </div>
  );
};

export default Success;
