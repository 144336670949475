import React from "react";
import { RankingTileViewProps } from "./types";
import RankingTile from "./RankingTile";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";

const RankingTileContainer: React.FC<RankingTileViewProps> = (props) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  return <RankingTile {...props} game={game} />;
};

export default RankingTileContainer;
