import { Reducer } from "redux";
import { defaultRequestState, paginatedDefaults } from "../constants";
import { getFilters } from "../tournaments/functions";
import { ScrimsInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as scrimsHandlers from "./handlers/getScrims";
import * as filterHandlers from "./handlers/setFilter";

const INITIAL_STATE: ScrimsInterface = {
  scrims: {
    data: [],
    // refactor to use scrim filters
    filter: getFilters(),
    game: null,
    ...paginatedDefaults,
  },
  requestStates: {
    data: { ...defaultRequestState },
    filter: { ...defaultRequestState },
    paginated: { ...defaultRequestState },
  },
};

const scrimsReducer: Reducer<ScrimsInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.SCRIMS_GET_SCRIMS_REQUESTED:
      return scrimsHandlers.requested(state, action.paginated);
    case actionTypes.SCRIMS_GET_SCRIMS_FAILED:
      return scrimsHandlers.failed(state, action, action.paginated);
    case actionTypes.SCRIMS_GET_SCRIMS_SUCCESS:
      return scrimsHandlers.success(state, action, action.paginated);
    case actionTypes.SCRIMS_GET_SCRIMS_CLEAR:
      return scrimsHandlers.clear(state, action.paginated);
    case actionTypes.SCRIMS_FILTER_SCRIMS_REQUESTED:
      return filterHandlers.requested(state);
    case actionTypes.SCRIMS_FILTER_SCRIMS_FAILED:
      return filterHandlers.failed(state, action);
    case actionTypes.SCRIMS_FILTER_SCRIMS_SUCCESS:
      return filterHandlers.success(state, action);
    default:
      return state;
  }
};

export default scrimsReducer;
