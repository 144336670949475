import { mergeClasses } from "@twire/utility/helpers";
import classes from "./Placeholder.module.scss";

const Placeholder: React.FC = () => (
  <div
    className={mergeClasses(
      classes.container,
      "flex background-twire-grey p-5 items-center"
    )}
  >
    <div className={mergeClasses(classes.tournamentImageP, "animate-pulse")} />
    <div className="w-full animate-pulse">
      <div className={classes.headingP} />
      <div className={mergeClasses(classes.detailRow)}>
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default Placeholder;
