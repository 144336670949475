import { SeoData } from "./types";

export enum Pages {
  TOURNAMENT_PAGE = "tournament",
  TOURNAMENTS_LIST_PAGE = "tournaments",
  SCRIMS_LIST_PAGE = "scrims",
  TEAM_RANKING_PAGE = "team-ranking",
  NEWS_PAGE = "news",
  ARTICLE_PAGE = "article",
  FANTASY_LIST_PAGE = "fantasy-tournaments",
  FANTASY_PAGE = "fantasy-tournament",
  HOME_PAGE = "home",
}

const pageTitles = {
  [Pages.TOURNAMENT_PAGE]: 'PUBG Tournament',
  [Pages.TOURNAMENTS_LIST_PAGE]: 'PUBG Tournaments',
  [Pages.SCRIMS_LIST_PAGE]: 'PUBG Scrims',
  [Pages.TEAM_RANKING_PAGE]: 'PUBG Team Ranking',
  [Pages.NEWS_PAGE]: 'PUBG News',
  [Pages.ARTICLE_PAGE]: 'PUBG News',
  [Pages.FANTASY_LIST_PAGE]: 'PUBG Fantasy League',
  [Pages.FANTASY_PAGE]: 'PUBG Fantasy League',
  [Pages.HOME_PAGE]: 'All about PUBG Esports',
}

const pageDescriptions = {
  [Pages.TOURNAMENT_PAGE]: 'Leaderboard, Stats, and Matches',
  [Pages.TOURNAMENTS_LIST_PAGE]: 'PUBG Tournaments, Leaderboards, Stats, and Matches',
  [Pages.SCRIMS_LIST_PAGE]: 'PUBG Scrims, Leaderboards, Stats, and Matches',
  [Pages.TEAM_RANKING_PAGE]: 'Top 100 PUBG Teams worldwide',
  [Pages.NEWS_PAGE]: 'Latest News about PUBG esports scene',
  [Pages.ARTICLE_PAGE]: '',
  [Pages.FANTASY_LIST_PAGE]: 'Play PUBG Fantasy League With Real Players',
  [Pages.FANTASY_PAGE]: '',
  [Pages.HOME_PAGE]: 'Everything about PUBG esports - Tournaments, Teams, Players and News',
}

export const getSeoData = (
  page: Pages,
  titlePrefix?: string,
  descriptionPrefix?: string,
): SeoData => {
  titlePrefix = titlePrefix ? titlePrefix + ' | ' : '';
  return {
    title: `${titlePrefix}${pageTitles[page]} | Twire`,
    description: `${descriptionPrefix || ''}${pageDescriptions[page]}`,
  }
}