import React from "react";
import { Switch, Route } from "react-router-dom";
import TournamentsView from "../Tournaments/TournamentsView";
// import Tournament from "../Tournaments/Tournament";
// import classes from "./Scrims.module.css";

const Scrims: React.FC<{ url: string }> = ({ url }) => (
  <Switch>
    <Route
      path={`${url}/`}
      exact
      render={() => <TournamentsView type="upcoming" scrims />}
    />
  </Switch>
);

export default Scrims;
