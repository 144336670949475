import {
  mergeClasses,
  setDefaultTeamLogo,
  setTeamLogo,
} from "@twire/utility/helpers";
import ArrowSpan from "@twire/common/ArrowSpan";
import Text from "@twire/common/Text";
import { ReactComponent as ArrowTip } from "../../../../../assets/svgs/arrow-tip-right.svg";
import { Team } from "../../../../../PublicAPI";
import classes from "./TeamsTile.module.scss";
import { Games } from "../../../../../utility/constants";

const TeamsTile: React.FC<{ team: Team; game: Games; long?: boolean }> = ({
  team,
  game,
  long,
}) => (
  <div className={mergeClasses(classes.container, "background-twire-grey")}>
    <img
      className={classes.logo}
      src={setTeamLogo(game, team.name || "")}
      alt="team logo"
      onError={setDefaultTeamLogo}
    />
    <div>
      <div
        className={mergeClasses(
          "rift-bold text-xl",
          long ? "text-twire-light" : "text-twire-orange"
        )}
      >
        {team.name}
      </div>
      {/* <div className="text-twire-light opacity-70 open-sans text-xs">
        {`${t("words.tier")} ${tier ? `${tier}` : ""}${
          prize > 0
            ? `, ${formatter(currency)
                .format(prize)
                .replace(/\.00/g, "")} prize pool`
            : ""
        }${location ? `, ${location}` : ""}`}
      </div> */}
    </div>
    {long ? (
      <div className="ml-auto">
        <ArrowSpan>
          <Text i18nKey="words.team"></Text>
        </ArrowSpan>
      </div>
    ) : (
      <ArrowTip className="ml-auto" />
    )}
  </div>
);

export default TeamsTile;
