import { mergeClasses } from "../../../../../utility/helpers";
import classes from "./TeamInfo.module.scss";

const Placeholder: React.FC = () => (
  <>
    <div
      className={mergeClasses(
        "text-twire-light rift-bold text-xl",
        classes.header
      )}
    >
      team info
    </div>
    <div
      className={mergeClasses(
        classes.container,
        "background-twire-grey p-7 flex items-center"
      )}
    >
      <div
        className={mergeClasses(
          "background-twire-placeholder-color animate-pulse",
          classes.teamLogo
        )}
      />
      <div
        className={mergeClasses(
          "background-twire-placeholder-color animate-pulse w-9/12",
          classes.placeholderTitle
        )}
      />
    </div>
  </>
);

export default Placeholder;
