import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { PgcPointsInterface, PgcPointsRegions } from "./types";
import * as actionTypes from "./actionTypes";
import * as pgcPointsHandlers from "./handlers/getPgcPoints";

const INITAL_REGION = {
  teams: [],
  advance: [],
  tournaments: [],
}

const INITIAL_STATE: PgcPointsInterface = {
  regions: {
    [PgcPointsRegions.EU]: INITAL_REGION,
    [PgcPointsRegions.AM]: INITAL_REGION,
    [PgcPointsRegions.ASIA]: INITAL_REGION,
    [PgcPointsRegions.APAC]: INITAL_REGION,
  },
  requestStates: {
    regions: { ...defaultRequestState },
  },
};

const pgcPointsReducer: Reducer<PgcPointsInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TEAMS_GET_PGC_POINTS_REQUESTED:
      return pgcPointsHandlers.requested(state);
    case actionTypes.TEAMS_GET_PGC_POINTS_FAILED:
      return pgcPointsHandlers.failed(state, action);
    case actionTypes.TEAMS_GET_PGC_POINTS_SUCCESS:
      return pgcPointsHandlers.success(state, action);
    case actionTypes.TEAMS_GET_PGC_POINTS_CLEAR:
      return pgcPointsHandlers.clear(state);
    default:
      return state;
  }
};

export default pgcPointsReducer;
