import React, { ReactNode } from 'react';
import ComponentError, { ComponentErrorInterface } from '../ComponentError';
import { t } from '@twire/utility/helpers';
import { ReactComponent as TrophySvg } from "../../../assets/svgs/trophy.svg";
import classes from "./ErrorBoundary.module.scss";

class ErrorBoundary extends React.Component<any, {
  error: ComponentErrorInterface | null
}> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(): void {
    this.setState({ error: {
      message: t("sentences.unknownError"),
      suggestion: t("sentences.refreshThePage"),
      svg: TrophySvg,
    } });
  }

  render(): ReactNode {
    if (this.state.error) {
      return (
        <ComponentError
          className={classes.fullHeight}
          {...this.state.error}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;