import { Action } from "redux";
import { Games } from "@twire/utility/constants";
import {
  RequestStates,
  // TransitionStates
} from "@twire/redux/types";
import React from "react";

export interface TooltipInterface {
  x: number;
  y: number;
  value: string | JSX.Element;
}

// interface TooltipReducerInterface extends TransitionStates<TooltipInterface> {
//   value: string;
// }

export interface AppInterface {
  game: Games;
  displayFullPageLoadingOverlay: string;
  banner: Banner | null;
  changingLanguage: {
    currLang: string;
    targetLang: string;
  } | null;
  hidingFullPageLoadingOverlay: number;
  requestStates: {
    changingLanguage: RequestStates;
  };
  // showTooltip: TooltipReducerInterface;
  showTooltip: TooltipInterface | null;
  modalElement: React.ReactNode | null;
}

export interface SetGameParams {
  game: Games;
}

export interface SetGame extends Action {
  payload: SetGameParams;
}

// Supported banner types
export enum BannerTypes {
  INFO = "info",
  ERROR = "error",
  FANTASY = "fantasy",
}

export interface Banner {
  node: React.ReactNode;
  type: BannerTypes;
}
