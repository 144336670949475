import { all, put, takeLatest } from "@redux-saga/core/effects";
import * as actionTypes from "./actionTypes";

import { fantasyPublicQuery } from "../../graphql/requestHelper";
import {
  getTournaments,
} from "../../graphql/fantasy/queries";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchTournaments() {
  yield put({ type: actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_REQUESTED });

  try {
    const tournaments: Generator<any, any> = yield fantasyPublicQuery(
      getTournaments,
      {},
      CACHE_TIME.MINUTE * 10,
    );

    yield put({
      type: actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_SUCCESS,
      tournaments: (tournaments as any).data.getTournaments,
    });
  } catch (error) {
    yield put({ type: actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS, fetchTournaments),
  ]);
}
