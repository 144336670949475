import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { filterStats, getStatsFilters } from "@twire/redux/tournament/actions";
import CategorizedFilter from "@twire/common/CategorizedFilter";
import {
  ParentData,
  ChildData,
  CategoryProps,
  OptionProp,
} from "@twire/common/CategorizedFilter/types";
import { mergeClasses, TwireUrl } from "@twire/utility/helpers";
import { TournamentStatsFilters, TournamentStatsFilterType } from "@twire/redux/tournament/types";

const StatsFilter: React.FC = () => {
  const {
    tournament: { statsFilters, requestStates, filter },
  } = useSelector((state: RootReducer) => state);

  const dispatch = useDispatch();

  const [categories, setCategories] = useState<CategoryProps[]>([]);

  const [selectedCategories, setSelectedCategories] = useState<ChildData[]>([]);

  useEffect(() => {
    if (requestStates.tournament.success) {
      dispatch(getStatsFilters());
    }
  }, [filter, requestStates.tournament]);

  const getAllAndSelectedCategories = (
    filterType: TournamentStatsFilterType.MAPS | TournamentStatsFilterType.MATCHES
  ): {
    allOptions: OptionProp[];
    selectedOptions: ChildData[];
  } => {
    const selectedOptions: ChildData[] = [];
    const allOptions = statsFilters[filterType].map((m, i) => {
      const option = {
        value: m.key.toString(),
        name: m.value,
      };
      if (m.selected) {
        selectedOptions.push({
          index: i,
          parentId: "maps",
          ...option,
        })
      }
      return option;
    });
    return {
      allOptions,
      selectedOptions,
    }
  }

  useEffect(() => {
    if (
      requestStates.tournament.success &&
      requestStates.statsFilters.success
    ) {
      const {
        allOptions: allMapOptions,
        selectedOptions: selectedMapOptions,
      } = getAllAndSelectedCategories(
        TournamentStatsFilterType.MAPS
      );
      const {
        allOptions: allMatchOptions,
        selectedOptions: selectedMatchOptions,
      } = getAllAndSelectedCategories(
        TournamentStatsFilterType.MATCHES
      );
      setCategories([
        {
          id: "default",
          options: [
            {
              value: "",
              name: "All",
            },
          ],
        },
        {
          id: "maps",
          options: allMapOptions,
        },
        {
          id: "match",
          options: allMatchOptions,
        },
      ]);
      setSelectedCategories([
        ...selectedMapOptions,
        ...selectedMatchOptions,
      ]);
    }
  }, [filter, statsFilters]);

  const setUrlStatFilters = (
    currentlySelectedCategories: TournamentStatsFilters, 
    filterType: TournamentStatsFilterType.MAPS | TournamentStatsFilterType.MATCHES
  ) => {
    const selectedMapValues = currentlySelectedCategories[filterType]
      .filter(m => m.selected).map(m => m.key).join(",");
    if (selectedMapValues) {
      TwireUrl.set(filterType, selectedMapValues);
    } else {
      TwireUrl.delete(filterType);
    }
  }

  const getStatsFilterState = (
    selectedCategories: ChildData[],
  ): TournamentStatsFilters => {
    return {
      all: selectedCategories.length === 0,
      maps: statsFilters.maps.map((mapData) => ({
        ...mapData,
        selected: !!selectedCategories.find(
          (val) => val.value === mapData.key
        ),
      })),
      matches: statsFilters.matches.map((matchData) => ({
        ...matchData,
        selected: !!selectedCategories.find(
          (val) => val.value === matchData.key.toString()
        ),
      })),
    };
  }

  const onOptionSelect = (
    parent: ParentData,
    child: ChildData,
    deselect?: boolean
  ) => {
    let selectedCategoriesCopy = [...selectedCategories];
    if (parent.index !== 0) {
      if (!deselect) {
        selectedCategoriesCopy.push(child);
      } else {
        selectedCategoriesCopy = selectedCategoriesCopy.filter(
          ({ value }) => !(value === child.value)
        );
      }
    } else {
      selectedCategoriesCopy = [];
    }

    const statsFilterState = getStatsFilterState(selectedCategoriesCopy);

    setUrlStatFilters(statsFilterState, TournamentStatsFilterType.MAPS);
    setUrlStatFilters(statsFilterState, TournamentStatsFilterType.MATCHES);

    dispatch(filterStats(statsFilterState));
    setSelectedCategories(selectedCategoriesCopy);
  };

  return requestStates.statsFilters.requesting ||
    requestStates.statsFilters.failed ||
    (statsFilters.maps.length === 0 && statsFilters.matches.length === 0) ? (
    <div
      style={{ height: "40px", width: "200px" }}
      className={mergeClasses(
        "background-twire-grey text-twire-light open-sans text-xs flex items-center justify-center",
        [requestStates.statsFilters.requesting, "animate-pulse"]
      )}
    />
  ) : (
    <CategorizedFilter
      categories={categories}
      selected={
        selectedCategories.length > 0
          ? selectedCategories
          : [{ value: "", name: "All" }]
      }
      onOptionSelect={onOptionSelect}
    />
  );
};

export default StatsFilter;
