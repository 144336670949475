import React, { useState, useRef, useEffect } from 'react';

import Expandable from './Expandable';
import classes from './Expandable.module.scss';

const ExpandableContainer: React.FC<{ open?: boolean; className?: string }> = ({
  open = false,
  className = '',
  children,
}) => {
  const [height, setHeight] = useState<number | string | undefined>(0);
  const expandableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expandableRef.current) {
      if (open && (height === 0 || expandableRef.current.clientHeight === 0)) {
        setHeight(expandableRef.current.scrollHeight);
        setTimeout(() => {
          setHeight("fit-content")
        }, 450)
      }
      if (height && ((!open && height > 0) || (!open && typeof height === "string"))) {
        setHeight(expandableRef.current.scrollHeight);
        setTimeout(() => {
          setHeight(0);
        }, 0)
      }
    }
  }, [height, open]);

  const expandableClassName = `${classes.expandable}${open ? ` ${classes.open}` : ""}${className ? ` ${className}` : ''}`;

  return (
    <Expandable height={height} innerRef={expandableRef} className={expandableClassName}>
      {children}
    </Expandable>
  );
};

export default ExpandableContainer;
