import { useEffect, useRef, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { mergeClasses } from "../../../utility/helpers";
import classes from "./ScrollableTableShade.module.scss";

const ScrollableTableShade: React.FC<{
  scrollableRef: React.RefObject<HTMLElement>;
  tableRef: React.RefObject<HTMLElement>;
}> = ({ scrollableRef, tableRef }) => {
  const hide = useRef(false);
  const shadeRef = useRef<HTMLDivElement>(null);
  const emptyTableRef = useRef<HTMLDivElement>(null);
  const fakeScrollbarRef = useRef<HTMLDivElement>(null);

  const shadeFunction = useCallback(() => {
    const maxScrollLeft =
      (scrollableRef?.current?.scrollWidth || 0) -
      (scrollableRef?.current?.clientWidth || 0);
    if (
      maxScrollLeft <=
      Math.trunc(scrollableRef.current?.scrollLeft || 0) + 10
    ) {
      if (!hide.current) {
        if (shadeRef.current) shadeRef.current.style.opacity = "0";
        hide.current = true;
      }
    } else {
      if (hide.current) {
        if (shadeRef.current) shadeRef.current.style.opacity = "1";
        hide.current = false;
      }
    }
  }, []);

  useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (
      scrollableRef &&
      scrollableRef.current &&
      (scrollableRef?.current?.scrollWidth || 0) >
        (scrollableRef?.current?.clientWidth || 0)
    ) {
      if (shadeRef.current) shadeRef.current.style.display = "block";
      if (emptyTableRef.current && emptyTableRef.current) {
        emptyTableRef.current.style.width = `${tableRef.current?.scrollWidth}px`;
      }

      if (scrollableRef.current && fakeScrollbarRef.current) {
        fakeScrollbarRef.current.addEventListener("scroll", () => {
          setTimeout(() => {
            // This is to prevent stuttering
            if (scrollableRef.current && fakeScrollbarRef.current) {
              if (
                scrollableRef.current.scrollLeft !==
                fakeScrollbarRef.current.scrollLeft
              ) {
                scrollableRef.current.scrollLeft =
                  fakeScrollbarRef.current.scrollLeft;
              }
            }
          }, 0);
        });
      }

      scrollableRef.current.addEventListener("scroll", () => {
        if (scrollableRef.current && fakeScrollbarRef.current) {
          if (scrollableRef.current && fakeScrollbarRef.current) {
            fakeScrollbarRef.current.scrollLeft =
              scrollableRef.current.scrollLeft;
          }
        }
        shadeFunction();
      });

      resizeObserver = new ResizeObserver(shadeFunction);
      resizeObserver.observe(scrollableRef.current);
    }
    return () => {
      if (resizeObserver && scrollableRef.current) {
        resizeObserver.unobserve(scrollableRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={classes.scrollBar} ref={fakeScrollbarRef}>
        <div className={classes.emptyTable} ref={emptyTableRef} />
      </div>
      <div ref={shadeRef} className={mergeClasses(classes.shade)} />
    </>
  );
};

export default ScrollableTableShade;
