import { AnyAction } from "redux";
import { UserStateInterface } from "../types";

export const requested = (state: UserStateInterface): UserStateInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    signIn: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: UserStateInterface,
  action: AnyAction
): UserStateInterface => {
  const { user } = action;
  return {
    ...state,
    signedIn: true,
    userData: user,
    requestStates: {
      ...state.requestStates,
      signIn: {
        failed: false,
        requesting: false,
        success: true,
      },
    },
  };
};

export const failed = (
  state: UserStateInterface,
  action: AnyAction
): UserStateInterface => ({
  ...state,
  userUnverified: action.error.code === 'UserNotConfirmedException',
  requestStates: {
    ...state.requestStates,
    signIn: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: UserStateInterface): UserStateInterface => ({
  ...state,
  userUnverified: false,
  requestStates: {
    ...state.requestStates,
    signIn: {
      failed: false,
      requesting: false,
      success: false,
    },
    resendVerifyEmail: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
