import React from "react";
import Line from "@twire/common/Line";
import placeholderClasses from "./Placeholder.module.scss";
import classes from "../TournamentInfo.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

import { ReactComponent as ArrowTip } from "../../../../../../../assets/svgs/arrowTip.svg";

const Placeholder: React.FC = () => (
  <div className={classes.container}>
    <div className={classes.left}>
      <div className="flex items-center">
        <div
          className={mergeClasses(
            placeholderClasses.placeholder,
            classes.badge,
            placeholderClasses.badge
          )}
        />
        <div className={classes.name}>
          <div
            className={mergeClasses(
              placeholderClasses.placeholder,
              placeholderClasses.tournamentName
            )}
          />
        </div>
        <button className={classes.trigger}>
          <ArrowTip />
        </button>
      </div>

      <div className="my-6">
        <Line />
      </div>

      <div className="flex"></div>
    </div>
  </div>
);

export default Placeholder;
