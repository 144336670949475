import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatTournamentData } from "@twire/redux/tournaments/functions";
import { TournamentData } from "@twire/redux/tournaments/types";
import { t } from "@twire/utility/helpers";
import { RootReducer } from "@twire/redux/types";
import { useUrl } from "@twire/utility/hooks";
import Placeholder from "@twire/common/Tournaments/Placeholder";
import { ComponentErrorInterface } from "@twire/common/ComponentError";
import {
  getTeamTournaments,
  // clearTeamTournaments,
} from "@twire/redux/team/actions";
import { ReactComponent as TrophySvg } from "../../../../../assets/svgs/trophy.svg";
import Tournaments from "./Tournaments";
import { saveUserAction } from "@twire/redux/track/actions";
import { teamTournamentsView } from "@twire/redux/track/userActions/userActions";

const TournamentsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<ComponentErrorInterface | null>(null);
  const {
    team: { tournaments, requestStates, team },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}/tournaments/tournament/`);
  const [tournamentsData, setTournamentsData] = useState<
    { rank: number | null | undefined; tournament: TournamentData }[]
  >([]);

  useEffect(() => {
    if (requestStates.tournaments.success) {
      setTournamentsData(
        tournaments.data.map(({ tournament }) => ({
          rank: tournament.teamRank,
          tournament: formatTournamentData(tournament),
        }))
      );
      dispatch(saveUserAction(teamTournamentsView(team?.name)));
    }
  }, [requestStates.tournaments]);

  useEffect(() => {
    if (requestStates.team.success && team && tournaments.data.length === 0) {
      dispatch(getTeamTournaments(team?.id, 1));
    }
  }, [requestStates.team]);

  useEffect(() => {
    if (requestStates.tournaments.failed && tournaments.data.length === 0) {
      setError({
        message: t("sentences.getTournamentsError"),
        suggestion: t("sentences.refreshThePage"),
        svg: TrophySvg,
        actionText: t("words.tryAgain"),
        onActionClicked: () => dispatch(getTeamTournaments(team?.id, 0)),
      });
    } else if (
      requestStates.tournaments.success &&
      tournaments.data.length === 0
    ) {
      setError({
        message: t("sentences.noTournamentsError"),
        suggestion: t("sentences.noTournamentsSuggestion"),
        svg: TrophySvg,
      });
    } else {
      setError(null);
    }
  }, [requestStates.tournaments, tournaments.data]);

  const onScroll = () => {
    dispatch(getTeamTournaments(team?.id, tournaments.page + 1));
  };

  return requestStates.team.requesting ||
    (requestStates.tournaments.requesting &&
      (!tournamentsData ||
        !tournaments.data ||
        tournamentsData?.length === 0)) ? (
    <Placeholder />
  ) : (
    <Tournaments
      onScroll={onScroll}
      data={tournamentsData}
      url={url}
      error={error}
      hasMore={!tournaments.isLastPage}
    />
  );
};

export default TournamentsContainer;
