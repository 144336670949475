import React from "react";
import { mergeClasses } from "@twire/utility/helpers";
import UnopDropdown from "unop-react-dropdown";

import classes from "./DropDown.module.scss";

const Dropdown: React.FC<{
  open: boolean;
  onAppear: () => void;
  onDisappear: () => void;
  dropdownTrigger: any;
  className?: string;
}> = ({
  children,
  open,
  onAppear,
  onDisappear,
  dropdownTrigger,
  className,
}) => (
  <UnopDropdown
    trigger={dropdownTrigger({ open: open })}
    onAppear={onAppear}
    onDisappear={onDisappear}
    closeOnDropdownClicked
    closeOnClickOut
  >
    <ul
      className={mergeClasses(
        classes.list,
        "background-twire-menu-grey",
        className ? className : ""
      )}
    >
      {children}
    </ul>
  </UnopDropdown>
);

export default Dropdown;
