import { TwireScoreChartItem } from "@twire/common/TwireScoreChart/types";
import { PlayerStats } from "@twire/redux/playerRanking/types";
import { TWIRE_SCORE_SKILLS } from "@twire/utility/constants";

const scaleAndRound = (value: number) => {
  return (value / 50 - 1) * 100;
};

export const formatPlayerDataForChart = (
  stats: PlayerStats,
  averageStats: PlayerStats
): TwireScoreChartItem[] => {
  return [
    {
      id: 0,
      skill: TWIRE_SCORE_SKILLS.attk,
      value: scaleAndRound(stats.attackerScore),
      secondValue: scaleAndRound(averageStats.attackerScore),
      text: Math.round(stats.attackerScore).toString(),
    },
    {
      id: 1,
      skill: TWIRE_SCORE_SKILLS.fin,
      value: scaleAndRound(stats.finisherScore),
      secondValue: scaleAndRound(averageStats.finisherScore),
      text: Math.round(stats.finisherScore).toString(),
    },
    {
      id: 2,
      skill: TWIRE_SCORE_SKILLS.util,
      value: scaleAndRound(stats.utilityScore),
      secondValue: scaleAndRound(averageStats.utilityScore),
      text: Math.round(stats.utilityScore).toString(),
    },
    {
      id: 3,
      skill: TWIRE_SCORE_SKILLS.sur,
      value: scaleAndRound(stats.survivorScore),
      secondValue: scaleAndRound(averageStats.survivorScore),
      text: Math.round(stats.survivorScore).toString(),
    },
    {
      id: 4,
      skill: TWIRE_SCORE_SKILLS.team,
      value: scaleAndRound(stats.teammateScore),
      secondValue: scaleAndRound(averageStats.teammateScore),
      text: Math.round(stats.teammateScore).toString(),
    },
  ];
};
