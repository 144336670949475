import { ArticleCardData } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatArticleCardData = (data: any): ArticleCardData => ({
  id: data.id,
  title: data.title,
  summary: data.summary,
  updatedAt: data.updatedAt,
  commentsCount: data.numberOfComments,
  coverImage: data.summaryImg || "",
});
