import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";

import {
  displayFullPageLoadingOverlay as displayOverlay,
  hideFullPageLoadingOverlay,
} from "@twire/redux/app/actions";
import { mergeClasses } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { useUrl } from "@twire/utility/hooks";

// import { AppRoute } from "./Router";

import Dashboard from "./components/pages/Dashboard";
import classes from "./App.module.scss";
import { ScrollToTop } from "./ScrollToTop";
import i18n from "./i18n";
import { HashUrlWrapper } from "./HashUrlWrapper";
// import Login from "./components/pages/Login";
// import ForgotPassword from "./components/pages/ForgotPassword";

// const NotFound = () => <h1>NOT FOUND</h1>;

const RedirectToVerificationRoute = () => {
  const { replace, location } = useHistory();
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}${location.pathname}`);
  useEffect(() => {
    replace(url);
  }, []);
  return <></>;
};

const RedirectToResetPasswordRoute = () => {
  const { replace, location } = useHistory();
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}${location.pathname}`);
  useEffect(() => {
    replace(url);
  }, []);
  return <></>;
};

const Loader: FC<{ loadingText: string }> = () =>
  // { loadingText }
  {
    const {
      app: { hidingFullPageLoadingOverlay },
    } = useSelector((state: RootReducer) => state);
    return (
      <div
        className={mergeClasses(classes.fullPageOverlay, [
          !!hidingFullPageLoadingOverlay,
          classes.hide,
        ])}
        style={{ transition: `opacity ${hidingFullPageLoadingOverlay}s` }}
      >
        <img
          src="/images/animated/loader.gif"
          style={{ height: "25px" }}
          alt="loading"
        />
        {/* <p>{loadingText}</p> */}
      </div>
    );
  };

const App: FC = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    app: { requestStates, displayFullPageLoadingOverlay, showTooltip },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (
      requestStates.changingLanguage.requesting &&
      !displayFullPageLoadingOverlay
    ) {
      dispatch(displayOverlay("Loading..."));
    }
    if (
      !requestStates.changingLanguage.requesting &&
      displayFullPageLoadingOverlay
    ) {
      dispatch(hideFullPageLoadingOverlay());
    }
  }, [requestStates.changingLanguage]);

  i18n.on("initialized", () => {
    setPageLoading(false);
  });

  return (
    <>
      {(displayFullPageLoadingOverlay || pageLoading) && (
        <Loader loadingText={displayFullPageLoadingOverlay} />
      )}
      {showTooltip && (
        <div
          className={classes.tooltip}
          style={{ left: showTooltip.x, top: showTooltip.y }}
        >
          {showTooltip.value}
        </div>
      )}
      <Router>
        <HashUrlWrapper>
          <ScrollToTop>
            <Switch>
              {/* <AppRoute path="/:lang?/login" component={Login} exact />
              <AppRoute
                path="/:lang?/forgot-password"
                component={ForgotPassword}
                exact
              /> */}

              <Route
                path={URLS.RESET_PASSWORD}
                component={RedirectToResetPasswordRoute}
              />
              <Route
                path={URLS.VERIFICATION}
                component={RedirectToVerificationRoute}
              />
              <Route path="/:lang?/:game?" component={Dashboard} />
            </Switch>
          </ScrollToTop>
        </HashUrlWrapper>
      </Router>
    </>
  );
};

export default App;
