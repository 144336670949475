import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { t } from "@twire/utility/helpers";
import { getTeamStatistics } from "@twire/redux/team/actions";
import { ComponentErrorInterface } from "@twire/common/ComponentError";
import { ReactComponent as TrophySvg } from "../../../../../assets/svgs/trophy.svg";
import Statistics from "./Statistics";
import { saveUserAction } from "@twire/redux/track/actions";
import { teamStatisticsView } from "@twire/redux/track/userActions/userActions";

const StatisticsContainer: React.FC = () => {
  const [perTournament, setPerTournament] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState<ComponentErrorInterface | null>(null);
  const {
    team: { statistics, requestStates, team },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (requestStates.team.success && team) {
      dispatch(getTeamStatistics(team?.id || 0));
      dispatch(saveUserAction(teamStatisticsView(team.name)));
    }
  }, [requestStates.team]);

  useEffect(() => {
    if (requestStates.statistics.failed && !statistics) {
      setError({
        message: t("sentences.getTournamentsError"),
        suggestion: t("sentences.refreshThePage"),
        svg: TrophySvg,
        actionText: t("words.tryAgain"),
        onActionClicked: () => dispatch(getTeamStatistics(team?.id || 0)),
      });
    } else if (requestStates.statistics.success && statistics.length === 0) {
      setError({
        message: t("sentences.noDataAvailable"),
        suggestion: t("sentences.noTournamentsSuggestion"),
        svg: TrophySvg,
      });
    } else {
      setError(null);
    }
  }, [requestStates.statistics, statistics]);

  const togglePerTournament = () => {
    if (
      !requestStates.statistics.requesting &&
      !requestStates.team.requesting
    ) {
      setPerTournament(!perTournament);
    }
  };

  return (
    <Statistics
      perTournament={perTournament}
      togglePerTournament={togglePerTournament}
      error={error}
    />
  );
};

export default StatisticsContainer;
