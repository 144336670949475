import {FC, ReactNode} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import recaptchaConfig from '../../../recaptcha-config';

type RecaptchaWrapperProps = {
    children: ReactNode;
}

const RecaptchaWrapper: FC<RecaptchaWrapperProps> = ({children}) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaConfig.public_key}>
            {children}
        </GoogleReCaptchaProvider>
    )
}

export default RecaptchaWrapper;