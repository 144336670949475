import { AnyAction } from "redux";
import { PaymentMethod } from "@stripe/stripe-js";
import * as actionTypes from "./actionTypes";

export const getSubscriptionPlans = (): AnyAction => ({
  type: actionTypes.GET_SUBSCRIPTION_PLANS,
});

export const subscribeToPlan = (
  planId: string,
  paymentMethod: PaymentMethod
): AnyAction => ({
  type: actionTypes.SUBSCRIBE_USER_TO_PLAN,
  planId,
  paymentMethod,
});

export const unsubscribeFromPlan = (): AnyAction => ({
  type: actionTypes.UNSUBSCRIBE_USER_FROM_PLAN,
});
