import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const HashUrlWrapper: React.FC = ({ children }) => {
  const { replace, location } = useHistory();

  useEffect(() => {
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
      replace(path);
    }
  }, [location]);

  return <>{ location.hash ? null : children }</>;
};