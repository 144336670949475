import React from "react";
import { Link } from "react-router-dom";
import RankingTile from "../../../common/RankingTile/RankingTile";
import classes from "./TeamRanking.module.scss";
import { RankingTeam } from "@twire/redux/teamRanking/types";
import { getTheme, t } from "@twire/utility/helpers";
import ComponentError from "@twire/common/ComponentError";
import Placeholder from "./Placeholder";
import { ReactComponent as Trophy } from "../../../../assets/svgs/trophy.svg";
import { mergeClasses } from "@twire/utility/helpers";
import { Games } from "@twire/utility/constants";
import { useGetTeamUrl } from "@twire/utility/hooks";
import Text from "@twire/common/Text";

const Ranking: React.FC<{
  topTeams: RankingTeam[];
  loading: boolean;
  updatedAt: string;
  showError: boolean;
  game: Games;
  getTeamUrl: ReturnType<typeof useGetTeamUrl>;
}> = ({ topTeams, loading, updatedAt, showError, game, getTeamUrl }) => (
  <div className={classes.container}>
    <div className={mergeClasses("w-full", classes.left)}>
      <div className={classes.top}>
        <h2>team ranking</h2>
        <p>
          Last updated on:{" "}
          {updatedAt
            ? new Date(Date.parse(updatedAt)).toLocaleDateString()
            : "-- : -- : --"}
        </p>
      </div>
      <div className={mergeClasses("flex items-center", classes.heading)}>
        <div className={classes.rank}>
          <Text i18nKey="words.rank">rank</Text>
        </div>
        <div className={classes.team}>
          <Text i18nKey="words.team">team</Text>
        </div>
        <div className={classes.point}>
          <Text i18nKey="words.rankingPoints">points</Text>
        </div>
        {/* <div className={classes.region}>region</div> */}
      </div>
      {loading ? (
        <Placeholder />
      ) : showError ? (
        <ComponentError
          message={t("sentences.rankingLoadFailed")}
          svg={Trophy}
          suggestion={t("sentences.refreshThePage")}
          className="h-96 mt-4"
        />
      ) : (
        topTeams.map(({ id, name, ranking }, index) => (
          <React.Fragment key={id}>
            <Link to={getTeamUrl(id || 0, name)} className="block my-1">
              <RankingTile
                rank={index + 1}
                team={name}
                theme={getTheme(index)}
                points={String(ranking)}
                game={game}
              />
            </Link>
          </React.Fragment>
        ))
      )}
      <div className={classes.bottom}>
        <h3>How ranking is calculated?</h3>
        <p>
          Twire Team Ranking evaluates teams based on their performance in all stages of official 
          PUBG Esports global tournaments and the final stage of official PUBG Esports regional tournaments. 
          The ranking includes a decay factor, meaning the points earned in a tournament will decrease over time.
        </p>
      </div>
      <div className={classes.bottom}>
        <p>
          Each team&apos;s score is calculated using four categories:
        </p>
      </div>
      <div className={classes.bottom}>
        <h3>Achievement (max 300 points):</h3>
        <p>Overall performance over the past 365 days.</p>
      </div>
      <div className={classes.bottom}>
        <h3>Form (max 250 points):</h3>
        <p>Current performance in tournaments within the last 90 days.</p>
      </div>
      <div className={classes.bottom}>
        <h3>Regional performance (max 200 points):</h3>
        <p>Performance in regional tournaments over the past 365 days.</p>
      </div>
      <div className={classes.bottom}>
        <h3>Global performance (max 250 points):</h3>
        <p>Performance in global tournaments over the past 365 days.</p>
      </div>
    </div>
  </div>
);

export default Ranking;
