import React from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { useLanguageChange } from "@twire/utility/hooks";
import { MatchTileProps } from "./types";
import MatchTile from "./MatchTile";

const MatchTileContainer: React.FC<MatchTileProps> = ({ ...rest }) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const lang = useLanguageChange();
  return <MatchTile game={game} lang={lang} {...rest} />;
};

export default MatchTileContainer;
