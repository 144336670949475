import { useRouteMatch, useHistory } from "react-router-dom";
import { useUrl } from "@twire/utility/hooks";
// import { RootReducer } from "@twire/redux/types";
import {
  getTournament,
  clearTournament,
  clearFilters,
  unsetStatsFilters,
} from "@twire/redux/tournament/actions";
import { RootReducer } from "@twire/redux/types";
import { urlFriendlyString, TwireUrl } from "@twire/utility/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import Tournament from "./Tournament";
import { saveUserAction } from "@twire/redux/track/actions";
import { clearBanner, setBanner } from "@twire/redux/app/actions";
import { tournamentPageView } from "@twire/redux/track/userActions/userActions";
import { BannerTypes } from "../../../../../redux/app/types";

const TournamentContainer: React.FC = () => {
  const { params } = useRouteMatch();

  const history = useHistory();

  const dispatch = useDispatch();

  const {
    tournament: { requestStates, tournament, filter, token },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const errorRedirecturl = useUrl(`${game}/tournaments/ongoing`);

  const { id, name } = params as { id: string; name: string };

  const urlIdRef = useRef<string>(id.toString());

  useEffect(() => {
    dispatch(getTournament(id));
    return () => {
      dispatch(clearTournament());
      dispatch(clearFilters());
      dispatch(clearBanner());
      dispatch(unsetStatsFilters());
    };
  }, []);

  useEffect(() => {
    if ((id && !urlIdRef.current) || id !== urlIdRef.current) {
      urlIdRef.current = id;
      dispatch(getTournament(id));
      dispatch(clearFilters());
      dispatch(clearBanner());
      dispatch(unsetStatsFilters());
    }
  }, [id]);

  useEffect(() => {
    if (requestStates.tournament.success) {
      if (name !== urlFriendlyString(tournament?.friendlyName || "")) {
        history.replace(
          history.location.pathname.replace(
            name,
            urlFriendlyString(tournament?.friendlyName || "")
          )
        );
      }
      if (tournament?.tournamentMessage) {
        dispatch(
          setBanner({
            node: (
              <p
                className={"open-sans flex items-center justify-center text-sm"}
              >
                {tournament?.tournamentMessage}
              </p>
            ),
            type: BannerTypes.ERROR,
          })
        );
      }
    }
  }, [requestStates.tournament, history.location]);

  useEffect(() => {
    if (requestStates.tournament.success) {
      dispatch(
        saveUserAction(
          tournamentPageView(
            tournament?.friendlyName,
            tournament?.eventType,
            tournament?.region
          )
        )
      );
    }
  }, [tournament]);

  useEffect(() => {
    if (requestStates.tournament.success) {
      TwireUrl.set("round", filter.parent);
      TwireUrl.set("group", filter.child);
    }
  }, [requestStates.tournament]);

  useEffect(() => {
    if (token && !TwireUrl.get("token")) {
      TwireUrl.set("token", token);
    }
  }, [history.location]);

  const onErrorClicked = () => {
    history.push(errorRedirecturl + location.search);
  };

  useEffect(() => {
    return () => {
      TwireUrl.delete("round");
      TwireUrl.delete("group");
    };
  }, []);

  return (
    <Tournament
      failed={!!requestStates.tournament.failed}
      onErrorClicked={onErrorClicked}
    />
  );
};

export default TournamentContainer;
