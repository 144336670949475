import { useState } from "react";
import MobileMainSection from "./MobileMainSection";

const MobileMainSectionContainer: React.FC = () => {
  const [tab, setTab] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onClick = (index: number) => {
    setTab(index);
  };
  return (
    <MobileMainSection
      index={tab}
      onClick={onClick}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
    />
  );
};

export default MobileMainSectionContainer;
