import React from "react";
import { getStripeKey } from "../../../../stripe-config";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Subscription from "./Settings";

const stripePromise = loadStripe(getStripeKey());

const SubscriptionContainer: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <Subscription />
    </Elements>
  );
};

export default SubscriptionContainer;
