import { LeaderboardRow, LeaderboardHeaderType } from "./types";
import { formatToHeaderMap } from "./constants";
import { GeneralTournamentFormat } from "../../../../../../../utility/constants";
// eslint-disable-next-line
export const formatLeaderboardData = (leaderboard: any): LeaderboardRow[] => {
  return leaderboard.map((leaderboardData: any) => {
    return {
      id: leaderboardData.teamId,
      team: leaderboardData.team,
      badge: leaderboardData.teamImage || "",
      wins: leaderboardData.wins,
      dmg: leaderboardData.damageDealt,
      kills: leaderboardData.kills,
      totalPoints: leaderboardData.totalPoints,
      points: leaderboardData.points,
      logo: leaderboardData.logo,
      numberOfMatches: leaderboardData.numberOfMatches,
      advance: leaderboardData.advance,
      positionDiff: leaderboardData.previousPositionChange,
      positionChange: leaderboardData.previousPosition,
      players: leaderboardData.players || [],
      statsPerMatch: leaderboardData.statsPerMatch,
    };
  });
};

export const getHeader = (
  format: string | null | undefined
): LeaderboardHeaderType[] => {
  return formatToHeaderMap[format || GeneralTournamentFormat.POINT_SYSTEM];
};
