import React from "react";
import Dropdown from "unop-react-dropdown";
import { CategorizedFilterProps } from "./types";
import { ReactComponent as ArrowTip } from "../../../assets/svgs/arrowTip.svg";
import classes from "./CategorizedFilter.module.scss";
import { mergeClasses } from "../../../utility/helpers";

const FilterTrigger: React.FC<CategorizedFilterProps> = (props) => (
  <button
    className={mergeClasses(
      classes.w200,
      classes.h40,
      classes.pBig,
      "ml-auto flex items-center background-twire-grey text-xs"
    )}
    {...props}
  >
    <div className="text-twire-light opacity-50">filter:</div>
    <div className="text-twire-light ml-2 font-bold truncate pr-2">
      {props.selected.reduce((a, b, i) => {
        a += `${i !== 0 ? ", " : ""}${b.name}`;
        return a;
      }, "")}
    </div>
    <ArrowTip className={mergeClasses("ml-auto", classes.arrow)} />
  </button>
);

const CategorizedFilter: React.FC<CategorizedFilterProps> = (props) => (
  <Dropdown
    dropdownWrapperClassName={classes.z5}
    closeOnClickOut
    trigger={<FilterTrigger {...props} />}
  >
    <ul
      className={mergeClasses(
        classes.mainList,
        classes.w200,
        classes.pSmall,
        classes.dropdown,
        "background-twire-dark-ash"
      )}
    >
      {props.categories.map((parent, index) => (
        <ul key={parent.id}>
          {parent.options.map((child, childIndex) => (
            <li
              key={child.value}
              className={mergeClasses(
                classes.pBig,
                "bg-black-20, w-full",
                "flex items-center",
                classes.h40,
                [
                  !!props.selected.find((op) => child.value === op.value),
                  classes.lightBlack,
                ]
              )}
              onClick={() => {
                props.onOptionSelect(
                  { id: parent.id, index },
                  { index: childIndex, parentId: parent.id, ...child },
                  !!props.selected.find((op) => child.value === op.value)
                );
              }}
            >
              <button
                type="button"
                className={mergeClasses("twire-radio", [
                  !!props.selected.find((op) => child.value === op.value),
                  "twire-radio-active",
                ])}
              ></button>
              <span
                className={mergeClasses(
                  "ml-2 text-twire-light text-xs open-sans",
                  [
                    !!props.selected.find((op) => child.value === op.value),
                    "font-bold",
                  ]
                )}
              >
                {child.name}
              </span>
            </li>
          ))}
        </ul>
      ))}
    </ul>
  </Dropdown>
);

export default CategorizedFilter;
