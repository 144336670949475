import { RankingTeam } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatTeamRankingData = (data: any): RankingTeam => ({
  id: data.id,
  name: data.name,
  ranking: data.ranking,
  previousPos: data.previousPosition,
  prevPosChange: data.previousPositionChange,
});
