import { FC, useEffect, useRef } from "react";
import i18next from "i18next";
import { Route, RouteProps, useParams, useHistory } from "react-router-dom";
import { Games, supportedLangs } from "@twire/utility/constants";
import { changeLanguage } from "@twire/redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../../redux/types";
import { setGame } from "@twire/redux/app/actions";
import { url } from "@twire/utility/helpers";
import { useTranslation } from "react-i18next";
import { SeoData } from "./types";
import { getSeoData, Pages } from "./constants";

interface DashboardRouteInterface extends RouteProps {
  component: React.ComponentType<any>;
}

/* This is responsible for checking if the game passed
in is valid and is supported by twire */
const isValidGame = (game: string) => {
  if (game && Object.values(Games).includes(game as Games)) return true;
  return false;
};

/* This attempts to extract the game from the URL in the case the game cannot
be extracted from the url because of AWS's #!*/
const extractGameFromUrl = (url: string) => {
  const values = url.split("/");
  return values[2];
};

/* This sets the page title and description for SEO optimization */
const setSeoData = (url: string, state: RootReducer) => {
  let pageSeoData: SeoData;
  if (url.includes("/fantasy/tournaments")) {
    pageSeoData = getSeoData(Pages.FANTASY_LIST_PAGE);
  } else if (url.includes("/tournaments/tournament")) {
    const tournamentName = state.tournament.tournament?.friendlyName;
    if (tournamentName) {
      pageSeoData = getSeoData(Pages.TOURNAMENT_PAGE, tournamentName);
    } else {
      return;
    }
  } else if (url.includes("/tournaments")) {
    pageSeoData = getSeoData(Pages.TOURNAMENTS_LIST_PAGE);
  } else if (url.includes("/scrims")) {
    pageSeoData = getSeoData(Pages.SCRIMS_LIST_PAGE);
  } else if (url.includes("/team-ranking")) {
    pageSeoData = getSeoData(Pages.TEAM_RANKING_PAGE);
  } else if (url.includes("/news/")) {
    pageSeoData = getSeoData(
      Pages.ARTICLE_PAGE,
      state.article.title,
      state.article.summary
    );
  } else if (url.includes("/news")) {
    pageSeoData = getSeoData(Pages.NEWS_PAGE);
  } else {
    pageSeoData = getSeoData(Pages.HOME_PAGE);
  }
  document.title = pageSeoData.title;
  document
    .querySelector("meta[name='description']")
    ?.setAttribute("content", pageSeoData.description);
};

const isValidLang = (lang: string) => {
  return lang && supportedLangs.includes(lang);
};

/* This component is responsible for ensuring that every route
visited is associated with a game. It also redirects the user to
the game found in cookin/default game if the game in not specified
in url or the game specified in url is invalid/unsupported*/
const GameUrlWrapper: FC = ({ children }) => {
  const params = useParams<{ lang: string; game: string }>();
  const { i18n } = useTranslation();
  const { replace, location } = useHistory();
  const dispatch = useDispatch();
  const {
    app: { game, requestStates },
  } = useSelector((state: RootReducer) => state);
  const state: RootReducer = useSelector((state: RootReducer) => state);
  const gameRef = useRef(game);

  const replaceUrl = () => {
    const path = location.pathname + location.search;
    // Get lang from the URL
    const pathSplit = path.split("/");
    let selectedLang = params.lang;
    if (pathSplit.length > 1 && isValidLang(pathSplit[1])) {
      selectedLang = pathSplit[1];
    }
    // Adding language to URL
    const redirUrl = !selectedLang
      ? `/${i18n.language}${path.replace(`/${i18n.language}/`, "/")}`
      : `${path.replace(`/${selectedLang}/`, `/${i18n.language}/`)}`;
    replace(redirUrl);
  };

  const gameValue = params.game || extractGameFromUrl(location.pathname);

  useEffect(() => {
    if (isValidLang(params.lang)) {
      if (i18n.language !== params.lang) {
        // i18n.changeLanguage(lang);
        dispatch(changeLanguage(params.lang));
      }
    } else {
      if (i18n.isInitialized) {
        replaceUrl();
      } else {
        i18next.on("initialized", () => {
          replaceUrl();
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isValidGame(gameValue)) {
      if (game === gameRef.current && gameRef.current !== gameValue) {
        gameRef.current = gameValue as Games;
        dispatch(setGame(gameValue));
      }
      if (game !== gameRef.current) {
        if (gameValue === gameRef.current) {
          replace(
            location.pathname.replace(`/${gameValue}`, `/${game}`) +
              location.search
          );
        }
        gameRef.current = game;
      }
    } else {
      i18next.on("initialized", () => {
        let redirUrl = url(`/${game}`);
        if (isValidGame(extractGameFromUrl(location.pathname))) {
          redirUrl = location.pathname;
        }
        replace(redirUrl + location.search);
      });
    }
  }, [game, i18n, params.lang, location, replace]);

  useEffect(() => {
    if (
      isValidLang(params.lang) &&
      requestStates.changingLanguage.success &&
      i18n.language !== params.lang
    ) {
      replaceUrl();
    }
  }, [requestStates.changingLanguage]);

  useEffect(() => {
    setSeoData(location.pathname, state);
  }, [state]);

  return <>{children}</>;
};

export const DashboardRoute: FC<DashboardRouteInterface> = ({
  path,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      path={path}
      render={() => (
        <GameUrlWrapper>
          <Component />
        </GameUrlWrapper>
      )}
      {...rest}
    />
  );
};
