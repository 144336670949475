import { t } from "@twire/utility/helpers";
import Filter from "@twire/common/Filter";
import Select, { InstanceType } from "@twire/common/Select";
// import { } from "@twire/common/Select/types";
import classes from "./TournamentsFilter.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const TournamentsFilter: React.FC<{
  regionInstance: InstanceType;
  tierInstance: InstanceType;
  onApplyClicked: () => void;
  onClearClicked: () => void;
  onOpen?: (arg?: any) => void;
  onClose?: (arg?: any) => void;
}> = ({
  regionInstance,
  tierInstance,
  onApplyClicked,
  onClearClicked,
  ...rest
}) => (
  <Filter
    onApplyClicked={onApplyClicked}
    onClearClicked={onClearClicked}
    {...rest}
  >
    <div className={mergeClasses("flex w-full space-x-4", classes.container)}>
      <Select instance={regionInstance} title={t("words.region")} />
      <Select instance={tierInstance} title={t("words.tier")} />
    </div>
  </Filter>
);

export default TournamentsFilter;
