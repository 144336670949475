import { AnyAction } from "redux";
import * as actionTypes from "./actionTypes";
import { SelectedFilterType, SetRankingFilterAction } from "./types";

export const getPlayerRanking = (): AnyAction => ({
  type: actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING,
});

export const setPlayerRankingFilter = (
  args: SelectedFilterType
): SetRankingFilterAction => ({
  type: actionTypes.SET_PLAYER_RANKING_FILTER,
  currentFilter: args,
});
