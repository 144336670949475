import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { RankedPlayersInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as playerRankingHandlers from "./handlers/getPlayerRanking";

export const INITIAL_STATE: RankedPlayersInterface = {
  data: null,
  updatedAt: "",
  currentFilter: null,
  filters: [],
  requestStates: {
    rankedPlayers: { ...defaultRequestState },
  },
};

const playerRankingReducer: Reducer<RankedPlayersInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_REQUESTED:
      return playerRankingHandlers.requested(state);
    case actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_FAILED:
      return playerRankingHandlers.failed(state, action);
    case actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_SUCCESS:
      return playerRankingHandlers.success(state, action);
    case actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_CLEAR:
      return playerRankingHandlers.clear();
    case actionTypes.SET_PLAYER_RANKING_FILTER:
      return {
        ...state,
        currentFilter: { ...action.currentFilter },
      };
    default:
      return state;
  }
};

export default playerRankingReducer;
