import {
  mergeClasses,
  roundToX,
  setDefaultTeamLogo,
} from "@twire/utility/helpers";
import React from "react";
import ScrollableTableShade from "@twire/common/ScrollableTableShade";
import ScreenSizeButton from "@twire/common/ScreenSizeButton";
import { HeaderType, DataModel, ColHeadingType } from "./types";
import { ReactComponent as ArrowTip } from "../../../../../../../assets/svgs/arrowTip.svg";
import classes from "./AdvancedTable.module.scss";
import Text from "@twire/common/Text";
import { LanguagePath, Path } from "@twire/utility/constants";

const AdvancedTable: React.FC<{
  headers: HeaderType;
  colHeading: ColHeadingType;
  data: DataModel;
  sortedBy: string;
  sortByKey: (key: any) => void;
  highToLow: boolean;
  containerRef: any;
  tableRef: any;
  onMouseEnterStatHeader: (e: React.MouseEvent, description: string) => void;
  onMouseLeaveStatHeader: () => void;
  onResizeClick: () => void;
  fullscreen: boolean;
}> = ({
  headers: h,
  data,
  sortedBy,
  sortByKey,
  highToLow,
  colHeading,
  containerRef,
  tableRef,
  onMouseEnterStatHeader,
  onMouseLeaveStatHeader,
  onResizeClick,
  fullscreen,
}) => (
  <div className="relative">
    <div className={classes.resizer}>
      <ScreenSizeButton onClick={onResizeClick} alt={fullscreen} />
    </div>
    <ScrollableTableShade scrollableRef={containerRef} tableRef={tableRef} />
    <div className={mergeClasses(classes.container)} ref={containerRef}>
      <table className={classes.table} ref={tableRef}>
        <thead className={classes.tableHead}>
          <tr className={classes.header}>
            {h.map(({ headerValue, headerId, description }, headerIndex) =>
              headerIndex !== 0 ? (
                <td
                  key={headerId + headerIndex + headerValue}
                  style={{
                    transform: `translateX(${headerIndex === 0 ? 4 : 0}px)`,
                  }}
                  onMouseEnter={(e) => {
                    onMouseEnterStatHeader(e, description || "");
                  }}
                  onMouseLeave={onMouseLeaveStatHeader}
                >
                  <div
                    className={mergeClasses(
                      classes.tableHeading,
                      classes.cell,
                      classes.centered,
                      "background-twire-dark-ash cursor-pointer"
                    )}
                    onClick={() => sortByKey(headerId)}
                  >
                    <div className={classes.content}>
                      <Text i18nKey={`words.${headerId}` as Path<LanguagePath>}>
                        {headerValue}
                      </Text>
                      {sortedBy === headerId && (
                        <div
                          className={mergeClasses(classes.arrows, [
                            sortedBy === headerId,
                            classes.visible,
                          ])}
                        >
                          <button
                            className={mergeClasses(classes.top, [
                              !highToLow,
                              classes.fade,
                            ])}
                          >
                            <ArrowTip />
                          </button>
                          <button
                            className={mergeClasses(classes.bottom, [
                              highToLow,
                              classes.fade,
                            ])}
                          >
                            <ArrowTip />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              ) : (
                <td
                  className={mergeClasses(
                    classes.bigCell,
                    classes.cell,
                    "background-twire-dark-ash cursor-pointer",
                    classes.rightColHead,
                    "py-0"
                  )}
                >
                  <div
                    onClick={() => sortByKey(colHeading.sortId)}
                    className={mergeClasses(
                      classes.content,
                      classes.firstCell,
                      "relative"
                    )}
                  >
                    {colHeading.value}
                    {sortedBy === colHeading.sortId && (
                      <div
                        className={mergeClasses(
                          classes.arrows,
                          classes.firstHeaderArrow,
                          [sortedBy === headerId, classes.visible]
                        )}
                      >
                        <button
                          className={mergeClasses(classes.top, [
                            !highToLow,
                            classes.fade,
                          ])}
                        >
                          <ArrowTip />
                        </button>
                        <button
                          className={mergeClasses(classes.bottom, [
                            highToLow,
                            classes.fade,
                          ])}
                        >
                          <ArrowTip />
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {data.map(({ teamName, teamLogo, ...rest }: any, index: number) => (
            <tr
              key={index + teamName}
              style={{ transform: `translateY(${4 * (index + 1)}px)` }}
            >
              <td
                className={mergeClasses(
                  classes.bigCell,
                  classes.cell,
                  "background-twire-dark-ash text-xl"
                )}
              >
                <div
                  className={mergeClasses(classes.content, classes.firstCell)}
                >
                  <img
                    src={teamLogo}
                    className={classes.logo}
                    onError={setDefaultTeamLogo}
                    alt="logo"
                  />
                  <span className="truncate">{teamName}</span>
                </div>
              </td>
              {h.map(({ headerId, component: Component }, headerIndex) =>
                headerIndex !== 0 ? (
                  <td
                    key={headerIndex + headerId}
                    className={mergeClasses(
                      classes.cell,
                      classes.centered,
                      "background-twire-grey text-xl"
                    )}
                    style={{
                      transform: `translateX(${headerIndex === 0 ? 4 : 0}px)`,
                    }}
                  >
                    <div className={classes.content}>
                      {Component ? (
                        <Component value={rest.data[headerId]} />
                      ) : typeof rest.data[headerId] === "string" ? (
                        rest.data[headerId]
                      ) : (
                        roundToX(rest.data[headerId], 1)
                      )}
                    </div>
                  </td>
                ) : (
                  <></>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default AdvancedTable;
