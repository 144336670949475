import React from "react";
import TwireButton from "@twire/common/TwireButton";

import classes from "./FantasyBanner.module.scss";
import Text from "../Text";

const FantasyBanner: React.FC = () => (
  <div className={classes.container}>
    <h3>
      <Text i18nKey="sentences.playFantasy">Play Fantasy League</Text>
    </h3>
    <p>
      <Text i18nKey="sentences.playFantasyDescription">
        Build your own team and compete agains other users!
      </Text>
    </p>
    <a
      href="https://fantasy.twire.gg"
      target="_blank"
      rel="noreferrer"
      className={classes.fancy}
    >
      <TwireButton className={classes.button}>
        <Text i18nKey="words.playNow">Play Now</Text>
      </TwireButton>
    </a>
  </div>
);

export default FantasyBanner;
