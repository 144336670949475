import { ReactComponent as SuccessSVG } from "../../../assets/svgs/success.svg";
import TwireButton from "@twire/common/TwireButton";
import TwireLink from "@twire/common/TwireLink";
import Text from "@twire/common/Text";

const Success: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <SuccessSVG className="mb-4" />
      <h1><Text i18nKey="words.success">Success</Text></h1>
      <h5 className="mb-4 open-sans text-sm text-center">
        <Text i18nKey="sentences.passwordResetSuccessfully">
          Password reset successfully
        </Text>
      </h5>
      <TwireLink to="/login" className="w-full">
        <TwireButton>
          <Text i18nKey="login.loginText">login</Text>
        </TwireButton>
      </TwireLink>
    </div>
  );
};

export default Success;
