import React from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import PremiumBanner from "./PremiumBanner";

const PremiumBannerContainer: React.FC = () => {
  const {
    user: { userSubscription },
  } = useSelector((state: RootReducer) => state);
  return !(userSubscription.status) ? (
    <PremiumBanner />
  ) : (
    <></>
  );
};

export default PremiumBannerContainer;
