import React from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import TeamInfo from "./TeamInfo";
import Placeholder from "./Placeholder";

const TeamInfoContainer: React.FC = () => {
  const {
    team: { team, requestStates },
  } = useSelector((state: RootReducer) => state);

  return !requestStates.team.requesting ? (
    <TeamInfo name={team?.name || ""} logo={team?.logo || ""} />
  ) : (
    <Placeholder />
  );
};

export default TeamInfoContainer;
