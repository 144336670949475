import { AnyAction } from "redux";
import { TournamentInterface } from "../types";

export const requested = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    teamStats: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  teamStats: action.teamStats,
  requestStates: {
    ...state.requestStates,
    teamStats: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    teamStats: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  teamStats: null,
  requestStates: {
    ...state.requestStates,
    teamStats: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
