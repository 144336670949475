import React from "react";
import Card from "./Card";
import { TeamStatistic } from "../../../../../../PublicAPI";
import { formatStatisticData } from "./functions";
import classes from "./Cards.module.scss";
import { mergeClasses } from "../../../../../../utility/helpers";

const Cards: React.FC<{ teamStatistic: TeamStatistic[] }> = ({
  teamStatistic,
}) => (
  <div className={mergeClasses(classes.container, "appear")}>
    {teamStatistic.map((statisticData, index) => (
      <React.Fragment key={index}>
        <Card
          name={statisticData.statisticName}
          overall={formatStatisticData(
            statisticData.overallValue,
            statisticData.statisticName
          )}
        />
      </React.Fragment>
    ))}
  </div>
);

export default Cards;
