import { Link, LinkProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { useUrl } from "@twire/utility/hooks";

const TwireLink: React.FC<LinkProps> = ({ to, ...rest }) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}${to}`);
  return <Link to={url} {...rest}></Link>;
};

export default TwireLink;
