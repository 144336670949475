import React from "react";
import { RootReducer } from "@twire/redux/types";
import { clearModal } from "@twire/redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseSvg } from "../../../assets/svgs/close.svg";

import classes from "./Modal.module.scss";

const Modal: React.FC = () => {
  const {
    app: { modalElement },
  } = useSelector((state: RootReducer) => state);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(clearModal());
  };
  return modalElement ? (
    <div className={classes.container}>
      <div className={classes.modal}>
        <button className={classes.close} onClick={() => closeModal()}>
          <CloseSvg />
        </button>
        {modalElement}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
