export const config = {
  DEV_KEY:
    "pk_test_51IklrqGitMJcD7IOUzZqg4qDpKqHhvIjZ5kiuQMzClihLshGKkXUELds70yg3JbDmVDWGVKPnpjybkACT6EeZTrP000SZc40gD",
  PROD_KEY:
    "pk_live_51IklrqGitMJcD7IO2gjRvt9duLtRXjyMEU2yX240QjRaIlfo0OUQ3vyDFlKHH5Y8CnBuDVVFvbNGuvpOk5G7d8l700Kzvp0kdU",
};

export const getStripeKey = (): string => {
  if (process.env.REACT_APP_STAGE === "prod") {
    return config.PROD_KEY;
  } else {
    return config.DEV_KEY;
  }
};
