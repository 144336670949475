import { PlayerRankingSortType, PlayerStatKey } from "../types";
import externalClass from "@twire/common/PlayerRankingTile/RankingTile.module.scss";
import classes from "./PlayerRankingHeader.module.scss";
import { mergeClasses } from "@twire/utility/helpers";
import { ReactComponent as ArrowTip } from "../../../../../assets/svgs/arrowTip.svg";

const statButtons: { name: string; key: PlayerStatKey }[] = [
  { name: "Attacker", key: "attackerScore" },
  { name: "Utility", key: "utilityScore" },
  { name: "Survivor", key: "survivorScore" },
  { name: "Teammate", key: "teammateScore" },
  { name: "Finisher", key: "finisherScore" },
];

const PlayerRankingHeader: React.FC<{
  setSortby: (key: PlayerStatKey) => void;
  sortedBy: PlayerRankingSortType;
}> = ({ setSortby, sortedBy }) => (
  <div className={mergeClasses(externalClass.container, classes.container)}>
    <div className={mergeClasses(externalClass.first)}>
      <div />
      <div />
      <div
        className={mergeClasses(
          "text-twire-light rift-bold",
          classes.playerName
        )}
      >
        Player name
      </div>
      <button
        className={mergeClasses(
          "text-twire-light rift-bold ml-auto relative",
          externalClass.progressWrapper,
          classes.overallHeader
        )}
        onClick={() => setSortby("overallScore")}
      >
        Overall
        <div
          className={mergeClasses(classes.arrows, [
            sortedBy[0] === "overallScore",
            classes.visible,
          ])}
        >
          <div
            className={mergeClasses(classes.top, [
              sortedBy[1] === "ASCENDING",
              classes.fade,
            ])}
          >
            <ArrowTip />
          </div>
          <div
            className={mergeClasses(classes.bottom, [
              sortedBy[1] === "DESCENDING",
              classes.fade,
            ])}
          >
            <ArrowTip />
          </div>
        </div>
      </button>
      <div
        className={mergeClasses(
          "flex ml-auto",
          externalClass.statsWrapper,
          classes.statHeaders
        )}
      >
        {statButtons.map((statData) => (
          <button key={statData.key} onClick={() => setSortby(statData.key)}>
            <div className={mergeClasses(classes.button, "rift-bold")}>
              {statData.name}
              <div
                className={mergeClasses(classes.arrows, [
                  sortedBy[0] === statData.key,
                  classes.visible,
                ])}
              >
                <div
                  className={mergeClasses(classes.top, [
                    sortedBy[1] === "ASCENDING",
                    classes.fade,
                  ])}
                >
                  <ArrowTip />
                </div>
                <div
                  className={mergeClasses(classes.bottom, [
                    sortedBy[1] === "DESCENDING",
                    classes.fade,
                  ])}
                >
                  <ArrowTip />
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  </div>
);

export default PlayerRankingHeader;
