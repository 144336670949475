import {
  all,
  put,
  takeLatest,
  call,
  // select
} from "@redux-saga/core/effects";
// import { RootReducer } from "@twire/redux/types";

import * as actionTypes from "./actionTypes";
import { formatTeamRankingData } from "./functions";

// import { publicQuery } from "../../graphql/requestHelper";
// import { teamRanking } from "../../graphql/queries";

function* fetchTopTeams() {
  yield put({ type: actionTypes.TEAM_RANKING_GET_TEAM_RANKING_REQUESTED });

  // const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    // const topTeams: Generator<any, any> = yield publicQuery(teamRanking, {
    //   // game: state.app.game,
    //   game: "pubg",
    // });

    const response: Generator<any, any> = yield call(
      fetch,
      "https://twire-assets.s3.eu-west-1.amazonaws.com/pubg/team-ranking/team-ranking.json"
    );
    const jsonResponse: Generator<any, any> = yield (
      response as any as Response
    ).json();
    const topTeams = { data: { teamRanking: jsonResponse } };

    yield put({
      type: actionTypes.TEAM_RANKING_GET_TEAM_RANKING_SUCCESS,
      topTeams: (topTeams as any).data.teamRanking.teams.map((data: any) =>
        formatTeamRankingData(data)
      ),
      updatedAt: (topTeams as any).data.teamRanking.updatedAt,
    });
  } catch (error) {
    yield put({ type: actionTypes.TEAM_RANKING_GET_TEAM_RANKING_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([takeLatest(actionTypes.TEAM_RANKING_GET_TEAM_RANKING, fetchTopTeams)]);
}
