import { AnyAction } from "redux";
import { LOCAL_STORAGE_KEYS } from "@twire/utility/constants";
import { TournamentsInterface, TimeType } from "../types";

export const success = (
  state: TournamentsInterface,
  action: AnyAction,
  key: TimeType
): TournamentsInterface => ({
  ...state,
  tournaments: {
    ...state.tournaments,
    [key]: {
      ...state.tournaments[key],
      filter: action.payload.filter,
    },
  },
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      filter: { failed: false, requesting: false, success: true },
    },
  },
});

export const requested = (
  state: TournamentsInterface,
  key: TimeType
): TournamentsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      filter: { failed: false, requesting: true, success: false },
    },
  },
});

export const failed = (
  state: TournamentsInterface,
  action: AnyAction,
  key: TimeType
): TournamentsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      filter: { failed: action.error, requesting: false, success: false },
    },
  },
});

export const clear = (
  state: TournamentsInterface,
  key: TimeType
): TournamentsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      filter: { failed: false, requesting: false, success: false },
    },
  },
});

export const setGlobalFilter = (
  state: TournamentsInterface,
  action: AnyAction
): TournamentsInterface => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.globalTournamentFilters,
    JSON.stringify(action.filter)
  );
  return {
    ...state,
    tournaments: {
      ...state.tournaments,
      filter: action.filter,
    },
  };
};
