export const TEAM_GET_TEAM = 
  "TEAM_GET_TEAM";
export const TEAM_GET_TEAM_REQUESTED = 
  "TEAM_GET_TEAM_REQUESTED";
export const TEAM_GET_TEAM_SUCCESS = 
  "TEAM_GET_TEAM_SUCCESS";
export const TEAM_GET_TEAM_FAILED = 
  "TEAM_GET_TEAM_FAILED";
export const TEAM_GET_TEAM_CLEAR = 
  "TEAM_GET_TEAM_CLEAR";

  export const TEAM_GET_TOURNAMENTS = 
  "TEAM_GET_TOURNAMENTS";
export const TEAM_GET_TOURNAMENTS_REQUESTED = 
  "TEAM_GET_TOURNAMENTS_REQUESTED";
export const TEAM_GET_TOURNAMENTS_SUCCESS = 
  "TEAM_GET_TOURNAMENTS_SUCCESS";
export const TEAM_GET_TOURNAMENTS_FAILED = 
  "TEAM_GET_TOURNAMENTS_FAILED";
export const TEAM_GET_TOURNAMENTS_CLEAR = 
  "TEAM_GET_TOURNAMENTS_CLEAR";

  export const TEAM_GET_MATCHES = 
  "TEAM_GET_MATCHES";
export const TEAM_GET_MATCHES_REQUESTED = 
  "TEAM_GET_MATCHES_REQUESTED";
export const TEAM_GET_MATCHES_SUCCESS = 
  "TEAM_GET_MATCHES_SUCCESS";
export const TEAM_GET_MATCHES_FAILED = 
  "TEAM_GET_MATCHES_FAILED";
export const TEAM_GET_MATCHES_CLEAR = 
  "TEAM_GET_MATCHES_CLEAR";

  export const TEAM_GET_STATISTICS = 
  "TEAM_GET_STATISTICS";
export const TEAM_GET_STATISTICS_REQUESTED = 
  "TEAM_GET_STATISTICS_REQUESTED";
export const TEAM_GET_STATISTICS_SUCCESS = 
  "TEAM_GET_STATISTICS_SUCCESS";
export const TEAM_GET_STATISTICS_FAILED = 
  "TEAM_GET_STATISTICS_FAILED";
export const TEAM_GET_STATISTICS_CLEAR = 
  "TEAM_GET_STATISTICS_CLEAR";
