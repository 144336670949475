import { Reducer } from "redux";

import { MatchInterface } from "./types";
import * as getmatchesHandlers from "./handlers/getMatches";
import * as actionTypes from "./actionTypes";

import { defaultRequestState } from "../constants";

const INITIAL_STATE: MatchInterface = {
  lastMatches: [],
  requestStates: {
    lastMatches: { ...defaultRequestState },
  },
};

const matchesReducer: Reducer<MatchInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.MATHCES_GET_MATCHES_RQUESTED:
      return getmatchesHandlers.requested(state);
    case actionTypes.MATHCES_GET_MATCHES_SUCCESS:
      return getmatchesHandlers.success(state, action);
    case actionTypes.MATHCES_GET_MATCHES_FAILED:
      return getmatchesHandlers.failed(state, action);
    case actionTypes.MATHCES_GET_MATCHES_CLEAR:
      return getmatchesHandlers.clear(state);
    default:
      return state;
  }
};

export default matchesReducer;
