import React from "react";

import classes from "./PubgPageBanner.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const PubgPageBanner: React.FC = () => (
  <div>
    <a
      id="pubg_home_page_banner"
      className={mergeClasses(
        "g4a_banner_pubg",
        classes.container,
      )}
      href="https://pubgesports.com"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="/images/pubg/pubg_page.png"
        alt="PUBG Page Banner"
      />
    </a>
  </div>
);

export default PubgPageBanner;
