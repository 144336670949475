// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config =  {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://qu2qygng5zf4zbzt6j3wesx6fy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-76ksxkwekrcm5bn4divdkgspoi",
};


export default config;