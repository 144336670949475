import React from "react";
import ArrowSpan from "./ArrowSpan";

const ArrowSpanContainer: React.FC<{
  onClick?: () => void;
  className?: string;
}> = ({ children, ...rest }) => {
  return <ArrowSpan {...rest}>{children}</ArrowSpan>;
};

export default ArrowSpanContainer;
