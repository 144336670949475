import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { getPlayerRanking } from "@twire/redux/playerRanking/actions";

import Placeholder from "./Placeholder";
import TopTeams from "./PlayerRankingWidget";
import { sortAndTrim } from "./functions";

const PlayerRankingWidgetContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    playerRanking: { data, requestStates, currentFilter },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    dispatch(getPlayerRanking());
  }, [game]);

  const playersData =
    data && currentFilter
      ? data[currentFilter?.year][currentFilter?.contest]
      : undefined;

  return requestStates.rankedPlayers.requesting ||
    !data ||
    !playersData ||
    !currentFilter ||
    !data[currentFilter?.year][currentFilter?.contest] ? (
    <Placeholder />
  ) : (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <TopTeams players={sortAndTrim(playersData!.players, 10)} game={game} />
  );
};

export default PlayerRankingWidgetContainer;
