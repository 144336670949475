import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { ArticleInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as articleHandlers from "./handlers/getArticle";
import * as commentHandlers from "./handlers/postComment";

const INITIAL_STATE: ArticleInterface = {
  game: null,
  author: "",
  comments: null,
  content: "",
  id: null,
  insertedAt: null,
  summary: "",
  summaryImg: "",
  title: "",
  updatedAt: null,
  numberOfComments: null,
  requestStates: {
    article: { ...defaultRequestState },
    comment: { ...defaultRequestState, id: null },
  },
};

const articleReducer: Reducer<ArticleInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.ARTICLES_GET_ARTICLE_REQUESTED:
      return articleHandlers.requested(state);
    case actionTypes.ARTICLES_GET_ARTICLE_FAILED:
      return articleHandlers.failed(state, action);
    case actionTypes.ARTICLES_GET_ARTICLE_SUCCESS:
      return articleHandlers.success(state, action);
    case actionTypes.ARTICLES_GET_ARTICLE_CLEAR:
      return articleHandlers.clear(state);

    case actionTypes.ARTICLES_POST_COMMENT_REQUESTED:
      return commentHandlers.requested(state, action);
    case actionTypes.ARTICLES_POST_COMMENT_FAILED:
      return commentHandlers.failed(state, action);
    case actionTypes.ARTICLES_POST_COMMENT_SUCCESS:
      return commentHandlers.success(state, action);
    case actionTypes.ARTICLES_POST_COMMENT_CLEAR:
      return commentHandlers.clear(state);
    default:
      return state;
  }
};

export default articleReducer;
