import { combineReducers } from "redux";

import { RootReducer } from "./types";
import app from "./app/reducer";
import user from "./user/reducer";
import tournaments from "./tournaments/reducer";
import article from "./article/reducer";
import articles from "./articles/reducer";
import team from "./team/reducer";
import teamRanking from "./teamRanking/reducer";
import featured from "./featured/reducer";
import matches from "./matches/reducer";
import pgcPoints from "./pgcPoints/reducer";
import tournament from "./tournament/reducer";
import scrims from "./scrims/reducer";
import search from "./search/reducer";
import fantasyTournaments from "./fantasyTournaments/reducer";
import subscriptions from "./subscriptions/reducer";
import playerRanking from "./playerRanking/reducer";

export default combineReducers<RootReducer>({
  app,
  user,
  tournaments,
  article,
  articles,
  team,
  teamRanking,
  featured,
  matches,
  pgcPoints,
  tournament,
  scrims,
  search,
  fantasyTournaments,
  subscriptions,
  playerRanking,
});
