import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { mergeClasses, getFullRoute, t } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";
import Articles from "@twire/common/Articles";
import { useLanguageChange } from "@twire/utility/hooks";
import { RootReducer } from "@twire/redux/types";
import { useSelector } from "react-redux";
import TeamRanking from "../TeamRanking";
import PlayerRanking from "../PlayerRanking";
import classes from "./Ranking.module.scss";
import FantasyBanner from "@twire/common/FantasyBanner";
import FantasyTournaments from "@twire/common/FantasyTournaments";

const Ranking: React.FC = () => {
  const lang = useLanguageChange();

  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  return (
    <div className={classes.container}>
      <div className={mergeClasses("w-full", classes.left)}>
        <div className={classes.navLinks}>
          <NavLink
            to={`/${lang}/${game}${URLS.POWER_RANKING}`}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            Power Ranking
          </NavLink>
          <NavLink
            to={`/${lang}/${game}${URLS.TEAM_RANKING}`}
            className={classes.navLink}
            activeClassName={classes.active}
          >
            {t("words.teamRanking")}
          </NavLink>
        </div>
        <Switch>
          <Route
            path={getFullRoute(URLS.TEAM_RANKING)}
            component={TeamRanking}
          />
          <Route
            path={getFullRoute(URLS.POWER_RANKING)}
            component={PlayerRanking}
          />
          <Redirect to={getFullRoute(URLS.POWER_RANKING)} />
        </Switch>
      </div>
      <div className={classes.right}>
        <div className="mb-4">
          <FantasyBanner />
        </div>
        <FantasyTournaments />
        <Articles config={{ visibleCount: 3 }} />
      </div>
    </div>
  );
};

export default Ranking;
