import Dropdown from "unop-react-dropdown";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { useTournament } from "../hooks";

import { ReactComponent as ArrowTip } from "../../../../../../assets/svgs/arrowTip.svg";
import classes from "./TournamentFilter.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const TournamentFilterTrigger: React.FC<{ hideArrow?: boolean }> = (props) => {
  const { selectedRound } = useTournament();
  const {
    tournament: { requestStates },
  } = useSelector((state: RootReducer) => state);
  return requestStates.tournament.requesting ||
    requestStates.tournament.failed ? (
    <>
      <button className={classes.trigger}></button>
    </>
  ) : (
    <>
      <button className={mergeClasses(classes.trigger)} {...props}>
        <span className="truncate">{selectedRound?.name || "overall"}</span>
        {!props.hideArrow && <ArrowTip />}
      </button>
      <div />
      <div />
      <div />
    </>
  );
};

const TournamentFilterDropdown: React.FC<{
  onChange: () => void;
}> = ({onChange}) => {
  const {
    tournament: { tournament },
  } = useSelector((state: RootReducer) => state);
  const { selectedRound, changeRound } = useTournament();
  return (
    <div className={classes.dropdownWrapper}>
      <Dropdown
        dropdownMenuClassName={classes.wFull}
        closeOnDropdownClicked
        closeOnClickOut
        trigger={
          <TournamentFilterTrigger
            hideArrow={
              !tournament?.tournamentFilters ||
              tournament?.tournamentFilters?.length === 0
            }
          />
        }
      >
        {tournament?.tournamentFilters &&
          tournament?.tournamentFilters.length > 0 && (
            <div className={classes.dropdown}>
              {tournament?.tournamentFilters &&
                tournament.tournamentFilters.map((filter) => (
                  <button
                    key={filter?.value}
                    className={mergeClasses("truncate px-2", [
                      filter?.value === selectedRound?.value,
                      classes.active,
                    ])}
                    onClick={() => {
                      changeRound(filter);
                      onChange();
                    }}
                  >
                    {filter?.name}
                  </button>
                ))}
            </div>
          )}
      </Dropdown>
    </div>
  );
};

const TournamentFilter: React.FC<{
  onChange: () => void;
}> = ({ onChange }) => {
  const { selectedRound, selectedGroup, changeGroup } = useTournament();
  return (
    <div className={classes.container}>
      <TournamentFilterDropdown onChange={onChange} />
      <div className={classes.groups}>
        {selectedRound?.children?.map((data) => (
          <button
            key={data?.value}
            className={mergeClasses("truncate", [
              selectedGroup?.value == data?.value,
              classes.active,
            ])}
            onClick={() => {
              changeGroup(data?.value || "");
              onChange();
            }}
          >
            {data?.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TournamentFilter;
