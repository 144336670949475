import React from "react";
import { Link } from "react-router-dom";
import NewsTile from "@twire/common/NewsTile";
import { t, urlFriendlyString } from "@twire/utility/helpers";
import ArrowSpan from "@twire/common/ArrowSpan";
import { ArticleCardData } from "@twire/redux/articles/types";

const Articles: React.FC<{
  news: ArticleCardData[];
  visibleCount: number;
  game: string;
  lang: string;
}> = ({ news, visibleCount, lang, game }) => (
  <>
    <div className="flex items-center">
      <div className="flex items-center">
        <span className="text-xl bold mr-3 text-twire-light">
          {t("words.news")}
        </span>
      </div>
      <Link to={`/${lang}/${game}/news`} className="ml-auto">
        <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
      </Link>
    </div>
    {news.map((data, index) => (
      <div className="my-1" key={data.id}>
        <Link to={`/${lang}/${game}/news/${data.id}/${urlFriendlyString(data.title)}`}>
          <NewsTile short={index > visibleCount - 1} {...data} />
        </Link>
      </div>
    ))}
  </>
);

export default Articles;
