import React from "react";
import { useSelector } from "react-redux";
import { mergeClasses, urlFriendlyString } from "@twire/utility/helpers";
import { RootReducer } from "@twire/redux/types";
import TwireButton from "@twire/common/TwireButton";
import Text from "@twire/common/Text";
import classes from "./TournamentFantasyBanner.module.scss";

const TournamentFantasyBanner: React.FC<{ className?: string }> = ({
  className,
}) => {
  const {
    tournament: { tournament, requestStates },
  } = useSelector((state: RootReducer) => state);
  return tournament &&
    requestStates.tournament.success &&
    tournament.fantasy ? (
    <div
      className={mergeClasses(classes.container, className ? className : "")}
    >
      <p>
        <span>
          <Text i18nKey="words.fantasyLeague">FANTASY LEAGUE</Text>
        </span>
        <Text i18nKey="words.availableFor">available for</Text>{` ${tournament.friendlyName}`}
      </p>
      <a
        href={`https://fantasy.twire.gg/en/tournament/${tournament.fantasyTournamentId}/${urlFriendlyString(tournament.friendlyName)}/my-team`}
        target="_blank"
        rel="noreferrer"
      >
        <TwireButton className={classes.button}>
          <Text i18nKey="words.playNow">Play Now</Text>
        </TwireButton>
      </a>
    </div>
  ) : (
    <></>
  );
};

export default TournamentFantasyBanner;
