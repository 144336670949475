import { createContext } from "react";
// import { PrizeDistributionRow } from "./types";

export type TableHeader = {
  value: string;
  title: string;
};

export type ContextType = {
  shard: string;
  totalPrize: string;
  revealRestOfTable: boolean;
  setShard: (shard: string) => void;
  onRevealTableClicked: (arg?: any) => void;
  table: {
    headers: TableHeader[];
    data: any;
  };
};

const defaultValue: ContextType = {
  shard: "",
  totalPrize: "N/A",
  setShard: () => {
    return;
  },
  onRevealTableClicked: () => {
    return;
  },
  revealRestOfTable: false,
  table: {
    headers: [],
    data: [],
  },
};

export const PrizeTableContext = createContext(defaultValue);
