import { mergeClasses } from "@twire/utility/helpers";

import classes from "../DropDown.module.scss";

const DropdownItem: React.FC<{
  active: boolean;
  onClick: (arg?: any) => void;
  className?: string;
  acitveClassName?: string;
}> = ({ active, onClick, children, className, acitveClassName }) => (
  <li
    className={mergeClasses(
      "flex",
      [active, classes.active, acitveClassName || ""],
      className || ""
    )}
    onClick={onClick}
  >
    {children}
  </li>
);

export default DropdownItem;
