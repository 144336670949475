import Cookies from "js-cookie";
import { ActionCreator, AnyAction } from "redux";
import { Games } from "@twire/utility/constants";
import { Banner, SetGame } from "./types";
import * as actionTypes from "./actionTypes";
import React from "react";

export const setGame: ActionCreator<SetGame> = (game: Games) => {
  Cookies.set("selected-game", game);
  return {
    type: actionTypes.SET_GAME,
    payload: {
      game,
    },
  };
};

export const changeLanguage = (lang: string): AnyAction => {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    lang,
  };
};

export const displayFullPageLoadingOverlay = (text: string): AnyAction => {
  return {
    type: actionTypes.DISPLAY_FULL_PAGE_LOADING_OVERLAY,
    payload: {
      text,
    },
  };
};

export const hideFullPageLoadingOverlay = (time?: number): AnyAction => {
  return {
    type: actionTypes.HIDE_FULL_PAGE_LOADING_OVERLAY,
    time: time || 0.3,
  };
};

export const showTooltip = (
  x: number,
  y: number,
  value: string | JSX.Element
): AnyAction => {
  return {
    type: actionTypes.SHOW_GLOBAL_TOOL_TIP,
    x,
    y,
    value,
  };
};

export const hideTooltip = (): AnyAction => {
  return {
    type: actionTypes.HIDE_TOOLTIP,
  };
};

export const setBanner = (banner: Banner): AnyAction => {
  return {
    type: actionTypes.SET_BANNER,
    banner,
  };
};

export const clearBanner = (): AnyAction => ({
  type: actionTypes.CLEAR_BANNER,
});

export const setModal = (element: React.ReactNode): AnyAction => ({
  type: actionTypes.SET_MODAL_ELEMENT,
  element,
});

export const clearModal = (): AnyAction => ({
  type: actionTypes.SET_MODAL_ELEMENT,
});
