/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { DateTime } from "luxon";
import Text from "@twire/common/Text";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
  // CustomizedProps,
} from "recharts";
import {
  mergeClasses,
  fomratToDecimalPlaces,
  formatDate,
  formatSecondsToTime,
} from "../../../../../../../utility/helpers";
import { getSvg } from "../../Cards/Card";
import { ChartsData, TimeTournamentMap } from "./types";
import classes from "./Charts.module.scss";

const getMonth = (millis: number) => {
  return `${formatDate(DateTime.fromMillis(millis).toSQLDate())}`;
};

const CustomizedAxisTick: React.FC<any> = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fontWeight="bold"
        fill="#ffffffb3"
        fontSize={12}
        transform="rotate(-35)"
      >
        {getMonth(payload.value)}
      </text>
    </g>
  );
};

const CustomTooltip: React.FC<{
  payload?: any;
  statisticName: string;
  timeTournamentaMap: TimeTournamentMap;
}> = (props) => {
  // console.log(props);
  const { payload, timeTournamentaMap, statisticName } = props;

  return payload.length > 0 ? (
    <div className={classes.toolTipContainer}>
      {timeTournamentaMap[`${payload[0].payload.x}-${statisticName}`].map(
        (stat) => (
          <div key={stat.tournament.id}>
            <div>{fomratToDecimalPlaces(stat.value)}</div>
            <div>{stat.tournament.friendlyName}</div>
            <div>{formatDate(stat.tournament.stopDate!)}</div>
          </div>
        )
      )}
    </div>
  ) : (
    <></>
  );
};

const Chart: React.FC<{ data: ChartsData }> = ({ data }) => (
  <div className={mergeClasses(classes.chartContainer, "appear")}>
    <div className={classes.top}>
      <div className={classes.ring}>{getSvg(data.statisticName)}</div>
      <div className={classes.info}>
        <div className={classes.stat}>
          <Text i18nKey={`words.${data.statisticName}` as any}>
            {data.statisticName}
          </Text>
        </div>
        <div className={classes.description}>
          {/* Quick description about the type of stats */}
          <Text i18nKey="sentences.teamStatisticDescription">
            in last 12 months
          </Text>
        </div>
      </div>
      <div className={classes.overall}>
        {
          data.statisticName === "avgTimeSurvived" ?
          formatSecondsToTime(data.overall)
          :
          fomratToDecimalPlaces(data.overall)
        }
      </div>
    </div>
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        // width={500}
        // height={300}
        data={data.stats}
        margin={{
          left: -25,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#f0ab2e" stopOpacity={0.1} />
            <stop offset="95%" stopColor="transparent" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <CartesianGrid
          vertical={false}
          strokeDasharray="4 4"
          stroke="#ffffff26"
        />
        <XAxis
          dataKey="x"
          height={60}
          max={data.max}
          min={data.min}
          type="number"
          domain={[data.min, data.max]}
          ticks={data.ticks}
          xAxisId="per-tournament-x"
          tickFormatter={getMonth}
          tick={<CustomizedAxisTick />}
          className={classes.xAxis}
        />
        <YAxis
          dataKey="y"
          type="number"
          domain={[0, data.yMax || 0]}
          yAxisId="per-tournament-y"
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          cursor={{ stroke: "#ffffff26", strokeWidth: 1 }}
          content={
            <CustomTooltip
              statisticName={data.statisticName}
              timeTournamentaMap={data.timeTournamentaMap}
            />
          }
        />
        <Line
          type="monotone"
          dataKey="y"
          stroke="#f0ab2e"
          strokeWidth={2}
          xAxisId="per-tournament-x"
          yAxisId="per-tournament-y"
          dot={false}
        />
        <Area
          type="monotone"
          dataKey="y"
          stroke="#f0ab2e"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
          xAxisId="per-tournament-x"
          yAxisId="per-tournament-y"
        />
      </ComposedChart>
    </ResponsiveContainer>
  </div>
);

export default Chart;
