import React from "react";
import TwireButton from "@twire/common/TwireButton";
import TwireLink from "@twire/common/TwireLink";

import classes from "./PremiumBanner.module.scss";
import Text from "../Text";

const PremiumBanner: React.FC = () => {
  return (
    <div className={classes.container}>
      <h3>
        <Text i18nKey="sentences.supportUs">Support us!</Text>
      </h3>
      <p>
        <Text i18nKey="sentences.supportUsDescription">
          Remove ads and support our work for as little as €1.99/month.
        </Text>
      </p>
      <TwireLink to="/plans" className={classes.fancy}>
        <TwireButton className={classes.button}>
          <Text i18nKey="sentences.supportUs">Support us</Text>
        </TwireButton>
      </TwireLink>
    </div>
  );
};
export default PremiumBanner;
