import { Reducer } from "redux";

import { FeaturedInterface } from "./types";
import * as getFeaturedHandlers from "./handlers/getFeatured";
import * as actionTypes from "./actionTypes";

import { defaultRequestState } from "../constants";

const INITIAL_STATE: FeaturedInterface = {
  featured: [],
  requestStates: {
    featured: { ...defaultRequestState },
  },
};

const featuredReducer: Reducer<FeaturedInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.FEATURED_GET_FEATURED_REQUESTED:
      return getFeaturedHandlers.requested(state);
    case actionTypes.FEATURED_GET_FEATURED_SUCCESS:
      return getFeaturedHandlers.success(state, action);
    case actionTypes.FEATURED_GET_FEATURED_FAILED:
      return getFeaturedHandlers.failed(state, action);
    default:
      return state;
  }
};

export default featuredReducer;
