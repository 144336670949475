import { mergeClasses } from "@twire/utility/helpers";
import React from "react";
import Plan from "./Plan";
import { PlanProps } from "./Plan/types";
import classes from "./Plans.module.scss";

const Plans: React.FC<{ plans: PlanProps[]; loading: boolean }> = ({
  plans,
  loading,
}) => (
  <div className={classes.wrapper}>
    <h1 className={classes.header}>Select a plan for you</h1>
    <p className="text-twire-light open-sans">
      Our pricing is simple and transparent.
    </p>
    {!loading ? (
      <div className={mergeClasses(classes.container, "appear")}>
        <div className={classes.pHolder1} />
        {plans.map((plan) => (
          <div key={plan.planId} className={classes.planWrapper}>
            <Plan {...plan} />
          </div>
        ))}
        <div className={classes.pHolder2} />
      </div>
    ) : (
      <div className={mergeClasses("appear", classes.container)}>
        <img
          src="/images/animated/loader.gif"
          style={{ height: "25px" }}
          className={classes.loader}
          alt="loading"
        />
      </div>
    )}
  </div>
);

export default Plans;
