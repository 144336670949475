import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUrl } from "@twire/utility/hooks";
import { Subscription, TwireUrl } from "@twire/utility/helpers";
import { getSubscriptionPlans } from "@twire/redux/subscriptions/actions";
import { RootReducer } from "@twire/redux/types";
import { PlanProps } from "./Plan/types";
import Plans from "./Plans";

const PlansContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    subscriptions: { subscriptionPlans, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const [plans, setPlans] = useState<PlanProps[]>([]);
  const url = useUrl(`/${game}`);
  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, []);

  useEffect(() => {
    if (requestStates.subscriptionPlans.success) {
      const filteredPlans: PlanProps[] = [];
      subscriptionPlans.forEach((sub) => {
        if (sub.provider === "stripe") {
          filteredPlans.push({
            title: sub.name,
            caption: "Billed monthly",
            advantages: [
              "Support Us",
              "Remove Ads",
              "Drawing On Map",
              "Saving Matches",
            ],
            onClick: async () => {
              await Subscription.checkout({
                planId: sub.planId,
                paymentMethod: "normal",
              });
            },
            planId: sub.planId,
            price: sub.price,
            currency: "usd",
          });
        }
      });
      setPlans(filteredPlans);
    }
  }, [requestStates.subscriptionPlans]);

  return (
    <Plans loading={requestStates.subscriptionPlans.requesting} plans={plans} />
  );
};

export default PlansContainer;
