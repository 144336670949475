import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getSearchResults,
  clearSearchResults,
} from "@twire/redux/search/actions";
import { RootReducer } from "@twire/redux/types";
import { useUrl } from "@twire/utility/hooks";
import { TriggerProps } from "unop-react-dropdown";
import { useEffect, useRef, useState } from "react";
import Search from "./Search";
import { saveUserAction } from "@twire/redux/track/actions";
import { searchMainTriggered } from "@twire/redux/track/userActions/userActions";

type Timeout = ReturnType<typeof setTimeout>;

const SearchContainer: React.FC = () => {
  const dispatch = useDispatch();
  const delayRef = useRef<Timeout | null>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const {
    search: {
      requestStates,
      teams,
      tournaments,
    },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (searchValue) {
      dispatch(saveUserAction(searchMainTriggered(
        searchValue,
        (teams?.length || 0) + (tournaments?.length || 0),
      )));
    }
  }, [teams, tournaments]);

  const url = useUrl(`/${game}/search`);
  const onChange = (value: string, dropdownProps: TriggerProps) => {
    if (delayRef.current) {
      clearTimeout(delayRef.current);
    }
    if (value) {
      delayRef.current = setTimeout(() => {
        dispatch(getSearchResults(value));
        setSearchValue(value);
        if (!dropdownProps.open) {
          dropdownProps.show();
        }
      }, 700);
    } else {
      if (dropdownProps.open) {
        dropdownProps.hide();
      }
    }
  };

  const clearValue = () => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
    dispatch(clearSearchResults());
  };

  const onViewMoreClicked = (hide: () => void) => {
    if (searchRef.current) {
      history.push(`${url}/${searchRef.current.value}`);
      clearValue();
      hide();
    }
  };

  return (
    <Search
      searchRef={searchRef}
      onChange={onChange}
      searchRequestStates={requestStates}
      clearValue={clearValue}
      onViewMoreClicked={onViewMoreClicked}
    />
  );
};

export default SearchContainer;
