import React from "react";
import { mergeClasses } from "@twire/utility/helpers";
import MatchTilePlaceholder from "./MatchTile/Placeholder";
import classes from "./Matches.module.scss";

const Matches: React.FC = () => (
  <div className={mergeClasses(classes.container, "appear")}>
    {Array(10)
      .fill("")
      .map((matchData) => (
        <React.Fragment key={matchData.match.id}>
          <MatchTilePlaceholder />
        </React.Fragment>
      ))}
  </div>
);

export default Matches;
