import Placeholder from "./Placeholder";
import classes from "../LeaderboardTable.module.scss";

const PlaceholderContainer: React.FC = () => {
  return (
    <div>
      <div className={classes.desktop}>
        <Placeholder />
        <Placeholder startIndex={10} />
      </div>
      <div className={classes.tab}>
        <Placeholder />
      </div>
    </div>
  );
};

export default PlaceholderContainer;
