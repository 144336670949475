import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "@twire/redux/user/actions";
import { RootReducer } from "@twire/redux/types";
import Header from "./Header";

const HeaderContainer: React.FC<{
  dropdownOpen: boolean;
  toggleDropdown: () => void;
}> = (props) => {
  const {
    user: { signedIn, requestStates, userData },
  } = useSelector((state: RootReducer) => state);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Header
      signingOut={requestStates.signOut.requesting}
      handleSignOut={handleSignOut}
      signedIn={signedIn}
      username={userData ? userData.username : "user"}
      {...props}
    />
  );
};

export default HeaderContainer;
