import { Action } from "redux";
import { Trie } from "@twire/utility/classes";
import {
  Tournament,
  PlatformLeaderboardQuery,
  TeamStatsResponse,
  PlatformStatsQuery,
} from "../../PublicAPI";

import { RequestStates } from "../types";

declare let platformLeaderboardQuery: PlatformLeaderboardQuery;

declare let platformStatsQuery: PlatformStatsQuery;

export type TournamentFilter = {
  shard: string;
  parent: string;
  child: string;
  ruleset: string | null;
};

export type TournamentStatsFilter = {
  key: string | number;
  value: string;
  selected: boolean | null;
}

export enum TournamentStatsFilterType {
  ALL = "all",
  MAPS = "maps",
  MATCHES = "matches",
}

export type TournamentStatsFilters = {
  [TournamentStatsFilterType.ALL]: boolean;
  [TournamentStatsFilterType.MAPS]: TournamentStatsFilter[];
  [TournamentStatsFilterType.MATCHES]: TournamentStatsFilter[];
};

export type Match = {
  id: number;
  name: string;
  map: string;
  winner: string;
};

export interface TournamentInterface {
  tournament: Tournament | null;
  filter: TournamentFilter;
  statsFilters: TournamentStatsFilters;
  token: string;
  matchByTournament: Match[] | null;
  platformLeaderboard:
    | typeof platformLeaderboardQuery.platformLeaderboard
    | null;
  teamStats: TeamStatsResponse | null;
  platformStats: typeof platformStatsQuery.platformStats | null;
  platformStatsLeaderboardTrie: Trie<number> | null;
  matchesPage: number;
  isLastMatchesPage: boolean;
  requestStates: {
    tournament: RequestStates;
    filter: RequestStates;
    platformLeaderboard: RequestStates;
    matchByTournament: RequestStates;
    teamStats: RequestStates;
    platformStats: RequestStates;
    statsFilters: RequestStates;
  };
}

export interface GetMatchesPaginated extends Action {
  page: number;
}
