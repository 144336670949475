import { useRouteMatch } from "react-router-dom";
import { t } from "@twire/utility/helpers";
// import { t } from "@twire/utility/helpers";

import { TournamentsNavItem } from "../../../TournamentsNav/types";

export type NavLinksType = TournamentsNavItem[];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTournamentDataNavLinks = () => {
  const { url } = useRouteMatch();

  const navLinks: TournamentsNavItem[] = [
    // {
    //   url: `${url}/participants`,
    //   title: t("words.participants"),
    // },
    {
      url: `${url}/leaderboards`,
      title: t("words.leaderboards"),
    },
    {
      url: `${url}/matches`,
      title: t("words.matches"),
    },
    {
      url: `${url}/team-stats`,
      title: t("words.teamStats"),
    },
    {
      url: `${url}/player-stats`,
      title: t("words.playerStats"),
    },
  ];
  return {
    navLinks,
  };
};
