import { AnyAction } from "redux";
import { FantasyTournamentsInterface } from "../types";

export const requested = (
  state: FantasyTournamentsInterface,
): FantasyTournamentsInterface => ({
  ...state,
  requestStates: {
    tournaments: {
      failed: false,
      requesting: true,
      success: false,
    },
  },
});

export const success = (
  state: FantasyTournamentsInterface,
  action: AnyAction,
): FantasyTournamentsInterface => ({
  ...state,
  tournaments: [
    ...state.tournaments,
    ...action.tournaments,
  ],
  requestStates: {
    ...state.requestStates,
    tournaments: {
      failed: false,
      requesting: false,
      success: true,
    },
  },
});

export const failed = (
  state: FantasyTournamentsInterface,
  action: AnyAction,
): FantasyTournamentsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    tournaments: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (
  state: FantasyTournamentsInterface,
): FantasyTournamentsInterface => ({
  ...state,
  tournaments: [],
  requestStates: {
    ...state.requestStates,
    tournaments: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
