import { all, takeLatest } from "@redux-saga/core/effects";
import { isProduction } from "@twire/utility/helpers";

import { saveAdInteraction } from "./actions";
import * as actionTypes from "./actionTypes";

function* saveAdInteractionRequest({ payload }: ReturnType<typeof saveAdInteraction>) {
  
  try {
    if (isProduction()) {
      yield fetch(payload.url);
    }
  } catch (error) {
    // Do not do anything if call fails
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.SAVE_AD_INTERACTION, saveAdInteractionRequest),
  ]);
}
