import { AnyAction } from "redux";
import { FeaturedInterface } from "../types";

export const requested = (state: FeaturedInterface): FeaturedInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    featured: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: FeaturedInterface,
  action: AnyAction
): FeaturedInterface => ({
  ...state,
  featured: action.featured,
  requestStates: {
    ...state.requestStates,
    featured: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: FeaturedInterface,
  action: AnyAction
): FeaturedInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    featured: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: FeaturedInterface): FeaturedInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    featured: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
