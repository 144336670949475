import { mergeClasses } from "@twire/utility/helpers";
import { t } from "@twire/utility/helpers";
import AdvancedTable from "../Stats/AdvancedTable";
import { getHeaders } from "./constants";
import PlayerStat from "./PlayerStat";
import classes from "./PlayerStats.module.scss";

const PlayerStats: React.FC<{
  stats: any;
  fullscreen?: boolean;
  onResizeClick: () => void;
}> = ({ stats, fullscreen, onResizeClick }) => (
  <div className={mergeClasses("mt-5 h-full", classes.container)}>
    <div
      className={mergeClasses(classes.statsRow, "mb-8", [
        !!fullscreen,
        "hidden",
      ])}
    >
      <PlayerStat dataIndex={0} />
      <PlayerStat dataIndex={1} />
      <PlayerStat dataIndex={2} />
      <PlayerStat dataIndex={3} />
    </div>
    <AdvancedTable
      stats={stats}
      fullscreen={fullscreen}
      headers={getHeaders(t)}
      colHeading={{ value: "Player", id: "", sortId: "teamName" }}
      onResizeClick={onResizeClick}
    />
  </div>
);

export default PlayerStats;
