import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { RootReducer } from "@twire/redux/types";

import * as actionTypes from "./actionTypes";
import { publicQuery, privateMutation } from "../../graphql/requestHelper";
import { getArticle } from "../../graphql/public/queries";
import { addComment } from "../../graphql/private/mutations";
import { postComment } from "./actions";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchArticle(filter?: { id: number }) {
  yield put({ type: actionTypes.ARTICLES_GET_ARTICLE_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const article: Generator<any, any> = yield publicQuery(
      getArticle,
      {
        game: state.app.game,
        articleId: filter?.id,
      },
      CACHE_TIME.MINUTE * 5,
    );

    yield put({
      type: actionTypes.ARTICLES_GET_ARTICLE_SUCCESS,
      article: (article as any).data.getArticle,
      game: state.app.game,
    });
  } catch (error) {
    yield put({ type: actionTypes.ARTICLES_GET_ARTICLE_FAILED, error });
  }
}

function* postCommentAction(action: ReturnType<typeof postComment>) {
  yield put({
    type: actionTypes.ARTICLES_POST_COMMENT_REQUESTED,
    payload: action.payload,
  });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const comment: Generator<any, any> = yield privateMutation(
      addComment,
      {
        game: state.app.game,
        belongsTo: "article",
        belongsToId: state.article.id,
        content: action.payload.content,
        parentId: action.payload.parentId,
      },
      [
        {
          query: getArticle,
          varaibles: {
            game: state.app.game,
            articleId: state.article.id,
          },
        }
      ]
    );

    yield put({
      type: actionTypes.ARTICLES_POST_COMMENT_SUCCESS,
      comment: (comment as any).data.addComment,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: actionTypes.ARTICLES_POST_COMMENT_FAILED,
      error,
      payload: action.payload,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.ARTICLES_GET_ARTICLE, fetchArticle),
    takeLatest(actionTypes.ARTICLES_POST_COMMENT, postCommentAction),
  ]);
}
