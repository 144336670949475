import { ActionCreator } from "redux";
import * as actionTypes from "./actionTypes";
import { SaveUserAction, SaveUserActionParams } from "./types";

export const saveUserAction: ActionCreator<SaveUserAction> = (
  action: SaveUserActionParams,
) => ({
  type: actionTypes.SAVE_USER_ACTION,
  payload: action,
});
