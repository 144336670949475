import React from "react";
import TournamentTile from "./TournamentTile";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../redux/types";
import { TournamentTileContainer } from "./types";

const ComponentContainer: React.FC<TournamentTileContainer> = (props) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  return <TournamentTile {...props} game={game} />;
};

export default ComponentContainer;
