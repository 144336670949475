import Dropdown from "unop-react-dropdown";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { setPlayerRankingFilter } from "@twire/redux/playerRanking/actions";

import { ReactComponent as ArrowTip } from "../../../../../assets/svgs/arrowTip.svg";
import classes from "./PlayerRankingFilter.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const PlayerRankingFilterTrigger: React.FC<{ hideArrow?: boolean }> = (
  props
) => {
  const {
    playerRanking: { requestStates, currentFilter },
  } = useSelector((state: RootReducer) => state);
  return requestStates.rankedPlayers.requesting ||
    requestStates.rankedPlayers.failed ? (
    <>
      <button className={classes.trigger}></button>
    </>
  ) : (
    <>
      <button className={mergeClasses(classes.trigger)} {...props}>
        <span className="truncate">{currentFilter?.year || "overall"}</span>
        {!props.hideArrow && <ArrowTip />}
      </button>
      <div />
      <div />
      <div />
    </>
  );
};

const PlayerRankingFilterDropdown: React.FC<{
  onChange: () => void;
}> = ({ onChange }) => {
  const dispatch = useDispatch();
  const {
    playerRanking: { data, currentFilter, filters },
  } = useSelector((state: RootReducer) => state);
  return (
    <div className={classes.dropdownWrapper}>
      <Dropdown
        dropdownMenuClassName={classes.wFull}
        closeOnDropdownClicked
        closeOnClickOut
        trigger={<PlayerRankingFilterTrigger hideArrow={false} />}
      >
        {currentFilter && filters && data && filters.length > 0 && (
          <div className={classes.dropdown}>
            {filters.map((f) => (
              <button
                key={f.value}
                className={mergeClasses("truncate px-2", [
                  f.value === currentFilter?.year,
                  classes.active,
                ])}
                onClick={() => {
                  dispatch(
                    setPlayerRankingFilter({
                      year: f.value,
                      contest: f.children[0].value,
                    })
                  );
                  onChange();
                }}
              >
                {f.name}
              </button>
            ))}
          </div>
        )}
      </Dropdown>
    </div>
  );
};

const PlayerRankingFilter: React.FC = () => {
  const {
    playerRanking: { requestStates, currentFilter, filters },
  } = useSelector((state: RootReducer) => state);
  const dispatch = useDispatch();

  return (
    <div className={classes.container}>
      <PlayerRankingFilterDropdown
        onChange={() => {
          1 + 2;
        }}
      />
      <div className={classes.groups}>
        {filters &&
          currentFilter &&
          requestStates.rankedPlayers.success &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          filters
            .find((f) => f.value === currentFilter?.year)!
            .children.map((data) => (
              <button
                key={data?.value}
                className={mergeClasses("truncate", [
                  currentFilter.contest == data?.value,
                  classes.active,
                ])}
                onClick={() => {
                  dispatch(
                    setPlayerRankingFilter({
                      year: currentFilter.year,
                      contest: data.value,
                    })
                  );
                }}
              >
                {data?.name}
              </button>
            ))}
      </div>
    </div>
  );
};

export default PlayerRankingFilter;
