// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
/* eslint-disable @typescript-eslint/no-var-requires */
import ApolloClient from "apollo-client";
import { gql } from "@apollo/client";
import { Auth } from "aws-amplify";
import { split } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import { hasSubscription } from "@jumpn/utils-graphql";

// const endpointConfig = {
//   http: "http://staging-payment-905173160.eu-west-1.elb.amazonaws.com/graphql",
//   ws: "wss://staging-api.twire.gg/socket",
// };

let endpointConfig;
if (process.env.REACT_APP_STAGE === "prod") {
  endpointConfig = {
    http: "https://payment-api.twire.gg/graphql",
    ws: "wss://staging-api.twire.gg/socket",
  };
} else {
  endpointConfig = {
    http: "http://staging-payment-905173160.eu-west-1.elb.amazonaws.com/graphql",
    ws: "wss://staging-api.twire.gg/socket",
  };
}

// Create an HTTP link to the Absinthe server.
const httpLink = createHttpLink({
  uri: endpointConfig.http,
});

// Use setContext to create a chainable link object that sets
// the token cookie to the Authorization header.
const authLink = setContext((_, { headers }) => {
  // Get the authentication token from the cookie if it exists.
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      // Add the new Authorization header.
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
        },
      };
    })
    .catch(() => {
      return headers;
    });
});

// Chain the HTTP link and the authorization link.
const authedHttpLink = authLink.concat(httpLink);

// Create a standard Phoenix websocket connection. If you need
// to provide additional params, like an authentication token,
// you can configure them in the `params` option.
const phoenixSocket = new PhoenixSocket(endpointConfig.ws);

// Wrap the Phoenix socket in an AbsintheSocket.
const absintheSocket = AbsintheSocket.create(phoenixSocket);

// Create an Apollo link from the AbsintheSocket instance.
const websocketLink = createAbsintheSocketLink(absintheSocket);

// If the query contains a subscription, send it through the
// websocket link. Otherwise, send it through the HTTP link.
const link = split(
  (operation) => hasSubscription(operation.query),
  websocketLink as any,
  authedHttpLink
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

// export const subscribeUser = (
//   planId: string,
//   token: string,
//   bearerToken: string
// ) =>
//   fetch(`http://52.49.1.96:6000/graphql`, {
//     method: "POST",
//     headers: {
//       authorization: `Bearer ${bearerToken}`,
//     },
//     body: JSON.stringify({
//       query: `
//       mutation {
//         createSubscription(planId: "${planId}", token: "${token}") {
//           subscriptionId
//           planId
//           validFrom
//           validTo
//           status
//         }
//       }`,
//     }),
//   });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const subscribeUser = (planId: string, token: string) =>
  new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: defaultOptions as any,
  }).query({
    query: gql`
    mutation {
        createSubscription(planId: "${planId}", token: "${token}") {
          subscriptionId
          planId
          validFrom
          validTo
          status
        }
      }
    `,
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const unsubscribeuser = (subId: string) =>
  new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: defaultOptions as any,
  }).query({
    query: gql`
      mutation {
        deleteSubscription(subscriptionId: "${subId}") {
          subscriptionId
          planId
          validFrom
          validTo
          status
        }
      }
    `,
  });
