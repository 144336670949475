import { AnyAction } from "redux";
import { getGeneralTournamentFormat } from "../../../utility/helpers";
import { TournamentInterface } from "../types";

export const requested = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    filter: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  filter: {
    ...action.filter,
    ruleset: getGeneralTournamentFormat(action.filter.ruleset),
  },
  matchByTournament: [],
  matchesPage: 1,
  isLastMatchesPage: false,
});

export const failed = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    filter: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  filter: {
    parent: "",
    shard: "",
    child: "",
    ruleset: null,
  },
  requestStates: {
    ...state.requestStates,
    filter: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
