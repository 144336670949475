import {
  t,
  setDefaultTournamentLogo,
  setTournamentLogo,
} from "@twire/utility/helpers";
import { MatchTile } from "@twire/redux/matches/types";
import { ReactComponent as RightArrowTip } from "../../../assets/svgs/arrow-tip-right.svg";
import classes from "./LastMatchTile.module.scss";

const LastMatchTile: React.FC<MatchTile> = ({
  tournamentName,
  name,
  winner,
  tournamentLogo,
  shard,
  game,
}) => (
  <div className={classes.container}>
    <div className="flex items-center">
      <img
        src={setTournamentLogo(game, tournamentLogo || shard)}
        onError={setDefaultTournamentLogo}
        alt="team logo"
      />
      <p className={classes.tournamentName}>{tournamentName}</p>
    </div>
    <hr />
    <div className={classes.desc}>
      <p>
        {name}. {t("words.winner")}: {winner}
      </p>
      <RightArrowTip className="ml-auto" />
    </div>
  </div>
);

export default LastMatchTile;
