import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { subscriptionPlans } from "../../graphql/public/queries";
import * as actionTypes from "./actionTypes";
import * as userActionTypes from "../user/actionTypes";
import { subscribeUser, unsubscribeuser } from "./functions";
import { subscribeToPlan as subscribeToPlanAction } from "./actions";
import { publicQuery } from "../../graphql/requestHelper";
import { RootReducer } from "../types";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchSubscriptionPlans() {
  yield put({
    type: actionTypes.GET_SUBSCRIPTION_PLANS_REQUESTED,
  });

  try {
    const plans: Generator<any, any> = yield publicQuery(
      subscriptionPlans,
      {},
      CACHE_TIME.DAY,
    );

    yield put({
      type: actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS,
      subscriptionPlans: (plans as any).data.subscriptionPlans,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_SUBSCRIPTION_PLANS_FAILED, error });
  }
}

function* subscribeToPlan({
  planId,
  paymentMethod,
}: ReturnType<typeof subscribeToPlanAction>) {
  yield put({
    type: actionTypes.SUBSCRIBE_USER_TO_PLAN_REQUESTED,
  });
  try {
    const result: Generator<any, any> = yield subscribeUser(
      planId,
      paymentMethod.id
    );

    if (
      !(result as any).errors &&
      (result as any).data.createSubscription.status
    ) {
      yield put({
        type: actionTypes.SUBSCRIBE_USER_TO_PLAN_SUCCESS,
        result: (result as any).data.createSubscription,
      });
      yield put({
        type: userActionTypes.AUTH_GET_USER_SUBSCRIPTION_SUCCESS,
        premium: (result as any).data.createSubscription,
      });
    } else {
      yield put({
        type: actionTypes.SUBSCRIBE_USER_TO_PLAN_FAILED,
        error: true,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.SUBSCRIBE_USER_TO_PLAN_FAILED, error });
  }
}

function* unsubscribeFromPlan() {
  yield put({
    type: actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_REQUESTED,
  });
  try {
    const state: RootReducer = yield select((state: RootReducer) => state);
    const sub = state.user.userSubscription;
    const result: Generator<any, any> = yield unsubscribeuser(
      sub.status ? (sub as any).subscriptionId : ""
    );

    if (!(result as any).errors) {
      yield put({
        type: actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_SUCCESS,
        result,
      });
      yield put({
        type: userActionTypes.AUTH_GET_USER_SUBSCRIPTION_SUCCESS,
        premium: (result as any).data.deleteSubscription,
      });
    } else {
      yield put({
        type: actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_FAILED,
        error: (result as any).errors,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.GET_SUBSCRIPTION_PLANS, fetchSubscriptionPlans),
    takeLatest(actionTypes.SUBSCRIBE_USER_TO_PLAN, subscribeToPlan),
    takeLatest(actionTypes.UNSUBSCRIBE_USER_FROM_PLAN, unsubscribeFromPlan),
  ]);
}
