import { Switch, Route, Redirect } from "react-router-dom";
import TournamentsView from "./TournamentsView";
import Tournament from "./Tournament";
import { getFullRoute } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";
// import classes from "./Tournaments.module.scss";

const Tournaments: React.FC = () => (
  <Switch>
    <Route
      path={getFullRoute(URLS.ONGOING)}
      exact
      render={() => <TournamentsView type="ongoing" />}
    />
    <Route
      path={getFullRoute(URLS.PAST)}
      exact
      render={() => <TournamentsView type="past" />}
    />
    <Route
      path={getFullRoute(URLS.UPCOMING)}
      exact
      render={() => <TournamentsView type="upcoming" />}
    />
    <Route path={getFullRoute(URLS.TOURNAMENT)} component={Tournament} />
    <Redirect to={getFullRoute(URLS.ONGOING)} />
  </Switch>
);

export default Tournaments;
