import { AnyAction } from "redux";
import {
  getFirstChild,
  getGeneralTournamentFormat,
  getSlectedRound,
} from "@twire/utility/helpers";
import { TournamentInterface } from "../types";

export const requested = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    tournament: { failed: false, requesting: true, success: false },
  },
});

// const getChildFromParent

export const success = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => {
  const parent = getSlectedRound(action.tournament.tournamentFilters);
  const parentValue = parent
    ? parent.value || ""
    : "";
  const firstChildFilter = getFirstChild(
    state.filter.parent || parentValue,
    action.tournament.tournamentFilters
  );

  return {
    ...state,
    filter: {
      ...state.filter,
      shard: action.tournament.shard,
      parent: state.filter.parent || parentValue,
      child: state.filter.child || firstChildFilter?.value || "",
      ruleset: getGeneralTournamentFormat(firstChildFilter?.ruleset || parent.ruleset),
    },
    tournament: action.tournament,
    requestStates: {
      ...state.requestStates,
      tournament: { failed: false, requesting: false, success: true },
    },
  };
};

export const failed = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    tournament: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  tournament: null,
  requestStates: {
    ...state.requestStates,
    tournament: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
