import { t } from "@twire/utility/helpers";
import { HeaderType } from "../Stats/AdvancedTable/types";
import { GeneralTournamentFormat } from "@twire/utility/constants";

export const headers: HeaderType = [
  { headerId: "teamName", headerValue: "" }, // This will not be renered. It is required to be at the top so that is can be ignored. This is used to sort by team name
  { headerId: "numOfMatches", headerValue: "Number Of Matches" },
  { headerId: "totalPoints", headerValue: "Total Points" },
  { headerId: "avgTotalPoints", headerValue: "Average Total Points" },
  { headerId: "wins", headerValue: "Wins" },
  { headerId: "avgRank", headerValue: "Avg Rank" },
  { headerId: "points", headerValue: "points" },
  { headerId: "avgPoints", headerValue: "Average Points" },
  { headerId: "kills", headerValue: "Kills" },
  { headerId: "avgKills", headerValue: "Average Kills" },
  { headerId: "assists", headerValue: "Assists" },
  { headerId: "damageDealt", headerValue: "Damage Dealt" },
  { headerId: "avgDamageDealt", headerValue: "Average Damage Dealt" },
  { headerId: "damageTaken", headerValue: "Damage Taken" },
  { headerId: "avgDamageTaken", headerValue: "Average Damage Taken" },
  { headerId: "dbnos", headerValue: "Knocks" },
  { headerId: "revives", headerValue: "Revives" },
  { headerId: "headshotKills", headerValue: "Headshot Kills" },
  {
    headerId: "killSteals",
    headerValue: "Stolen Kills",
    description: t("sentences.killStealsTooltip"),
  },
  {
    headerId: "killsStolenFrom",
    headerValue: "Kills stolen from player",
    description: t("sentences.killsStolenFromTooltip"),
  },
  { headerId: "grenadePickup", headerValue: "Grenade Pickup" },
  { headerId: "grenadeDrop", headerValue: "Grenade Drop" },
  { headerId: "grenadeThrow", headerValue: "Grenade Thrown" },
  { headerId: "grenadeDamage", headerValue: "Grenade Damage" },
  { headerId: "molotovPickup", headerValue: "Molotov Pickup" },
  { headerId: "molotovDrop", headerValue: "Molotov Drop" },
  { headerId: "molotovThrow", headerValue: "Molotov Thrown" },
  { headerId: "molotovDamage", headerValue: "Molotov Damage" },
  { headerId: "smokebombPickup", headerValue: "Smokebomb Pickup" },
  { headerId: "smokebombDrop", headerValue: "Smokebomb Drop" },
  { headerId: "smokebombThrow", headerValue: "Smokebomb Thrown" },
  { headerId: "flashbangPickup", headerValue: "Flashbang Pickup" },
  { headerId: "flashbangDrop", headerValue: "Flashbang Drop" },
  { headerId: "flashbangThrow", headerValue: "Flashbang Throw" },
  { headerId: "swimDistance", headerValue: "Swim Distance (KM)" },
  { headerId: "walkDistance", headerValue: "Walk Distance (KM)" },
  { headerId: "rideDistance", headerValue: "Ride Distance (KM)" },
  { headerId: "timeSurvived", headerValue: "Time Survived" },
  { headerId: "avgTimeSurvived", headerValue: "Average Time Survived" },
  { headerId: "heals", headerValue: "Heals" },
  { headerId: "healthRecovered", headerValue: "Health Recovered" },
  { headerId: "boosts", headerValue: "Boosts" },
  { headerId: "vehicleDestroys", headerValue: "Vehicle Destroys" },
];

export const super21Headers: HeaderType = [
  { headerId: "teamName", headerValue: "" }, // This will not be renered. It is required to be at the top so that is can be ignored. This is used to sort by team name
  { headerId: "numOfMatches", headerValue: "Number Of Matches" },
  { headerId: "wins", headerValue: "Wins" },
  { headerId: "avgRank", headerValue: "Avg Rank" },
  { headerId: "kills", headerValue: "Kills" },
  { headerId: "avgKills", headerValue: "Average Kills" },
  { headerId: "assists", headerValue: "Assists" },
  { headerId: "damageDealt", headerValue: "Damage Dealt" },
  { headerId: "avgDamageDealt", headerValue: "Average Damage Dealt" },
  { headerId: "damageTaken", headerValue: "Damage Taken" },
  { headerId: "avgDamageTaken", headerValue: "Average Damage Taken" },
  { headerId: "dbnos", headerValue: "Knocks" },
  { headerId: "revives", headerValue: "Revives" },
  { headerId: "headshotKills", headerValue: "Headshot Kills" },
  {
    headerId: "killSteals",
    headerValue: "Stolen Kills",
    description: t("sentences.killStealsTooltip"),
  },
  {
    headerId: "killsStolenFrom",
    headerValue: "Kills stolen from player",
    description: t("sentences.killsStolenFromTooltip"),
  },
  { headerId: "grenadePickup", headerValue: "Grenade Pickup" },
  { headerId: "grenadeDrop", headerValue: "Grenade Drop" },
  { headerId: "grenadeThrow", headerValue: "Grenade Thrown" },
  { headerId: "grenadeDamage", headerValue: "Grenade Damage" },
  { headerId: "molotovPickup", headerValue: "Molotov Pickup" },
  { headerId: "molotovDrop", headerValue: "Molotov Drop" },
  { headerId: "molotovThrow", headerValue: "Molotov Thrown" },
  { headerId: "molotovDamage", headerValue: "Molotov Damage" },
  { headerId: "smokebombPickup", headerValue: "Smokebomb Pickup" },
  { headerId: "smokebombDrop", headerValue: "Smokebomb Drop" },
  { headerId: "smokebombThrow", headerValue: "Smokebomb Thrown" },
  { headerId: "flashbangPickup", headerValue: "Flashbang Pickup" },
  { headerId: "flashbangDrop", headerValue: "Flashbang Drop" },
  { headerId: "flashbangThrow", headerValue: "Flashbang Throw" },
  { headerId: "swimDistance", headerValue: "Swim Distance (KM)" },
  { headerId: "walkDistance", headerValue: "Walk Distance (KM)" },
  { headerId: "rideDistance", headerValue: "Ride Distance (KM)" },
  { headerId: "timeSurvived", headerValue: "Time Survived" },
  { headerId: "avgTimeSurvived", headerValue: "Average Time Survived" },
  { headerId: "heals", headerValue: "Heals" },
  { headerId: "healthRecovered", headerValue: "Health Recovered" },
  { headerId: "boosts", headerValue: "Boosts" },
  { headerId: "vehicleDestroys", headerValue: "Vehicle Destroys" },
];

export const formatToHeaderMap: { [key: string]: any } = {
  [GeneralTournamentFormat.POINT_SYSTEM_22]: headers,
  [GeneralTournamentFormat.MOST_CHICKEN]: super21Headers,
  [GeneralTournamentFormat.POINT_SYSTEM]: headers,
  [GeneralTournamentFormat.WWCD]: headers,
};
