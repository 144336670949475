import {
  all,
  put,
  takeLatest,
  call,
  // select
} from "@redux-saga/core/effects";

import * as actionTypes from "./actionTypes";

function* fetchTopTeams() {
  yield put({ type: actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_REQUESTED });

  try {
    const response: Generator<any, any> = yield call(
      fetch,
      "https://twire-assets.s3.eu-west-1.amazonaws.com/pubg/power-ranking/power-ranking.json"
    );
    const jsonResponse: Generator<any, any> = yield (
      response as any as Response
    ).json();

    yield put({
      type: actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_SUCCESS,
      ...jsonResponse,
    });
  } catch (error) {
    yield put({
      type: actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING_FAILED,
      error,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.PLAYER_RANKING_GET_PLAYER_RANKING, fetchTopTeams),
  ]);
}
