import "react-toastify/dist/ReactToastify.min.css";

import { FC } from "react";
import { ToastContainer } from "react-toastify";

const ToastifyContainer: FC = () => {
  return (
    <ToastContainer
      position={"bottom-center"}
      autoClose={5000}
      theme={"dark"}
    />
  );
};

export default ToastifyContainer;
