import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamStats, clearTeamStats } from "@twire/redux/tournament/actions";
import { RootReducer } from "@twire/redux/types";
import ComponentError, {
  ComponentErrorInterface,
} from "@twire/common/ComponentError";
import { useFullScreen } from "@twire/utility/hooks";
import TeamStats from "./TeamStats";
import Placeholder from "./Placeholder";
import { dataToTableFormat, generateMapType, getHeader } from "./functions";
import { MapType } from "./types";
import { saveUserAction } from "@twire/redux/track/actions";
import { tournamentTeamStatsView } from "@twire/redux/track/userActions/userActions";
import { useTournament } from "../hooks";
import StatsFilter from "../StatsFilter";
import { ReactComponent as TrophySvg } from "../../../../../../assets/svgs/trophy.svg";
import { mergeClasses, t } from "../../../../../../utility/helpers";

const TeamStatsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [map, setMap] = useState("all");
  const [isLoading, setIsLoading] = useState(true);
  const [mapTypes, setMapTypes] = useState<MapType[]>([]);
  const [error, setError] = useState<ComponentErrorInterface | null>(null);
  const allRef = useRef(true);
  const { selectedRound, selectedGroup } = useTournament();
  const {
    tournament: { tournament, requestStates, filter, teamStats, statsFilters },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const { fullscreen, toggleFullScreen } = useFullScreen();
  useEffect(() => {
    if (requestStates.tournament.success) {
      if (
        !teamStats ||
        allRef.current !== statsFilters.all ||
        statsFilters.all === false
      ) {
        allRef.current = statsFilters.all;
        dispatch(getTeamStats());
      }
    }
  }, [statsFilters]);

  useEffect(() => {
    if (requestStates.tournament.success) {
      dispatch(getTeamStats());
    }
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(clearTeamStats());
    };
  }, []);

  useEffect(() => {
    dispatch(
      saveUserAction(
        tournamentTeamStatsView(
          tournament?.friendlyName,
          tournament?.eventType,
          `${selectedRound?.name} ${selectedGroup?.name}`,
          tournament?.region,
          // TODO: add view type (top 4, advance, graphic)
          "advance",
          // TODO: add full screen
          false
        )
      )
    );
  }, [tournament, selectedRound, selectedGroup]);

  useEffect(() => {
    if (
      teamStats &&
      requestStates.teamStats.success &&
      teamStats.teamStats?.length !== 0
    ) {
      setMapTypes(generateMapType(teamStats.teamStats));
    }
  }, [requestStates.teamStats]);

  useEffect(() => {
    if (requestStates.teamStats.success || requestStates.teamStats.failed) {
      setIsLoading(false);
    }
    if (requestStates.teamStats.requesting && !isLoading) {
      setIsLoading(true);
    }
  }, [
    requestStates.tournament.requesting,
    requestStates.teamStats.requesting,
    teamStats,
  ]);

  useEffect(() => {
    if (!teamStats && requestStates.teamStats.failed) {
      setError({
        message: t("sentences.teamStatsLoadFailed"),
        suggestion: t("sentences.refreshThePage"),
        svg: TrophySvg,
        // actionText: t("words.tryAgain"),
        // onActionClicked: () => timeTypeActionMap[type](),
      });
    } else if (
      teamStats &&
      requestStates.teamStats.success &&
      teamStats.teamStats?.length === 0
    ) {
      setError({
        message: t("sentences.noDataAvailable"),
        suggestion: t("sentences.comeBackLater"),
        svg: TrophySvg,
        // actionText: t("words.tryAgain"),
        // onActionClicked: () => timeTypeActionMap[type](),
      });
    } else {
      setError(null);
    }
  }, [
    requestStates.tournament.requesting,
    requestStates.teamStats.requesting,
    teamStats,
  ]);

  return (
    <div className={mergeClasses([fullscreen, "fullscreen"])}>
      <div className="flex mt-5">
        <div className="ml-auto">
          <StatsFilter />
        </div>
      </div>

      {isLoading ? (
        <Placeholder />
      ) : error ? (
        <div className="mt-5">
          <ComponentError {...error} className="h-96" />
        </div>
      ) : (
        <TeamStats
          onResizeClick={toggleFullScreen}
          stats={
            teamStats ? dataToTableFormat(teamStats.teamStats, map, game) : []
          }
          mapTypes={mapTypes}
          map={map}
          setMap={setMap}
          headers={getHeader(filter)}
          fullscreen={fullscreen}
        />
      )}
    </div>
  );
};

export default TeamStatsContainer;
