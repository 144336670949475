import { TimeType } from "@twire/redux/tournaments/types";
import { saveUserAction } from "@twire/redux/track/actions";
import { tournamentListPageView } from "@twire/redux/track/userActions/userActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import TournamentsView from "./TournamentsView";

const TournamentsViewContainer: React.FC<{ type: TimeType; scrims?: boolean }> =
  ({ type, ...rest }) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(saveUserAction(tournamentListPageView(type)));
    }, [type]);

    return <TournamentsView type={type} {...rest} />;
  };

export default TournamentsViewContainer;
