import { ActionCreator, AnyAction } from "redux";
import * as actionTypes from "./actionTypes";
import { GetPaginated } from "./types";

export const getTeam = (id: number): AnyAction => ({
  type: actionTypes.TEAM_GET_TEAM,
  id,
});

export const getTeamTournaments: ActionCreator<GetPaginated> = (
  id: number,
  page: number
) => ({
  type: actionTypes.TEAM_GET_TOURNAMENTS,
  id,
  page,
});

export const getTeamMatches: ActionCreator<GetPaginated> = (
  id: number,
  page: number
) => ({
  type: actionTypes.TEAM_GET_MATCHES,
  id,
  page,
});

export const getTeamStatistics = (id: number): AnyAction => ({
  type: actionTypes.TEAM_GET_STATISTICS,
  id,
});

export const clearTeam = (): AnyAction => ({
  type: actionTypes.TEAM_GET_TEAM_CLEAR,
});

export const clearTeamTournaments = (): AnyAction => ({
  type: actionTypes.TEAM_GET_TOURNAMENTS_CLEAR,
});
