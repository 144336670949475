import { TogglerViewProps } from "./types";
import classes from "./Toggler.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const Toggler: React.FC<TogglerViewProps> = ({ on, onClick }) => (
  <button
    onClick={onClick}
    className={mergeClasses(classes.container, [on, classes.active])}
  >
    <div />
  </button>
);

export default Toggler;
