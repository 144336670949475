import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootReducer } from "@twire/redux/types";
import { verifyEmail, resendVerifyEmail } from "@twire/redux/user/actions";
import Verification from "./Verification";

const VerificationContainer: React.FC = () => {
  const {
    user: { requestStates },
  } = useSelector((state: RootReducer) => state);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const params = useParams<{ username: string; code: string }>();

  useEffect(() => {
    if (params.username && params.code) {
      dispatch(verifyEmail(params.username, params.code));
    } else {
      setError(true);
    }
  }, []);

  const onResendVerificationEmail = (username: string) => {
    dispatch(resendVerifyEmail(username));
  }

  return (
    <Verification
      error={error || requestStates.verifyEmail.failed}
      success={requestStates.verifyEmail.success}
      username={params.username}
      onResendVerificationEmail={onResendVerificationEmail}
      resendLoading={requestStates.resendVerifyEmail.requesting}
      resendSuccess={requestStates.resendVerifyEmail.success}
      resendFailed={requestStates.resendVerifyEmail.failed}
    />
  );
};

export default VerificationContainer;
