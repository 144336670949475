import { mergeClasses } from "@twire/utility/helpers";
import UnopDropdown, { DropDowndirections } from "unop-react-dropdown";
import React from "react";
import { ReactComponent as ArrowTip } from "../../../assets/svgs/arrowTip.svg";
import { DropdownItemInterface } from "./types";
import classes from "./Select.module.scss";

const SelectTrigger: React.FC<{
  onClick?: () => void;
  value: DropdownItemInterface;
  title: string;
}> = ({ onClick, value, title }) => (
  <div className={classes.container} onClick={onClick}>
    <span className={classes.title}>{title}</span>
    <div className={classes.selector}>
      <div className={classes.focused}>{value.title}</div>
      <div className={mergeClasses("ml-auto", classes.trigger)}>
        <ArrowTip />
      </div>
    </div>
  </div>
);

interface DropdownMenuItem extends DropdownItemInterface {
  slectedValue: DropdownItemInterface;
  updateValue: (arg: any) => void;
}
const DropdownItem: React.FC<DropdownMenuItem> = ({
  slectedValue,
  updateValue,
  ...rest
}) => (
  <div
    className={mergeClasses(
      classes.dropdownItem,
      slectedValue.value === rest.value ? classes.selected : ""
    )}
    onClick={() => updateValue({ ...rest })}
  >
    {rest.title}
  </div>
);

const Select: React.FC<{
  items: DropdownItemInterface[];
  value: DropdownItemInterface;
  title: string;
  updateValue: (arg: any) => void;
}> = ({ items, title, value, updateValue }) => (
  <UnopDropdown
    closeOnDropdownClicked
    closeOnClickOut
    align={DropDowndirections.LEFT}
    trigger={<SelectTrigger value={value} title={title} />}
  >
    <div className={classes.dropdown}>
      {items.map((props) => (
        <React.Fragment key={props.value}>
          <DropdownItem
            slectedValue={value}
            updateValue={updateValue}
            {...props}
          />
        </React.Fragment>
      ))}
    </div>
  </UnopDropdown>
);

export default Select;
