import React from "react";
import UnopDropdown, { DropDowndirections } from "unop-react-dropdown";
import { TwireScoreChartItem } from "@twire/common/TwireScoreChart/types";
import TwireScoreChart from "@twire/common/TwireScoreChart";
import {
  getPlayerAvatar,
  mergeClasses,
  setDefaultPlayerLogo,
  setTeamLogo,
} from "@twire/utility/helpers";
import {
  PlayerId,
  RankedPlayer,
  RankedPlayermap,
} from "@twire/redux/playerRanking/types";
import { ReactComponent as SearchSvg } from "../../../assets/svgs/search.svg";
import { ReactComponent as ArrowTip } from "../../../assets/svgs/arrowTip.svg";
import classes from "./RankedPlayerStat.module.scss";
import { Games } from "@twire/utility/constants";

const RankedPlayerStat: React.FC<{
  data: TwireScoreChartItem[];
  player: RankedPlayer;
  onChange: (v: string) => void;
  collection: PlayerId[];
  game: Games;
  onButtonClick: (v: PlayerId, callBack: () => void) => void;
  showAverageStats: boolean;
  rankedPlayermap: RankedPlayermap;
}> = ({
  data,
  player,
  onChange,
  collection,
  onButtonClick,
  game,
  showAverageStats,
  rankedPlayermap,
}) => (
  <div className={classes.box}>
    <div className={mergeClasses(classes.overallScore, "rift-bold")}>
      {Math.round(player.overallScore)}
    </div>

    <div className={classes.grad2} />

    <img className={classes.teamImg} src={setTeamLogo(game, player.team)} />
    <img
      className={classes.playerImage}
      src={getPlayerAvatar(player.nickname)}
      onError={(e) => setDefaultPlayerLogo(e, true)}
    />

    <div className={classes.details}>
      <UnopDropdown
        align={DropDowndirections.CENTER}
        closeOnClickOut
        trigger={
          <div className={classes.playerName}>
            {player.nickname && player.nickname}
            <ArrowTip className={classes.arrowTip} />
          </div>
        }
      >
        {({ hide }: { hide: (e?: any) => void }) => (
          <ul className={classes.dropdown}>
            <div className={classes.searchBox}>
              <div className={classes.searchSvgBox}>
                <SearchSvg />
              </div>
              <input
                type="search"
                className={classes.search}
                onChange={(e) => onChange(e.target.value.toLowerCase())}
              />
            </div>
            {collection.length > 0 && (
              <div className={classes.predictions}>
                {collection.map(
                  (id) =>
                    rankedPlayermap[id] && (
                      <button
                        key={id}
                        onClick={() => onButtonClick(id, hide)}
                        className={mergeClasses(classes.button, [
                          id === player.id,
                          classes.active,
                        ])}
                      >
                        {rankedPlayermap[id].player.nickname}
                      </button>
                    )
                )}
              </div>
            )}
          </ul>
        )}
      </UnopDropdown>

      <div
        className={mergeClasses(
          "open-sans opacity-70 text-twire-light text-center text-xs mb-auto",
          classes.teamName
        )}
      >
        {player.team}
      </div>
      <TwireScoreChart moreData={showAverageStats} data={data} />
    </div>
  </div>
);

export default RankedPlayerStat;
