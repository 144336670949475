import { useEffect } from "react";
import { useLanguageChange } from "@twire/utility/hooks";
import { getArticles, getPaginated } from "@twire/redux/articles/actions";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { saveUserAction } from "@twire/redux/track/actions";
import { newsPageView } from "@twire/redux/track/userActions/userActions";
import NewsGrid from "./NewsGrid";

const NewsGridContainer: React.FC = () => {
  const lang = useLanguageChange();
  const dispatch = useDispatch();
  const {
    articles: { articles, requestStates, isLastPage, page, game: articleGame },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (!articleGame || (articleGame && articleGame !== game)) {
      dispatch(getArticles());
    }
  }, [game]);

  useEffect(() => {
    dispatch(saveUserAction(newsPageView(page)));
  }, [page])

  const onScroll = () => {
    dispatch(getPaginated(page + 1));
  };

  return (
    <NewsGrid
      lang={lang}
      game={game}
      loading={requestStates.articles.requesting && articles.length === 0}
      articles={articles}
      onScroll={onScroll}
      infinite={!isLastPage}
      fetching={requestStates.articles.requesting}
      failed={requestStates.articles.failed && articles.length === 0}
    />
  );
};

export default NewsGridContainer;
