import React, {useCallback} from "react";
import {Link} from "react-router-dom";
import Text from "@twire/common/Text";
import AuthPage from "@twire/common/AuthPage";
import TwireInput from "@twire/common/TwireInput";
import TwireButton from "@twire/common/TwireButton";
import {mergeClasses, t} from "@twire/utility/helpers";
import {ErrorMessage, Form, Formik} from "formik";

import Success from "./Success";
import {RegisterViewProps} from "./types";

import {initialValues, regFormSchema} from "./constants";
import classes from "./Register.module.scss";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const Register: React.FC<RegisterViewProps> = ({
                                                   placeholders,
                                                   loginUrl,
                                                   success,
                                                   error,
                                                   onSubmit,
                                                   loading,
                                               }) => {
    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleRecaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return '';
        }

        return await executeRecaptcha('login'); // This is not a mistake, don't replace it with 'register'. If you are curious why, check google recaptcha docs.
    }, [executeRecaptcha]);

    return (
        <AuthPage>
            {success ? (
                <Success/>
            ) : (
                <>
                    {" "}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={regFormSchema(t)}
                        onSubmit={async (values) => {
                            const recaptchaToken = await handleRecaptchaVerify();
                            console.log(`Recaptcha token: ${recaptchaToken}`);
                            onSubmit(values, recaptchaToken);
                        }}
                    >
                        {({values, setFieldValue}) => (
                            <Form className="w-full">
                                <div className="flex flex-col w-full">
                                    <h1>
                                        <Text i18nKey="words.register">Register</Text>
                                    </h1>
                                    <div className="info-message mb-5 text-xs">
                                        If you are unable to register please open a ticket in our <a
                                        className="open-sans underline cursor-pointer"
                                        href="https://discord.gg/6EWzf2DXB9">Discord</a> and explain your problem.
                                    </div>
                                    {/* <ErrorMessage name="username" component="span" className="" /> */}
                                    {error && (
                                        <div className="error-message mb-5 text-xs">
                                            {(typeof error === "object" || typeof error === "string") &&
                                            error
                                                ? error.message
                                                    ? error.message
                                                    : error
                                                : ""}
                                        </div>
                                    )}
                                    {/* {error && (
            <div className="error-message mb-5 text-xs">
              Invalid username or password
            </div>
          )} */}
                                    <TwireInput
                                        type="text"
                                        className="input"
                                        placeholder={placeholders.username}
                                        name="username"
                                        error={!!error}
                                    />

                                    <TwireInput
                                        type="email"
                                        className="input"
                                        placeholder={placeholders.email}
                                        name="email"
                                        error={!!error}
                                    />

                                    {/* <ErrorMessage name="password" component="span" className="" /> */}
                                    <div
                                        className={mergeClasses(
                                            classes.passwordsRow,
                                            classes.rowResponsive
                                        )}
                                    >
                                        <TwireInput
                                            type="password"
                                            className="input"
                                            placeholder={placeholders.password}
                                            name="password"
                                            error={!!error}
                                        />
                                        <TwireInput
                                            type="password"
                                            className="input"
                                            placeholder={placeholders.confirmPassword}
                                            name="confirmPassword"
                                            error={!!error}
                                        />
                                    </div>
                                    <div
                                        className={mergeClasses("flex mb-5", classes.rowResponsive)}
                                    >
                                        <div className="flex items-center mr-auto">
                                            <button
                                                type="button"
                                                name="tos"
                                                className={mergeClasses("twire-radio", [
                                                    values.tos,
                                                    " twire-radio-active",
                                                ])}
                                                onClick={() => setFieldValue("tos", !values.tos)}
                                            ></button>
                                            <span className="open-sans text-sm ml-2">
                        <a
                            className="text-twire-orange open-sans"
                            href="https://analytics.twire.gg/en/terms"
                        >
                          <Text i18nKey="sentences.agreeWithTos">
                            I agree with Terms of Use
                          </Text>
                        </a>
                      </span>
                                        </div>
                                        <div
                                            className={mergeClasses(
                                                classes.newsLetter,
                                                "flex items-center ml-auto"
                                            )}
                                        >
                                            <button
                                                type="button"
                                                className={mergeClasses("twire-radio", [
                                                    values.newsLetter,
                                                    " twire-radio-active",
                                                ])}
                                                onClick={() =>
                                                    setFieldValue("newsLetter", !values.newsLetter)
                                                }
                                            ></button>
                                            <span className="open-sans text-sm ml-2">
                        <Text i18nKey="sentences.newsletterSignUp">
                          Sign up to newsletter
                        </Text>
                      </span>
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage
                                    name="tos"
                                    component="div"
                                    className="error-message mb-5 text-xs"
                                />
                                <TwireButton loading={loading} disabled={loading} type="submit">
                                    {/* <Text i18nKey="login.loginText">login</Text> */}
                                    <Text i18nKey="words.createAccount">Register</Text>
                                </TwireButton>
                            </Form>
                        )}
                    </Formik>
                    <p className="mt-5 text-center rift-bold">
                        <Text i18nKey="sentences.alreadyRegistered">
                            Already regisgterd?{" "}
                        </Text>
                        <Link to={loginUrl} className="text-twire-orange font-bold">
                            {" "}
                            <Text i18nKey="login.loginText">login</Text>
                        </Link>
                    </p>
                </>
            )}
        </AuthPage>
    );
};

export default Register;
