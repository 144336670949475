export const PLAYER_RANKING_GET_PLAYER_RANKING =
  "PLAYER_RANKING_GET_PLAYER_RANKING";
export const PLAYER_RANKING_GET_PLAYER_RANKING_REQUESTED =
  "PLAYER_RANKING_GET_PLAYER_RANKING_REQUESTED";
export const PLAYER_RANKING_GET_PLAYER_RANKING_SUCCESS =
  "PLAYER_RANKING_GET_PLAYER_RANKING_SUCCESS";
export const PLAYER_RANKING_GET_PLAYER_RANKING_FAILED =
  "PLAYER_RANKING_GET_PLAYER_RANKING_FAILED";
export const PLAYER_RANKING_GET_PLAYER_RANKING_CLEAR =
  "PLAYER_RANKING_GET_PLAYER_RANKING_CLEAR";
export const SET_PLAYER_RANKING_FILTER = "SET_PLAYER_RANKING_FILTER";
