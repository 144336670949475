/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { setTeamLogo } from "@twire/utility/helpers";
import { Games } from "@twire/utility/constants";
import { Match } from "./types";

export const mapToMatch = (data: any, game: Games): Match[] => {
  return data.map((item: any) => ({
    name: item.name,
    map: item.map,
    winner: item.winner,
    logo: setTeamLogo(game, item.winner),
    id: item.id,
    gameCreatedAt: item.gameCreatedAt || "",
  }));
};
