import React, {useCallback} from "react";
import {Link} from "react-router-dom";
import Text from "@twire/common/Text";
import AuthPage from "@twire/common/AuthPage";
import TwireInput from "@twire/common/TwireInput";
import TwireButton from "@twire/common/TwireButton";
import {Form, Formik} from "formik";

import {initialValues} from "./constants";
import Success from "./Success";
import {LoginViewProps} from "./types";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
// import classes from "./Login.module.scss";

const Login: React.FC<LoginViewProps> = ({
  onSubmit,
  usernamePlaceholder,
  passwordPlaceholder,
  loading,
  error,
  registerUrl,
  forgotPasswordUrl,
  success,
  showSuccessLoginButton,
  userUnverified,
  onResendVerificationEmail,
  onCloseSuccessMessage,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRecaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return '';
    }

    return await executeRecaptcha('login');
  }, [executeRecaptcha]);

  return (
      <AuthPage>
        {success ? (
            <Success
                showLoginButton={showSuccessLoginButton}
                onCloseSuccessMessage={onCloseSuccessMessage}
            />
        ) : (
            <>
              <Formik
                  initialValues={initialValues}
                  onSubmit={async (values) => {
                    const recaptchaToken = await handleRecaptchaVerify();
                    onSubmit(values, recaptchaToken);
                  }}
              >
                {({values}) => (
                    <Form className="w-full">
                      <div className="flex flex-col w-full">
                        <h1><Text i18nKey="login.loginText">login</Text></h1>
                        <div className="info-message mb-5 text-xs">
                          If you are unable to login please open a ticket in our <a
                            className="open-sans underline cursor-pointer"
                            href="https://discord.gg/6EWzf2DXB9">Discord</a> and explain your problem.
                        </div>
                        {/* <ErrorMessage name="username" component="span" className="" /> */}
                        {/* {error && (
                <div className="error-message mb-5 text-xs">
                  {(typeof error === "object" || typeof error === "string") &&
                  error
                    ? error.message
                      ? error.message
                      : error
                    : ""}
                </div>
              )} */}
                        {
                            userUnverified && (
                                <div className="error-message mb-5 text-xs">
                                  <p className="open-sans">
                                    <Text i18nKey="sentences.unverifiedEmail">Your email is not verified</Text>{" - "}
                                    <span
                                        className="open-sans underline lowercase cursor-pointer"
                                        onClick={() => onResendVerificationEmail(values.username)}
                                    >
                            <Text i18nKey="sentences.resendVerificationEmail">Resend verification email</Text>
                          </span>
                                  </p>
                                </div>
                            ) ||
                            error && (
                                <div className="error-message mb-5 text-xs">
                                  <Text i18nKey="sentences.invalidLogin">Invalid username or password</Text>
                                </div>
                            )
                        }
                        <TwireInput
                            type="text"
                            className="input"
                            placeholder={usernamePlaceholder}
                            name="username"
                            error={!!error}
                        />

                        {/* <ErrorMessage name="password" component="span" className="" /> */}
                        <TwireInput
                            type="password"
                            className="input"
                            placeholder={passwordPlaceholder}
                            name="password"
                            error={!!error}
                        />
                      </div>
                      <TwireButton loading={loading} disabled={loading}>
                        <Text i18nKey="login.loginText">login</Text>
                      </TwireButton>
                    </Form>
                )}
              </Formik>
              <div className="flex mt-4">
                <p>
                  <Text i18nKey="sentences.noAccountYet">Dont have an account?</Text>{" "}
                  <Link to={registerUrl} className="text-twire-orange font-bold">
                    <Text i18nKey="words.register">register</Text>
                  </Link>
                </p>
                <Link
                    to={forgotPasswordUrl}
                    className="ml-auto text-twire-orange font-bold"
                >
                  <Text i18nKey="words.forgotPassowrd">forgot password</Text>
                </Link>
              </div>
            </>
        )}
      </AuthPage>
  );
};

export default Login;
