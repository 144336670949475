import { all, fork, AllEffect, ForkEffect } from "redux-saga/effects";

import app from "./app/saga";
import user from "./user/saga";
import tournaments from "./tournaments/saga";
import article from "./article/saga";
import articles from "./articles/saga";
import search from "./search/saga";
import team from "./team/saga";
import teamRanking from "./teamRanking/saga";
import featured from "./featured/saga";
import matches from "./matches/saga";
import pgcPoints from "./pgcPoints/saga";
import tournament from "./tournament/saga";
import track from "./track/saga";
import scrims from "./scrims/saga";
import fantasyTournaments from "./fantasyTournaments/saga";
import subscriptions from "./subscriptions/saga";
import playerRanking from "./playerRanking/saga";
import ads from "./ads/saga";

function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
  yield all([
    fork(app),
    fork(user),
    fork(tournaments),
    fork(article),
    fork(articles),
    fork(team),
    fork(teamRanking),
    fork(featured),
    fork(matches),
    fork(pgcPoints),
    fork(tournament),
    fork(track),
    fork(scrims),
    fork(search),
    fork(fantasyTournaments),
    fork(subscriptions),
    fork(playerRanking),
    fork(ads),
  ]);
}

export default rootSaga;
