import i18next from "i18next";
import { all, put, takeLatest } from "@redux-saga/core/effects";
import { DEFAULT_LANG } from "@twire/utility/constants";
import { changeLanguage, hideFullPageLoadingOverlay } from "./actions";
import * as actionTypes from "./actionTypes";

function* changeLanguageSaga(action: ReturnType<typeof changeLanguage>) {
  yield put({ type: actionTypes.CHANGE_LANGUAGE_REQUESTED });
  const currLang = i18next.language;
  try {
    yield i18next.changeLanguage(action.lang);
    yield put({
      type: actionTypes.CHANGE_LANGUAGE_SUCCESS,
      payload: {
        currLang: currLang || DEFAULT_LANG,
        targetLang: i18next.language,
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.CHANGE_LANGUAGE_FAILED });
  }
}

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time * 1000);
  });

function* hideFullPageLoadingOverlaySaga(
  action: ReturnType<typeof hideFullPageLoadingOverlay>
) {
  yield put({
    type: actionTypes.HIDE_FULL_PAGE_LOADING_OVERLAY_STARTED,
    time: action.time,
  });

  try {
    yield delay(action.time);
    yield put({
      type: actionTypes.HIDE_FULL_PAGE_LOADING_OVERLAY_COMPLETED,
    });
  } catch (error) {
    console.error(error);
  }
}

// function* showTooltipSaga(action: ReturnType<typeof showTooltip>) {
//   yield put({
//     type: actionTypes.SHOW_GLOBAL_TOOL_TIP_STARTED,
//     payload: {
//       x: action.payload.x,
//       y: action.payload.y,
//       time: action.payload.time,
//       value: action.payload.value,
//     },
//   });
//   try {
//     yield delay(action.payload.time);
//     yield put({
//       type: actionTypes.SHOW_GLOBAL_TOOL_TIP_COMPLETED,
//       payload: {
//         x: action.payload.x,
//         y: action.payload.y,
//         time: action.payload.time,
//         value: action.payload.value,
//       },
//     });
//   } catch (error) {
//     console.error(error);
//   }
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.CHANGE_LANGUAGE, changeLanguageSaga),
    takeLatest(
      actionTypes.HIDE_FULL_PAGE_LOADING_OVERLAY,
      hideFullPageLoadingOverlaySaga
    ),
    // takeLatest(actionTypes.SHOW_GLOBAL_TOOL_TIP, showTooltipSaga),
  ]);
}
