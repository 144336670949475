export const ARTICLES_GET_ARTICLE = "ARTICLES_GET_ARTICLE";
export const ARTICLES_GET_ARTICLE_REQUESTED = "ARTICLES_GET_ARTICLE_REQUESTED";
export const ARTICLES_GET_ARTICLE_SUCCESS = "ARTICLES_GET_ARTICLE_SUCCESS";
export const ARTICLES_GET_ARTICLE_FAILED = "ARTICLES_GET_ARTICLE_FAILED";
export const ARTICLES_GET_ARTICLE_CLEAR = "ARTICLES_GET_ARTICLE_CLEAR";

export const ARTICLES_POST_COMMENT = "ARTICLES_POST_COMMENT";
export const ARTICLES_POST_COMMENT_REQUESTED =
  "ARTICLES_POST_COMMENT_REQUESTED";
export const ARTICLES_POST_COMMENT_SUCCESS = "ARTICLES_POST_COMMENT_SUCCESS";
export const ARTICLES_POST_COMMENT_FAILED = "ARTICLES_POST_COMMENT_FAILED";
export const ARTICLES_POST_COMMENT_CLEAR = "ARTICLES_POST_COMMENT_CLEAR";
