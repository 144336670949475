import React, { useRef, useEffect } from "react";
import TriangleRow from "./TriangleRow";

class Point {
  constructor(public x: number, public y: number) {}
  add(x: number, y: number): Point {
    this.x += x;
    this.y += y;
    return this;
  }
}

interface TriangleSetConfig {
  gap: number;
  height: number;
  width: number;
  point: Point;
  color?: string;
}

class TriangleSet {
  constructor(public config: TriangleSetConfig) {}
  draw(ctx: CanvasRenderingContext2D) {
    // drawing first triangle
    ctx.beginPath();
    ctx.moveTo(this.config.point.x, this.config.point.y);
    ctx.lineTo(
      this.config.point.x + this.config.width,
      this.config.point.y - this.config.height / 2
    );
    ctx.lineTo(
      this.config.point.x + this.config.width,
      this.config.point.y + this.config.height / 2
    );
    ctx.closePath();
    ctx.fillStyle = "#F0AB2E";
    ctx.fill();

    // drawing second triangle
    ctx.beginPath();
    ctx.moveTo(
      this.config.point.x + this.config.gap + 2 * this.config.width,
      this.config.point.y
    );
    ctx.lineTo(
      this.config.point.x + this.config.gap + this.config.width,
      this.config.point.y - this.config.height / 2
    );
    ctx.lineTo(
      this.config.point.x + this.config.gap + this.config.width,
      this.config.point.y + this.config.height / 2
    );
    ctx.closePath();
    ctx.fillStyle = "#F0AB2E";
    ctx.fill();
  }
  getSecondPoint(): Point {
    return new Point(
      this.config.point.x + this.config.gap + this.config.width,
      this.config.point.y
    );
  }
}

const TriangleRowContainer: React.FC<{ sizes: number[] }> = ({ sizes }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const triangles: TriangleSetConfig[] = sizes.map((size, index) => {
    return {
      gap: 30,
      height: size,
      width: size,
      point: new Point(index * 80, 5),
    };
  });
  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.height = canvasRef.current?.clientHeight;
      canvasRef.current.width = canvasRef.current?.clientWidth;
      const ctx = canvasRef.current.getContext("2d");
      triangles.forEach((triangle) => {
        const set = new TriangleSet(triangle);
        set.draw(ctx as CanvasRenderingContext2D);
      });
    }
  }, []);
  return <TriangleRow canvasRef={canvasRef} />;
};

export default TriangleRowContainer;
