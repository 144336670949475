import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "@twire/redux/user/actions";
import { RootReducer } from "@twire/redux/types";
import { useNavLinks } from "../Nav/NavigationLinks/hooks";
import HamburgerDropdown from "./HamburgerDropdown";

const HamburgerDropdownContainer: React.FC<{
  toggleDropdown: () => void;
}> = ({
  toggleDropdown,
}) => {
  const { navLinks } = useNavLinks();
  const {
    user: { signedIn, requestStates, userData },
  } = useSelector((state: RootReducer) => state);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <HamburgerDropdown
      handleSignOut={handleSignOut}
      navLinks={navLinks}
      signinigOut={requestStates.signOut.requesting}
      signedIn={signedIn}
      username={userData ? userData.username : "user"}
      toggleDropdown={toggleDropdown}
    />
  );
};

export default HamburgerDropdownContainer;
