import { FC } from "react";
import { Formik, Form } from "formik";
import { t } from "@twire/utility/helpers";
import AuthPage from "@twire/common/AuthPage";
import TwireInput from "@twire/common/TwireInput";
import TwireButton from "@twire/common/TwireButton";
import Text from "@twire/common/Text";

import Success from "./Success";
import { ForgotPasswordFormSchema, initialValues } from "./constants";
import { ForgotPasswordViewProps } from "./types";
// import classes from "./ForgotPassword.module.scss";

const ForgotPassword: FC<ForgotPasswordViewProps> = ({
  onSubmit,
  usernamePlaceholder,
  loading,
  error,
  success,
}) => (
  <AuthPage>
    {success ? (
      <Success />
    ) : (
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordFormSchema(t)}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="flex flex-col">
              <h1><Text i18nKey="words.forgotPassowrd">Forgot password</Text></h1>
              <h6>
                <Text i18nKey="sentences.forgotPasswordExplain">
                  A link with a password change will been sent to your email
                  address.
                </Text>
              </h6>
              {error && (
                <div className="error-message mb-4">
                  <Text i18nKey="sentences.forgotPasswordFailed">
                    Faild to send email. Did you enter correct username?
                  </Text>
                </div>
              )}
              <TwireInput
                type="text"
                className="input"
                placeholder={usernamePlaceholder}
                name="username"
              />
            </div>
            <TwireButton loading={loading} disabled={loading}>
              <Text i18nKey="sentences.sendReset">Send Reset Link</Text>
            </TwireButton>
          </Form>
        )}
      </Formik>
    )}
  </AuthPage>
);

export default ForgotPassword;
