import React from "react";
import classes from "./Placeholder.module.scss";
import mainClasses from "../../TeamTile/TeamTile.module.scss";
import { mergeClasses } from "@twire/utility/helpers";
import { Theme } from "@twire/utility/constants";

const Placeholder: React.FC<{ theme?: Theme }> = ({ theme }) => (
  <div
    className={mergeClasses(
      classes.container,
      mainClasses.container,
      theme ? mainClasses[theme.toLowerCase()] : ""
    )}
  >
    <div className={classes.pos} />
    <div className={classes.logo} />
    <div className={classes.desc} />
  </div>
);

export default Placeholder;
