import { useState } from "react";
import Select from "./Select";
import { DropdownItemInterface } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSelect = (
  dropdownTtems: DropdownItemInterface[],
  defaultValue: string
) => {
  const [value, setValue] = useState(
    dropdownTtems[
      dropdownTtems.findIndex(({ value }) => value === defaultValue)
    ]
  );

  const updateValue = (item: DropdownItemInterface) => {
    setValue(item);
  };

  return {
    dropdownTtems,
    value,
    updateValue,
  };
};

export type InstanceType = ReturnType<typeof useSelect>;

const SelectContainer: React.FC<{
  instance: ReturnType<typeof useSelect>;
  title: string;
}> = ({ instance, title }) => {
  return (
    <Select
      title={title}
      items={instance.dropdownTtems}
      value={instance.value}
      updateValue={instance.updateValue}
    />
  );
};

export default SelectContainer;
