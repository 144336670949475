import { Games, GeneralTournamentFormat } from "@twire/utility/constants";
import { setTeamLogo } from "@twire/utility/helpers";
import { TournamentFilter } from "../../../../../../redux/tournament/types";
import { HeaderType } from "../Stats/AdvancedTable/types";
import { formatToHeaderMap } from "./constants";
import { MapType } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dataToTableFormat = (teamStats: any, map: string, game: Games) => {
  return teamStats.reduce((acc: any, currV: any) => {
    const data = currV.stats.find((e: any) => e.map === map);
    if (data) {
      acc.push({
        data: data.teamName ? data : { ...data, teamName: currV.teamName },
        teamName: currV.teamName,
        // teamLogo: currV.teamLogo,
        teamLogo: setTeamLogo(game, currV.teamName),
      });
    }
    return acc;
  }, []);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generateMapType = (teamStats: any): MapType[] => {
  let mostMapsIndex = 0;
  let mostMap = teamStats[0].stats.length;
  teamStats.forEach((item: any, index: number) => {
    if (item.stats.length > mostMap) {
      mostMap = item.stats.length;
      mostMapsIndex = index;
    }
  });
  return teamStats[mostMapsIndex].stats.map(({ map }: { map: string }) => ({
    key: map,
    name: map.replaceAll("_", " "),
  }));
};

export const getHeader = (filter: TournamentFilter): HeaderType[] => {
  return formatToHeaderMap[filter.ruleset || GeneralTournamentFormat.POINT_SYSTEM];
};
