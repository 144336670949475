import {FC, useEffect} from "react";
// import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearSignIn, resendVerifyEmail, signIn} from "@twire/redux/user/actions";
import {useT, useUrl} from "@twire/utility/hooks";
import {RootReducer} from "@twire/redux/types";

import Login from "./Login";
import {LoginFormData} from "./types";
import RecaptchaWrapper from '../../common/RecaptchaWrapper';

const LoginContainer: FC = () => {
    const dispatch = useDispatch();
    // const { push } = useHistory();
    const {
        user: {requestStates, signedIn, userUnverified},
        app: {game},
    } = useSelector((state: RootReducer) => state);
    const usernamePlaceholder = useT("words.username");
    const passwordPlaceholder = useT("words.password");

    const registerUrl = useUrl(`/${game}/register`);
    const forgotPasswordUrl = useUrl(`/${game}/forgot-password`);
    const home = useUrl(`/${game}/`);

    useEffect(() => {
        return () => {
            dispatch(clearSignIn());
        };
    }, [dispatch]);

    const onSubmit = async (values: LoginFormData, recaptchaToken: string) => {
        dispatch(signIn(values.username, values.password, recaptchaToken));
    };

    const onResendVerificationEmail = (username: string) => {
        dispatch(resendVerifyEmail(username));
    }

    const onCloseSuccessMessage = () => {
        dispatch(clearSignIn());
    }

    useEffect(() => {
        if (requestStates.signIn.success || signedIn) {
            // push(home);
            // Here because ads that we do not control are
            // still displayed to the premium user until
            // they dont do a refresh.
            window.location.assign(home);
        }
    }, [requestStates.signIn]);

    return (
        <RecaptchaWrapper>
            <Login
                onSubmit={onSubmit}
                usernamePlaceholder={usernamePlaceholder}
                passwordPlaceholder={passwordPlaceholder}
                loading={requestStates.signIn.requesting}
                error={requestStates.signIn.failed}
                clear={clearSignIn}
                registerUrl={registerUrl}
                forgotPasswordUrl={forgotPasswordUrl}
                success={
                    requestStates.signIn.success || requestStates.resendVerifyEmail.success
                }
                showSuccessLoginButton={requestStates.resendVerifyEmail.success}
                userUnverified={userUnverified}
                onResendVerificationEmail={onResendVerificationEmail}
                onCloseSuccessMessage={onCloseSuccessMessage}
            />
        </RecaptchaWrapper>
    );
};

export default LoginContainer;
