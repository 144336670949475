import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMatches } from "@twire/redux/matches/actions";
import { RootReducer } from "@twire/redux/types";
import LastMatches from "./LastMatches";
import Placeholder from "./Placeholder";

const LastMatchesContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    matches: { lastMatches, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    dispatch(getMatches());
  }, [game]);

  return requestStates.lastMatches.requesting && lastMatches.length === 0 ? (
    <Placeholder />
  ) : (
    <LastMatches matches={lastMatches} />
  );
};

export default LastMatchesContainer;
