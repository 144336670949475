// Upcoming action types
export const TOURNAMENTS_GET_UPCOMING = "TOURNAMENTS_GET_UPCOMING";
export const TOURNAMENTS_GET_UPCOMING_REQUESTED =
  "TOURNAMENTS_GET_UPCOMING_REQUESTED";
export const TOURNAMENTS_GET_UPCOMING_SUCCESS =
  "TOURNAMENTS_GET_UPCOMING_SUCCESS";
export const TOURNAMENTS_GET_UPCOMING_FAILED =
  "TOURNAMENTS_GET_UPCOMING_FAILED";
export const TOURNAMENTS_UPCOMING_CLEAR = "TOURNAMENTS_GET_UPCOMING_CLEAR";

// Ongoing action types
export const TOURNAMENTS_GET_ONGOING = "TOURNAMENTS_GET_ONGOING";
export const TOURNAMENTS_GET_ONGOING_REQUESTED =
  "TOURNAMENTS_GET_ONGOING_REQUESTED";
export const TOURNAMENTS_GET_ONGOING_SUCCESS =
  "TOURNAMENTS_GET_ONGOING_SUCCESS";
export const TOURNAMENTS_GET_ONGOING_FAILED = "TOURNAMENTS_GET_ONGOING_FAILED";
export const TOURNAMENTS_ONGOING_CLEAR = "TOURNAMENTS_GET_ONGOING_CLEAR";

// past action types
export const TOURNAMENTS_GET_PAST = "TOURNAMENTS_GET_PAST";
export const TOURNAMENTS_GET_PAST_REQUESTED = "TOURNAMENTS_GET_PAST_REQUESTED";
export const TOURNAMENTS_GET_PAST_SUCCESS = "TOURNAMENTS_GET_PAST_SUCCESS";
export const TOURNAMENTS_GET_PAST_FAILED = "TOURNAMENTS_GET_PAST_FAILED";
export const TOURNAMENTS_PAST_CLEAR = "TOURNAMENTS_GET_PAST_CLEAR";

// Filter action types
export const TOURNAMENTS_FILTER_TOURNAMENTS = "TOURNAMENTS_FILTER_TOURNAMENTS";
export const TOURNAMENTS_FILTER_TOURNAMENTS_REQUESTED =
  "TOURNAMENTS_FILTER_TOURNAMENTS_REQUESTED";
export const TOURNAMENTS_FILTER_TOURNAMENTS_SUCCESS =
  "TOURNAMENTS_FILTER_TOURNAMENTS_SUCCESS";
export const TOURNAMENTS_FILTER_TOURNAMENTS_FAILED =
  "TOURNAMENTS_FILTER_TOURNAMENTS_FAILED";
export const TOURNAMENTS_FILTER_CLEAR = "TOURNAMENTS_FILTER_CLEAR";

export const TOURNAMENTS_GLOBAL_FILTER = "TOURNAMENTS_GLOBAL_FILTER";

export const TOURNAMENTS_GET_PAGINATED = "TOURNAMENTS_GET_PAGINATED";
