// FANTASY TOURNAMENTS action types
export const FANTASY_TOURNAMENTS_GET_TOURNAMENTS =
  "FANTASY_TOURNAMENTS_GET_TOURNAMENTS";
export const FANTASY_TOURNAMENTS_GET_TOURNAMENTS_REQUESTED =
  "FANTASY_TOURNAMENTS_GET_TOURNAMENTS_REQUESTED";
export const FANTASY_TOURNAMENTS_GET_TOURNAMENTS_SUCCESS =
  "FANTASY_TOURNAMENTS_GET_TOURNAMENTS_SUCCESS";
export const FANTASY_TOURNAMENTS_GET_TOURNAMENTS_FAILED =
  "FANTASY_TOURNAMENTS_GET_TOURNAMENTS_FAILED";
export const FANTASY_TOURNAMENTS_GET_TOURNAMENTS_CLEAR =
  "FANTASY_TOURNAMENTS_GET_TOURNAMENTS_CLEAR";
