import { AnyAction } from "redux";
import { TeamInterface } from "../types";

export const requested = (state: TeamInterface): TeamInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    tournaments: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TeamInterface,
  action: AnyAction
): TeamInterface => ({
  ...state,
  tournaments: {
    data:
      state.tournaments &&
      state.tournaments.data &&
      state.tournaments.data.length > 0
        ? [...state.tournaments.data, ...action.tournaments]
        : action.tournaments,
    page: action.page,
    isLastPage: action.tournaments.length === 0,
  },
  requestStates: {
    ...state.requestStates,
    tournaments: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: TeamInterface,
  action: AnyAction
): TeamInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    tournaments: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TeamInterface): TeamInterface => ({
  ...state,
  tournaments: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  requestStates: {
    ...state.requestStates,
    tournaments: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
