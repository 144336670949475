import React from "react";
import AuthPage from "@twire/common/AuthPage";
import { t } from "@twire/utility/helpers";
import TwireInput from "@twire/common/TwireInput";
import TwireButton from "@twire/common/TwireButton";
import Text from "@twire/common/Text";
import Success from "./Success";
import { Formik, Form } from "formik";

import { ResetPasswordViewProps } from "./types";
import { initialValues, resetPasswordSchema } from "./constants";
// import classes from "./ResetPassword.module.scss";

const ResetPassword: React.FC<ResetPasswordViewProps> = ({
  placeholders,
  handleSubmit,
  loading,
  error,
  success,
}) => (
  <AuthPage>
    {success ? (
      <Success />
    ) : (
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordSchema(t)}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <h1 className="text-cente" style={{ display: "block" }}>
              <Text i18nKey="words.resetPassowrd">
                Reset password
              </Text>
            </h1>
            {error && (
              <div className="error-message mb-5 text-xs">
                {(typeof error === "object" || typeof error === "string") &&
                error
                  ? error.message
                    ? error.message
                    : error
                  : "An error occured"}
              </div>
            )}
            <h6>
              <Text i18nKey="sentences.enterNewPassword">
                Select new password
              </Text>
            </h6>
            <TwireInput
              type="password"
              className="input"
              placeholder={placeholders.password}
              name="password"
              error={!!error}
            />
            <TwireInput
              type="password"
              className="input"
              placeholder={placeholders.confirmPassword}
              name="confirmPassword"
              error={!!error}
            />
            <TwireButton loading={loading}>
              <Text i18nKey="words.resetPassowrd">
                Reset password
              </Text>
            </TwireButton>
          </Form>
        )}
      </Formik>
    )}
  </AuthPage>
);

export default ResetPassword;
