import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaderboard,
  clearLeaderboard,
} from "@twire/redux/tournament/actions";
import { useFullScreen } from "@twire/utility/hooks";
import ComponentError from "@twire/common/ComponentError";
import { RootReducer } from "@twire/redux/types";
import Leaderboard from "./Leaderboard";
import { formatLeaderboardData } from "./LeaderboardTable/functions";
import { ReactComponent as TrophySvg } from "../../../../../../assets/svgs/trophy.svg";
import { t } from "@twire/utility/helpers";

const LeaderboardContainer: React.FC = () => {
  const [showPerMatch, setShowPerMatch] = useState(false);
  const [showToggler, setShowToggler] = useState(false);
  const { fullscreen, toggleFullScreen } = useFullScreen();
  const dispatch = useDispatch();
  const {
    tournament: { requestStates, filter, platformLeaderboard },
  } = useSelector((state: RootReducer) => state);

  const toggleShowPerMatch = () => {
    setShowPerMatch(!showPerMatch);
  };

  useEffect(() => {
    if (requestStates.tournament.success) {
      dispatch(getLeaderboard());
    }
  }, [requestStates.tournament, filter]);

  useEffect(() => {
    return () => {
      dispatch(clearLeaderboard());
    };
  }, []);

  useEffect(() => {
    if (
      requestStates.tournament.success &&
      requestStates.platformLeaderboard.success &&
      platformLeaderboard
    ) {
      const leaderboard = formatLeaderboardData(
        platformLeaderboard.leaderboard
      );
      let v = true;
      leaderboard.forEach((item) => {
        if (!item.statsPerMatch) {
          v = false;
        }
      });
      setShowToggler(v);
    }
  }, [requestStates.tournament, requestStates.platformLeaderboard]);

  return requestStates.platformLeaderboard.failed ? (
    <div className="mt-5">
      <ComponentError
        message={t("sentences.leaderboardLoadFailed")}
        suggestion={t("sentences.refreshThePage")}
        svg={TrophySvg}
        className="h-96"
      />
    </div>
  ) : requestStates.platformLeaderboard.success &&
    platformLeaderboard?.leaderboard.length === 0 ? (
    <div className="mt-5">
      <ComponentError
        message={t("sentences.noDataAvailable")}
        suggestion={t("sentences.comeBackLater")}
        svg={TrophySvg}
        className="h-96"
      />
    </div>
  ) : (
    <Leaderboard
      fullscreen={fullscreen}
      toggleFullScreen={toggleFullScreen}
      toggleShowPerMatch={toggleShowPerMatch}
      showPerMatch={showPerMatch}
      showToggler={showToggler}
      // setShowToggler={setShowToggler}
      loading={
        requestStates.tournament.requesting ||
        requestStates.platformLeaderboard.requesting ||
        !platformLeaderboard
      }
    />
  );
};

export default LeaderboardContainer;
