import { useState, useRef, useEffect } from "react";
import Layout from "./Layout";

const LayoutContainer: React.FC = ({ children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (dropdownOpen && dropdownRef.current) {
      setDropdownHeight(dropdownRef.current.scrollHeight);
    } else {
      setDropdownHeight(0);
    }
  }, [dropdownOpen]);

  return (
    <Layout
      toggleDropdown={() => setDropdownOpen(!dropdownOpen)}
      dropdownOpen={dropdownOpen}
      dropdownRef={dropdownRef}
      dropdownHeight={dropdownHeight}
    >
      {children}
    </Layout>
  );
};

export default LayoutContainer;
