import { Reducer } from "redux";
import { defaultRequestState, paginatedDefaults } from "../constants";
import { defaultTournamentFilters } from "./constants";
import { getFilters } from "./functions";
import { TournamentsInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as tournamentsHandlers from "./handlers/getTournaments";
import * as filterHandlers from "./handlers/setFilter";

const INITIAL_STATE: TournamentsInterface = {
  tournaments: {
    filter: getFilters(),
    ongoing: {
      game: null,
      data: [],
      filter: { ...defaultTournamentFilters },
      ...paginatedDefaults,
    },
    past: {
      game: null,
      data: [],
      filter: { ...defaultTournamentFilters },
      ...paginatedDefaults,
    },
    upcoming: {
      game: null,
      data: [],
      filter: { ...defaultTournamentFilters },
      ...paginatedDefaults,
    },
  },
  requestStates: {
    ongoing: {
      data: { ...defaultRequestState },
      filter: { ...defaultRequestState },
      paginated: { ...defaultRequestState },
    },
    past: {
      data: { ...defaultRequestState },
      filter: { ...defaultRequestState },
      paginated: { ...defaultRequestState },
    },
    upcoming: {
      data: { ...defaultRequestState },
      filter: { ...defaultRequestState },
      paginated: { ...defaultRequestState },
    },
  },
};

const tournamentsReducer: Reducer<TournamentsInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TOURNAMENTS_GET_ONGOING_REQUESTED:
      return tournamentsHandlers.requested(state, "ongoing", action.paginated);
    case actionTypes.TOURNAMENTS_GET_ONGOING_FAILED:
      return tournamentsHandlers.failed(
        state,
        action,
        "ongoing",
        action.paginated
      );
    case actionTypes.TOURNAMENTS_GET_ONGOING_SUCCESS:
      return tournamentsHandlers.success(
        state,
        action,
        "ongoing",
        action.paginated
      );
    case actionTypes.TOURNAMENTS_ONGOING_CLEAR:
      return tournamentsHandlers.clear(state, "ongoing");
    case actionTypes.TOURNAMENTS_GET_UPCOMING_REQUESTED:
      return tournamentsHandlers.requested(state, "upcoming", action.paginated);
    case actionTypes.TOURNAMENTS_GET_UPCOMING_FAILED:
      return tournamentsHandlers.failed(
        state,
        action,
        "upcoming",
        action.paginated
      );
    case actionTypes.TOURNAMENTS_GET_UPCOMING_SUCCESS:
      return tournamentsHandlers.success(
        state,
        action,
        "upcoming",
        action.paginated
      );
    case actionTypes.TOURNAMENTS_UPCOMING_CLEAR:
      return tournamentsHandlers.clear(state, "upcoming");
    case actionTypes.TOURNAMENTS_GET_PAST_REQUESTED:
      return tournamentsHandlers.requested(state, "past", action.paginated);
    case actionTypes.TOURNAMENTS_GET_PAST_FAILED:
      return tournamentsHandlers.failed(
        state,
        action,
        "past",
        action.paginated
      );
    case actionTypes.TOURNAMENTS_GET_PAST_SUCCESS:
      return tournamentsHandlers.success(
        state,
        action,
        "past",
        action.paginated
      );
    case actionTypes.TOURNAMENTS_PAST_CLEAR:
      return tournamentsHandlers.clear(state, "past");
    case actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS_SUCCESS:
      return filterHandlers.success(state, action, action.payload.key);
    case actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS_FAILED:
      return filterHandlers.failed(state, action, action.payload.key);
    case actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS_REQUESTED:
      return filterHandlers.requested(state, action.payload.key);
    case actionTypes.TOURNAMENTS_GLOBAL_FILTER:
      return filterHandlers.setGlobalFilter(state, action);
    default:
      return state;
  }
};

export default tournamentsReducer;
