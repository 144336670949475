import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";

import Placeholder from "./Placeholder";
import Cards from "./Cards";

const CardsContainer: React.FC = () => {
  const {
    // app: { game },
    team: { requestStates, statistics },
  } = useSelector((state: RootReducer) => state);
  return requestStates.statistics.requesting ||
    requestStates.team.requesting ? (
    <Placeholder />
  ) : (
    <Cards teamStatistic={statistics} />
  );
};

export default CardsContainer;
