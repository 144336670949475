const isProd = process.env.REACT_APP_STAGE === "prod";

type Config = {
  url: string;
};

let paymentConfig: Config;

if (isProd) {
  paymentConfig = {
    url: "https://payment-api.fantasy.twire.gg",
  };
} else {
  paymentConfig = {
    url: "http://52.49.1.96:40001",
  };
}

export default paymentConfig;
