import { AnyAction } from "redux";
import { MatchInterface } from "../types";

export const requested = (state: MatchInterface): MatchInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    lastMatches: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: MatchInterface,
  action: AnyAction
): MatchInterface => ({
  ...state,
  lastMatches: action.lastMatches,
  requestStates: {
    ...state.requestStates,
    lastMatches: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: MatchInterface,
  action: AnyAction
): MatchInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    lastMatches: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: MatchInterface): MatchInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    lastMatches: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
