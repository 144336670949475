import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeatured } from "../../../../redux/featured/actions";
import { RootReducer } from "@twire/redux/types";
import { t } from "@twire/utility/helpers";
import { Games } from "@twire/utility/constants";
import { FeaturedTournamentsContext } from "./context";
import FeaturedTournaments from "./FeaturedTournaments";
import React from "react";
import { ReactComponent as Trophy } from "../../../../assets/svgs/trophy.svg";
import { ComponentErrorInterface } from "@twire/common/ComponentError";

const FeaturedTournamentsContainer: React.FC = () => {
  const [currentindex, setCurrentindex] = useState(0);
  const currIndexRef = useRef(0);
  const loadedOnce = useRef(false);
  const currentTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isAppearing, setIsAppearing] = useState(false);
  const [transitionDuration] = useState(0.3);
  const [slideshowDuration] = useState(10000);
  // const [nextIndex, setNextIndex] = useState(1)
  const dispatch = useDispatch();
  const {
    featured: { requestStates, featured },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const upateSlideshow = () => {
    if (currIndexRef.current === featured.length - 1) {
      setCurrentindex(0);
      currIndexRef.current = 0;
    } else {
      setCurrentindex(currIndexRef.current + 1);
      currIndexRef.current += 1;
    }
    appearingFunction();
  };

  const disappearingFunction = () => {
    setIsTransitioning(true);
    currentTimeout.current = setTimeout(() => {
      setIsTransitioning(false);
      upateSlideshow();
    }, transitionDuration * 1000);
  };

  const waitFunction = () => {
    currentTimeout.current = setTimeout(() => {
      if (featured.length > 1) {
        disappearingFunction();
      }
    }, slideshowDuration);
  };
  const appearingFunction = () => {
    setIsAppearing(true);
    currentTimeout.current = setTimeout(() => {
      setIsAppearing(false);
      waitFunction();
    }, transitionDuration * 1000);
  };

  useEffect(() => {
    dispatch(getFeatured());
  }, []);

  useEffect(() => {
    if (!loadedOnce.current && requestStates.featured.success) {
      loadedOnce.current = true;
      setCurrentindex(Math.floor(Math.random() * featured.length))
      waitFunction();
    }
  }, [requestStates]);

  const setIndex = (index: number) => {
    clearTimeout(currentTimeout.current as NodeJS.Timeout);
    if (isTransitioning) setIsTransitioning(false);
    setCurrentindex(index);
    currIndexRef.current = index;
    appearingFunction();
  };

  const value = {
    isTransitioning,
    currentindex,
    isAppearing,
    featuredTournaments: featured,
    transitionDuration,
    setIndex,
  };

  const errorObj: ComponentErrorInterface = {
    message: t("sentences.unknownError"),
    svg: Trophy,
    suggestion: t("sentences.refreshThePage"),
    actionText: t("words.refresh"),
    onActionClicked: () => {
      dispatch(getFeatured());
    },
  };

  return game === Games.PUBGC ? (
    <></>
  ) : (
    <FeaturedTournamentsContext.Provider value={value}>
      <FeaturedTournaments
        isLoading={
          requestStates.featured.requesting
        }
        failed={!!requestStates.featured.failed}
        errorObj={errorObj}
      />
    </FeaturedTournamentsContext.Provider>
  );
};

export default FeaturedTournamentsContainer;
