import {
  all,
  put,
  takeLatest,
  // select
} from "@redux-saga/core/effects";
// import { RootReducer } from "@twire/redux/types";

import { formatFeatured } from "./functions";
import * as actionTypes from "./actionTypes";

import { publicQuery } from "../../graphql/requestHelper";
import { getFeatured } from "../../graphql/public/queries";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchFeatured() {
  yield put({ type: actionTypes.FEATURED_GET_FEATURED_REQUESTED });

  // const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const featured: Generator<any, any> = yield publicQuery(
      getFeatured,
      {
        // game: state.app.game,
        game: "pubg",
      },
      CACHE_TIME.HOUR,
    );

    //
    const formatFeaturedTournaments = (featured as any).data.getFeatured.map(
      ({ data, type }: any) => ({
        data: typeof data === "string" ? JSON.parse(data) : data,
        type,
      })
    );

    const featuredData = formatFeaturedTournaments.map(formatFeatured);

    yield put({
      type: actionTypes.FEATURED_GET_FEATURED_SUCCESS,
      featured: featuredData,
    });
    //
  } catch (error) {
    yield put({ type: actionTypes.FEATURED_GET_FEATURED_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([takeLatest(actionTypes.FEATURED_GET_FEATURED, fetchFeatured)]);
}
