import { TournamentStatsFilters, TournamentStatsFilter } from "./types";
import { TournamentStatsFilterInput } from "../../PublicAPI";

export const formatTournamentStatsFilters = (
  filters: TournamentStatsFilters
): TournamentStatsFilterInput | null => {
  if (filters.all) {
    return null;
  } else {
    const maps = filters.maps
      .filter(m => m.selected)
      .map(m => m.key);
    const matchIds = filters.matches
      .filter(m => m.selected)
      .map(m => m.key);
    return {
      maps: (maps as string[]),
      matchIds: (matchIds as number[]),
    }
  }
};

export const combineStatsFilters = (
  existingFilters: TournamentStatsFilter[],
  newFilters: TournamentStatsFilter[],
): TournamentStatsFilter[] => {
  return newFilters.map(m => {
    const existingFilter = existingFilters
      .find(em => em.key === m.key);
    return {
      ...m,
      selected: existingFilter?.selected || false,
  }});
}
