import { AnyAction } from "redux";
import {
  SelectedFilterType,
  RankedPlayersInterface,
  FiltersType,
} from "../types";
import { INITIAL_STATE } from "../reducer";

const getInitialFilterFromFilters = (
  filters: FiltersType
): SelectedFilterType => {
  const selectedYearIndex = filters.findIndex((f) => f.selected);
  const contest = filters[selectedYearIndex].children[0];

  return {
    year: filters[selectedYearIndex].value,
    contest: contest.value,
  };
};

export const requested = (
  state: RankedPlayersInterface
): RankedPlayersInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    rankedPlayers: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: RankedPlayersInterface,
  action: AnyAction
): RankedPlayersInterface => ({
  ...state,
  updatedAt: action.updatedAt,
  filters: action.tournamentsFilters.map((filter: any) => ({
    name: filter.name,
    value: filter.value,
    selected: filter.selected,
    children: filter.children,
  })),
  currentFilter: getInitialFilterFromFilters(action.tournamentsFilters),
  data: action.ranking,
  requestStates: {
    ...state.requestStates,
    rankedPlayers: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: RankedPlayersInterface,
  action: AnyAction
): RankedPlayersInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    rankedPlayers: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (): RankedPlayersInterface => ({
  ...INITIAL_STATE,
});
