import Line from "@twire/common/Line";
import Expandable from "@twire/common/Expandable";
import {
  // mergeClasses,
  replaceEmpty,
  formatter,
  setDefaultTournamentLogo,
  setTournamentLogo,
  formatDate,
} from "@twire/utility/helpers";
import { ReactComponent as FacebookSvg } from "../../../../../../assets/svgs/facebook.svg";
import { ReactComponent as Twitter } from "../../../../../../assets/svgs/twitter.svg";
import { ReactComponent as Discord } from "../../../../../../assets/svgs/discord.svg";
import { ReactComponent as Instagram } from "../../../../../../assets/svgs/instagram.svg";
import { ReactComponent as Linkedin } from "../../../../../../assets/svgs/linkedin.svg";
import { ReactComponent as Youtube } from "../../../../../../assets/svgs/youtube.svg";
import { ReactComponent as Twitch } from "../../../../../../assets/svgs/twitch.svg";
import { ReactComponent as ArrowTip } from "../../../../../../assets/svgs/arrowTip.svg";

import PrizeTable from "./PrizeTable";
import { TournamentInfoViewProps } from "./types";
import classes from "./TournamentInfo.module.scss";
import Text from "@twire/common/Text";

export const socialsMap = {
  twitter: <Twitter />,
  discord: <Discord />,
  facebook: <FacebookSvg />,
  instagram: <Instagram />,
  linkedin: <Linkedin />,
  twitch: <Twitch />,
  youtube: <Youtube />,
};

type SocialMapType = keyof typeof socialsMap;

const getSocialsMap = (key: SocialMapType) => socialsMap[key];

const TournamentInfo: React.FC<TournamentInfoViewProps> = ({
  open,
  toggle,
  tournamentData,
  onSocialMediaOpen,
  game,
}) => (
  <div className={classes.container}>
    <div className={classes.left}>
      <div className="flex items-center mb-4">
        <img
          src={setTournamentLogo(
              game,
              tournamentData?.imageName || tournamentData?.shard || ""
          )}
          alt="hello"
          className={classes.badge}
          onError={setDefaultTournamentLogo}
        />
        <div className={classes.name}>
          {replaceEmpty(tournamentData?.friendlyName)}
        </div>
        {tournamentData?.tournamentPrizePool && tournamentData.tournamentPrizePool.length > 0 &&
          <button className={classes.trigger} onClick={toggle}>
            <ArrowTip />
          </button>
        }
      </div>

        <div className={classes.infoSection}>
          <div className={classes.info}>
            <div>
              <Text i18nKey="words.startDate">
                Start Date
              </Text>
            </div>
            <div>
              {(tournamentData?.startDate ? formatDate(tournamentData.startDate) : "N/A")}
            </div>
          </div>
          <div className={classes.info}>
            <div>
              <Text i18nKey="words.endDate">
                End Date
              </Text>
            </div>
            <div>
              {(tournamentData?.stopDate ? formatDate(tournamentData.stopDate) : "N/A")}
            </div>
          </div>
          {/* <div className={classes.info}>
            <div>venue</div>
            <div>Studio Paradise</div>
          </div> */}
          {/* <div className={classes.info}>
            <div>teams</div>
            <div>26</div>
          </div> */}
          <div className={classes.info}>
            <div>
              <Text i18nKey="words.tier">
                tier
              </Text>
            </div>
            <div><Text i18nKey="words.tier">tier</Text> {replaceEmpty(tournamentData?.tier)}</div>
          </div>
          <div className={classes.info}>
            <div>
              <Text i18nKey="words.region">
                region
              </Text>
            </div>
            <div>{replaceEmpty(tournamentData?.region)}</div>
          </div>
          <div className={classes.info}>
            <div>
              <Text i18nKey="words.prizePool">
                prize pool
              </Text>
            </div>
            <div>
              {tournamentData
                ? formatter("usd")
                    .format(tournamentData.prizePool || 0)
                    .replace(/\.00/g, "")
                : "N/A"}
            </div>
          </div>
          {/* <div className={classes.info}>
            <div>organizer</div>
            <div>PUBG Corporation</div>
          </div> */}
        </div>
        {/* 
        <div className="mb-6">
          <Line />
        </div>

        <div className={classes.formatBox}>
          <div className="font-bold text-twire-orange">tournament format</div>
          <div className={classes.format}>
            <div>Weekly Survival</div>
            <div>Every Wednesday and Thursday during the tournament</div>
            <div>WWCD rule</div>
          </div>
          <div className={classes.format}>
            <div>Wild Card</div>
            <div>Every Friday during the tournament</div>
            <div>WWCD rule</div>
          </div>
        </div> */}

        {/* <div className="my-6">
          <Line />
        </div> */}

        {/* <div
          className={mergeClasses(
            "mb-6 flex items-center",
            classes.prizeDetails
          )}
        >
          <div className="font-bold text-twire-orange">prize distribution</div>
          <div
            className={mergeClasses(
              "font-bold text-twire-light",
              classes.prize
            )}
          >
            {tournamentData?.prizePool
              ? formatter("usd").format(tournamentData?.prizePool)
              : "N/A"}
          </div>
          <div className="text-xs text-twire-light open-sans-i opacity-70 ml-3 mt-1">
            is spread among the teams as seen below
          </div>
        </div> */}

      {!!tournamentData?.tournamentSocials?.length && (
        <div className="flex">
          <div className={classes.socials}>
            {tournamentData.tournamentSocials.map(social => {
              if (social && social?.type) {
                return (
                  <a
                    key={social?.type}
                    className={tournamentData.live && social.videoId ? classes.live : ""}
                    href={social?.value}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onSocialMediaOpen(social?.type)}
                  >
                    {getSocialsMap(social?.type as SocialMapType)}
                  </a>    
                );
              } else {
                return null;
              }
            })}
          </div>

        {/* <div className="ml-auto">
          <ArrowSpan>registration</ArrowSpan>
        </div> */}
        </div>
      )}

        {/* <div className="mb-6"> */}
      {tournamentData?.tournamentPrizePool && tournamentData.tournamentPrizePool.length > 0 &&
        <Expandable open={open}>
          <div className="my-6">
            <Line />
          </div>
          <PrizeTable />
          {/* </div> */}
        </Expandable>
      }
    </div>
  </div>
);

export default TournamentInfo;
