import { mergeClasses } from "@twire/utility/helpers";
import Text from "@twire/common/Text";
import TwireLink from "@twire/common/TwireLink";
import TwireButton from "@twire/common/TwireButton";
import Search from "@twire/common/Search";

import { ReactComponent as GearSvg } from "../../../../assets/svgs/settings.svg";
import LanguageSelect from "../LanguageSelect";
import GameSelect from "../GameSelect";
import classes from "./Header.module.scss";

const Header: React.FC<{
  dropdownOpen: boolean;
  toggleDropdown: () => void;
  handleSignOut: () => void;
  signedIn: boolean;
  signingOut: boolean;
  username: string;
}> = ({ toggleDropdown, signedIn, handleSignOut, signingOut, username }) => (
  <header
    className={mergeClasses(
      "background-twire-grey flex h-20 items-center px-10",
      classes.header
    )}
  >
    <TwireLink to="/" className={classes.iconLink}>
      <img src="/images/logo.png" alt="twire logo" className={classes.logo} />
    </TwireLink>
    <button className={classes.hamburger} onClick={toggleDropdown}>
      <div />
      <div />
      <div />
    </button>
    <hr className={mergeClasses(classes.partition, "mr-0")} />
    <GameSelect />
    <hr className={mergeClasses(classes.partition, "ml-0 mr-0")} />
    <div className="w-full h-full" style={{ maxWidth: "700px" }}>
      <Search />
    </div>
    {signedIn && (
      <>
        <hr className={mergeClasses(classes.partition, "ml-auto")} />
        <TwireLink to="/settings" className="flex items-center">
          <span className="text-twire-light font-bold whitespace-nowrap">
            {username}
          </span>
          <button className={classes.gear}>
            <GearSvg />
          </button>
        </TwireLink>
      </>
    )}
    <hr
      className={mergeClasses(classes.partition, "mx-0", [
        !signedIn,
        "ml-auto",
      ])}
    />
    <div className="flex items-center">
      <LanguageSelect />
      <hr className={mergeClasses(classes.partition, "ml-0")} />
      {!signedIn ? (
        <>
          <TwireLink to="/login">
            <button
              className={mergeClasses(
                classes.button,
                "background-twire-light-ash",
                "border-twire-dark-ash-i",
                "text-twire-orange"
              )}
            >
              <Text i18nKey="words.signIn">sign in</Text>
            </button>
          </TwireLink>
          <TwireLink to="/register">
            <button
              className={mergeClasses(
                classes.button,
                "ml-2",
                "background-twire-dark-orange",
                "border-twire-orange",
                "text-twire-dark"
              )}
            >
              <Text i18nKey="words.signUp">sign up</Text>
            </button>
          </TwireLink>
        </>
      ) : (
        <TwireButton
          loading={signingOut}
          className={mergeClasses(
            classes.button,
            "background-twire-light-ash",
            "border-twire-dark-ash-i",
            "text-twire-orange"
          )}
          onClick={handleSignOut}
        >
          sign out
        </TwireButton>
      )}
    </div>
  </header>
);

export default Header;
