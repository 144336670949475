import { TWIRE_ASSETS_URL } from "@twire/utility/constants";

/**
 * Returns URL address to content image
 *
 * @param {*} image
 */
export const getContentImage = (image: string): string => {
  if (image) {
    return TWIRE_ASSETS_URL + "pubg/content/" + image + ".png";
  } else {
    return TWIRE_ASSETS_URL + "pubg/content/default.png";
  }
};
