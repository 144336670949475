import { formatSecondsToTime } from "../../../../../../utility/helpers";

export const formatStatisticData = (value: number, type: string): string => {
  switch (type) {
    case "avgTimeSurvived":
      return `${formatSecondsToTime(value)}`;
    default:
      return value % 1 != 0 ? value.toFixed(1) : value.toString();
  }
};
