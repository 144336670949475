import React from "react";
import { GlowDirection } from "./types";
import MenuItem from "./MenuItem";

const MenuItemContainer: React.FC<{
  dir?: GlowDirection;
  className?: string;
  active?: boolean;
  to: string;
  external?: boolean;
  exact?: boolean;
  isActive?: any;
  blue?: boolean;
}> = ({ children, ...rest }) => {
  return (
    <MenuItem dir="BOTTOM" {...rest}>
      {children}
    </MenuItem>
  );
};

export default MenuItemContainer;
