import { AnyAction } from "redux";
import { TeamInterface } from "../types";

export const requested = (state: TeamInterface): TeamInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    matches: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TeamInterface,
  action: AnyAction
): TeamInterface => ({
  ...state,
  matches: {
    data:
      state.matches && state.matches.data && state.matches.data.length > 0
        ? [...state.matches.data, ...action.matches]
        : action.matches,
    page: action.page,
    isLastPage: action.matches.length === 0,
  },
  requestStates: {
    ...state.requestStates,
    matches: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: TeamInterface,
  action: AnyAction
): TeamInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    matches: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TeamInterface): TeamInterface => ({
  ...state,
  matches: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  requestStates: {
    ...state.requestStates,
    matches: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
