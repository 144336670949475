import React from "react";
import { mergeClasses } from "../../../utility/helpers";
import classes from "./AuthPage.module.scss";

const AuthPage: React.FC = ({ children }) => (
  <div className={mergeClasses("auth-page", classes.container)}>
    <div className={mergeClasses(classes.formWrapper, "twire-top-border")}>
      {children}
    </div>
  </div>
);

export default AuthPage;
