import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { t } from "@twire/utility/helpers";

import Placeholder from "./Placeholder";
import FantasyTournaments from "./FantasyTournaments";
import { getFantasyTournaments } from "@twire/redux/fantasyTournaments/actions";
import { TournamentStatus } from "../../../FantasyPublicAPI";
import { mergeClasses } from "@twire/utility/helpers";
import ArrowSpan from "../ArrowSpan/ArrowSpan";
import classes from "./FantasyTournaments.module.scss";

const FantasyTournamentsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    fantasyTournaments: { tournaments, requestStates },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (tournaments.length === 0) {
      dispatch(getFantasyTournaments());
    }
  }, []);

  const Title = () => {
    return (
      <div className="flex items-center">
        <div className="flex items-center">
          <span className="text-xl bold mr-3 text-twire-light">
            {t("words.fantasy")} {t("words.tournaments")}
          </span>
        </div>
        <a
          href="https://fantasy.twire.gg"
          target="_blank"
          rel="noreferrer"
          className={mergeClasses(classes.blue, "ml-auto")}
        >
          <ArrowSpan>{t("words.playNow")}</ArrowSpan>
        </a>
      </div>
    );
  }

  return requestStates.tournaments.requesting && tournaments.length === 0 ? (
    <>
      <Title />
      <Placeholder />
    </>
  ) : requestStates.tournaments.success && tournaments.filter(t => t.status === TournamentStatus.LIVE).length === 0 ? (
    null
  ) : (
    <>
      <Title />
      <FantasyTournaments
        tournaments={
          tournaments.filter(
            t => t.status === TournamentStatus.LIVE
          )
        }
      />
    </>
  );
};

export default FantasyTournamentsContainer;
