import React from "react";
import { PositionChange } from "@twire/utility/constants";
import { mergeClasses, getPosVal } from "@twire/utility/helpers";
import classes from "./RankChange.module.scss";

import { ReactComponent as ArrowTip } from "../../../assets/svgs/arrowTip.svg";

interface RankChangeProps {
  positionChange: PositionChange;
  positionDiff: number;
  className?: string;
}

const RankChange: React.FC<RankChangeProps> = ({
  positionChange,
  positionDiff,
  className,
}) =>
  getPosVal(positionChange, positionDiff) ? (
    <div
      className={mergeClasses(
        "hidden md:flex",
        classes.increment, [
          true,
          className || "",
          classes[positionChange.toLowerCase()],
        ]
      )}
    >
      <ArrowTip />
      {getPosVal(positionChange, positionDiff)}
    </div>
  ) : (
    <></>
  );

export default RankChange;
