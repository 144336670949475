import React from "react";
import { formatTime, mergeClasses } from "@twire/utility/helpers";

import { ReactComponent as ChatBubble } from "../../../assets/svgs/chat-bubble.svg";
import { getContentImage } from "./functions";
import { NewsTileContainerInterface } from "./types";
import classes from "./NewsTile.module.scss";

const NewsTile: React.FC<NewsTileContainerInterface> = ({
  updatedAt,
  commentsCount,
  title,
  summary,
  coverImage,
  short,
  noBar,
  bigDescription,
}) => (
  <div
    className={mergeClasses(
      classes.container,
      [short, classes.short],
      noBar ? classes.hideBoarder : ""
    )}
    style={{
      background: `linear-gradient(180deg, rgba(32, 34, 41, 0.5) 0%, #202229 100%), url(${getContentImage(
        coverImage
      )})`,
    }}
  >
    {!noBar && <div className={classes.top} />}
    <div
      className={mergeClasses(
        "h-full flex flex-col justify-between",
        classes.inner
      )}
    >
      <div className="mt-auto">
        <p className={classes.headline}>{title}</p>
        <p
          className={mergeClasses(classes.caption, [
            !!bigDescription,
            classes.bigFont,
          ])}
        >
          {summary}
        </p>
      </div>
      <div className={classes.base}>
        <span className="flex items-center">
          <ChatBubble />
          <span className="ml-1">{commentsCount}</span>
        </span>
        <span className="ml-auto">{formatTime(updatedAt)}</span>
      </div>
    </div>
  </div>
);

export default NewsTile;
