import { mergeClasses } from "@twire/utility/helpers";
import { ReactComponent as RightArrowTip } from "../../../assets/svgs/arrow-tip-right.svg";
import classes from "./ArrowSpan.module.scss";

const ArrowSpan: React.FC<{ onClick?: () => void; className?: string }> = ({
  children,
  onClick,
  className,
}) => (
  <span
    className={mergeClasses(classes.arrowSpan, className || "")}
    onClick={onClick}
  >
    <span className="rift-bold">{children}</span> <RightArrowTip />
  </span>
);

export default ArrowSpan;
