import * as Yup from "yup";
import { getSchema } from "@twire/utility/helpers";
import { TFunction } from "i18next";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loginFormSchema = (t: TFunction) =>
  Yup.object().shape({
    username: getSchema(t).username,
    password: getSchema(t).password,
  });

export const initialValues = {
  username: "",
  password: "",
};
