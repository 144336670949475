import { DateTime } from "luxon";
import { all, put, takeLatest, select, call } from "@redux-saga/core/effects";
import { ARTICLE_RECENT_DAYS } from "@twire/utility/constants";
import { RootReducer } from "@twire/redux/types";

import { formatArticleCardData } from "./functions";
import { ArticleCardData } from "./types";
import * as actionTypes from "./actionTypes";
import { getPaginated } from "./actions";
import { publicQuery } from "../../graphql/requestHelper";
import { getArticles } from "../../graphql/public/queries";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchArticles(filter?: { page: number }) {
  yield put({ type: actionTypes.ARTICLES_GET_ARTICLES_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const articles: Generator<any, any> = yield publicQuery(
      getArticles,
      {
        game: state.app.game,
        ...filter,
        // type: "article",
      },
      CACHE_TIME.HOUR,
    );

    const now = DateTime.local();
    const leastRecentDay = now.minus({ days: ARTICLE_RECENT_DAYS });

    const all: ArticleCardData[] = [];
    const recent: ArticleCardData[] = [];

    (articles as any).data.getArticles.forEach(
      (article: { updatedAt: string }) => {
        const articleDate = DateTime.fromISO(article.updatedAt);
        if (leastRecentDay.toMillis() < articleDate.toMillis()) {
          recent.push(formatArticleCardData(article));
        }
        all.push(formatArticleCardData(article));
      }
    );

    yield put({
      type: actionTypes.ARTICLES_GET_ARTICLES_SUCCESS,
      articles: all,
      page: filter?.page,
      paginated: !!(filter as any).page,
      game: state.app.game,
    });
  } catch (error) {
    yield put({ type: actionTypes.ARTICLES_GET_ARTICLES_FAILED, error });
  }
}

function* fetchPaginated({ page }: ReturnType<typeof getPaginated>) {
  yield call(fetchArticles, { page });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.ARTICLES_GET_ARTICLES, fetchArticles),
    takeLatest(actionTypes.ARTICLES_GET_ARTICLES_PAGINATED, fetchPaginated),
  ]);
}
