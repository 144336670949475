import { mergeClasses } from "@twire/utility/helpers";
import classes from "./OldTableStyles.module.scss";
import placeholderClasses from "./Placeholder.module.scss";

const Placeholder: React.FC<{ startIndex?: number }> = ({ startIndex }) => {
  const emptyData = Array(10).fill("");
  return (
    <div className={mergeClasses(classes.container, "appear")}>
      <div className={classes.wrapperGrid}>
        <div className="w-full">
          <div
            className={mergeClasses(
              classes.grey1,
              classes.row,
              "mb-1 flex items-center font-bold",
              classes.p30
            )}
          >
            Rank
          </div>
          <div>
            {emptyData.map((_, index) => (
              <div
                className={mergeClasses(
                  classes.rank,
                  classes.grey2,
                  "text-twire-orange mb-1 flex items-center font-bold",
                  classes.row,
                  classes.p30
                )}
                key={index}
              >{`#${(startIndex || 0) + index + 1}`}</div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div
            className={mergeClasses(
              classes.grey1,
              classes.row,
              "mb-1 flex items-center font-bold pl-5"
            )}
          >
            Team
          </div>
          <div>
            {emptyData.map((_, index) => (
              <div
                className={mergeClasses(
                  classes.rank,
                  classes.grey2,
                  "mb-1 flex items-center font-bold whitespace-nowrap overflow-ellipsis pr-4",
                  classes.team,
                  classes.row
                )}
                key={index}
              >
                <div
                  className={mergeClasses(
                    placeholderClasses.placeholder,
                    classes.teamNamePlaceholder
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div
            className={mergeClasses(
              classes.grey1,
              classes.row,
              "mb-1 flex items-center font-bold justify-center px-3"
            )}
          >
            {/* Wins */}
          </div>
          {emptyData.map((_, index) => (
            <div
              className={mergeClasses(
                classes.rank,
                classes.grey3,
                "mb-1 flex items-center font-bold whitespace-nowrap justify-center",
                classes.row,
                classes.wins
              )}
              key={index}
            >
              <div className={placeholderClasses.placeholder} />
            </div>
          ))}
        </div>
        <div className="w-full">
          <div
            className={mergeClasses(
              classes.grey1,
              classes.row,
              "mb-1 flex items-center font-bold justify-center px-3"
            )}
          >
            {/* Wins */}
          </div>
          {emptyData.map((_, index) => (
            <div
              className={mergeClasses(
                classes.rank,
                classes.grey3,
                classes.damage,
                "mb-1 flex items-center font-bold whitespace-nowrap justify-center",
                classes.row
              )}
              key={index}
            >
              <div className={placeholderClasses.placeholder} />
            </div>
          ))}
        </div>
        <div className="w-full">
          <div
            className={mergeClasses(
              classes.grey1,
              classes.row,
              "mb-1 flex items-center font-bold justify-center px-3"
            )}
          >
            {/* Kills */}
          </div>
          {emptyData.map((_, index) => (
            <div
              className={mergeClasses(
                classes.rank,
                classes.grey3,
                classes.kills,
                "mb-1 flex items-center font-bold whitespace-nowrap justify-center",
                classes.row
              )}
              key={index}
            >
              <div className={placeholderClasses.placeholder} />
            </div>
          ))}
        </div>
        {/* <div className="w-full">
          <div
            className={mergeClasses(
              classes.grey1,
              classes.row,
              "mb-1 flex items-center font-bold justify-center text-twire-orange px-3"
            )}
          >
            Points
          </div>
          {emptyData.map((_, index) => (
            <div
              className={mergeClasses(
                classes.rank,
                classes.grey3,
                "mb-1 flex items-center font-bold text-twire-orange-i whitespace-nowrap justify-center",
                classes.row,
                classes.points
              )}
              key={index}
            >
              <div className={placeholderClasses.placeholder} />
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Placeholder;
