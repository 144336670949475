import { useState } from "react";

export interface ContextValue {
  value: string;
  setValue: (value: string) => void;
}

export const useTextArea = (initialValue?: string): ContextValue => {
  const [value, setValue] = useState(initialValue || "");
  return {
    value,
    setValue,
  };
};
