import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { RootReducer } from "@twire/redux/types";

import * as actionTypes from "./actionTypes";

import { generalSearch } from "../../graphql/public/queries";
import { publicQuery } from "../../graphql/requestHelper";
import { getSearchResults } from "./actions";
import { formatTournamentData } from "../tournaments/functions";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchSearchResults({ query }: ReturnType<typeof getSearchResults>) {
  yield put({ type: actionTypes.SEARCH_GET_SEARCH_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const searchResults: Generator<any, any> = yield publicQuery(
      generalSearch,
      {
        searchString: query,
        game: state.app.game,
      },
      CACHE_TIME.MINUTE * 10,
    );

    yield put({
      type: actionTypes.SEARCH_GET_SEARCH_RESULTS_SUCCESS,
      tournaments: (searchResults as any).data.generalSearch.tournaments.map(
        (d: any) => formatTournamentData(d)
      ),
      teams: (searchResults as any).data.generalSearch.teams,
    });
  } catch (error) {
    yield put({ type: actionTypes.SEARCH_GET_SEARCH_RESULTS_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.SEARCH_GET_SEARCH_RESULTS, fetchSearchResults),
  ]);
}
