import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { SubscriptionsInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as subscriptionHandlers from "./handlers/getSubscriptionPlans";
import * as subscribeUserHandlers from "./handlers/subscribeUser";
import * as unsubscribeUserHandlers from "./handlers/unsubscribeUser";

const INITIAL_STATE: SubscriptionsInterface = {
  subscriptionPlans: [],
  requestStates: {
    subscriptionPlans: { ...defaultRequestState },
    subscribeUser: { ...defaultRequestState },
    unsubscribeUser: { ...defaultRequestState },
  },
};

const subscriptionsReducer: Reducer<SubscriptionsInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION_PLANS_FAILED:
      return subscriptionHandlers.failed(state, action);
    case actionTypes.GET_SUBSCRIPTION_PLANS_REQUESTED:
      return subscriptionHandlers.requested(state);
    case actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return subscriptionHandlers.success(state, action);
    case actionTypes.GET_SUBSCRIPTION_PLANS_CLEAR:
      return subscriptionHandlers.clear(state);

    ///////////////

    case actionTypes.SUBSCRIBE_USER_TO_PLAN_FAILED:
      return subscribeUserHandlers.failed(state, action);
    case actionTypes.SUBSCRIBE_USER_TO_PLAN_REQUESTED:
      return subscribeUserHandlers.requested(state);
    case actionTypes.SUBSCRIBE_USER_TO_PLAN_SUCCESS:
      return subscribeUserHandlers.success(state);
    case actionTypes.SUBSCRIBE_USER_TO_PLAN_CLEAR:
      return subscribeUserHandlers.clear(state);

    /////////////

    case actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_FAILED:
      return unsubscribeUserHandlers.failed(state, action);
    case actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_REQUESTED:
      return unsubscribeUserHandlers.requested(state);
    case actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_SUCCESS:
      return unsubscribeUserHandlers.success(state);
    case actionTypes.UNSUBSCRIBE_USER_FROM_PLAN_CLEAR:
      return unsubscribeUserHandlers.clear(state);
    default:
      return state;
  }
};

export default subscriptionsReducer;
