import React from "react";
import Filter from "./Filter";

const FilterContainer: React.FC<{
  onApplyClicked: () => void;
  onClearClicked: () => void;
  onOpen?: (arg?: any) => void;
}> = ({ children, ...rest }) => {
  return <Filter {...rest}>{children}</Filter>;
};

export default FilterContainer;
