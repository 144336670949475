import React from "react";
import { ReactComponent as ZoomArrow } from "../../../assets/svgs/zoom-arrow.svg";
import { mergeClasses } from "../../../utility/helpers";
import classes from "./ScreenSizeButton.module.scss";

const ScreenSizeButton: React.FC<{ onClick: () => void; alt?: boolean }> = ({
  onClick,
  alt,
}) => (
  <button
    className={mergeClasses(classes.container, [!!alt, classes.close])}
    onClick={onClick}
  >
    <>
      <ZoomArrow />
      <ZoomArrow />
      <ZoomArrow />
      <ZoomArrow />
    </>
  </button>
);

export default ScreenSizeButton;
