import React, { useState } from "react";
import { formatToCurrency } from "@twire/utility/helpers";
import TwireButton from "@twire/common/TwireButton";
import { PlanProps } from "./types";
import classes from "./Plan.module.scss";

const Plan: React.FC<PlanProps> = ({
  title,
  caption,
  advantages,
  onClick,
  price,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.container}>
      <p className="rift-bold text-twire-light text-xl text-center mb-2">
        {title}
      </p>
      <div className="flex mx-auto">
        <div className={classes.priceMain}>
          {formatToCurrency(price, "eur").split(".")[0]}
        </div>
        <div className={classes.dec}>
          ,{formatToCurrency(price, "eur").split(".")[1]}
        </div>
      </div>
      <p className="text-xs open-sans text-twire-light opacity-70 mt-2">
        {caption}
      </p>
      <hr className={classes.hr} />
      <p className="opacity-70 rift-bold text-twire-light">
        what&apos;s included
      </p>
      <ul className={classes.ul}>
        {advantages.map((advantage) => (
          <li key={advantage}>{advantage}</li>
        ))}
      </ul>
      <TwireButton
        onClick={async () => {
          setLoading(true);
          await onClick();
          setLoading(false);
        }}
        loading={loading}
      >
        subscribe
      </TwireButton>
    </div>
  );
};

export default Plan;
