/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call } from "redux-saga/effects";
import gql from "graphql-tag";
import { privateClient, publicClient, fantasyPublicClient } from "./client";
import {
  InvalidateCache,
  getCachedData,
  invalidateCachedData,
  setCachedData,
} from "./cache";

const CACHE_TURN_ON = false;

/**
 * Makes a query request to server (public or private)
 *
 * @param {*} client public or private client
 * @param {*} query query that needs to be executed
 * @param {*} variables varaibles
 */
const queryRequest = function* (client: any, query: any, variables: any, cache: number): any {
  const cachedData = CACHE_TURN_ON && getCachedData(query, variables);

  const data = cachedData
    ? cachedData
    : yield call([client, 'query'], {
        query: gql(query),
        fetchPolicy: 'no-cache',
        variables: variables,
      });

  if (!cachedData && cache && CACHE_TURN_ON) {
    setCachedData(query, variables, data, cache);
  }

  return data;
};

/**
 * Makes a mutation request to server (public or private)
 *
 * @param {*} client public or private client
 * @param {*} mutation mutation that needs to be executed
 * @param {*} variables varaibles
 */
const mutationRequest = function* (
  client: any,
  mutation: any,
  variables: any,
  invalidateCache?: InvalidateCache[],
): any {
  const response = yield call([client, 'mutate'], {
    mutation: gql(mutation),
    variables: variables,
  });

  if (invalidateCache) {
    invalidateCache.forEach((c) => {
      invalidateCachedData(c);
    });
  }

  return response;
};

/**
 * Makes a subscription request to server (public or private)
 *
 * @param {*} client public or private client
 * @param {*} subscription subscription that needs to be executed
 * @param {*} variables variables
 * @param {*} next next function that will be executed when data arrives
 * @param {*} error error function that will be executed on error
 */
const subscriptionRequest = function* (
  client: any,
  subscription: any,
  variables: any,
  next: any,
  error: any
): any {
  const result = yield call([client, "subscribe"], {
    query: gql(subscription),
    variables: variables,
  });
  result.subscribe({
    next: (data: any) => next(data),
    error: (err: any) => error(err),
  });
};

/**
 * Public query helper
 *
 * @param {*} query query that needs to be executed
 * @param {*} variables variables
 */
export const publicQuery = function* (query: any, variables: any, cache = 0): any {
  return yield queryRequest(publicClient, query, variables, cache);
};

/**
 * Private query helper
 *
 * @param {*} query query that needs to be executed
 * @param {*} variables variables
 */
export const privateQuery = function* (query: any, variables: any, cache = 0): any {
  return yield queryRequest(privateClient, query, variables, cache);
};

/**
 * Fantasy Public query helper
 *
 * @param {*} query query that needs to be executed
 * @param {*} variables variables
 */
export const fantasyPublicQuery = function* (query: any, variables: any, cache = 0): any {
  return yield queryRequest(fantasyPublicClient, query, variables, cache);
};

/**
 * Public mutation helper
 *
 * @param {*} mutation mutation that needs to be executed
 * @param {*} variables variables
 */
export const publicMutation = function* (mutation: any, variables: any, invalidateCache?: InvalidateCache[]): any {
  return yield mutationRequest(publicClient, mutation, variables, invalidateCache);
};

/**
 * Private mutation helper
 *
 * @param {*} mutation mutation that needs to be executed
 * @param {*} variables variables
 */
export const privateMutation = function* (mutation: any, variables: any, invalidateCache?: InvalidateCache[]): any {
  return yield mutationRequest(privateClient, mutation, variables, invalidateCache);
};

/**
 * Public subscription helper
 *
 * @param {*} subscription subscription that needs to be executed
 * @param {*} variables variables
 * @param {*} next next function that will be executed when data arrives
 * @param {*} error error function that will be executed on error
 */
export const publicSubscription = function* (
  subscription: any,
  variables: any,
  next: any,
  error: any
) {
  yield subscriptionRequest(publicClient, subscription, variables, next, error);
};

/**
 * Private subscription helper
 *
 * @param {*} subscription subscription that needs to be executed
 * @param {*} variables variables
 * @param {*} next next function that will be executed when data arrives
 * @param {*} error error function that will be executed on error
 */
export const privateSubscription = function* (
  subscription: any,
  variables: any,
  next: any,
  error: any
) {
  yield subscriptionRequest(
    privateClient,
    subscription,
    variables,
    next,
    error
  );
};
