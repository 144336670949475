import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearMatches } from "@twire/redux/tournament/actions";

import { MatchesContext, MatchTime } from "./context";
import Matches from "./Matches";

const MatchesContainer: React.FC = () => {
  const [time, setTime] = useState<MatchTime>("ongoing");
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearMatches());
    };
  }, []);
  return (
    <MatchesContext.Provider value={{ time, setTime }}>
      <Matches />
    </MatchesContext.Provider>
  );
};

export default MatchesContainer;
