import React from "react";
// import { ContextValue } from "./hooks";
import { TextAreaProps } from "./types";
import classes from "./TextArea.module.scss";
import { mergeClasses } from "../../../utility/helpers";

const TextArea: React.FC<TextAreaProps> = ({ instance, ...rest }) => (
  <textarea
    {...rest}
    value={instance.value}
    onChange={(e) => {
      instance.setValue(e.target.value);
    }}
    className={mergeClasses(
      classes.textArea,
      typeof rest.className === "string" ? rest.className : ""
    )}
  ></textarea>
);

export default TextArea;
