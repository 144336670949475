import React from "react";
import { TwireUrl, mergeClasses } from "@twire/utility/helpers";
import { TournamentsNavItem } from "./types";
import { NavLink } from "react-router-dom";
import classes from "./TournamentsNav.module.scss";

const TournamentsNav: React.FC<{
  navLinks: TournamentsNavItem[];
  blue?: boolean;
}> = ({ navLinks, blue }) => (
  <div className={mergeClasses(classes.container, [!!blue, classes.blue])}>
    {navLinks.map(({ title, url }) => (
      <React.Fragment key={title}>
        <NavLink
          to={TwireUrl.appendParams(url)}
          className={classes.navLink}
          activeClassName={classes.active}
        >
          {title}
        </NavLink>
      </React.Fragment>
    ))}
  </div>
);

export default TournamentsNav;
