import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { urlFriendlyString } from "@twire/utility/helpers";
import { useLanguageChange } from "@twire/utility/hooks";
import { RootReducer } from "@twire/redux/types";
import ArrowSpan from "@twire/common/ArrowSpan";
import Text from "@twire/common/Text";

import TeamsTile from "./TeamsTile";
import classes from "./Teams.module.scss";

const Teams: React.FC<{
  onTileClick: () => void;
  onViewMoreClicked: () => void;
}> = ({ onTileClick, onViewMoreClicked }) => {
  const lang = useLanguageChange();
  const {
    search: { teams, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const getTeamUrl = (id: number, name: string) => {
    return `/${lang}/${game}/team/${id}/${urlFriendlyString(name)}`;
  };
  return requestStates.success && teams && teams.length === 0 ? (
    <>
      <span className="text-twire-light rift-bold">
        <Text i18nKey="words.team"></Text> ({teams.length})
      </span>
      <div className="text-twire-red open-sans font-bold text-xs mt-1">
        No Teams found
      </div>
    </>
  ) : !teams ? (
    <></>
  ) : requestStates.success && teams && teams.length > 0 ? (
    <>
      <div className="flex">
        <span className="text-twire-light">
          <Text i18nKey="words.team"></Text> ({teams.length})
        </span>
        {teams.length > 2 && (
          <div className="ml-auto rift-bold text-sm">
            <ArrowSpan onClick={onViewMoreClicked}>
              <Text i18nKey="words.viewMore"></Text>
            </ArrowSpan>
          </div>
        )}
      </div>
      <div className={classes.list}>
        {teams.slice(0, 2).map((data, i) => (
          <Link
            to={getTeamUrl(data.id, data.name)}
            key={i}
            onClick={onTileClick}
          >
            <TeamsTile team={data} game={game} />
          </Link>
        ))}
      </div>
    </>
  ) : (
    <div>Error when getting Teams</div>
  );
};

export default Teams;
