import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { ArticlesInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as articlesHandlers from "./handlers/getArticles";

const INITIAL_STATE: ArticlesInterface = {
  articles: [],
  page: 1,
  game: null,
  isLastPage: false,
  requestStates: {
    articles: { ...defaultRequestState },
    paginated: { ...defaultRequestState },
  },
};

const articlesReducer: Reducer<ArticlesInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.ARTICLES_GET_ARTICLES_REQUESTED:
      return articlesHandlers.requested(state);
    case actionTypes.ARTICLES_GET_ARTICLES_FAILED:
      return articlesHandlers.failed(state, action, action.paginated);
    case actionTypes.ARTICLES_GET_ARTICLES_SUCCESS:
      return articlesHandlers.success(state, action, action.paginated);
    case actionTypes.ARTICLES_GET_ARTICLES_CLEAR:
      return articlesHandlers.clear(state, action.paginated);
    default:
      return state;
  }
};

export default articlesReducer;
