import { AnyAction } from "redux";
import { TournamentsInterface, TimeType } from "../types";
import { defaultTournamentFilters } from "../constants";

export const requested = (
  state: TournamentsInterface,
  key: TimeType,
  paginated?: boolean
): TournamentsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      data: { failed: false, requesting: true, success: false },
      paginated: paginated
        ? { failed: false, requesting: true, success: false }
        : state.requestStates[key].paginated,
    },
  },
});

export const success = (
  state: TournamentsInterface,
  action: AnyAction,
  key: TimeType,
  paginated?: boolean
): TournamentsInterface => ({
  ...state,
  tournaments: {
    ...state.tournaments,
    [key]: {
      ...state.tournaments[key],
      data: paginated
        ? [
            ...(action.payload.tournaments.page === 1 ? [] : state.tournaments[key].data || []),
            ...action.payload.tournaments.data,
          ]
        : action.payload.tournaments.data,
      page: paginated
        ? action.payload.tournaments.data.length === 0
          ? action.payload.tournaments.page - 1
          : action.payload.tournaments.page
        : 1,
      isLastPage:
        action.payload.tournaments.data.length === 0
          ? true
          : state.tournaments[key].isLastPage,
      game: action.payload.tournaments.game,
    },
  },
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      data: { failed: false, requesting: false, success: true },
      paginated: paginated
        ? { failed: false, requesting: false, success: true }
        : state.requestStates[key].paginated,
    },
  },
});

export const failed = (
  state: TournamentsInterface,
  action: AnyAction,
  key: TimeType,
  paginated?: boolean
): TournamentsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      data: { failed: action.error, requesting: false, success: false },
      paginated: paginated
        ? { failed: action.error, requesting: false, success: false }
        : state.requestStates[key].paginated,
    },
  },
});

export const clear = (
  state: TournamentsInterface,
  key: TimeType,
  paginated?: boolean
): TournamentsInterface => ({
  ...state,
  tournaments: {
    ...state.tournaments,
    [key]: {
      game: null,
      data: [],
      filter: { ...defaultTournamentFilters },
    },
  },
  requestStates: {
    ...state.requestStates,
    [key]: {
      ...state.requestStates[key],
      data: { failed: false, requesting: false, success: false },
      paginated: paginated
        ? { failed: false, requesting: false, success: false }
        : state.requestStates[key].paginated,
    },
  },
});
