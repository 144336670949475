import React from "react";
import Toggler from "@twire/common/Toggler";
import Text from "@twire/common/Text";
import { mergeClasses } from "@twire/utility/helpers";
import Cards from "./Cards";
import PerTournament from "./PerTournament";
import { StatisitcsTypes } from "./types";
import classes from "./Statistics.module.scss";

const Statistics: React.FC<StatisitcsTypes> = ({
  togglePerTournament,
  perTournament,
}) => (
  <div className={classes.container}>
    <div className="flex w-full">
      <div className="ml-auto flex items-center mb-4">
        {/* <span
          className={mergeClasses("text-xs text-twire-light opacity-30 mr-2", [
            !perTournament,
            "text-twire-orange opacity-100",
          ])}
        >
          overall
        </span> */}
        <Toggler
          onToggleOn={togglePerTournament}
          onToggleOff={togglePerTournament}
          on={perTournament}
        />
        <span
          className={mergeClasses("text-xs ml-2 text-twire-light opacity-30", [
            perTournament,
            "text-twire-orange opacity-100",
          ])}
        >
          <Text i18nKey="words.perTournament"></Text>
        </span>
      </div>
    </div>
    {!perTournament ? <Cards /> : <PerTournament />}
  </div>
);

export default Statistics;
