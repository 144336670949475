import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { RootReducer } from "@twire/redux/types";

// import { formatFeatured } from "./functions";
import * as actionTypes from "./actionTypes";

import { publicQuery } from "../../graphql/requestHelper";
import { getLastestMatches } from "../../graphql/public/queries";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchFeatured() {
  yield put({ type: actionTypes.MATHCES_GET_MATCHES_RQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const lastMatches: Generator<any, any> = yield publicQuery(
      getLastestMatches,
      {
        game: state.app.game,
      },
      CACHE_TIME.MINUTE * 5,
    );

    yield put({
      type: actionTypes.MATHCES_GET_MATCHES_SUCCESS,
      lastMatches: (lastMatches as any).data.getLastestMatches,
    });
    //
  } catch (error) {
    yield put({ type: actionTypes.MATHCES_GET_MATCHES_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([takeLatest(actionTypes.MATHCES_GET_MATCHES, fetchFeatured)]);
}
