import React from "react";
// import ArrowSpan from "@twire/common/ArrowSpan";
import MatchesTable from "./MatchesTable";
import { MatchesContext } from "./context";
import classes from "./Matches.module.scss";
import { mergeClasses } from "@twire/utility/helpers";
// import { mergeClasses } from "@twire/utility/helpers";

const Matches: React.FC = () => (
  <MatchesContext.Consumer>
    {() => (
      <div className={mergeClasses(classes.container, "mt-5")}>
        {/* <div className={classes.top}>
          <button
            onClick={() => setTime("ongoing")}
            className={mergeClasses(
              classes.button,
              time === "ongoing" ? classes.active : ""
            )}
          >
            ongoing tournaments
          </button>
          <button
            onClick={() => setTime("upcoming")}
            className={mergeClasses(
              classes.button,
              "ml-4",
              time === "upcoming" ? classes.active : ""
            )}
          >
            upcoming tournaments
          </button>
          <div className="ml-auto">
            <ArrowSpan>View more</ArrowSpan>
          </div>
        </div> */}
        <MatchesTable />
      </div>
    )}
  </MatchesContext.Consumer>
);

export default Matches;
