// sign in action types
export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_SIGN_IN_REQUESTED = "AUTH_SIGN_IN_REQUESTED";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILED = "AUTH_SIGN_IN_FAILED";
export const AUTH_SIGN_IN_CLEAR = "AUTH_SIGN_IN_CLEAR";

// sign out action types
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_OUT_REQUESTED = "AUTH_SIGN_OUT_REQUESTED";
export const AUTH_SIGN_OUT_SUCCESS = "AUTH_SIGN_OUT_SUCCESS";
export const AUTH_SIGN_OUT_FAILED = "AUTH_SIGN_OUT_FAILED";
export const AUTH_SIGN_OUT_CLEAR = "AUTH_SIGN_OUT_CLEAR";

// sign up action types
export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const AUTH_SIGN_UP_REQUESTED = "AUTH_SIGN_UP_REQUESTED";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILED = "AUTH_SIGN_UP_FAILED";
export const AUTH_SIGN_UP_CLEAR = "AUTH_SIGN_UP_CLEAR";

// reset password action types
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_RESET_PASSWORD_REQUESTED = "AUTH_RESET_PASSWORD_REQUESTED";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILED = "AUTH_RESET_PASSWORD_FAILED";
export const AUTH_RESET_PASSWORD_CLEAR = "AUTH_RESET_PASSWORD_CLEAR";

// forgot password action types
export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_FORGOT_PASSWORD_REQUESTED = "AUTH_FORGOT_PASSWORD_REQUESTED";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAILED = "AUTH_FORGOT_PASSWORD_FAILED";
export const AUTH_FORGOT_PASSWORD_CLEAR = "AUTH_FORGOT_PASSWORD_CLEAR";

// verify email action types
export const AUTH_VERIFY_EMAIL = "AUTH_VERIFY_EMAIL";
export const AUTH_VERIFY_EMAIL_REQUESTED = "AUTH_VERIFY_EMAIL_REQUESTED";
export const AUTH_VERIFY_EMAIL_SUCCESS = "AUTH_VERIFY_EMAIL_SUCCESS";
export const AUTH_VERIFY_EMAIL_FAILED = "AUTH_VERIFY_EMAIL_FAILED";
export const AUTH_VERIFY_EMAIL_CLEAR = "AUTH_VERIFY_EMAIL_CLEAR";

// resend verify email action types
export const AUTH_RESEND_VERIFY_EMAIL = "AUTH_RESEND_VERIFY_EMAIL";
export const AUTH_RESEND_VERIFY_EMAIL_REQUESTED =
  "AUTH_RESEND_VERIFY_EMAIL_REQUESTED";
export const AUTH_RESEND_VERIFY_EMAIL_SUCCESS =
  "AUTH_RESEND_VERIFY_EMAIL_SUCCESS";
export const AUTH_RESEND_VERIFY_EMAIL_FAILED =
  "AUTH_RESEND_VERIFY_EMAIL_FAILED";
export const AUTH_RESEND_VERIFY_EMAIL_CLEAR = "AUTH_RESEND_VERIFY_EMAIL_CLEAR";

// get sign in user action types
export const AUTH_GET_SIGN_IN_USER = "AUTH_GET_SIGN_IN_USER";
export const AUTH_GET_SIGN_IN_USER_REQUESTED =
  "AUTH_GET_SIGN_IN_USER_REQUESTED";
export const AUTH_GET_SIGN_IN_USER_SUCCESS = "AUTH_GET_SIGN_IN_USER_SUCCESS";
export const AUTH_GET_SIGN_IN_USER_FAILED = "AUTH_GET_SIGN_IN_USER_FAILED";
export const AUTH_GET_SIGN_IN_USER_CLEAR = "AUTH_GET_SIGN_IN_USER_CLEAR";

// get sign in user subscription
export const AUTH_GET_USER_SUBSCRIPTION = "AUTH_GET_USER_SUBSCRIPTION";
export const AUTH_GET_USER_SUBSCRIPTION_REQUESTED =
  "AUTH_GET_USER_SUBSCRIPTION_REQUESTED";
export const AUTH_GET_USER_SUBSCRIPTION_SUCCESS =
  "AUTH_GET_USER_SUBSCRIPTION_SUCCESS";
export const AUTH_GET_USER_SUBSCRIPTION_FAILED =
  "AUTH_GET_USER_SUBSCRIPTION_FAILED";
export const AUTH_GET_USER_SUBSCRIPTION_CLEAR =
  "AUTH_GET_USER_SUBSCRIPTION_CLEAR";
