import Toggler from "@twire/common/Toggler";
import LeaderboardTable from "./LeaderboardTable";
import LeaderboardStatsPerMatch from "./LeaderboardStatsPerMatch";
import Placeholder from "./LeaderboardTable/Placeholder";
// import classes from "./Leaderboard.module.scss";
import { leaderboardViewInterface } from "./types";

const Leaderboard: React.FC<leaderboardViewInterface> = ({
  showPerMatch,
  toggleShowPerMatch,
  loading,
  showToggler,
  fullscreen,
  toggleFullScreen,
}) => (
  <div className="pt-2.5">
    {showToggler && (
      <div className="text-twire-opaque-white text-xs flex items-center">
        <span className="ml-auto mr-2.5">leaderboard per match </span>
        <Toggler
          on={showPerMatch}
          onToggleOff={toggleShowPerMatch}
          onToggleOn={toggleShowPerMatch}
        />
      </div>
    )}
    <div className="mt-2.5">
      {loading ? (
        <Placeholder />
      ) : showPerMatch && showToggler ? (
        <LeaderboardStatsPerMatch
          fullscreen={fullscreen}
          toggleFullScreen={toggleFullScreen}
        />
      ) : (
        <LeaderboardTable />
      )}
    </div>
  </div>
);

export default Leaderboard;
