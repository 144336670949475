import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { formatDataForChart } from "./functions";
import PlayerStat from "./PlayerStat";

const ComponentContainer: React.FC<{ dataIndex?: number }> = ({
  dataIndex,
}) => {
  const {
    tournament: { platformStats, platformStatsLeaderboardTrie },
  } = useSelector((state: RootReducer) => state);
  const [playerIndex, setPlayerIndex] = useState(dataIndex || 0);
  const [searchQuery, setSearchQuery] = useState("");
  const [collection, setCollection] = useState(
    platformStats ? platformStats?.leaderboard.map((_, index) => index) : []
  );

  useEffect(() => {
    if (platformStatsLeaderboardTrie)
      setCollection(platformStatsLeaderboardTrie?.search(searchQuery));
  }, [searchQuery]);

  return platformStats && platformStats.leaderboard ? (
    <PlayerStat
      data={formatDataForChart(platformStats.leaderboard[playerIndex])}
      twrScore={platformStats.leaderboard[playerIndex].twr as number}
      playerName={platformStats.leaderboard[playerIndex].username}
      teamName={platformStats.leaderboard[playerIndex].teamName as string}
      onChange={setSearchQuery}
      collection={collection}
      platformLeaderboard={platformStats.leaderboard}
      onButtonClick={(i, callBack) => {
        setPlayerIndex(i);
        callBack();
      }}
      playerIndex={playerIndex}
    />
  ) : (
    <></>
  );
};

export default ComponentContainer;
