export const SET_GAME = "SET_GAME";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_LANGUAGE_REQUESTED = "CHANGE_LANGUAGE_REQUESTED";
export const CHANGE_LANGUAGE_SUCCESS = "CHANGE_LANGUAGE_SUCCESS";
export const CHANGE_LANGUAGE_FAILED = "CHANGE_LANGUAGE_FAILED";

export const DISPLAY_FULL_PAGE_LOADING_OVERLAY =
  "DISPLAY_FULL_PAGE_LOADING_OVERLAY";

export const HIDE_FULL_PAGE_LOADING_OVERLAY_STARTED =
  "HIDE_FULL_PAGE_LOADING_OVERLAY_STARTED";
export const HIDE_FULL_PAGE_LOADING_OVERLAY_COMPLETED =
  "HIDE_FULL_PAGE_LOADING_OVERLAY_COMPLETED";
export const HIDE_FULL_PAGE_LOADING_OVERLAY = "HIDE_FULL_PAGE_LOADING_OVERLAY";

export const SHOW_GLOBAL_TOOL_TIP_STARTED = "SHOW_GLOBAL_TOOL_TIP_STARTED";
export const SHOW_GLOBAL_TOOL_TIP_COMPLETED = "SHOW_GLOBAL_TOOL_TIP_COMPLETED";
export const SHOW_GLOBAL_TOOL_TIP = "SHOW_GLOBAL_TOOL_TIP";

export const SET_BANNER = "SET_BANNER";
export const CLEAR_BANNER = "CLEAR_BANNER";

export const HIDE_TOOLTIP = "HIDE_TOOLTIP";

export const SET_MODAL_ELEMENT = "SET_MODAL_ELEMENT";
export const HIDE_MODAL_ELEMENT = "HIDE_MODAL_ELEMENT";
