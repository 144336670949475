import { AnyAction } from "redux";
import { Trie } from "@twire/utility/classes";
import { TournamentInterface } from "../types";
import { PlatformStatsQuery } from "../../../PublicAPI";

declare let platformStatsQuery: PlatformStatsQuery;

const getTrie = (
  leaderboard: typeof platformStatsQuery.platformStats.leaderboard
) => {
  const trie = new Trie<number>();
  leaderboard.forEach((v, index) => {
    trie.insert(v.username.toLowerCase(), index);
  });
  return trie;
};

export const requested = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    platformStats: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  platformStats: action.platformStats,
  platformStatsLeaderboardTrie: getTrie(
    (action.platformStats as typeof platformStatsQuery.platformStats)
      .leaderboard
  ),
  requestStates: {
    ...state.requestStates,
    platformStats: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    platformStats: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  platformStats: null,
  requestStates: {
    ...state.requestStates,
    platformStats: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
