import { TournamentData } from "./types";
import { LOCAL_STORAGE_KEYS } from "@twire/utility/constants";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatTournamentData = (data: any): TournamentData => ({
  id: data.id,
  badge: data.imageName,
  tier: data.tier || "",
  prize: data.prizePool || -1,
  currency: "usd",
  location: data.region,
  name: data.friendlyName,
  shard: data.shard,
  fantasy: !!data.fantasy,
});

export const getFilters = (): any => {
  const userFilterPreference = localStorage.getItem(
    LOCAL_STORAGE_KEYS.globalTournamentFilters
  );
  if (userFilterPreference) {
    return JSON.parse(userFilterPreference);
  }
  const filter: any = { tier: "", region: "" };
  return filter;
};
