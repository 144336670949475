import { TileProps } from "./types";
import TeamTile from "./TeamTile";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../redux/types";

const ComponentContainer: React.FC<TileProps> = (props) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  return <TeamTile {...props} game={game} />;
};

export default ComponentContainer;
