import { saveUserAction } from "@twire/redux/track/actions";
import { twireSocialMediaOpen } from "@twire/redux/track/userActions/userActions";
import React from "react";
import { useDispatch } from "react-redux";
import Nav from "./Nav";

const NavContainer: React.FC<{ className?: string }> = (props) => {
  const dispatch = useDispatch();

  const onSocialMediaOpen = (linkType: string) => {
    dispatch(saveUserAction(twireSocialMediaOpen(
      linkType,
    )));
  } 

  return <Nav
    onSocialMediaOpen={onSocialMediaOpen}
    {...props}
  />;
};

export default NavContainer;
