import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { getTeam, clearTeam } from "@twire/redux/team/actions";
import { hideTooltip } from "@twire/redux/app/actions";
import { t, urlFriendlyString } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";
import { useLanguageChange } from "@twire/utility/hooks";
import ComponentError, {
  ComponentErrorInterface,
} from "@twire/common/ComponentError";
import { ReactComponent as TrophySvg } from "../../../../assets/svgs/trophy.svg";
import Team from "./Team";
import classes from "./Team.module.scss";
import { saveUserAction } from "@twire/redux/track/actions";
import { teamPageView } from "@twire/redux/track/userActions/userActions";

const TeamContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState<ComponentErrorInterface | null>(null);
  const params = useParams<{ id: string; team: string }>();
  const {
    app: { game, showTooltip: shouldShowTooltip },
    team: { requestStates, team },
  } = useSelector((state: RootReducer) => state);
  const buildUrl = (url: string) => {
    return `/${lang}/${game}${url}`;
  };
  const lang = useLanguageChange();

  useEffect(() => {
    const invalidId = !params.id || isNaN(Number(params.id));
    if (invalidId) {
      setError({
        message: t("sentences.teamLoadFailed"),
        suggestion: t("sentences.topTeamsPageExplanation"),
        svg: TrophySvg,
        actionText: t("words.home"),
        onActionClicked: () => {
          history.push(`/${lang}/${game}`);
        },
      });
      if (team) {
        dispatch(clearTeam());
      }
    } else {
      dispatch(getTeam(Number(params.id)));
    }
  }, [params.id]);

  useEffect(() => {
    const invalidId = !params.id || isNaN(Number(params.id));
    if (requestStates.team.failed) {
      setError({
        message: t("sentences.teamLoadFailed"),
        suggestion: t("sentences.topTeamsPageExplanation"),
        svg: TrophySvg,
        actionText: t("words.home"),
        onActionClicked: () => {
          history.push(`/${lang}/${game}`);
        },
      });
      if (team) {
        dispatch(clearTeam());
      }
    } else if (requestStates.team.success && !invalidId) {
      setError(null);
      if (team) {
        if (params.team !== urlFriendlyString(team.name)) {
          history.replace(
            URLS.TEAM.replace(":lang", lang)
              .replace(":game", game)
              .replace(":id", team.id.toString())
              .replace(":team", urlFriendlyString(team.name))
          );
        }
        dispatch(saveUserAction(teamPageView(team.name)));
      }
    }
  }, [requestStates.team]);

  useEffect(() => {
    if (shouldShowTooltip) {
      dispatch(hideTooltip());
    }
    return () => {
      dispatch(clearTeam());
    };
  }, []);

  return error ? (
    <ComponentError className={classes.error} {...error} />
  ) : (
    <Team buildUrl={buildUrl} />
  );
};

export default TeamContainer;
