import React, { useState } from "react";
import i18next from "i18next";
import { DateTime } from "luxon";
// import { Formik, Form } from "formik";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import TwireLink from "@twire/common/TwireLink";
import { CardElement } from "@stripe/react-stripe-js";
// import TwireInput from "@twire/common/TwireInput";
import TwireButton from "@twire/common/TwireButton";
import Text from "@twire/common/Text";
import classes from "./SubscriptionForm.module.scss";

// import { initialValues } from "./constants";
import { mergeClasses } from "@twire/utility/helpers";

const CARD_OPTIONS = {
  //   iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "rgba(255, 255, 255,",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const SubscriptionForm: React.FC<{
  onSubmit: () => void;
  unSubscribe: () => Promise<any>;
  subscribed: boolean;
  onCardChange: (e: StripeCardElementChangeEvent) => void;
  requesting: boolean;
  signedIn: boolean;
  startDate: string;
  endDate: string;
  errorPaying: boolean;
  subscriptionPlanId: string;
  subscriptionId: string;
}> = ({
  onSubmit,
  onCardChange,
  requesting,
  subscribed,
  unSubscribe,
  signedIn,
  endDate,
  startDate,
  errorPaying,
  subscriptionPlanId,
  subscriptionId,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={mergeClasses(classes.container, "appear")}>
      {/* <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className={mergeClasses("w-full", classes.form)}> */}
      <div className={mergeClasses("w-full", classes.form)}>
        {signedIn ? (
          <>
            {/* <div className={classes.row}>
            <div className="w-full">
              <label className={classes.label}>Name</label>
              <TwireInput
                type="text"
                label="Name"
                className={mergeClasses("input mb-4", classes.input)}
                placeholder="name here"
                name="name"
              />
            </div>
  
            <div className="w-full">
              <label className={classes.label}>Email</label>
              <TwireInput
                type="email"
                className="input"
                placeholder={"email"}
                name="password"
              />
            </div>
          </div> */}

            {/* <h2 className={classes.subHeader}>Subscription</h2> */}

            {!subscribed ? (
              <div className={classes.row}>
                <div className={classes.cardElementContainer}>
                  {startDate && endDate && new Date(endDate) > new Date() && (
                    <p className={classes.caption}>
                      Your current subscription will expire on{" "}
                      <b>
                        {DateTime.fromISO(endDate).toLocaleString({
                          weekday: "short",
                          month: "short",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </b>
                    </p>
                  )}
                  {errorPaying && (
                    <p className="error-message mb-4 text-twire-light text-xs">
                      <Text i18nKey="sentences.unknownError"></Text>
                    </p>
                  )}

                  {!subscriptionPlanId &&
                    ((endDate && DateTime.fromISO(endDate) < DateTime.now()) ||
                      !endDate) && (
                      <h1 className={classes.subHeader}>
                        Select a{` `}
                        <TwireLink
                          to="/plans"
                          className="rift-bold text-twire-orange inline-i"
                        >
                          plan
                        </TwireLink>{" "}
                        to support twire
                      </h1>
                    )}

                  {subscriptionPlanId &&
                    ((endDate && DateTime.fromISO(endDate) < DateTime.now()) ||
                      !endDate) && (
                      <>
                        <label className={classes.label}>
                          <Text i18nKey="sentences.enterCardDetails">
                            Enter your card details
                          </Text>{" "}
                        </label>
                        <CardElement
                          options={CARD_OPTIONS}
                          className={mergeClasses("input", classes.cardElement)}
                          onChange={onCardChange}
                        />
                      </>
                    )}
                </div>

                {subscriptionPlanId &&
                  ((endDate && DateTime.fromISO(endDate) < DateTime.now()) ||
                    !endDate) && (
                    <TwireButton
                      loading={requesting}
                      disabled={false}
                      className={classes.button}
                      onClick={onSubmit}
                    >
                      <Text i18nKey="words.subscribe">Subscribe</Text>
                    </TwireButton>
                  )}
              </div>
            ) : (
              <div>
                <h1 className={classes.subHeader}>
                  You are subscribed to the{" "}
                  <span className="rift-bold text-twire-orange">premium</span>{" "}
                  plan
                </h1>
                <p className={classes.caption}>
                  your subscription will be auto renewed on{" "}
                  <b>
                    {DateTime.fromISO(endDate, {
                      locale: i18next.language,
                    }).toLocaleString({
                      weekday: "short",
                      month: "short",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </b>
                </p>
                {subscriptionId.startsWith("I-") ? (
                  <p className={classes.caption}>
                    <Text i18nKey="sentences.manageSubscription">
                      You can manage subscription in your PayPal account.
                    </Text>
                  </p>
                ) : (
                  <TwireButton
                    loading={requesting || loading}
                    disabled={false}
                    className={classes.button}
                    onClick={async () => {
                      setLoading(true);
                      await unSubscribe();
                      setLoading(false);
                    }}
                  >
                    {/* <Text i18nKey="login.loginText">login</Text> */}
                    <Text i18nKey="words.unsubscribe">unsubscribe</Text>
                  </TwireButton>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="appear flex">
            <p className={classes.text}>
              You have to{" "}
              <TwireLink to="/login" className="mx-1 text-twire-orange">
                login
              </TwireLink>{" "}
              or{" "}
              <TwireLink to="/register" className="mx-1 text-twire-orange">
                register
              </TwireLink>{" "}
              to support twire.
            </p>
          </div>
        )}
      </div>
      {/* </Form>
      </Formik> */}
    </div>
  );
};

export default SubscriptionForm;
