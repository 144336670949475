import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getArticles } from "@twire/redux/articles/actions";
import { RootReducer } from "@twire/redux/types";
import { useLanguageChange } from "@twire/utility/hooks";

import Articles from "./Articles";
import Placeholder from "./Placeholder";
import { ArticlesContainerProps } from "./types";

const ArticlesContainer: React.FC<ArticlesContainerProps> = ({ config }) => {
  const dispatch = useDispatch();
  const lang = useLanguageChange();
  const {
    articles: { articles, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    dispatch(getArticles());
  }, [game]);

  return requestStates.articles.requesting && articles.length === 0 ? (
    <Placeholder />
  ) : (
    <Articles
      lang={lang}
      game={game}
      news={articles.slice(0, config.visibleCount || 8)}
      visibleCount={config.visibleCount}
    />
  );
};

export default ArticlesContainer;
