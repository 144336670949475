import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { url, urlFriendlyString } from "@twire/utility/helpers";
import { Path, LanguagePath } from "@twire/utility/constants";
import { useTranslation } from "react-i18next";
import { TOptions, StringMap } from "i18next";

/**
 * This hook is a wrapper around the "t" function in i18next.
 * It's job is to ensure that the translation changes evertime
 * the user changes their language. It accepts the same arguments
 * as "t".
 */

// REMEMBER Write more accurate type defs
export const useT = (
  key: Path<LanguagePath>,
  options?: TOptions<StringMap> | string
): string => {
  const { i18n, t } = useTranslation();
  const [value, setValue] = useState<string>(t(key, options));

  useEffect(() => {
    setValue(t(key, options));
  }, [i18n.language, t, key, options]);

  return value;
};

/**
 * This hook is useful when a certain value (such as a link to a page)
 * needs to be changed every time the language is changed.
 *
 * @param arg This is a function will be called everytime the language changes.
 * It is expected to return the value that needs to be updated whenever the language changes.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLangSensitive = <T>(arg: (arg?: any) => T): T => {
  const [value, setValue] = useState<T>(arg());
  const { i18n } = useTranslation();

  useEffect(() => {
    setValue(arg());
  }, [i18n.language, arg]);

  return value;
};

/**
 * This hook returns the current language. Adding this to your dependency array to
 * useEffect for example ensures the component rerenders when the language changes
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLanguageChange = (): string => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  return lang;
};

/**
 * This generates a url that is appended by the current set language
 * e.g /login will become /en/login if the language is set to english.
 * This url will also cause a re-render when the language is changed.
 *
 * @param urlPath This is the url without the language specified. e.g /login
 */
export const useUrl = (urlPath: string): string => {
  const value = useLangSensitive(() => url(urlPath));
  return value;
};

export const useFullUrl = (url: string): string => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  return useUrl(`${game}${url}`);
};

export const useScrollerTable = (
  dataLength: number
): {
  tableRef: React.RefObject<HTMLTableElement>;
  containerRef: React.RefObject<HTMLDivElement>;
} => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  useEffect(() => {
    const distContstant = 12;
    const containerHeight = containerRef.current?.clientHeight || 0;
    const totalTableHeight =
      (tableRef.current?.clientHeight || 0) + dataLength * 4;

    if (containerHeight - totalTableHeight > distContstant) {
      if (containerRef.current) {
        containerRef.current.style.height = `${
          totalTableHeight + distContstant
        }px`;
      }
    }
  }, []);

  return {
    tableRef,
    containerRef,
  };
};

export const useFullScreen = (
  initial?: boolean
): {
  showFullScreen: () => void;
  hideFullScreen: () => void;
  toggleFullScreen: () => void;
  fullscreen: boolean;
} => {
  const [fullscreen, setFullScreen] = useState(initial || false);
  const addKeyboardEvt = (e: KeyboardEvent) => {
    if (e.code === "Escape") {
      setFullScreen(false);
      window.removeEventListener("keydown", addKeyboardEvt);
    }
  };
  useEffect(() => {
    if (fullscreen) {
      window.addEventListener("keydown", addKeyboardEvt);
    }
  }, [fullscreen]);
  return {
    showFullScreen: () => setFullScreen(true),
    hideFullScreen: () => setFullScreen(false),
    toggleFullScreen: () => setFullScreen(!fullscreen),
    fullscreen,
  };
};

export const useGetTeamUrl = (): ((
  id: number | string,
  teamName: string
) => string) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}/team/:id/:name`);
  const getTeamUrl = (id: number | string, teamName: string) =>
    url
      .replace(":id", id.toString())
      .replace(":name", urlFriendlyString(teamName));
  return getTeamUrl;
};
