import React from "react";
import { mergeClasses } from "@twire/utility/helpers";
import classes from "./LineText.module.scss";

const LineText: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={mergeClasses(classes.container, className || "")}>
    <div />
    <div className="rift-bold text-sm text-twire-orange">{children}</div>
    <div />
  </div>
);

export default LineText;
