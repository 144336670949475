// import TournamentTile from "@twire/common/TournamentTile";
import { t } from "@twire/utility/helpers";
import Articles from "@twire/common/Articles";
import TopTeams from "@twire/common/TopTeams";
// import SettingsToggler from "@twire/common/SettingsToggler";
// import Filter from "@twire/common/Filter";
import ArrowSpan from "@twire/common/ArrowSpan";
import PremiumBanner from "@twire/common/PremiumBanner";
import MobileMainSection from "../MobileMainSection";

import FeaturedTournaments from "../FeaturedTournaments";
import TournamentsTemplate from "../TournamentsTemplate";
import classes from "./Home.module.scss";
import { Link } from "react-router-dom";
import { mergeClasses } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";
import FantasyBanner from "@twire/common/FantasyBanner";
import { AdsContext } from "../context";
import HomeStreamEmbed from "./HomeStreamEmbed";
import FantasyTournaments from "@twire/common/FantasyTournaments";
import PlayerRankingWidget from "@twire/common/PowerRankingWidget";
import { TournamentState } from "@twire/redux/tournaments/types";

const Home: React.FC<{
  lang: string;
  game: string,
  ongoingTournaments: TournamentState; 
}> = ({ lang, game, ongoingTournaments }) => (
  <>
    <div className="flex w-full mx-auto appear">
      <div className={classes.ml}>
        <FeaturedTournaments />
        <div className={mergeClasses("w-full flex mt-4", classes.mainLeft)}>
          <div className={classes.mainSubLeft}>
            {
              ongoingTournaments.data.length ?
              <div className="flex items-center">
                <div className="flex items-center">
                  <span className="text-xl bold mr-3 text-twire-light">
                    {t("words.ongoingTournaments")}
                  </span>
                </div>
                <Link to={`/${lang}/${game}/tournaments`} className="ml-auto">
                  <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
                </Link>
              </div>
              :
              null
            }
            {
              ongoingTournaments.data.length ?
              <TournamentsTemplate type="ongoing" />
              :
              null
            }
            <div className="flex items-center">
              <div className="flex items-center">
                <span className="text-xl bold mr-3 text-twire-light">
                  {t("words.pastTournaments")}
                </span>
              </div>
              <Link to={`/${lang}/${game}/tournaments/past`} className="ml-auto">
                <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
              </Link>
            </div>
            <TournamentsTemplate type="past" />
            <div className={classes.mobileMain}>
              <MobileMainSection />
            </div>
            <AdsContext.Consumer>
              {({ showAds }) => (
                showAds && <div className="iframe-center" data-onpage="true" data-adpath="/339474670,22606297850/Twire/InContent"></div>
              )}
            </AdsContext.Consumer>
          </div>
          <div className={classes.mainSubRight}>
            <div className={mergeClasses(classes.tabFantasyBanner)}>
              <PremiumBanner />
            </div>
            <div>
              <Articles config={{ visibleCount: 15 }} />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainRight}>
        <HomeStreamEmbed />
        <AdsContext.Consumer>
          {({ showAds }) => (
            showAds && <div
              className="clever-core-ads"
              style={{ width: '300px' }}
            ></div>
          )}
        </AdsContext.Consumer>
        <FantasyBanner />
        <FantasyTournaments />
        <div>
          <div className="flex items-center">
            <div className="flex items-center">
              <span className="text-xl bold mr-3 text-twire-light">
                {t("words.topTeams")}
              </span>
            </div>
            <Link
              to={`/${lang}/${game}${URLS.TEAM_RANKING}`}
              className="ml-auto"
            >
              <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
            </Link>
          </div>
          <TopTeams />
          <div className="mt-4">
            <div className="flex items-center mb-1">
              <div className="flex items-center">
                <span className="text-xl bold mr-3 text-twire-light">
                  Power ranking
                </span>
              </div>
              <Link
                to={`/${lang}/${game}${URLS.POWER_RANKING}`}
                className="ml-auto"
              >
                <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
              </Link>
            </div>
            <PlayerRankingWidget />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Home;
