import { PlayerStatData } from "./types";
import { TwireScoreChartItem } from "@twire/common/TwireScoreChart/types";
import { TWIRE_SCORE_SKILLS } from "@twire/utility/constants";

const calculatePoints = (points: number): number => {
  return Math.round(points * 100);
};

export const formatDataForChart = (
  data: PlayerStatData | null
): TwireScoreChartItem[] => {
  return data
    ? [
        {
          id: 0,
          skill: TWIRE_SCORE_SKILLS.attk,
          value: calculatePoints(data.attacker || 0),
        },
        {
          id: 1,
          skill: TWIRE_SCORE_SKILLS.fin,
          value: calculatePoints(data.finisher || 0),
        },
        {
          id: 2,
          skill: TWIRE_SCORE_SKILLS.util,
          value: calculatePoints(data.utility || 0),
        },
        {
          id: 3,
          skill: TWIRE_SCORE_SKILLS.sur,
          value: calculatePoints(data.survivor || 0),
        },
        {
          id: 4,
          skill: TWIRE_SCORE_SKILLS.team,
          value: calculatePoints(data.teammate || 0),
        },
        {
          id: 5,
          skill: TWIRE_SCORE_SKILLS.steal,
          value: calculatePoints(data.stealer || 0),
        },
      ]
    : [];
};
