export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLANS_REQUESTED =
  "GET_SUBSCRIPTION_PLANS_REQUESTED";
export const GET_SUBSCRIPTION_PLANS_SUCCESS = "GET_SUBSCRIPTION_PLANS_SUCCESS";
export const GET_SUBSCRIPTION_PLANS_FAILED = "GET_SUBSCRIPTION_PLANS_FAILED";
export const GET_SUBSCRIPTION_PLANS_CLEAR = "GET_SUBSCRIPTION_PLANS_CLEAR";

export const SUBSCRIBE_USER_TO_PLAN = "SUBSCRIBE_USER_TO_PLAN";
export const SUBSCRIBE_USER_TO_PLAN_REQUESTED =
  "SUBSCRIBE_USER_TO_PLAN_REQUESTED";
export const SUBSCRIBE_USER_TO_PLAN_SUCCESS = "SUBSCRIBE_USER_TO_PLAN_SUCCESS";
export const SUBSCRIBE_USER_TO_PLAN_FAILED = "SUBSCRIBE_USER_TO_PLAN_FAILED";
export const SUBSCRIBE_USER_TO_PLAN_CLEAR = "SUBSCRIBE_USER_TO_PLAN_CLEAR";

export const UNSUBSCRIBE_USER_FROM_PLAN = "UNSUBSCRIBE_USER_FROM_PLAN";
export const UNSUBSCRIBE_USER_FROM_PLAN_REQUESTED =
  "UNSUBSCRIBE_USER_FROM_PLAN_REQUESTED";
export const UNSUBSCRIBE_USER_FROM_PLAN_SUCCESS =
  "UNSUBSCRIBE_USER_FROM_PLAN_SUCCESS";
export const UNSUBSCRIBE_USER_FROM_PLAN_FAILED =
  "UNSUBSCRIBE_USER_FROM_PLAN_FAILED";
export const UNSUBSCRIBE_USER_FROM_PLAN_CLEAR =
  "UNSUBSCRIBE_USER_FROM_PLAN_CLEAR";
