import Text from "@twire/common/Text";
import { mergeClasses } from "@twire/utility/helpers";
import NavigationLinks from "./NavigationLinks";

import { ReactComponent as FacebookSvg } from "../../../../assets/svgs/facebook.svg";
import { ReactComponent as Twitter } from "../../../../assets/svgs/twitter.svg";
import { ReactComponent as Discord } from "../../../../assets/svgs/discord.svg";
import { ReactComponent as Instagram } from "../../../../assets/svgs/instagram.svg";
import { ReactComponent as Linkedin } from "../../../../assets/svgs/linkedin.svg";

import classes from "./Nav.module.scss";

const Nav: React.FC<{
    onSocialMediaOpen: (linkType: string) => void,
    className?: string,
  }> = ({ className, onSocialMediaOpen }) => (
  <div className={mergeClasses(classes.navWrapper, className || "")}>
    <div className={mergeClasses(classes.nav, "background-twire-grey w-full")}>
      <NavigationLinks />
      <div
        className={mergeClasses(
          "mt-4 flex align-items-center flex-col",
          classes.joinSection
        )}
      >
        <div className={classes.joinUs}>
          <Text i18nKey="words.joinUs">join us</Text>
          <div />
        </div>
        <div className={classes.socials}>
          <a
            href="https://www.facebook.com/twire.gg/"
            target="_blank"
            rel="noreferrer"
            onClick={() => onSocialMediaOpen("facebook")}
          >
            <FacebookSvg />
          </a>
          <a
            href="https://twitter.com/TwireGG_PUBG"
            target="_blank"
            rel="noreferrer"
            onClick={() => onSocialMediaOpen("twitter")}
          >
            <Twitter />
          </a>
          <a 
            href="https://discord.gg/Ed53vyn" 
            target="_blank"
            rel="noreferrer"
            onClick={() => onSocialMediaOpen("discord")}
          >
            <Discord />
          </a>
          <a
            href="https://www.instagram.com/twire_gg/"
            target="_blank"
            rel="noreferrer"
            onClick={() => onSocialMediaOpen("instagram")}
          >
            <Instagram />
          </a>
          <a
            href="https://www.linkedin.com/company/twiregg/"
            target="_blank"
            rel="noreferrer"
            onClick={() => onSocialMediaOpen("linkedin")}
          >
            <Linkedin />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Nav;
