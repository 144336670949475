import { FC, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Path, LanguagePath } from "@twire/utility/constants";

const Text: FC<{ i18nKey: Path<LanguagePath>; [key: string]: any }> = ({
  i18nKey,
  children,
  ...rest
}) => {
  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [i18n.language]);
  return (
    <Trans i18nKey={i18nKey} {...rest}>
      {children}
    </Trans>
  );
};

export default Text;
