import { Reducer } from "redux";

import * as actionTypes from "./actionTypes";
import * as searchHandlers from "./handlers/search";
import { SearchInterface } from "./types";

import { defaultRequestState } from "../constants";

const INITIAL_STATE: SearchInterface = {
  tournaments: null,
  teams: null,
  requestStates: { ...defaultRequestState },
};

const searchReducer: Reducer<SearchInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.SEARCH_GET_SEARCH_REQUESTED:
      return searchHandlers.requested(state);
    case actionTypes.SEARCH_GET_SEARCH_RESULTS_FAILED:
      return searchHandlers.failed(state, action);
    case actionTypes.SEARCH_GET_SEARCH_RESULTS_SUCCESS:
      return searchHandlers.success(state, action);
    case actionTypes.SEARCH_GET_SEARCH_RESULTS_CLEAR:
      return searchHandlers.clear(state);
    default:
      return state;
  }
};

export default searchReducer;
