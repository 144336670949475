import { AnyAction } from "redux";
import * as actionTypes from "./actionTypes";

export const getSearchResults = (query: string): AnyAction => ({
  type: actionTypes.SEARCH_GET_SEARCH_RESULTS,
  query,
});

export const clearSearchResults = (): AnyAction => ({
  type: actionTypes.SEARCH_GET_SEARCH_RESULTS_CLEAR,
});
