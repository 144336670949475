/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fileByParentFileId = /* GraphQL */ `
  query FileByParentFileId($parentFileId: Int) {
    fileByParentFileId(parentFileId: $parentFileId) {
      id
      actionType
      fileType
      filename
      createdAt
      parentFileId
      matchId
    }
  }
`;
export const highlightByFileId = /* GraphQL */ `
  query HighlightByFileId($fileId: Int!) {
    highlightByFileId(fileId: $fileId) {
      fileId
      isPublic
      filename
      highlights {
        time
        comment
        drawings
        sub
        username
      }
    }
  }
`;
export const settings = /* GraphQL */ `
  query Settings {
    settings {
      pubgUsernames {
        id
        pubgUsername
        pubgUsernameDone
        pubgUsernameError
      }
      emailNotifications
    }
  }
`;
export const userSubscription = /* GraphQL */ `
  query UserSubscription {
    userSubscription {
      subscriptionId
      status
      trial
      validFrom
      validTo
      planId
    }
  }
`;
export const tournamentOrganizerToken = /* GraphQL */ `
  query TournamentOrganizerToken {
    tournamentOrganizerToken
  }
`;
export const liveLeaderboardToken = /* GraphQL */ `
  query LiveLeaderboardToken($shardInfo: String!) {
    liveLeaderboardToken(shardInfo: $shardInfo)
  }
`;
export const getFantasyTeam = /* GraphQL */ `
  query GetFantasyTeam(
    $game: String!
    $tournamentId: Int!
    $round: String
    $group: String
  ) {
    getFantasyTeam(
      game: $game
      tournamentId: $tournamentId
      round: $round
      group: $group
    ) {
      team {
        players {
          id
          playerName
          teamName
          price
          substitute
          totalPoints
          kills
          killPoints
          damage
          damagePoints
          survives
          survivePoints
          diedQuickly
          diedQuicklyPoints
          captainPoints
          captain
          active
        }
        totalPrice
        totalPoints
        placement
      }
      incompletedPicks {
        parentName
        parentValue
        childName
        childValue
      }
      incompletedCaptains {
        parentName
        parentValue
        childName
        childValue
      }
    }
  }
`;
export const getFantasyPrivateLeaderboards = /* GraphQL */ `
  query GetFantasyPrivateLeaderboards($game: String!, $tournamentId: Int!) {
    getFantasyPrivateLeaderboards(game: $game, tournamentId: $tournamentId) {
      id
      name
      fantasyUsers {
        userId
        placement
        nickname
        prize
        points
      }
    }
  }
`;
export const getFantasyPrivateLeaderboard = /* GraphQL */ `
  query GetFantasyPrivateLeaderboard(
    $game: String!
    $privateLeaderboardId: String!
    $round: String
    $group: String
  ) {
    getFantasyPrivateLeaderboard(
      game: $game
      privateLeaderboardId: $privateLeaderboardId
      round: $round
      group: $group
    ) {
      id
      name
      fantasyUsers {
        userId
        placement
        nickname
        prize
        points
      }
    }
  }
`;
