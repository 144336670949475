import React from "react";
import classes from "./RankingTile.module.scss";
import {
  mergeClasses,
  setTeamLogo,
  setDefaultTeamLogo,
  roundToX,
} from "@twire/utility/helpers";
import { RankingTileViewProps } from "./types";
import Progress from "../Progress";

const RankingTile: React.FC<RankingTileViewProps> = ({
  theme,
  playerData,
  game,
}) => (
  <div
    className={mergeClasses(
      classes.container,
      theme ? `rank-tile-${theme?.toLowerCase()}` : ""
    )}
  >
    <div className={mergeClasses(classes.first)}>
      <div
        className={mergeClasses(
          classes.pos,
          theme ? `text-twire-${theme?.toLowerCase()}` : "text-twire-light"
        )}
      >
        <span className={mergeClasses([!theme, "opacity-30"])}>
          #{playerData.rank + 1}
        </span>
      </div>
      <img
        src={setTeamLogo(game, playerData.player.team)}
        alt="team"
        className={classes.teamLogo}
        onError={setDefaultTeamLogo}
      />
      <div className={classes.team}>
        {playerData.player.nickname}
        {/* “<span className="text-twire-orange">IBIZA</span>” */}
      </div>

      {/* <div className=""> */}
      <div
        className={mergeClasses(
          "ml-auto mb-2",
          classes.progressWrapper,
          classes.translateWrapper
        )}
      >
        <Progress
          progress={Math.round(playerData.player.overallScore)}
          radius={30}
          stroke={3}
          textClassName={classes.ratingText}
        />
      </div>

      <div className={mergeClasses("flex ml-auto", classes.statsWrapper)}>
        <span className="text-center">
          {roundToX(playerData.player.attackerScore, 1)}
        </span>
        <span className="text-center">
          {roundToX(playerData.player.utilityScore, 1)}
        </span>
        <span className="text-center">
          {roundToX(playerData.player.survivorScore, 1)}
        </span>
        <span className="text-center">
          {roundToX(playerData.player.teammateScore, 1)}
        </span>
        <span className="text-center">
          {roundToX(playerData.player.finisherScore, 1)}
        </span>
      </div>
      {/* </div> */}
    </div>
  </div>
);

export default RankingTile;
