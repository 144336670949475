import { GeneralTournamentFormat } from "@twire/utility/constants";

export const getColor = (position: number): string => {
  switch (position) {
    case 1:
      return "orange";
    case 2:
      return "blue";
    case 3:
      return "red";
    default:
      return "";
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getVal = (ruleset: string, stat: any): number | null =>
  ruleset == GeneralTournamentFormat.MOST_CHICKEN ? stat.rank : stat.points;
