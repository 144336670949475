import React from "react";
import {
  mergeClasses,
  setTeamLogo,
  setDefaultTeamLogo,
} from "@twire/utility/helpers";
import { getTheme } from "@twire/utility/helpers";
import { Games } from "@twire/utility/constants";
import { RankedPlayer } from "@twire/redux/playerRanking/types";
import classes from "./PlayerRankingWidget.module.scss";

const PlayerRankingWidget: React.FC<{
  players: RankedPlayer[];
  game: Games;
}> = ({ players, game }) => (
  <>
    {players.map((player, index) => (
      <React.Fragment key={index}>
        {/* <TeamTile
          pos={index + 1}
          theme={getTheme(index)}
          game={game}
          {...team}
        /> */}
        <div
          className={mergeClasses(
            classes.container,
            "mb-1",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getTheme(index) ? `rank-tile-${getTheme(index)!.toLowerCase()}` : ""
          )}
        >
          <span className={classes.index}>#{index + 1}</span>
          <img
            src={setTeamLogo(game, player.team)}
            alt={`${player.nickname}'s image`}
            className={classes.teamImage}
            onError={setDefaultTeamLogo}
          />
          <p className="text-twire-light truncate">{player.nickname}</p>
          {/* {previousPos && !(previousPos === "NEW" || previousPos === "SAME") && (
            <div
              className={mergeClasses(
                "ml-auto flex items-center",
                classes.rankChange,
                [!!previousPos, classes[previousPos.toLowerCase()]]
              )}
            >
              <ArrowTip />
              {prevPosChange}
            </div>
          )} */}
        </div>
      </React.Fragment>
    ))}
  </>
);

export default PlayerRankingWidget;
