import Progress from "@twire/common/Progress";

const PlayerRating: React.FC<{ value: string }> = ({ value }) => {
  return value ? (
    <Progress progress={Number(Number(value).toFixed(0))} />
  ) : (
    <div>N/A</div>
  );
};

export default PlayerRating;
