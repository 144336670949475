import { AnyAction } from "redux";
import { TeamInterface } from "../types";

export const requested = (state: TeamInterface): TeamInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    team: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TeamInterface,
  action: AnyAction
): TeamInterface => ({
  ...state,
  team: action.team,
  tournaments: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  matches: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  requestStates: {
    ...state.requestStates,
    team: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: TeamInterface,
  action: AnyAction
): TeamInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    team: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TeamInterface): TeamInterface => ({
  ...state,
  team: null,
  tournaments: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  matches: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  statistics: [],
  requestStates: {
    ...state.requestStates,
    team: {
      failed: false,
      requesting: false,
      success: false,
    },
    tournaments: {
      failed: false,
      requesting: false,
      success: false,
    },
    matches: {
      failed: false,
      requesting: false,
      success: false,
    },
    statistics: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
