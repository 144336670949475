import { useSelector, useDispatch } from "react-redux";
import { useLanguageChange } from "@twire/utility/hooks";
import { useHistory } from "react-router-dom";
import { RootReducer } from "@twire/redux/types";
import { setGame } from "@twire/redux/app/actions";
import { supportedGames } from "@twire/utility/constants";

import Dropdown from "../../DropDown";
import DropdownTrigger from "../../DropDown/DropdownTrigger";
import DropdownItem from "../../DropDown/DropdownItem";

import classes from "./GameSelect.module.scss";

const GameSelect: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useLanguageChange();
  const {
    app: { game: selectedGame },
  } = useSelector((state: RootReducer) => state);
  const selectedGameData = supportedGames.find(
    (item) => selectedGame === item.id
  );
  const dropDownItemClicked = (id: string) => {
    dispatch(setGame(id));
    history.push(`/${lang}/${id}`);
  };
  return (
    <Dropdown
      className={classes.dropDown}
      dropdownTrigger={(props: any) => (
        <DropdownTrigger className={classes.gameSelectTrigger} {...props}>
          {selectedGameData && (
            <selectedGameData.gameSvg className={classes.triggerSvg} />
          )}
          <span className="ml-2 text-twire-light open-sans uppercase text-twire-orange">
            {selectedGameData && selectedGameData.name}
          </span>
        </DropdownTrigger>
      )}
    >
      {supportedGames.map(({ name, id, gameSvg: GameSvg }) => (
        <div key={id} className="w-full">
          <DropdownItem
            active={selectedGame === id}
            onClick={() => dropDownItemClicked(id)}
            className={classes.dropDownItem}
            acitveClassName={classes.acitveClassName}
          >
            <GameSvg />
            <span className="ml-2 open-sans-i uppercase">{name}</span>
          </DropdownItem>
        </div>
      ))}
    </Dropdown>
  );
};

export default GameSelect;
