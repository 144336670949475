import { AnyAction } from "redux";
import { SearchInterface } from "../types";

export const requested = (state: SearchInterface): SearchInterface => ({
  ...state,
  requestStates: { failed: false, requesting: true, success: false },
});

export const success = (
  state: SearchInterface,
  action: AnyAction
): SearchInterface => ({
  ...state,
  tournaments: action.tournaments,
  teams: action.teams,
  requestStates: { failed: false, requesting: false, success: true },
});

export const failed = (
  state: SearchInterface,
  action: AnyAction
): SearchInterface => ({
  ...state,
  requestStates: {
    failed: action.error,
    requesting: false,
    success: false,
  },
});

export const clear = (state: SearchInterface): SearchInterface => ({
  ...state,
  tournaments: null,
  teams: null,
  requestStates: {
    failed: false,
    requesting: false,
    success: false,
  },
});
