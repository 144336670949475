import React from "react";
import TwireLink from "@twire/common/TwireLink";
import TwireButton from "@twire/common/TwireButton";
import Search from "@twire/common/Search";
import { NavLinksType } from "../Nav/NavigationLinks/hooks";
import MenuItem from "@twire/common/MenuItem";
import Text from "@twire/common/Text";
// import { Link } from "react-router-dom";
import { mergeClasses } from "@twire/utility/helpers";
// import { useUrl } from "@twire/utility/hooks";

import GameSelect from "../GameSelect";
import { ReactComponent as Profile } from "../../../../assets/svgs/settings.svg";
// import LanguageSelect from "../LanguageSelect";

import classes from "./HamburgerDropdown.module.scss";

const HamburgerDropdown: React.FC<{
  navLinks: NavLinksType;
  handleSignOut: () => void;
  signedIn: boolean;
  signinigOut: boolean;
  username: string;
  toggleDropdown: () => void;
}> = ({ navLinks, signedIn, signinigOut, handleSignOut, username, toggleDropdown }) => (
  <>
    <div className={classes.container}>
      <div className="mb-4 pt-4 flex items-center">
        <Search />
      </div>
      <hr className={classes.searchBoarder} />
      <div className="flex w-full my-4">
        {!signedIn ? (
          <>
            <TwireLink className={classes.buttonLink} to="/login">
              <button
                className={mergeClasses(
                  classes.button,
                  "background-twire-light-ash",
                  "border-twire-dark-ash-i",
                  "text-twire-orange"
                )}
              >
                <Text i18nKey="words.signIn">sign in</Text>
              </button>
            </TwireLink>
            <TwireLink
              className={mergeClasses("ml-auto block", classes.buttonLink)}
              to="/register"
            >
              <button
                className={mergeClasses(
                  classes.button,
                  "background-twire-dark-orange",
                  "border-twire-orange",
                  "text-twire-dark"
                )}
              >
                <Text i18nKey="words.signUp">sign up</Text>
              </button>
            </TwireLink>
          </>
        ) : (
          <TwireButton alternate onClick={handleSignOut} loading={signinigOut}>
            Sign Out
          </TwireButton>
        )}
      </div>
      <hr className={classes.searchBoarder} />
    </div>
    <div className={mergeClasses("flex w-full", classes.selection)}>
      <GameSelect />
      {signedIn && (
        <TwireLink to="/settings" className={classes.userProfile}>
          <span className="text-twire-light font-bold whitespace-nowrap">
            {username}
          </span>
          <button className={classes.gear}>
            <Profile />
          </button>
        </TwireLink>
      )}
      {/* <div className="ml-auto">
        <LanguageSelect />
      </div> */}
    </div>
    <div className={mergeClasses("flex", classes.mobileNav)}>
      {navLinks.map(
        ({ title, url, svg: Svg, external, exact, isActive, blue }) => (
          <div
            key={title}
            onClick={toggleDropdown}
          >
            <MenuItem
              to={url}
              dir="TOP"
              external={external}
              exact={!!exact}
              isActive={isActive}
              blue={blue}
            >
              <div className={classes.navItem}>
                <div className={classes.svgWrapper}>
                  <Svg />
                </div>
                <p>{title}</p>
              </div>
            </MenuItem>
          </div>
        )
      )}
    </div>
  </>
);

export default HamburgerDropdown;
