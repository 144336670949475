import { Games } from "@twire/utility/constants";
import React from "react";
// import { languageIcon } from "@twire/utility/constants";
import {
  mergeClasses,
  setTeamLogo,
  setDefaultTeamLogo,
} from "../../../../../utility/helpers";
import classes from "./TeamInfo.module.scss";

// const language = "en";

const TeamInfo: React.FC<{ name: string; logo: string }> = ({ name }) => (
  <div className={classes.container}>
    <div
      className={mergeClasses(
        "text-twire-light rift-bold text-xl",
        classes.header
      )}
    >
      team info
    </div>
    <div className="background-twire-grey p-7 flex items-center appear">
      <img
        src={setTeamLogo(Games.PUBG, name)}
        alt="team logo"
        onError={setDefaultTeamLogo}
        className={classes.teamLogo}
      />
      <h1 className={mergeClasses(classes.teamName, "rift-bold")}>{name}</h1>
      {/* <div className="ml-auto flex">
        <div className={mergeClasses(classes.infoSection, "mr-8")}>
          <div>region</div>
          <div>eu</div>
        </div>
        <div className={mergeClasses(classes.infoSection)}>
          <div>country</div>
          <div className="flex items-center">
            england
            <span
              className={`flag-icon flag-icon-${languageIcon[language]} ${classes.flagIcon}`}
            ></span>
          </div>
        </div>
      </div> */}
    </div>
  </div>
);

export default TeamInfo;
