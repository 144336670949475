import React from "react";
import classes from "./SettingsToggler.module.scss";

const SettingsToggler: React.FC = (props) => (
  <button className={classes.container} {...props}>
    <div />
    <div />
    <div />
  </button>
);

export default SettingsToggler;
