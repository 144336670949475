import { useEffect, useState, useRef } from "react";
import { t } from "@twire/utility/helpers";
import { useLanguageChange } from "@twire/utility/hooks";
import { useSelector, useDispatch } from "react-redux";
import {
  getOngoing,
  getPast,
  getUpcoming,
  getPaginated,
  filterTournaments,
} from "@twire/redux/tournaments/actions";
import {
  getPaginated as getScrimsPaginated,
  getScrims,
  filterScrims,
} from "@twire/redux/scrims/actions";
import { TimeType } from "@twire/redux/tournaments/types";
import { RootReducer } from "@twire/redux/types";
import Tournaments from "@twire/common/Tournaments";
import { ComponentErrorInterface } from "@twire/common/ComponentError";
import { ReactComponent as TrophySvg } from "../../../../assets/svgs/trophy.svg";

const timeTypeActionMap: { [key: string]: () => void } = {
  ongoing: getOngoing,
  past: getPast,
  upcoming: getUpcoming,
};

const OngoingTournamentsContainer: React.FC<{
  type: TimeType;
  infinite?: boolean;
  scrims?: boolean;
}> = ({ type, infinite, scrims }) => {
  const isUnmounting = useRef(false);
  const [error, setError] = useState<ComponentErrorInterface | null>(null);
  const dispatch = useDispatch();
  const lang = useLanguageChange();
  const {
    tournaments: { tournaments, requestStates },
    scrims: {
      scrims: {
        data,
        filter: scrimsFilters,
        page,
        isLastPage: isScrimsLastPage,
        game: scrimsGame,
      },
      requestStates: scrimsRequestStates,
    },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    // dispatch(timeTypeActionMap[type]());
    if (!scrims) {
      if (tournaments[type].game && tournaments[type].game !== game) {
        dispatch(timeTypeActionMap[type]());
      } else if (tournaments[type].data.length === 0) {
        dispatch(getPaginated(type, tournaments[type].page));
      }
    } else {
      if (scrimsGame && scrimsGame !== game) {
        dispatch(getScrims());
      } else if (data.length === 0) {
        dispatch(getScrimsPaginated(page));
      }
    }
  }, [game]);

  useEffect(() => {
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (scrims) {
        if (
          isUnmounting.current &&
          (scrimsFilters.region || scrimsFilters.tier)
        ) {
          dispatch(
            filterScrims({
              region: "",
              tier: "",
            })
          );
        }
      } else {
        if (
          isUnmounting.current &&
          (tournaments[type].filter.region || tournaments[type].filter.tier)
        ) {
          dispatch(
            filterTournaments(
              {
                region: "",
                tier: "",
              },
              type
            )
          );
        }
      }
    };
  }, [scrimsFilters, tournaments[type].filter]);

  const isLoading = () => {
    return !scrims
      ? (tournaments[type].data.length === 0 &&
          requestStates[type].data.requesting) ||
          requestStates[type].filter.requesting
      : (data.length === 0 && scrimsRequestStates.data.requesting) ||
          scrimsRequestStates.filter.requesting;
  };

  useEffect(() => {
    if (!scrims) {
      if (
        requestStates[type].data.failed &&
        tournaments[type].data.length === 0
      ) {
        setError({
          message: t("sentences.getTournamentsError"),
          suggestion: t("sentences.refreshThePage"),
          svg: TrophySvg,
          actionText: t("words.tryAgain"),
          onActionClicked: () =>
            dispatch(getPaginated(type, tournaments[type].page)),
        });
      } else if (
        ((requestStates[type].data.success &&
          tournaments[type].data.length === 0) ||
          (requestStates[type].filter.success &&
            tournaments[type].data.length === 0)) &&
        tournaments[type].filter.region === "" &&
        tournaments[type].filter.tier === ""
      ) {
        setError({
          message: t("sentences.noTournamentsError"),
          suggestion: t("sentences.noTournamentsSuggestion"),
          svg: TrophySvg,
        });
      } else if (
        requestStates[type].filter.success &&
        tournaments[type].data.length === 0
      ) {
        setError({
          message: t("sentences.noFilterResultError"),
          suggestion: t("sentences.noFilterResultErrorSuggestion"),
          svg: TrophySvg,
        });
      } else {
        setError(null);
      }
    } else {
      if (scrimsRequestStates.data.failed && data.length === 0) {
        setError({
          message: t("sentences.getTournamentsError"),
          suggestion: t("sentences.refreshThePage"),
          svg: TrophySvg,
          actionText: t("words.tryAgain"),
          onActionClicked: () =>
            dispatch(getPaginated(type, tournaments[type].page)),
        });
      } else if (
        ((scrimsRequestStates.data.success && data.length === 0) ||
          (scrimsRequestStates.filter.success && data.length === 0)) &&
        scrimsFilters.region === "" &&
        scrimsFilters.tier === ""
      ) {
        setError({
          message: t("sentences.noTournamentsError"),
          suggestion: t("sentences.noTournamentsSuggestion"),
          svg: TrophySvg,
        });
      } else if (scrimsRequestStates.filter.success && data.length === 0) {
        setError({
          message: t("sentences.noFilterResultError"),
          suggestion: t("sentences.noFilterResultErrorSuggestion"),
          svg: TrophySvg,
        });
      } else {
        setError(null);
      }
    }
  }, [
    requestStates[type].data,
    requestStates[type].filter,
    tournaments[type].data,
    data,
    scrimsRequestStates,
    lang,
  ]);

  const onScroll = () => {
    if (!scrims) {
      dispatch(getPaginated(type, tournaments[type].page + 1));
    } else {
      dispatch(getScrimsPaginated(page + 1));
    }
  };

  return (
    <Tournaments
      config={{}}
      loading={isLoading()}
      tournaments={scrims ? data : tournaments[type].data}
      error={error}
      onScroll={onScroll}
      infinite={
        !!infinite &&
        (!scrims ? !tournaments[type].isLastPage : !isScrimsLastPage)
      }
    />
  );
};

export default OngoingTournamentsContainer;
