import { useEffect } from "react";
import ComponentError from "@twire/common/ComponentError";
import { useDispatch, useSelector } from "react-redux";
import { getMatches } from "@twire/redux/tournament/actions";
import { RootReducer } from "@twire/redux/types";
import { useLanguageChange } from "@twire/utility/hooks";
import { showTooltip, hideTooltip } from "@twire/redux/app/actions";

import { mapToMatch } from "./functions";
import MatchesTable from "./MatchesTable";
import Placeholder from "./Placeholder";

import { ReactComponent as Trophy } from "../../../../../../../assets/svgs/trophy.svg";
import { saveUserAction } from "@twire/redux/track/actions";
import {
  tournamentMatchesView,
  tournamentMatchReplayView,
} from "@twire/redux/track/userActions/userActions";
import { useTournament } from "../../hooks";
import { t, getElementYOffset } from "../../../../../../../utility/helpers";

const MatchesTableContainer: React.FC = () => {
  const dispatch = useDispatch();
  const lang = useLanguageChange();
  const { selectedRound, selectedGroup } = useTournament();

  const {
    tournament: {
      tournament,
      requestStates,
      filter,
      matchByTournament,
      matchesPage,
      isLastMatchesPage,
    },
    app: { game, showTooltip: shouldShowTooltip },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (requestStates.tournament.success) {
      dispatch(getMatches(matchesPage));
    }
  }, [requestStates.tournament, filter]);

  useEffect(() => {
    dispatch(
      saveUserAction(
        tournamentMatchesView(
          tournament?.friendlyName,
          tournament?.eventType,
          `${selectedRound?.name} ${selectedGroup?.name}`,
          tournament?.region,
          // TODO: add real type (past, future)
          "past"
        )
      )
    );
  }, [tournament, selectedRound, selectedGroup]);

  const onMatchReplayClick = () => {
    dispatch(
      saveUserAction(
        tournamentMatchReplayView(
          tournament?.friendlyName,
          tournament?.eventType,
          `${selectedRound?.name} ${selectedGroup?.name}`,
          tournament?.region
        )
      )
    );
  };

  const onScroll = () => {
    dispatch(getMatches(matchesPage + 1));
  };

  const requesting =
    requestStates.matchByTournament.requesting ||
    requestStates.tournament.requesting;

  const successfulLoad =
    !requestStates.matchByTournament.requesting &&
    !requestStates.tournament.requesting &&
    requestStates.matchByTournament.success &&
    matchByTournament;

  const onMouseEnterMatchName = (
    e: React.MouseEvent,
    gameCreatedAt: string
  ) => {
    if (gameCreatedAt) {
      const date = new Date(gameCreatedAt);
      dispatch(
        showTooltip(
          e.pageX,
          getElementYOffset(e.currentTarget) - 15,
          `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
            timeStyle: "short",
          })}`
        )
      );
    }
  };

  const onMouseLeaveMatchName = () => {
    if (shouldShowTooltip) {
      dispatch(hideTooltip());
    }
  };

  return (
    <>
      {requesting && <Placeholder />}
      {successfulLoad && (matchByTournament as unknown as []).length > 0 && (
        <MatchesTable
          matches={matchByTournament ? mapToMatch(matchByTournament, game) : []}
          lang={lang}
          game={game}
          onMatchReplayClick={onMatchReplayClick}
          onScroll={onScroll}
          infinite={!isLastMatchesPage}
          onMouseEnterMatchName={onMouseEnterMatchName}
          onMouseLeaveMatchName={onMouseLeaveMatchName}
        />
      )}
      {successfulLoad && (matchByTournament as unknown as []).length === 0 && (
        <ComponentError
          message={t("sentences.noDataAvailable")}
          svg={Trophy}
          suggestion={t("sentences.comeBackLater")}
          className="h-96"
        />
      )}
      {!requestStates.tournament.requesting &&
        !requestStates.matchByTournament.requesting &&
        !requestStates.tournament.success &&
        requestStates.tournament.failed && (
          <ComponentError
            message={t("sentences.matchesLoadFailed")}
            svg={Trophy}
            suggestion={t("sentences.refreshThePage")}
            className="h-96"
          />
        )}
    </>
  );
};

export default MatchesTableContainer;
