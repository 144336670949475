import React from "react";
import TriangleRow from "./TriangleRow";
import classes from "./Triangles.module.scss";

const Triangles: React.FC<{ triangles: number[][] }> = ({ triangles }) => (
  <div className={classes.container}>
    {triangles.map((triangle, index) => (
      <div key={index} className={!(index % 2) ? classes.offset : ""}>
        <TriangleRow sizes={triangle} />
      </div>
    ))}
  </div>
);

export default Triangles;
