import axios from "axios";

import recaptchaConfig from "../../recaptcha-config";

export class Recaptcha {
  static URL = recaptchaConfig.url;
  static API = axios.create({
    baseURL: Recaptcha.URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static async verify(token: string): Promise<boolean | string> {
    // if domain is staging.twire.gg, skip verification
    if (window.location.hostname === "staging.twire.gg") {
      return true;
    }

    try {
      const response = await Recaptcha.API.post("/verify", { token });
      if (response.data.result) {
        return true;
      }

      return response.data.message;
    } catch (e) {
      return e.message;
    }
  }
}
