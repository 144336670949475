import { HeaderType } from "../Stats/AdvancedTable/types";
import { t as tutil } from "@twire/utility/helpers";
import PlayerRating from "./PlayerRating";

// export const headers: HeaderType = ;

export const getHeaders = (t: typeof tutil): HeaderType => [
  { headerId: "teamName", headerValue: "" }, // This will not be renered. It is required to be at the top so that is can be ignored. This is used to sort by team name
  { headerId: "twr", headerValue: "TWR", component: PlayerRating },
  { headerId: "numOfMatches", headerValue: "Number of Matches" },
  { headerId: "kills", headerValue: "Kills" },
  { headerId: "assists", headerValue: "Assists" },
  {
    headerId: "kda",
    headerValue: "KDA",
    description: t("sentences.kdaTooltip"),
  },
  { headerId: "kd", headerValue: "KD", description: t("sentences.kdTooltip") },
  {
    headerId: "kas",
    headerValue: "KAS",
    description: t("sentences.kasTooltip"),
  },
  { headerId: "dbnos", headerValue: "Knocks" },
  { headerId: "killsKnocks", headerValue: "Kills/Knocks" },
  { headerId: "damageDealt", headerValue: "Damage Dealt" },
  { headerId: "arDamage", headerValue: "AR Damage" },
  { headerId: "dmrDamage", headerValue: "DMR Damage" },
  { headerId: "srDamage", headerValue: "SR Damage" },
  { headerId: "avgDamageDealt", headerValue: "Avg Damage Dealt" },
  { headerId: "damageTaken", headerValue: "Damage Taken" },
  { headerId: "avgDamageTaken", headerValue: "Avg Damage Taken" },
  { headerId: "damageTakenFromBlueZone", headerValue: "Damage Taken (Zone)" },
  { headerId: "damageTakenFromEnemy", headerValue: "Damage Taken (Enemy)" },
  {
    headerId: "damageDealtDamageTaken",
    headerValue: "Damage Dealt/Damage Taken",
  },
  { headerId: "revives", headerValue: "Revives" },
  { headerId: "diedFirst", headerValue: "Died 1st" },
  { headerId: "diedSecond", headerValue: "Died 2nd" },
  { headerId: "diedThird", headerValue: "Died 3rd" },
  { headerId: "diedForth", headerValue: "Died 4th" },
  { headerId: "knocked", headerValue: "Knocked" },
  { headerId: "revived", headerValue: "Revived" },
  { headerId: "headshotKills", headerValue: "Headshot Kills" },
  { headerId: "longestKill", headerValue: "Longest Kill (m)" },
  {
    headerId: "killSteals",
    headerValue: "Stolen Kills",
    description: t("sentences.killStealsTooltip"),
  },
  {
    headerId: "killsStolenFrom",
    headerValue: "Kills stolen from player",
    description: t("sentences.killsStolenFromTooltip"),
  },
  { headerId: "grenadePickup", headerValue: "Grenades Picked" },
  { headerId: "grenadeDrop", headerValue: "Grenades Dropped" },
  { headerId: "grenadeThrow", headerValue: "Grenades Thrown" },
  { headerId: "grenadeDamage", headerValue: "Grenade Damage" },
  { headerId: "molotovPickup", headerValue: "Molotovs Picked" },
  { headerId: "molotovDrop", headerValue: "Molotovs Dropped" },
  { headerId: "molotovThrow", headerValue: "Molotovs Thrown" },
  { headerId: "molotovDamage", headerValue: "Molotovs Damage" },
  { headerId: "smokebombPickup", headerValue: "Smokes Picked" },
  { headerId: "smokebombDrop", headerValue: "Smokes Dropped" },
  { headerId: "smokebombThrow", headerValue: "Smokes Thrown" },
  { headerId: "flashbangPickup", headerValue: "Flashes Picked" },
  { headerId: "flashbangDrop", headerValue: "Flashes Dropped" },
  { headerId: "flashbangThrow", headerValue: "Flashes Thrown" },
  { headerId: "swimDistance", headerValue: "Swim Distance (km)" },
  { headerId: "walkDistance", headerValue: "Walk Distance (km)" },
  { headerId: "rideDistance", headerValue: "Ride Distance (km)" },
  { headerId: "timeSurvived", headerValue: "Time Survived" },
  { headerId: "avgTimeSurvived", headerValue: "Avg Time Survived" },
  { headerId: "heals", headerValue: "Heals" },
  { headerId: "healthRecovered", headerValue: "Health Recovered" },
  { headerId: "boosts", headerValue: "Boosts" },
  { headerId: "vehicleDestroys", headerValue: "Vehicle Destroys" },
];
