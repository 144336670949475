import { UserSubscription } from "../../PrivateAPI";

export const PREMIUM_USER_COOKIE = "PREMIUM_USER";

export const defaultUserSubscription: UserSubscription = {
  __typename: "UserSubscription",
  status: false,
  trial: false,
  validFrom: null,
  validTo: null,
  planId: null,
};
