import { t } from "@twire/utility/helpers";
import Dropdown, { DropDowndirections } from "unop-react-dropdown";
import SettingsToggler from "@twire/common/SettingsToggler";
import classes from "./Filter.module.scss";

const Filter: React.FC<{
  onApplyClicked: () => void;
  onClearClicked: () => void;
  onOpen?: (arg?: any) => void;
  onClose?: (arg?: any) => void;
}> = ({ children, onApplyClicked, onClearClicked, onOpen, onClose }) => (
  <Dropdown
    align={DropDowndirections.LEFT}
    closeOnClickOut
    trigger={<SettingsToggler />}
    onAppear={onOpen}
    onDisappear={onClose}
  >
    <div className={classes.container}>
      <div className={classes.filterContent}>{children}</div>
      <div className={classes.filterActions}>
        <button className={classes.clear} onClick={onClearClicked}>
          {t("words.clearFilters")}
        </button>
        <button className={classes.apply} onClick={onApplyClicked}>
          {t("words.applyFilters")}
        </button>
      </div>
    </div>
  </Dropdown>
);

export default Filter;
