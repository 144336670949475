/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGames = /* GraphQL */ `
  query GetGames {
    getGames {
      id
      orderNumber
      name
      shortName
      logo
      slug
      parameters {
        pickSize
        benchSize
        sameTeamPlayersLimit
        budget
        captainMultiplier
        captainPrice
        pickTiebreakers
        pickTiebreakersDescription
        pointsCalculation
        statistics
        freePrivateLeaderboardLimit
      }
    }
  }
`;
export const getTournament = /* GraphQL */ `
  query GetTournament($id: Int!) {
    getTournament(id: $id) {
      id
      name
      logo
      banner
      tier
      startDate
      endDate
      prize {
        from
        to
      }
      pickPhase {
        startDate
        endDate
        type
      }
      status
      region {
        id
        name
        shortName
      }
      game {
        id
        orderNumber
        name
        shortName
        logo
        slug
        parameters {
          pickSize
          benchSize
          sameTeamPlayersLimit
          budget
          captainMultiplier
          captainPrice
          pickTiebreakers
          pickTiebreakersDescription
          pointsCalculation
          statistics
          freePrivateLeaderboardLimit
        }
      }
    }
  }
`;
export const getTournaments = /* GraphQL */ `
  query GetTournaments($gameId: Int, $page: Int) {
    getTournaments(gameId: $gameId, page: $page) {
      id
      name
      logo
      banner
      tier
      startDate
      endDate
      prize {
        from
        to
      }
      pickPhase {
        startDate
        endDate
        type
      }
      status
      region {
        id
        name
        shortName
      }
      game {
        id
        orderNumber
        name
        shortName
        logo
        slug
        parameters {
          pickSize
          benchSize
          sameTeamPlayersLimit
          budget
          captainMultiplier
          captainPrice
          pickTiebreakers
          pickTiebreakersDescription
          pointsCalculation
          statistics
          freePrivateLeaderboardLimit
        }
      }
    }
  }
`;
export const getTournamentPlayers = /* GraphQL */ `
  query GetTournamentPlayers($tournamentId: Int!) {
    getTournamentPlayers(tournamentId: $tournamentId) {
      id
      nickname
      firstName
      lastName
      image
      price
      isSubstitute
      statistics
      statisticsLatest
      points
      pointsLatest
      totalPoints
      pickRate
      performance
      team {
        id
        name
        logo
      }
      tournamentPlayerId
    }
  }
`;
export const getTournamentPlayerStatistics = /* GraphQL */ `
  query GetTournamentPlayerStatistics($tournamentId: Int!) {
    getTournamentPlayerStatistics(tournamentId: $tournamentId) {
      id
      nickname
      firstName
      lastName
      image
      price
      isSubstitute
      statistics
      statisticsLatest
      points
      pointsLatest
      totalPoints
      pickRate
      performance
      team {
        id
        name
        logo
      }
      tournamentPlayerId
    }
  }
`;
export const getTournamentLeaderboard = /* GraphQL */ `
  query GetTournamentLeaderboard(
    $tournamentId: Int!
    $page: Int
    $query: String
    $premium: Boolean
  ) {
    getTournamentLeaderboard(
      tournamentId: $tournamentId
      page: $page
      query: $query
      premium: $premium
    ) {
      numberOfPages
      leaderboardUsers {
        placement
        totalPoints
        user {
          id
          username
        }
        prize {
          amount
          freeAmount
          premiumAmount
          name
        }
      }
    }
  }
`;
export const getTournamentUserPicks = /* GraphQL */ `
  query GetTournamentUserPicks($tournamentId: Int!, $userId: Int!) {
    getTournamentUserPicks(tournamentId: $tournamentId, userId: $userId) {
      totalPoints
      placement
      players {
        id
        isCaptain
        isActive
        price
        isSubstitute
        playerId
        nickname
        firstName
        lastName
        image
        points
        statistics
        totalPoints
        team {
          id
          name
          logo
        }
      }
      phaseName
    }
  }
`;
export const getSeasonLeaderboard = /* GraphQL */ `
  query GetSeasonLeaderboard($gameId: Int!, $query: String, $page: Int) {
    getSeasonLeaderboard(gameId: $gameId, query: $query, page: $page) {
      numberOfPages
      leaderboardUsers {
        placement
        totalPoints
        user {
          id
          username
        }
        prize {
          amount
          freeAmount
          premiumAmount
          name
        }
      }
    }
  }
`;
export const getShopCategories = /* GraphQL */ `
  query GetShopCategories {
    getShopCategories {
      id
      name
      image
      slug
      shopItemTypes {
        id
        name
        description
        image
        slug
      }
    }
  }
`;
export const getShopItems = /* GraphQL */ `
  query GetShopItems($shopItemTypeSlug: String!) {
    getShopItems(shopItemTypeSlug: $shopItemTypeSlug) {
      id
      name
      image
      price
      inStock
      shopItemType {
        id
        name
        description
        image
        slug
      }
    }
  }
`;
export const getPremiumPlans = /* GraphQL */ `
  query GetPremiumPlans {
    getPremiumPlans {
      id
      numberOfMonths
      name
      price
      currency
      providerPlanId
    }
  }
`;
