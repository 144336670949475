import { ActionCreator, AnyAction } from "redux";
import * as actionTypes from "./actionTypes";
import { PostComment } from "./types";

export const getArticle = (id: number): AnyAction => ({
  type: actionTypes.ARTICLES_GET_ARTICLE,
  id,
});

export const clearArticle = (): AnyAction => ({
  type: actionTypes.ARTICLES_GET_ARTICLE_CLEAR,
});

export const postComment: ActionCreator<PostComment> = (
  content: string,
  parentId: number
) => ({
  type: actionTypes.ARTICLES_POST_COMMENT,
  payload: {
    content,
    parentId,
  },
});

export const clearComment = (): AnyAction => ({
  type: actionTypes.ARTICLES_POST_COMMENT_CLEAR,
});
