import React from "react";
import { mergeClasses } from "@twire/utility/helpers";
import Text from "@twire/common/Text";
import { ReactComponent as Trophy } from "../../../../../../../assets/svgs/trophy.svg";
import { ReactComponent as Skull } from "../../../../../../../assets/svgs/skull.svg";
import { ReactComponent as Aim } from "../../../../../../../assets/svgs/aim.svg";
import { ReactComponent as Progress } from "../../../../../../../assets/svgs/progress.svg";
import { ReactComponent as Timer } from "../../../../../../../assets/svgs/timer.svg";
import { ReactComponent as Shield } from "../../../../../../../assets/svgs/shield.svg";
import { chartTypes } from "../../constants";
import classes from "./Card.module.scss";

export const statToSvgMap: any = {
  [chartTypes.NUMBER_OF_WINS]: <Trophy />,
  [chartTypes.AVG_KILLS]: <Skull />,
  [chartTypes.AVG_DAMAGE_DELT]: <Aim />,
  [chartTypes.AVG_RANK]: <Progress />,
  [chartTypes.AVG_TIME_SURVIVED]: <Timer />,
  [chartTypes.AVG_DAMAGE_TAKEN]: <Shield />,
};

export const getSvg = (s: string): any => statToSvgMap[s];
const Card: React.FC<{ name: string; overall: string }> = ({
  name,
  overall,
}) => (
  <div className={classes.container}>
    <div className={classes.svgCircle}>{getSvg(name)}</div>
    <div className="rift-bold text-twire-light text-lg mt-4">
      <Text i18nKey={`words.${name}` as any}>{name}</Text>
    </div>
    <div className="text-twire-light open-sans text-xs opacity-70 mt-1">
      <Text i18nKey="sentences.teamStatisticDescription">in last 12 months</Text>
    </div>
    <div
      className={mergeClasses("text-twire-light mt-5 rift-bold", classes.text)}
    >
      {overall}
    </div>
  </div>
);

export default Card;
