import { FeaturedTournament } from "./types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatFeatured = ({ data }: any): FeaturedTournament => ({
  id: data.id,
  time: data.startDate,
  tier: data.tier,
  prize: data.prizePool,
  curency: "usd",
  location: data.region,
  name: data.friendlyName,
  badge: data.imageName,
  live: data.live,
  tournamentSocials: data.tournamentSocials,
});
