import {
  all,
  put,
  takeLatest,
  select,
} from "@redux-saga/core/effects";
import { getTeam, getTeamMatches, getTeamStatistics, getTeamTournaments } from "../../graphql/public/queries";
import { publicQuery } from "../../graphql/requestHelper";
import { RootReducer } from "../types";
import * as actionTypes from "./actionTypes";
import {
  getTeam as getTeamAction,
  getTeamTournaments as getTeamTournamentsAction,
  getTeamMatches as getTeamMatchesAction,
  getTeamStatistics as getTeamStatisticsAction,
} from "./actions";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchTeam({ id }: ReturnType<typeof getTeamAction>) {
  yield put({ type: actionTypes.TEAM_GET_TEAM_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const team: Generator<any, any> = yield publicQuery(
      getTeam,
      {
        game: state.app.game,
        teamId: id,
      },
      CACHE_TIME.MINUTE * 10,
    );

    yield put({
      type: actionTypes.TEAM_GET_TEAM_SUCCESS,
      team: (team as any).data.getTeam,
    });
  } catch (error) {
    yield put({ type: actionTypes.TEAM_GET_TEAM_FAILED, error });
  }
}

function* fetchTeamTournaments({
  id,
  page,
}: ReturnType<typeof getTeamTournamentsAction>) {
  yield put({ type: actionTypes.TEAM_GET_TOURNAMENTS_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const team: Generator<any, any> = yield publicQuery(
      getTeamTournaments,
      {
        game: state.app.game,
        teamId: id,
        page: page,
      },
      CACHE_TIME.MINUTE * 10,
    );

    yield put({
      type: actionTypes.TEAM_GET_TOURNAMENTS_SUCCESS,
      tournaments: (team as any).data.getTeamTournaments,
      page: page,
    });
  } catch (error) {
    yield put({ type: actionTypes.TEAM_GET_TOURNAMENTS_FAILED, error });
  }
}

function* fetchTeamMatches({
  id,
  page,
}: ReturnType<typeof getTeamMatchesAction>) {
  yield put({ type: actionTypes.TEAM_GET_MATCHES_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const matches: Generator<any, any> = yield publicQuery(
      getTeamMatches,
      {
        game: state.app.game,
        teamId: id,
        page: page,
      },
      CACHE_TIME.MINUTE * 10,
    );

    yield put({
      type: actionTypes.TEAM_GET_MATCHES_SUCCESS,
      matches: (matches as any).data.getTeamMatches,
      page: page,
    });
  } catch (error) {
    yield put({ type: actionTypes.TEAM_GET_MATCHES_FAILED, error });
  }
}

function* fetchTeamStatistics({ id }: ReturnType<typeof getTeamStatisticsAction>) {
  yield put({ type: actionTypes.TEAM_GET_STATISTICS_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const statistics: Generator<any, any> = yield publicQuery(
      getTeamStatistics,
      {
        game: state.app.game,
        teamId: id,
      },
      CACHE_TIME.MINUTE * 10,
    );

    yield put({
      type: actionTypes.TEAM_GET_STATISTICS_SUCCESS,
      statistics: (statistics as any).data.getTeamStatistics,
    });
  } catch (error) {
    yield put({ type: actionTypes.TEAM_GET_STATISTICS_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([takeLatest(actionTypes.TEAM_GET_TEAM, fetchTeam)]);
  yield all([takeLatest(actionTypes.TEAM_GET_TOURNAMENTS, fetchTeamTournaments)]);
  yield all([takeLatest(actionTypes.TEAM_GET_MATCHES, fetchTeamMatches)]);
  yield all([takeLatest(actionTypes.TEAM_GET_STATISTICS, fetchTeamStatistics)]);
}
