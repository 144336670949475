import { RequestStates } from "../types";

export interface PgcPointsRegion {
  teams: PgcPointsTeam[];
  advance: string[];
  tournaments: PgcTournament[];
}

export interface PgcPointsTeam {
  team: string;
  points: string;
  tournamentPoints: PgcTournamentPoints[];
}

export interface PgcTournament {
  id: string,
  name: string,
}

export interface PgcTournamentPoints {
  id: string,
  points: number,
}

export enum PgcPointsRegions {
  EU = "eu",
  AM = "am",
  ASIA = "asia",
  APAC = "apac",
}

export interface PgcPointsInterface {
  regions: {[key in PgcPointsRegions]: PgcPointsRegion};
  requestStates: {
    regions: RequestStates;
  };
}
