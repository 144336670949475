import { AnyAction } from "redux";
import { TournamentInterface } from "../types";

export const success = (
  state: TournamentInterface,
  action: AnyAction
): TournamentInterface => ({
  ...state,
  statsFilters: action.filters,
});

export const clear = (state: TournamentInterface): TournamentInterface => ({
  ...state,
  statsFilters: {
    all: true,
    maps: [],
    matches: [],
  },
});
