import { useSelector, useDispatch } from "react-redux";
import { filterTournament } from "@twire/redux/tournament/actions";
import { RootReducer } from "@twire/redux/types";
import { TwireUrl, getFirstChild } from "@twire/utility/helpers";
import { TournamentFilter } from "../../../../../PublicAPI";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTournament = () => {
  const {
    tournament: { tournament, requestStates, filter },
  } = useSelector((state: RootReducer) => state);

  const dispatch = useDispatch();

  const isSuccess = requestStates.tournament.success || filter;

  const selectedRound = tournament?.tournamentFilters?.find((d) => {
    return d?.value === filter.parent;
  });

  const selectedGroup = selectedRound?.children?.find((c) => {
    return c?.value === filter.child;
  });

  // const getParent = (value: string) =>
  //   tournament?.tournamentFilters?.find((a) => a?.value === value);

  // const getFirstChild = (value: string): string => {
  //   const parent = getParent(value);
  //   if (parent) {
  //     if (parent.children) {
  //       if (parent.children[0] && parent.children[0].value) {
  //         return parent.children[0].value;
  //       } else {
  //         return "";
  //       }
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const changeGroup = (value: string) => {
    TwireUrl.set("group", value);
    dispatch(
      filterTournament({
        shard: tournament?.shard || "",
        parent: filter.parent || "",
        child: value || "",
        ruleset: filter?.ruleset || null,
      })
    );
  };

  const changeRound = (filter: TournamentFilter | null) => {
    const value = filter?.value || "";
    const firstChildFilter = getFirstChild(
      value,
      tournament?.tournamentFilters
    );
    TwireUrl.set("round", value);
    TwireUrl.set("group", firstChildFilter?.value || "");
    dispatch(
      filterTournament({
        shard: tournament?.shard || "",
        parent: value || "",
        child: firstChildFilter?.value || "",
        ruleset: firstChildFilter?.ruleset || filter?.ruleset || null,
      })
    );
  };

  return {
    ...filter,
    selectedRound,
    selectedGroup,
    changeGroup,
    changeRound,
    isSuccess,
  };
};
