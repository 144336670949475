/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Game = {
  __typename: "Game",
  id: number,
  orderNumber: number,
  name: string,
  shortName: string,
  logo: string,
  slug: string,
  parameters: GameParameters,
};

export type GameParameters = {
  __typename: "GameParameters",
  pickSize: number,
  benchSize: number,
  sameTeamPlayersLimit: number,
  budget: number,
  captainMultiplier: number,
  captainPrice: number,
  pickTiebreakers: string,
  pickTiebreakersDescription: string,
  pointsCalculation: string,
  statistics: string,
  freePrivateLeaderboardLimit: number,
};

export type Tournament = {
  __typename: "Tournament",
  id: number,
  name: string,
  logo?: string | null,
  banner?: string | null,
  tier: string,
  startDate: string,
  endDate: string,
  prize?: TournamentPrize | null,
  pickPhase?: TournamentPickPhase | null,
  status?: TournamentStatus | null,
  region: Region,
  game: Game,
};

export type TournamentPrize = {
  __typename: "TournamentPrize",
  from: number,
  to: number,
};

export type TournamentPickPhase = {
  __typename: "TournamentPickPhase",
  startDate: string,
  endDate: string,
  type: TournamentPickPhaseType,
};

export enum TournamentPickPhaseType {
  TEAM_PICK = "TEAM_PICK",
  ROLE_PICK = "ROLE_PICK",
}


export enum TournamentStatus {
  LIVE = "LIVE",
  UPCOMING = "UPCOMING",
  FINISHED = "FINISHED",
}


export type Region = {
  __typename: "Region",
  id: number,
  name: string,
  shortName?: string | null,
};

export type Player = {
  __typename: "Player",
  id: number,
  nickname: string,
  firstName?: string | null,
  lastName?: string | null,
  image?: string | null,
  price?: number | null,
  isSubstitute?: boolean | null,
  statistics?: string | null,
  statisticsLatest?: string | null,
  points?: string | null,
  pointsLatest?: string | null,
  totalPoints?: number | null,
  pickRate?: number | null,
  performance?: number | null,
  team: Team,
  tournamentPlayerId: number,
};

export type Team = {
  __typename: "Team",
  id: number,
  name: string,
  logo?: string | null,
};

export type Leaderboard = {
  __typename: "Leaderboard",
  numberOfPages: number,
  leaderboardUsers:  Array<LeaderboardUser >,
};

export type LeaderboardUser = {
  __typename: "LeaderboardUser",
  placement: number,
  totalPoints: number,
  user: User,
  prize?: LeaderboardPrize | null,
};

export type User = {
  __typename: "User",
  id: number,
  username: string,
  cosmetics?: Cosmetics | null,
};

export type Cosmetics = {
  __typename: "Cosmetics",
  avatar?: string | null,
  avatarBorder?: string | null,
  badge?: string | null,
  title?: string | null,
  banner?: string | null,
};

export type LeaderboardPrize = {
  __typename: "LeaderboardPrize",
  amount?: number | null,
  freeAmount?: number | null,
  premiumAmount?: number | null,
  name?: string | null,
};

export type Pick = {
  __typename: "Pick",
  totalPoints?: number | null,
  placement?: number | null,
  players:  Array<PickPlayer >,
  phaseName?: string | null,
};

export type PickPlayer = {
  __typename: "PickPlayer",
  id: number,
  isCaptain: boolean,
  isActive: boolean,
  price: number,
  isSubstitute?: boolean | null,
  playerId: number,
  nickname: string,
  firstName?: string | null,
  lastName?: string | null,
  image?: string | null,
  points?: string | null,
  statistics?: string | null,
  totalPoints?: number | null,
  team: Team,
};

export type ShopCategory = {
  __typename: "ShopCategory",
  id: number,
  name: string,
  image?: string | null,
  slug: string,
  shopItemTypes:  Array<ShopItemType >,
};

export type ShopItemType = {
  __typename: "ShopItemType",
  id: number,
  name: string,
  description: string,
  image?: string | null,
  slug: string,
};

export type ShopItem = {
  __typename: "ShopItem",
  id: number,
  name: string,
  image?: string | null,
  price: number,
  inStock: boolean,
  shopItemType: ShopItemType,
};

export type PremiumPlan = {
  __typename: "PremiumPlan",
  id: number,
  numberOfMonths: number,
  name: string,
  price: number,
  currency: string,
  providerPlanId: string,
};

export type GetGamesQuery = {
  getGames:  Array< {
    __typename: "Game",
    id: number,
    orderNumber: number,
    name: string,
    shortName: string,
    logo: string,
    slug: string,
    parameters:  {
      __typename: "GameParameters",
      pickSize: number,
      benchSize: number,
      sameTeamPlayersLimit: number,
      budget: number,
      captainMultiplier: number,
      captainPrice: number,
      pickTiebreakers: string,
      pickTiebreakersDescription: string,
      pointsCalculation: string,
      statistics: string,
      freePrivateLeaderboardLimit: number,
    },
  } >,
};

export type GetTournamentQueryVariables = {
  id: number,
};

export type GetTournamentQuery = {
  getTournament:  {
    __typename: "Tournament",
    id: number,
    name: string,
    logo?: string | null,
    banner?: string | null,
    tier: string,
    startDate: string,
    endDate: string,
    prize?:  {
      __typename: "TournamentPrize",
      from: number,
      to: number,
    } | null,
    pickPhase?:  {
      __typename: "TournamentPickPhase",
      startDate: string,
      endDate: string,
      type: TournamentPickPhaseType,
    } | null,
    status?: TournamentStatus | null,
    region:  {
      __typename: "Region",
      id: number,
      name: string,
      shortName?: string | null,
    },
    game:  {
      __typename: "Game",
      id: number,
      orderNumber: number,
      name: string,
      shortName: string,
      logo: string,
      slug: string,
      parameters:  {
        __typename: "GameParameters",
        pickSize: number,
        benchSize: number,
        sameTeamPlayersLimit: number,
        budget: number,
        captainMultiplier: number,
        captainPrice: number,
        pickTiebreakers: string,
        pickTiebreakersDescription: string,
        pointsCalculation: string,
        statistics: string,
        freePrivateLeaderboardLimit: number,
      },
    },
  },
};

export type GetTournamentsQueryVariables = {
  gameId?: number | null,
  page?: number | null,
};

export type GetTournamentsQuery = {
  getTournaments:  Array< {
    __typename: "Tournament",
    id: number,
    name: string,
    logo?: string | null,
    banner?: string | null,
    tier: string,
    startDate: string,
    endDate: string,
    prize?:  {
      __typename: "TournamentPrize",
      from: number,
      to: number,
    } | null,
    pickPhase?:  {
      __typename: "TournamentPickPhase",
      startDate: string,
      endDate: string,
      type: TournamentPickPhaseType,
    } | null,
    status?: TournamentStatus | null,
    region:  {
      __typename: "Region",
      id: number,
      name: string,
      shortName?: string | null,
    },
    game:  {
      __typename: "Game",
      id: number,
      orderNumber: number,
      name: string,
      shortName: string,
      logo: string,
      slug: string,
      parameters:  {
        __typename: "GameParameters",
        pickSize: number,
        benchSize: number,
        sameTeamPlayersLimit: number,
        budget: number,
        captainMultiplier: number,
        captainPrice: number,
        pickTiebreakers: string,
        pickTiebreakersDescription: string,
        pointsCalculation: string,
        statistics: string,
        freePrivateLeaderboardLimit: number,
      },
    },
  } >,
};

export type GetTournamentPlayersQueryVariables = {
  tournamentId: number,
};

export type GetTournamentPlayersQuery = {
  getTournamentPlayers:  Array< {
    __typename: "Player",
    id: number,
    nickname: string,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    price?: number | null,
    isSubstitute?: boolean | null,
    statistics?: string | null,
    statisticsLatest?: string | null,
    points?: string | null,
    pointsLatest?: string | null,
    totalPoints?: number | null,
    pickRate?: number | null,
    performance?: number | null,
    team:  {
      __typename: "Team",
      id: number,
      name: string,
      logo?: string | null,
    },
    tournamentPlayerId: number,
  } >,
};

export type GetTournamentPlayerStatisticsQueryVariables = {
  tournamentId: number,
};

export type GetTournamentPlayerStatisticsQuery = {
  getTournamentPlayerStatistics:  Array< {
    __typename: "Player",
    id: number,
    nickname: string,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    price?: number | null,
    isSubstitute?: boolean | null,
    statistics?: string | null,
    statisticsLatest?: string | null,
    points?: string | null,
    pointsLatest?: string | null,
    totalPoints?: number | null,
    pickRate?: number | null,
    performance?: number | null,
    team:  {
      __typename: "Team",
      id: number,
      name: string,
      logo?: string | null,
    },
    tournamentPlayerId: number,
  } >,
};

export type GetTournamentLeaderboardQueryVariables = {
  tournamentId: number,
  page?: number | null,
  query?: string | null,
  premium?: boolean | null,
};

export type GetTournamentLeaderboardQuery = {
  getTournamentLeaderboard:  {
    __typename: "Leaderboard",
    numberOfPages: number,
    leaderboardUsers:  Array< {
      __typename: "LeaderboardUser",
      placement: number,
      totalPoints: number,
      user:  {
        __typename: "User",
        id: number,
        username: string,
      },
      prize?:  {
        __typename: "LeaderboardPrize",
        amount?: number | null,
        freeAmount?: number | null,
        premiumAmount?: number | null,
        name?: string | null,
      } | null,
    } >,
  },
};

export type GetTournamentUserPicksQueryVariables = {
  tournamentId: number,
  userId: number,
};

export type GetTournamentUserPicksQuery = {
  getTournamentUserPicks:  Array< {
    __typename: "Pick",
    totalPoints?: number | null,
    placement?: number | null,
    players:  Array< {
      __typename: "PickPlayer",
      id: number,
      isCaptain: boolean,
      isActive: boolean,
      price: number,
      isSubstitute?: boolean | null,
      playerId: number,
      nickname: string,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      points?: string | null,
      statistics?: string | null,
      totalPoints?: number | null,
      team:  {
        __typename: "Team",
        id: number,
        name: string,
        logo?: string | null,
      },
    } >,
    phaseName?: string | null,
  } >,
};

export type GetSeasonLeaderboardQueryVariables = {
  gameId: number,
  query?: string | null,
  page?: number | null,
};

export type GetSeasonLeaderboardQuery = {
  getSeasonLeaderboard:  {
    __typename: "Leaderboard",
    numberOfPages: number,
    leaderboardUsers:  Array< {
      __typename: "LeaderboardUser",
      placement: number,
      totalPoints: number,
      user:  {
        __typename: "User",
        id: number,
        username: string,
      },
      prize?:  {
        __typename: "LeaderboardPrize",
        amount?: number | null,
        freeAmount?: number | null,
        premiumAmount?: number | null,
        name?: string | null,
      } | null,
    } >,
  },
};

export type GetShopCategoriesQuery = {
  getShopCategories:  Array< {
    __typename: "ShopCategory",
    id: number,
    name: string,
    image?: string | null,
    slug: string,
    shopItemTypes:  Array< {
      __typename: "ShopItemType",
      id: number,
      name: string,
      description: string,
      image?: string | null,
      slug: string,
    } >,
  } >,
};

export type GetShopItemsQueryVariables = {
  shopItemTypeSlug: string,
};

export type GetShopItemsQuery = {
  getShopItems:  Array< {
    __typename: "ShopItem",
    id: number,
    name: string,
    image?: string | null,
    price: number,
    inStock: boolean,
    shopItemType:  {
      __typename: "ShopItemType",
      id: number,
      name: string,
      description: string,
      image?: string | null,
      slug: string,
    },
  } >,
};

export type GetPremiumPlansQuery = {
  getPremiumPlans:  Array< {
    __typename: "PremiumPlan",
    id: number,
    numberOfMonths: number,
    name: string,
    price: number,
    currency: string,
    providerPlanId: string,
  } >,
};
