import { LeaderboardHeaderType } from "./types";
import { GeneralTournamentFormat } from "@twire/utility/constants";

const super22Headers: LeaderboardHeaderType[] = [
  {
    key: "numberOfMatches",
    value: "matches",
  },
  {
    key: "points",
    value: "Place Pts",
  },
  {
    key: "kills",
    value: "Kills",
  },
  {
    key: "totalPoints",
    value: "Points",
  },
];

const super21Headers: LeaderboardHeaderType[] = [
  {
    key: "numberOfMatches",
    value: "matches",
  },
  {
    key: "kills",
    value: "Kills",
  },
  {
    key: "wins",
    value: "Wins",
  },
];

const super20Headers: LeaderboardHeaderType[] = [
  {
    key: "numberOfMatches",
    value: "matches",
  },
  {
    key: "wins",
    value: "Wins",
  },
  {
    key: "kills",
    value: "Kills",
  },
  {
    key: "totalPoints",
    value: "Points",
  },
];

const wwcdheaders: LeaderboardHeaderType[] = [...super21Headers];

export const formatToHeaderMap: { [key: string]: any } = {
  [GeneralTournamentFormat.POINT_SYSTEM_22]: super22Headers,
  [GeneralTournamentFormat.MOST_CHICKEN]: super21Headers,
  [GeneralTournamentFormat.POINT_SYSTEM]: super20Headers,
  [GeneralTournamentFormat.WWCD]: wwcdheaders,
};
