import React, { useEffect, useRef } from "react";
import ComponentError from "@twire/common/ComponentError";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlatformStats,
  clearPlatformStats,
} from "@twire/redux/tournament/actions";
import { useFullScreen } from "@twire/utility/hooks";
import { RootReducer } from "@twire/redux/types";
import PlayerStats from "./PlayerStats";
import PlaceholderPlayerStats from "./Placeholder";
import { ReactComponent as Trophy } from "../../../../../../assets/svgs/trophy.svg";
import { formatDataForTable } from "./functions";
import PlaceholderTable from "../Stats/AdvancedTable/Placeholder";
import { saveUserAction } from "@twire/redux/track/actions";
import { tournamentPlayerStatsView } from "@twire/redux/track/userActions/userActions";
import { useTournament } from "../hooks";
import StatsFilter from "../StatsFilter";
import { mergeClasses, t } from "../../../../../../utility/helpers";

const PlayerStatsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedRound, selectedGroup } = useTournament();
  const { fullscreen, toggleFullScreen } = useFullScreen();
  const allRef = useRef(true);
  const {
    tournament: {
      tournament,
      requestStates,
      filter,
      platformStats,
      statsFilters,
    },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (requestStates.tournament.success) {
      if (
        !platformStats ||
        allRef.current !== statsFilters.all ||
        statsFilters.all === false
      ) {
        allRef.current = statsFilters.all;
        dispatch(getPlatformStats());
      }
    }
  }, [statsFilters]);

  useEffect(() => {
    if (requestStates.tournament.success) {
      dispatch(getPlatformStats());
    }
  }, [filter]);

  useEffect(() => {
    dispatch(
      saveUserAction(
        tournamentPlayerStatsView(
          tournament?.friendlyName,
          tournament?.eventType,
          `${selectedRound?.name} ${selectedGroup?.name}`,
          tournament?.region,
          // TODO: add view type (top 4, advance, graphic)
          "advance",
          // TODO: add full screen
          false
        )
      )
    );
  }, [tournament, selectedRound, selectedGroup]);

  useEffect(() => {
    return () => {
      dispatch(clearPlatformStats());
    };
  }, []);

  return (
    <div className={mergeClasses([fullscreen, "fullscreen"])}>
      <div className="flex mt-5">
        <div className="ml-auto">
          <StatsFilter />
        </div>
      </div>
      {requestStates.platformStats.success &&
      platformStats?.leaderboard.length !== 0 &&
      !requestStates.platformStats.failed &&
      !requestStates.platformStats.requesting ? (
        <>
          <PlayerStats
            onResizeClick={toggleFullScreen}
            fullscreen={fullscreen}
            stats={
              platformStats
                ? formatDataForTable(platformStats?.leaderboard, game)
                : []
            }
          />
        </>
      ) : requestStates.platformStats.failed &&
        !requestStates.platformStats.success &&
        !requestStates.platformStats.requesting ? (
        <div className="mt-5">
          <ComponentError
            message={t("sentences.playerStatsLoadFailed")}
            svg={Trophy}
            suggestion={t("sentences.refreshThePage")}
            className="h-96 appear"
          />
        </div>
      ) : requestStates.platformStats.success &&
        platformStats?.leaderboard.length === 0 &&
        !requestStates.platformStats.failed ? (
        <div className="mt-5">
          <ComponentError
            message={t("sentences.noDataAvailable")}
            svg={Trophy}
            suggestion={t("sentences.comeBackLater")}
            className="h-96 appear"
          />
        </div>
      ) : requestStates.platformStats.requesting &&
        !requestStates.platformStats.success &&
        !requestStates.platformStats.failed ? (
        <div className="mt-5 appear">
          <>
            {!fullscreen && <PlaceholderPlayerStats />}
            <PlaceholderTable />
          </>
        </div>
      ) : (
        <div className="mt-5 appear">
          <>
            {!fullscreen && <PlaceholderPlayerStats />}
            <PlaceholderTable />
          </>
        </div>
      )}
    </div>
  );
};

export default PlayerStatsContainer;
