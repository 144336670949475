import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { defaultUserSubscription } from "./constants";
import { UserStateInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as signInHandlers from "./handlers/signIn";
import * as getSignInHandlers from "./handlers/getSignIn";
import * as signOutHandlers from "./handlers/signOut";
import * as signUpHandlers from "./handlers/signUp";
import * as forgotPasswordHandlers from "./handlers/forgotPassword";
import * as resetPasswordHandlers from "./handlers/resetPassword";
import * as verifyEmailHandlers from "./handlers/verifyEmail";
import * as resendVerifyEmailHandlers from "./handlers/resendVerifyEmail";
import * as userSubscriptionHandlers from "./handlers/userSubscription";

const INITIAL_STATE: UserStateInterface = {
  signedIn: false,
  userSubscription: { ...defaultUserSubscription },
  userData: null,
  userUnverified: false,
  requestStates: {
    getSignIn: { ...defaultRequestState },
    signIn: { ...defaultRequestState },
    signOut: { ...defaultRequestState },
    signUp: { ...defaultRequestState },
    forgotPassword: { ...defaultRequestState },
    resetPassword: { ...defaultRequestState },
    verifyEmail: { ...defaultRequestState },
    resendVerifyEmail: { ...defaultRequestState },
    userSubscription: { ...defaultRequestState },
  },
};

const userReducer: Reducer<UserStateInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.AUTH_GET_SIGN_IN_USER_REQUESTED:
      return getSignInHandlers.requested(state);
    case actionTypes.AUTH_SIGN_IN_REQUESTED:
      return signInHandlers.requested(state);
    case actionTypes.AUTH_SIGN_IN_FAILED:
      return signInHandlers.failed(state, action);
    case actionTypes.AUTH_GET_SIGN_IN_USER_FAILED:
      return getSignInHandlers.failed(state, action);
    case actionTypes.AUTH_SIGN_IN_SUCCESS:
      return signInHandlers.success(state, action);
    case actionTypes.AUTH_GET_SIGN_IN_USER_SUCCESS:
      return getSignInHandlers.success(state, action);
    case actionTypes.AUTH_SIGN_IN_CLEAR:
      return signInHandlers.clear(state);

    case actionTypes.AUTH_SIGN_OUT_REQUESTED:
      return signOutHandlers.requested(state);
    case actionTypes.AUTH_SIGN_OUT_FAILED:
      return signOutHandlers.failed(state, action);
    case actionTypes.AUTH_SIGN_OUT_SUCCESS:
      return signOutHandlers.success(state, action);
    case actionTypes.AUTH_SIGN_OUT_CLEAR:
      return signOutHandlers.clear(state);

    case actionTypes.AUTH_SIGN_UP_REQUESTED:
      return signUpHandlers.requested(state);
    case actionTypes.AUTH_SIGN_UP_FAILED:
      return signUpHandlers.failed(state, action);
    case actionTypes.AUTH_SIGN_UP_SUCCESS:
      return signUpHandlers.success(state, action);
    case actionTypes.AUTH_SIGN_UP_CLEAR:
      return signUpHandlers.clear(state);

    case actionTypes.AUTH_FORGOT_PASSWORD_REQUESTED:
      return forgotPasswordHandlers.requested(state);
    case actionTypes.AUTH_FORGOT_PASSWORD_FAILED:
      return forgotPasswordHandlers.failed(state, action);
    case actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordHandlers.success(state, action);
    case actionTypes.AUTH_FORGOT_PASSWORD_CLEAR:
      return forgotPasswordHandlers.clear(state);

    case actionTypes.AUTH_RESET_PASSWORD_REQUESTED:
      return resetPasswordHandlers.requested(state);
    case actionTypes.AUTH_RESET_PASSWORD_FAILED:
      return resetPasswordHandlers.failed(state, action);
    case actionTypes.AUTH_RESET_PASSWORD_SUCCESS:
      return resetPasswordHandlers.success(state, action);
    case actionTypes.AUTH_RESET_PASSWORD_CLEAR:
      return resetPasswordHandlers.clear(state);

    case actionTypes.AUTH_VERIFY_EMAIL_REQUESTED:
      return verifyEmailHandlers.requested(state);
    case actionTypes.AUTH_VERIFY_EMAIL_FAILED:
      return verifyEmailHandlers.failed(state, action);
    case actionTypes.AUTH_VERIFY_EMAIL_SUCCESS:
      return verifyEmailHandlers.success(state, action);
    case actionTypes.AUTH_VERIFY_EMAIL_CLEAR:
      return verifyEmailHandlers.clear(state);

    case actionTypes.AUTH_RESEND_VERIFY_EMAIL_REQUESTED:
      return resendVerifyEmailHandlers.requested(state);
    case actionTypes.AUTH_RESEND_VERIFY_EMAIL_FAILED:
      return resendVerifyEmailHandlers.failed(state, action);
    case actionTypes.AUTH_RESEND_VERIFY_EMAIL_SUCCESS:
      return resendVerifyEmailHandlers.success(state, action);
    case actionTypes.AUTH_RESEND_VERIFY_EMAIL_CLEAR:
      return resendVerifyEmailHandlers.clear(state);

    case actionTypes.AUTH_GET_USER_SUBSCRIPTION_REQUESTED:
      return userSubscriptionHandlers.requested(state);
    case actionTypes.AUTH_GET_USER_SUBSCRIPTION_FAILED:
      return userSubscriptionHandlers.failed(state, action);
    case actionTypes.AUTH_GET_USER_SUBSCRIPTION_SUCCESS:
      return userSubscriptionHandlers.success(state, action);
    case actionTypes.AUTH_GET_USER_SUBSCRIPTION_CLEAR:
      return userSubscriptionHandlers.clear(state);

    default:
      return state;
  }
};

export default userReducer;
