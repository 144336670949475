import { AnyAction, ActionCreator } from "redux";
import { GetPaginated } from "./types";
import * as actionTypes from "./actionTypes";

export const getArticles = (): AnyAction => ({
  type: actionTypes.ARTICLES_GET_ARTICLES,
});

export const getPaginated: ActionCreator<GetPaginated> = (page: number) => ({
  type: actionTypes.ARTICLES_GET_ARTICLES_PAGINATED,
  page,
});
