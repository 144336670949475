import { AnyAction } from "redux";
import { ArticleInterface } from "../types";

export const requested = (
  state: ArticleInterface,
  action: AnyAction
): ArticleInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    comment: {
      failed: false,
      requesting: true,
      success: false,
      id: action.payload.parentId,
    },
  },
});

export const success = (
  state: ArticleInterface,
  action: AnyAction
): ArticleInterface => ({
  ...state,
  comments: [...(state.comments || []), action.comment],
  requestStates: {
    ...state.requestStates,
    comment: {
      failed: false,
      requesting: false,
      success: true,
      id: action.payload.parentId,
    },
  },
});

export const failed = (
  state: ArticleInterface,
  action: AnyAction
): ArticleInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    comment: {
      failed: action.error,
      requesting: false,
      success: false,
      id: action.payload.parentId,
    },
  },
});

export const clear = (state: ArticleInterface): ArticleInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    comment: {
      failed: false,
      requesting: false,
      success: false,
      id: null,
    },
  },
});
