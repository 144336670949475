import React from "react";
import { TwireScoreChartContext, ContextType } from "./context";
import { TwireScoreChartProps } from "./types";
import TwireScoreChart from "./TwireScoreChart";

const TwireScoreChartContainer: React.FC<TwireScoreChartProps> = (props) => {
  const contextData: ContextType = {
    data: props.data,
    moreData: props.moreData,
  };

  return (
    <TwireScoreChartContext.Provider value={contextData}>
      <TwireScoreChart />
    </TwireScoreChartContext.Provider>
  );
};

export default TwireScoreChartContainer;
