import {useDispatch, useSelector} from "react-redux";
import {signUp} from "@twire/redux/user/actions";
import {RootReducer} from "@twire/redux/types";
import {useUrl} from "@twire/utility/hooks";
import Register from "./Register";
import {RefisterFormFields} from "./types";
import {t} from "@twire/utility/helpers";
import RecaptchaWrapper from '../../common/RecaptchaWrapper';

const RegisterContainer: React.FC = () => {
    const placeholders: RefisterFormFields = {
        username: t("words.username"),
        password: t("words.password"),
        email: t("words.email"),
        confirmPassword: t("words.passwordConfirmation"),
        tos: false,
        newsLetter: false,
    };
    const dispatch = useDispatch();
    const {
        app: {game},
        user: {requestStates},
    } = useSelector((state: RootReducer) => state);
    const loginUrl = useUrl(`/${game}/login`);

    const onSubmit = async (values: RefisterFormFields, recaptchaToken: string) => {
        dispatch(
            signUp(values.username, values.password, values.email, values.newsLetter, recaptchaToken)
        );
    };

    return (
        <RecaptchaWrapper>
            <Register
                placeholders={placeholders}
                loginUrl={loginUrl}
                onSubmit={onSubmit}
                loading={requestStates.signUp.requesting}
                success={requestStates.signUp.success}
                error={requestStates.signUp.failed}
            />
        </RecaptchaWrapper>
    );
};

export default RegisterContainer;
