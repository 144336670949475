import { RequestStates, Paginated, TransitionStates } from "./types";

export const defaultRequestState: RequestStates = {
  failed: false,
  requesting: false,
  success: false,
};

export const defaultTransitionState: TransitionStates = {
  started: false,
  completed: false,
  time: 0,
};

export const paginatedDefaults: Paginated = {
  page: 1,
  isLastPage: false,
};
