import { Games } from "@twire/utility/constants";
import { setTeamLogo } from "@twire/utility/helpers";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatDataForTable = (platformStats: any, game: Games) => {
  return platformStats.map((data: any) => {
    return {
      data,
      teamName: data.username,
      teamLogo: setTeamLogo(game, data.teamName),
    };
  });
};
