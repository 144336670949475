import { AnyAction } from "redux";
import { ScrimsInterface } from "../types";

export const success = (
  state: ScrimsInterface,
  action: AnyAction
): ScrimsInterface => ({
  ...state,
  scrims: {
    ...state.scrims,
    filter: action.payload.filter,
  },
  requestStates: {
    ...state.requestStates,

    filter: { failed: false, requesting: false, success: true },
  },
});

export const requested = (state: ScrimsInterface): ScrimsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,

    filter: { failed: false, requesting: true, success: false },
  },
});

export const failed = (
  state: ScrimsInterface,
  action: AnyAction
): ScrimsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,

    filter: { failed: action.error, requesting: false, success: false },
  },
});

export const clear = (state: ScrimsInterface): ScrimsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,

    filter: { failed: false, requesting: false, success: false },
  },
});
