import React from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowSpan from "@twire/common/ArrowSpan";
import NewsTile from "@twire/common/NewsTile";
import ComponentError from "@twire/common/ComponentError";
import TopTeams from "@twire/common/TopTeams";
import { ArticleCardData } from "@twire/redux/articles/types";
import { mergeClasses, t } from "@twire/utility/helpers";
import { ReactComponent as Trophy } from "../../../../../assets/svgs/trophy.svg";
import classes from "./NewsGrid.module.scss";
import { URLS } from "@twire/utility/constants";
import FantasyBanner from "@twire/common/FantasyBanner";
import FantasyTournaments from "@twire/common/FantasyTournaments";

const NewsGrid: React.FC<{
  lang: string;
  game: string;
  loading: boolean;
  articles: ArticleCardData[];
  onScroll: () => void;
  infinite: boolean;
  fetching: boolean;
  failed: boolean;
}> = ({
  lang,
  game,
  articles,
  loading,
  onScroll,
  infinite,
  fetching,
  failed,
}) => (
  <div className={classes.container}>
    <div className={classes.left}>
      <h2 className={classes.title}>news & announcements</h2>
      {failed ? (
        <ComponentError
          message={t("sentences.noDataAvailable")}
          svg={Trophy}
          suggestion={t("sentences.comeBackLater")}
          className="h-96 appear"
        />
      ) : (
        <>
          <InfiniteScroll
            dataLength={articles.length}
            next={onScroll}
            hasMore={infinite}
            className={classes.leftInner}
            loader={<></>}
          >
            {(loading ? new Array(15).fill("") : articles).map(
              (data, index) => (
                <React.Fragment key={index}>
                  {loading ? (
                    <div
                      className={mergeClasses(
                        classes.placeholder,
                        "animate-pulse"
                      )}
                    />
                  ) : (
                    <Link to={`/${lang}/${game}/news/${data.id}`}>
                      <NewsTile
                        {...data}
                        short={false}
                        noBar={!(index === 0)}
                        bigDescription={index === 0}
                      />
                    </Link>
                  )}
                </React.Fragment>
              )
            )}
          </InfiniteScroll>
          {articles.length > 0 && fetching && (
            <div className="flex justify-center mt-5">
              <img
                src="/images/animated/loader.gif"
                style={{ height: "30px" }}
                alt="loading"
              />
            </div>
          )}
        </>
      )}
    </div>
    <div className={classes.right}>
      <div className="mb-4">
        <FantasyBanner />
      </div> 
      <FantasyTournaments />
      <div>
        <div className="flex items-center">
          <div className="flex items-center">
            <span className="text-xl bold mr-3 text-twire-light">
              {t("words.topTeams")}
            </span>
          </div>
          <Link
            to={`/${lang}/${game}${URLS.TEAM_RANKING}`}
            className="ml-auto"
          >
            <ArrowSpan>{t("words.viewMore")}</ArrowSpan>
          </Link>
        </div>
        <TopTeams />
      </div>
    </div>
  </div>
);

export default NewsGrid;
