import { useEffect, useState, useCallback, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { useScrollerTable } from "@twire/utility/hooks";
import { showTooltip, hideTooltip } from "@twire/redux/app/actions";
import { getElementYOffset } from "@twire/utility/helpers";
import { formatLeaderboardData } from "../LeaderboardTable/functions";
import LeaderboardStatsPerMatch from "./LeaderboardStatsPerMatch";

const LeaderboardStatsPerMatchContainer: React.FC<{
  fullscreen: boolean;
  toggleFullScreen: () => void;
}> = (rest) => {
  const dispatch = useDispatch();
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const [showResizeButton, setShowResizeButton] = useState(false);
  const {
    app: { game },
    tournament: {
      platformLeaderboard,
      filter: { ruleset },
    },
    app: { showTooltip: shouldShowTooltip },
  } = useSelector((state: RootReducer) => state);

  const { tableRef, containerRef } = useScrollerTable(
    platformLeaderboard?.leaderboard.length || 0
  );

  const onMouseEnterTeamName = (e: React.MouseEvent, players: string[]) => {
    if (players.length > 0) {
      dispatch(
        showTooltip(
          e.pageX,
          getElementYOffset(e.currentTarget) - 22,
          <p style={{ maxWidth: "150px" }}>{players.join(", ")}</p>
        )
      );
    }
  };

  const onMouseLeaveTeamName = () => {
    if (shouldShowTooltip) {
      dispatch(hideTooltip());
    }
  };

  const showResizerFunction = useCallback(() => {
    if (
      (containerRef?.current?.scrollWidth || 0) >
      (containerRef?.current?.clientWidth || 0)
    ) {
      if (!showResizeButton) {
        setShowResizeButton(true);
      }
    } else {
      if (showResizeButton) {
        setShowResizeButton(false);
      }
    }
  }, [showResizeButton]);

  useEffect(() => {
    if (containerRef.current) {
      resizeObserverRef.current = new ResizeObserver(showResizerFunction);
      resizeObserverRef.current.observe(containerRef.current);
    }
  }, [showResizeButton]);

  useEffect(() => {
    return () => {
      if (resizeObserverRef.current && containerRef.current) {
        resizeObserverRef.current.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <LeaderboardStatsPerMatch
      showResizeButton={showResizeButton}
      game={game}
      leaderboard={formatLeaderboardData(platformLeaderboard?.leaderboard)}
      ruleset={ruleset || ""}
      tableRef={tableRef}
      containerRef={containerRef}
      onMouseEnterTeamName={onMouseEnterTeamName}
      onMouseLeaveTeamName={onMouseLeaveTeamName}
      {...rest}
    />
  );
};

export default LeaderboardStatsPerMatchContainer;
