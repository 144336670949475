import { AnyAction } from "redux";
import { SubscriptionsInterface } from "../types";

export const requested = (
  state: SubscriptionsInterface
): SubscriptionsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    subscribeUser: {
      failed: false,
      requesting: true,
      success: false,
    },
  },
});

export const success = (
  state: SubscriptionsInterface
): SubscriptionsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    subscribeUser: {
      failed: false,
      requesting: false,
      success: true,
    },
  },
});

export const failed = (
  state: SubscriptionsInterface,
  action: AnyAction
): SubscriptionsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    subscribeUser: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (
  state: SubscriptionsInterface
): SubscriptionsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    subscribeUser: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
