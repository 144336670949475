import React from "react";
import { StreamEmbedProps, StreamEmbedType } from "./types";

const StreamEmbed: React.FC<StreamEmbedProps> = ({
  type,
  videoId,
}) => (
  type === StreamEmbedType.TWITCH && videoId ?
  <iframe
    src={`https://player.twitch.tv/?channel=${videoId}&parent=${document.location.hostname.replace('www.', '')}&muted=true`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen={true}
    scrolling="no"
    width={'100%'}
  ></iframe>
  :
  type === StreamEmbedType.YOUTUBE && videoId ?
  <iframe
    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen={true}
    scrolling="no"
    width={'100%'}
  ></iframe>
  :
  null
);

export default StreamEmbed;
