import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { TeamInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as teamHandlers from "./handlers/getTeam";
import * as tournamentsHandlers from "./handlers/getTeamTournaments";
import * as matchesHandlers from "./handlers/getTeamMatches";
import * as statisticsHandlers from "./handlers/getTeamStatistics";

const INITIAL_STATE: TeamInterface = {
  team: null,
  tournaments: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  matches: {
    data: [],
    page: 1,
    isLastPage: false,
  },
  statistics: [],
  requestStates: {
    team: { ...defaultRequestState },
    tournaments: { ...defaultRequestState },
    matches: { ...defaultRequestState },
    statistics: { ...defaultRequestState },
  },
};

const teamReducer: Reducer<TeamInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TEAM_GET_TEAM_REQUESTED:
      return teamHandlers.requested(state);
    case actionTypes.TEAM_GET_TEAM_FAILED:
      return teamHandlers.failed(state, action);
    case actionTypes.TEAM_GET_TEAM_SUCCESS:
      return teamHandlers.success(state, action);
    case actionTypes.TEAM_GET_TEAM_CLEAR:
      return teamHandlers.clear(state);

    case actionTypes.TEAM_GET_TOURNAMENTS_REQUESTED:
      return tournamentsHandlers.requested(state);
    case actionTypes.TEAM_GET_TOURNAMENTS_FAILED:
      return tournamentsHandlers.failed(state, action);
    case actionTypes.TEAM_GET_TOURNAMENTS_SUCCESS:
      return tournamentsHandlers.success(state, action);
    case actionTypes.TEAM_GET_TOURNAMENTS_CLEAR:
      return tournamentsHandlers.clear(state);

    case actionTypes.TEAM_GET_MATCHES_REQUESTED:
      return matchesHandlers.requested(state);
    case actionTypes.TEAM_GET_MATCHES_FAILED:
      return matchesHandlers.failed(state, action);
    case actionTypes.TEAM_GET_MATCHES_SUCCESS:
      return matchesHandlers.success(state, action);
    case actionTypes.TEAM_GET_MATCHES_CLEAR:
      return matchesHandlers.clear(state);

    case actionTypes.TEAM_GET_STATISTICS_REQUESTED:
      return statisticsHandlers.requested(state);
    case actionTypes.TEAM_GET_STATISTICS_FAILED:
      return statisticsHandlers.failed(state, action);
    case actionTypes.TEAM_GET_STATISTICS_SUCCESS:
      return statisticsHandlers.success(state, action);
    case actionTypes.TEAM_GET_STATISTICS_CLEAR:
      return statisticsHandlers.clear(state);
    default:
      return state;
  }
};

export default teamReducer;
