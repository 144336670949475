import { all, put, takeLatest, takeEvery, select, call } from "@redux-saga/core/effects";
import { RootReducer } from "@twire/redux/types";

import { formatTournamentData } from "./functions";
import { TimeType } from "./types";
import { filterTournaments, getPaginated } from "./actions";
import * as actionTypes from "./actionTypes";
import { publicQuery } from "../../graphql/requestHelper";
import { getTournaments } from "../../graphql/public/queries";
import { CACHE_TIME } from "../../graphql/cache";

const buildTournamentRequest = (timeType: TimeType, filter = {}) => {
  return function* fetchTournaments() {
    const upperCaseTimeType = timeType.toUpperCase();
    yield put({
      type: (actionTypes as any)[
        `TOURNAMENTS_GET_${upperCaseTimeType}_REQUESTED`
      ],
      payload: { key: timeType },
    });

    const state: RootReducer = yield select((state: RootReducer) => state);

    try {
      const tournaments: Generator<any, any> = yield publicQuery(
        getTournaments,
        {
          game: state.app.game,
          type: "tournament",
          timeType,
          ...state.tournaments.tournaments[timeType].filter,
          // ...state.tournaments.tournaments.filter,
          ...filter,
        },
        CACHE_TIME.MINUTE * 10,
      );

      const formattedTournaments = (tournaments as any).data.getTournaments.map(
        (tournament: any) => formatTournamentData(tournament)
      );

      yield put({
        type: (actionTypes as any)[
          `TOURNAMENTS_GET_${upperCaseTimeType}_SUCCESS`
        ],
        payload: {
          key: timeType,
          tournaments: {
            game: state.app.game,
            data: formattedTournaments,
            page: (filter as any).page || 0,
          },
        },
        paginated: !!(filter as any).page,
      });
    } catch (error) {
      yield put({
        type: (actionTypes as any)[
          `TOURNAMENTS_GET_${upperCaseTimeType}_FAILED`
        ],
        payload: { key: timeType },
        error,
      });
    }
  };
};

const fetchOngoing = buildTournamentRequest("ongoing");
const fetchUpcoming = buildTournamentRequest("upcoming");
const fetchPast = buildTournamentRequest("past");

function* applyFilter({ key, filter }: ReturnType<typeof filterTournaments>) {
  yield put({
    type: actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS_REQUESTED,
    payload: {
      key,
    },
  });
  try {
    yield call(buildTournamentRequest(key, filter));
    yield put({
      type: actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS_SUCCESS,
      payload: {
        filter,
        key,
      },
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS_FAILED,
      payload: {
        key,
      },
    });
  }
}

function* fetchPaginated({ key, page }: ReturnType<typeof getPaginated>) {
  yield call(buildTournamentRequest(key, { page }));
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.TOURNAMENTS_GET_ONGOING, fetchOngoing),
    takeLatest(actionTypes.TOURNAMENTS_GET_PAST, fetchPast),
    takeLatest(actionTypes.TOURNAMENTS_GET_UPCOMING, fetchUpcoming),
    takeLatest(actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS, applyFilter),
    takeEvery(actionTypes.TOURNAMENTS_GET_PAGINATED, fetchPaginated),
  ]);
}
