import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootReducer } from "./redux/types";
import { SCROLL_TO_TOP_URL_LENGTH } from "./constants";
import { useLanguageChange } from "./utility/hooks";

export const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();
  const [ lastPath, setLastPath ] = useState('');
  const lang = useLanguageChange();
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  /**
   * Determines if the page nees to be stolled to the top
   * 
   * @param currentPath 
   * @param lastPath 
   * @returns 
   */
  const shouldScrollToTop = (currentPath: string, lastPath: string): boolean => {
    const currentPathSplit = currentPath.split("/");
    const lastPathSplit = lastPath.split("/");
    const scrollToTheTopLenght = SCROLL_TO_TOP_URL_LENGTH[`/${currentPathSplit[1]}`] || 1;
    for (let i=0; i < scrollToTheTopLenght + 1; i++) {
      if (currentPathSplit[i] !== lastPathSplit[i]) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const currentPath = location.pathname.replace(`/${lang}/${game}`, "");
    if (shouldScrollToTop(currentPath, lastPath)) {
      window.scrollTo(0, 0);
    }
    setLastPath(currentPath);
  }, [location.pathname]);

  return <>{ children }</>;
};