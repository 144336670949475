import { AnyAction } from "redux";
import { ArticleInterface } from "../types";

export const requested = (
  state: ArticleInterface
): ArticleInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    article: {
      failed: false,
      requesting: true,
      success: false,
    },
  },
});

export const success = (
  state: ArticleInterface,
  action: AnyAction
): ArticleInterface => ({
  ...state,
  ...action.article,
  requestStates: {
    ...state.requestStates,
    article: {
      failed: false,
      requesting: false,
      success: true,
    },
  },
});

export const failed = (
  state: ArticleInterface,
  action: AnyAction
): ArticleInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    article: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (
  state: ArticleInterface
): ArticleInterface => ({
  ...state,
  game: null,
  author: '',
  comments: [],
  content: '',
  id: null,
  insertedAt: null,
  summary: '',
  summaryImg: '',
  title: '',
  updatedAt: null,
  numberOfComments: 0,
  requestStates: {
    ...state.requestStates,
    article: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
