import { ActionCreator, AnyAction } from "redux";
import { GetMatchesPaginated, TournamentFilter, TournamentStatsFilters } from "./types";
import * as actionTypes from "./actionTypes";

export const getTournament = (id: string): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_TOURNAMENT,
  id,
});

export const filterTournament = (filter: TournamentFilter): AnyAction => ({
  type: actionTypes.TOURNAMENT_SET_FILTER,
  filter,
});

export const getLeaderboard = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_LEADERBOARD,
});

export const getMatches: ActionCreator<GetMatchesPaginated> = (
  page: number
) => ({
  type: actionTypes.TOURNAMENT_GET_MATCHES,
  page,
});

export const getTeamStats = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_TEAM_STATS,
});

export const getPlatformStats = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_PLATFORM_STATS,
});

export const getStatsFilters = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_STATS_FILTERS,
});

export const filterStats = (filters: TournamentStatsFilters): AnyAction => ({
  type: actionTypes.TOURNAMENT_SET_STATS_FILTER,
  filters,
});

export const clearLeaderboard = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_LEADERBOARD_CLEAR,
});

export const clearMatches = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_MATCHES_CLEAR,
});

export const clearTournament = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_TOURNAMENT_CLEAR,
});

export const clearFilters = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_SET_FILTER_CLEAR,
});

export const clearTeamStats = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_TEAM_STATS_CLEAR,
});

export const clearPlatformStats = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_GET_PLATFORM_STATS_CLEAR,
});

export const clearStatsFilters = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_SET_STATS_FILTER_CLEAR,
});

export const unsetStatsFilters = (): AnyAction => ({
  type: actionTypes.TOURNAMENT_UNSET_STATS_FILTER,
});
