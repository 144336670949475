import React from "react";
import classes from "./RankingTile.module.scss";
// import ArrowSpan from "@twire/common/ArrowSpan";
import {
  mergeClasses,
  setTeamLogo,
  setDefaultTeamLogo,
} from "@twire/utility/helpers";
// import { ReactComponent as ArrowTip } from "../../../assets/svgs/arrowTip.svg";
import { ReactComponent as Crown } from "../../../assets/svgs/crown.svg";
import { RankingTileViewProps } from "./types";

const RankingTile: React.FC<RankingTileViewProps> = ({
  theme,
  rank,
  team,
  points,
  game,
}) => (
  <div
    className={mergeClasses(
      classes.container,
      theme ? `rank-tile-${theme?.toLowerCase()}` : ""
    )}
  >
    <div className={classes.first}>
      <div
        className={mergeClasses(
          classes.pos,
          theme ? `text-twire-${theme?.toLowerCase()}` : "text-twire-light"
        )}
      >
        <span className={mergeClasses([!theme, "opacity-30"])}>#{rank}</span>
        {/* <div className={mergeClasses(classes.diff, [true, classes.up])}>
          <div>
            <ArrowTip />
          </div>
          <div>-1</div>
        </div> */}
      </div>
      <img
        src={setTeamLogo(game, team)}
        alt="team"
        className={classes.teamLogo}
        onError={setDefaultTeamLogo}
      />
      <div className={classes.team}>{team}</div>
    </div>
    <div className={classes.second}>
      <div className={classes.points}>
        <Crown />
        {points} { isNaN(parseInt(points)) ? '' : 'PTS'}
      </div>
      {/* <div className={classes.region}>
        <AimSvg />
        Europe
      </div> */}
      {/* <div className={classes.arrowSpanWrapper}>
        <ArrowSpan>View team</ArrowSpan>
      </div> */}
    </div>
  </div>
);

export default RankingTile;
