import React from "react";
import classes from "./Placeholder.module.scss";
import mainClasses from "../../LastMatchTile/LastMatchTile.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const Placeholder: React.FC = () => (
  <div className={mergeClasses(classes.container, mainClasses.container)}>
    <div className={classes.top}>
      <div></div>
      <div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div className={classes.bottom} />
  </div>
);

export default Placeholder;
