import React from "react";
import TimeAgo from "timeago-react";
import TwireLink from "@twire/common/TwireLink";
import TextArea from "@twire/common/TextArea";
import TwireButton from "@twire/common/TwireButton";
import { CommentViewProps } from "./types";
import CommentsContainer from ".";
import classes from "./Comment.module.scss";

const Comment: React.FC<CommentViewProps> = (props) => (
  <>
    <div className={classes.container}>
      <div className="flex mb-1">
        <div className="font-bold text-twire-light">{props.username}</div>
        <div className="ml-1 opacity-30 font-bold text-twire-light">
          <TimeAgo datetime={props.updatedAt} />
        </div>
      </div>
      <p className="open-sans opacity-70 text-twire-light text-sm mb-2">
        {props.content}
      </p>
      {props.mode === "REPLY" && (
        <>
          <div className="relative">
            {!props.signedIn && (
              <div className={classes.mask}>
                You have to{" "}
                <TwireLink to="/login" className="mx-1 text-twire-orange">
                  login
                </TwireLink>{" "}
                or{" "}
                <TwireLink to="/register" className="mx-1 text-twire-orange">
                  register
                </TwireLink>{" "}
                to reply comments.
              </div>
            )}
            {props.error && (
              <div className="error-message text-twire-orange mb-3 text-sm">
                Failed to post reply
              </div>
            )}
            <TextArea
              instance={props.instance}
              placeholder="Reply to this comment"
            />
            <div className={classes.replyActions}>
              <TwireButton
                onClick={props.postReply}
                loading={props.postingReply}
                disabled={props.postingReply}
              >
                post
              </TwireButton>
              <TwireButton
                alternate
                className="ml-3"
                onClick={props.onCancelClick}
              >
                cancel
              </TwireButton>
            </div>
          </div>
        </>
      )}
      <div className="flex">
        {props.mode === "VIEW" && (
          <button
            onClick={() => {
              props.setMode("REPLY");
            }}
            className="font-bold text-twire-orange underline text-sm"
          >
            reply
          </button>
        )}
        {props.comments && props.mode === "VIEW" && props.comments.length > 0 && (
          <div className="ml-2 opacity-30 font-bold text-twire-light text-sm">
            {props.comments.length}{" "}
            {props.comments.length === 1 ? "reply" : "replies"}
          </div>
        )}
      </div>
    </div>
    <div className="ml-3 mt-3">
      {props.iteration < 5 &&
        props.comments?.map((comment) => (
          <React.Fragment key={comment.id}>
            <CommentsContainer
              comments={props.allComments?.filter(
                (c) => c.parentId === comment.id
              )}
              allComments={props.allComments}
              iteration={props.iteration + 1}
              {...comment}
            />
          </React.Fragment>
        ))}
    </div>
  </>
);

export default Comment;
