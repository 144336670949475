import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import StreamEmbed from "./StreamEmbed";
import { StreamEmbedType } from "./types";
import { Tournament } from "../../../PublicAPI";
import { tournamentStreamView } from "@twire/redux/track/userActions/userActions";
import { saveUserAction } from "@twire/redux/track/actions";

const StreamEmbedContainer: React.FC = () => {
  const {
    tournament: {
      tournament,
      requestStates,
    },
  } = useSelector((state: RootReducer) => state);

  const [streamType, setStreamType] = useState<StreamEmbedType | null>(null);
  const [videoId, setVideoId] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestStates.tournament.success && tournament && tournament.live) {
      setStreamVideo(tournament);
      dispatch(saveUserAction(tournamentStreamView(tournament.friendlyName)));
    }
  }, [tournament]);

  const setStreamVideo = (tournament: Tournament): void => {
    const twitch = tournament.tournamentSocials?.find(s => s?.type === "twitch");
    if (twitch) {
      setStreamType(StreamEmbedType.TWITCH);
      setVideoId(twitch?.videoId || "");
      return;
    }
    const youtube = tournament.tournamentSocials?.find(s => s?.type === "youtube");
    if (youtube) {
      setStreamType(StreamEmbedType.YOUTUBE);
      setVideoId(youtube?.videoId || "");
      return;
    }
    setStreamType(null);
    setVideoId("");
  }

  return <StreamEmbed
    type={streamType}
    videoId={videoId}
  />;
};

export default StreamEmbedContainer;
