import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
// import TournamentsTile from "@twire/common/TournamentTile";
import TeamsTile from "../../../../common/Search/Teams/TeamsTile";
import { useGetTeamUrl } from "@twire/utility/hooks";
import LineText from "@twire/common/LineText";
import Text from "@twire/common/Text";
import classes from "./TeamsTile.module.scss";

const Teams: React.FC = () => {
  const {
    search: { teams },
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const [reveal, setReveal] = useState(false);
  const getTeamUrl = useCallback(useGetTeamUrl(), []);

  return teams && teams?.length > 0 ? (
    <div>
      <p className="rift-bold text-twire-light my-4">
        <Text i18nKey="words.teams">teams</Text>
      </p>
      <div className={classes.resultsGrid}>
        {(reveal ? teams : teams.slice(0, 4))?.map((data, i) => (
          <Link to={getTeamUrl(data.id, data.name)} key={i}>
            <TeamsTile team={data} game={game} long />
          </Link>
        ))}
      </div>
      <LineText className="mt-3">
        <button
          className="whitespace-nowrap"
          onClick={() => {
            setReveal(!reveal);
          }}
        >
          {reveal ? "Show Less" : "Show More"}
        </button>
      </LineText>
    </div>
  ) : (
    <></>
  );
};

export default Teams;
