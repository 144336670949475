import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import StreamEmbed from "@twire/common/StreamEmbed/StreamEmbed";
import { StreamEmbedType } from "@twire/common/StreamEmbed/types";

const HomeStreamEmbed: React.FC = () => {
  const [videoId, setVideoId] = useState("");
  const [videoType, setVideoType] = useState<StreamEmbedType>(StreamEmbedType.TWITCH);

  const {
    featured: { featured, requestStates }
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    if (requestStates.featured.success && featured && featured.length) {
      const tournament = featured.find(
        f => f.tournamentSocials.find(s => s.videoId && f.live)
      );
      if (tournament) {
        const social = tournament.tournamentSocials.find(s => s.videoId);
        if (social) {
          setVideoId(social?.videoId || "");
          setVideoType(social.type === "youtube" ? StreamEmbedType.YOUTUBE : StreamEmbedType.TWITCH);
        }
      }
    }
  }, [requestStates, featured]);

  return <StreamEmbed type={videoType} videoId={videoId} />;
};

export default HomeStreamEmbed;
