import {
  t,
  formatTime,
  mergeClasses,
  urlFriendlyString,
} from "@twire/utility/helpers";
import { TileProps } from "./types";
import React from "react";
import classes from "./FantasyTournamentTile.module.scss";
import ArrowSpan from "@twire/common/ArrowSpan";

import { ReactComponent as PadLock } from "../../../assets/svgs/padlock.svg";

const FantasyTournamentTile: React.FC<TileProps> = ({
  tournament
}) => (
  <div
    className={mergeClasses(
      classes.container,
    )}
  >
    <img
      src={`https://twire-assets.s3.eu-west-1.amazonaws.com/fantasy/tournament/logo/${tournament.logo || "default.png"}`}
      alt={tournament.name}
      className={classes.teamImage}
      onError={(e: any) => e.target.src = "https://twire-assets.s3.eu-west-1.amazonaws.com/fantasy/tournament/logo/default.png"}
    />
    <p className="text-twire-light truncate">{tournament.name}</p>
    <div className={mergeClasses(classes.colInner)}>
      <div className={mergeClasses("flex", classes.tournamentDetail)}>
        <div className={mergeClasses(classes.lockDate, "flex items-center")}>
          <PadLock />
          <span className="ml-2 text-twire-light open-sans capitalize">
            {
              tournament.pickPhase?.endDate ? formatTime(tournament.pickPhase?.endDate) : ""
            }
            {" "}
          </span>
        </div>
        <div className={mergeClasses(classes.playNow, "flex items-center")}>
          <a
            href={`https://fantasy.twire.gg/en/tournament/${tournament.id}/${urlFriendlyString(tournament.name)}/my-team`}
            target="_blank"
            rel="noreferrer"
            className={mergeClasses(classes.blue, "ml-auto")}
          >
            <ArrowSpan>{t("words.playNow")}</ArrowSpan>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default FantasyTournamentTile;
