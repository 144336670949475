import Cookies from "js-cookie";
import { Reducer } from "redux";
import { Games, validGamesArray } from "@twire/utility/constants";
import { defaultRequestState } from "../constants";
import { AppInterface } from "./types";
import * as actionTypes from "./actionTypes";

/**
 *
 * @returns Returns selected game
 */
const getSelectedGame = (): Games => {
  const locationUrl = window.location.href;
  const gameInUrl = validGamesArray.reduce((a, b) => {
    if (!a) {
      if (locationUrl.includes(`/${b}/`)) {
        a = b;
      }
    }
    return a;
  }, "");
  if (gameInUrl) {
    Cookies.set("selected-game", gameInUrl);
    return gameInUrl as Games;
  }
  const gameInCookie = Cookies.get("selected-game");
  return gameInCookie ? (gameInCookie as Games) : Games.PUBG;
};

const INITIAL_STATE: AppInterface = {
  game: getSelectedGame(),
  banner: null,
  changingLanguage: null,
  hidingFullPageLoadingOverlay: 0,
  displayFullPageLoadingOverlay: "",
  showTooltip: null,
  modalElement: null,
  requestStates: {
    changingLanguage: { ...defaultRequestState },
  },
};

const appReducer: Reducer<AppInterface> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_GAME:
      return { ...state, game: action.payload.game };
    case actionTypes.SET_BANNER:
      return { ...state, banner: action.banner };
    case actionTypes.CLEAR_BANNER:
      return { ...state, banner: null };
    case actionTypes.DISPLAY_FULL_PAGE_LOADING_OVERLAY:
      return { ...state, displayFullPageLoadingOverlay: action.payload.text };
    case actionTypes.HIDE_FULL_PAGE_LOADING_OVERLAY_STARTED:
      return { ...state, hidingFullPageLoadingOverlay: action.time };
    case actionTypes.HIDE_FULL_PAGE_LOADING_OVERLAY_COMPLETED:
      return {
        ...state,
        displayFullPageLoadingOverlay: "",
        hidingFullPageLoadingOverlay: 0,
      };
    case actionTypes.CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        changingLanguage: {
          currLang: action.payload.currLang,
          targetLang: action.payload.targetLang,
        },
        requestStates: {
          ...state.requestStates,
          changingLanguage: {
            success: true,
            failed: false,
            requesting: false,
          },
        },
      };
    case actionTypes.CHANGE_LANGUAGE_FAILED:
      return {
        ...state,
        changingLanguage: null,
        requestStates: {
          ...state.requestStates,
          changingLanguage: {
            success: false,
            failed: true,
            requesting: false,
          },
        },
      };
    case actionTypes.CHANGE_LANGUAGE_REQUESTED:
      return {
        ...state,
        changingLanguage: null,
        requestStates: {
          ...state.requestStates,
          changingLanguage: {
            success: false,
            failed: false,
            requesting: true,
          },
        },
      };
    case actionTypes.SHOW_GLOBAL_TOOL_TIP:
      return {
        ...state,
        showTooltip: {
          x: action.x,
          y: action.y,
          value: action.value,
        },
      };
    case actionTypes.HIDE_TOOLTIP:
      return {
        ...state,
        showTooltip: null,
      };
    case actionTypes.SET_MODAL_ELEMENT:
      return {
        ...state,
        modalElement: action.element,
      };
    case actionTypes.HIDE_MODAL_ELEMENT:
      return {
        ...state,
        modalElement: null,
      };
    // case actionTypes.SHOW_GLOBAL_TOOL_TIP_STARTED:
    //   return {
    //     ...state,
    //     showTooltip: {
    //       ...state.showTooltip,
    //       started: {
    //         x: action.payload.x,
    //         y: action.payload.y,
    //         time: action.payload.time,
    //       },
    //       value: action.payload.value,
    //     },
    //   };
    // case actionTypes.SHOW_GLOBAL_TOOL_TIP_COMPLETED:
    //   return {
    //     ...state,
    //     showTooltip: {
    //       ...state.showTooltip,
    //       started: {
    //         x: action.payload.x,
    //         y: action.payload.y,
    //         time: action.payload.time,
    //       },
    //       value: action.payload.value,
    //     },
    //   };
    default:
      return state;
  }
};

export default appReducer;
