import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUserAction } from "@twire/redux/track/actions";
import { homePageView } from "@twire/redux/track/userActions/userActions";
import { RootReducer } from "@twire/redux/types";
import { useLanguageChange } from "@twire/utility/hooks";

import Home from "./Home";
import { clearBanner } from "@twire/redux/app/actions";

const HomeContainer: React.FC = () => {
  const dispatch = useDispatch();
  const lang = useLanguageChange();

  const {
    app: { game },
    tournaments: { tournaments },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    dispatch(saveUserAction(homePageView()));
    // dispatch(
    //   setBanner({
    //     node: <p
    //       className={mergeClasses(
    //         "open-sans flex items-center justify-center text-sm"
    //       )}
    //     >
    //       Our system currently has problem processing new subscriptions. We are aware of the issue and are fixing it.
    //     </p>,
    //     type: BannerTypes.ERROR,
    //   }
    //   )
    // );
    return () => {
      dispatch(clearBanner());
    };
  }, []);

  return <Home lang={lang} game={game} ongoingTournaments={tournaments.ongoing} />;
};

export default HomeContainer;
