import { all, put, takeLatest, select, call } from "@redux-saga/core/effects";
import { RootReducer } from "@twire/redux/types";

import { filterScrims, getPaginated } from "./actions";
import * as actionTypes from "./actionTypes";
import { publicQuery } from "../../graphql/requestHelper";
import { getTournaments } from "../../graphql/public/queries";
import { formatTournamentData } from "../tournaments/functions";
import { CACHE_TIME } from "../../graphql/cache";

const buildScrimsRequest = (filter = {}) => {
  return function* buildScrims() {
    yield put({
      type: actionTypes.SCRIMS_GET_SCRIMS_REQUESTED,
      payload: {},
    });

    const state: RootReducer = yield select((state: RootReducer) => state);

    try {
      const scrims: Generator<any, any> = yield publicQuery(
        getTournaments,
        {
          game: state.app.game,
          type: "scrim",
          ...state.scrims.scrims.filter,
          // ...state.tournaments.tournaments.filter,
          ...filter,
        },
        CACHE_TIME.MINUTE * 10,
      );

      const formattedScrims = (scrims as any).data.getTournaments.map(
        (tournament: any) => formatTournamentData(tournament)
      );

      yield put({
        type: actionTypes.SCRIMS_GET_SCRIMS_SUCCESS,
        payload: {
          scrims: {
            game: state.app.game,
            data: formattedScrims,
            page: (filter as any).page || 0,
          },
        },
        paginated: !!(filter as any).page,
      });
    } catch (error) {
      yield put({
        type: actionTypes.SCRIMS_GET_SCRIMS_FAILED,
        payload: {},
        error,
      });
    }
  };
};

function* applyFilter({ filter }: ReturnType<typeof filterScrims>) {
  yield put({
    type: actionTypes.SCRIMS_FILTER_SCRIMS_REQUESTED,
    payload: {},
  });
  try {
    yield call(buildScrimsRequest(filter));
    yield put({
      type: actionTypes.SCRIMS_FILTER_SCRIMS_SUCCESS,
      payload: {
        filter,
      },
    });
  } catch (error) {
    yield put({
      type: actionTypes.SCRIMS_FILTER_SCRIMS_FAILED,
      payload: {},
    });
  }
}

function* fetchPaginated({ page }: ReturnType<typeof getPaginated>) {
  yield call(buildScrimsRequest({ page }));
}

const fetchScrims = buildScrimsRequest();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.SCRIMS_GET_SCRIMS, fetchScrims),
    takeLatest(actionTypes.SCRIMS_FILTER_SCRIMS, applyFilter),
    takeLatest(actionTypes.SCRIMS_GET_SCRIMS_PAGINATED, fetchPaginated),
  ]);
}
