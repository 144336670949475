import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import {
  getElementYOffset,
  mergeClasses,
  urlFriendlyString,
} from "@twire/utility/helpers";
import { saveUserAction } from "@twire/redux/track/actions";
import { showTooltip, hideTooltip } from "@twire/redux/app/actions";
import { tournamentLeaderboardView } from "@twire/redux/track/userActions/userActions";
import { useTournament } from "../../hooks";

import { formatLeaderboardData, getHeader } from "./functions";
// import { formatToHeaderMap } from "./constants";
import LeaderboardTable from "./LeaderboardTable";
import classes from "./LeaderboardTable.module.scss";

const LeaderboardTableContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedRound, selectedGroup } = useTournament();

  const {
    tournament: {
      tournament,
      platformLeaderboard,
      filter: { ruleset },
    },
    app: { game, showTooltip: shouldShowTooltip },
  } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    dispatch(
      saveUserAction(
        tournamentLeaderboardView(
          tournament?.friendlyName,
          tournament?.eventType,
          `${selectedRound?.name} ${selectedGroup?.name}`,
          tournament?.region
        )
      )
    );
  }, [tournament]);

  const onMouseEnterTeamName = (e: React.MouseEvent, players: string[]) => {
    if (players.length > 0) {
      dispatch(
        showTooltip(
          e.pageX,
          getElementYOffset(e.currentTarget) - 22,
          <p style={{ maxWidth: "150px" }}>{players.join(", ")}</p>
        )
      );
    }
  };

  const onMouseLeaveTeamName = () => {
    if (shouldShowTooltip) {
      dispatch(hideTooltip());
    }
  };

  const getTeamUrl = (id: number, teamName: string): string => {
    // TODO: check
    // return useUrl(`/${game}/team/${id}/${urlFriendlyString(teamName)}`);
    return `/${game}/team/${id}/${urlFriendlyString(teamName)}`;
  };

  return (
    <div>
      <div className={mergeClasses(classes.desktop)}>
        <LeaderboardTable
          leaderboard={
            platformLeaderboard
              ? formatLeaderboardData(platformLeaderboard?.leaderboard).slice(
                  0,
                  Math.ceil(platformLeaderboard?.leaderboard.length / 2)
                )
              : []
          }
          headers={getHeader(platformLeaderboard?.format)}
          game={game}
          onMouseEnterTeamName={onMouseEnterTeamName}
          onMouseLeaveTeamName={onMouseLeaveTeamName}
          getTeamUrl={getTeamUrl}
          ruleset={ruleset}
          started={platformLeaderboard?.numberOfMatches !== 0}
          numberOfAdvanceTeams={selectedGroup?.advance}
        />
        <LeaderboardTable
          leaderboard={
            platformLeaderboard
              ? formatLeaderboardData(platformLeaderboard?.leaderboard).slice(
                  Math.ceil(platformLeaderboard?.leaderboard.length / 2)
                )
              : []
          }
          indexStart={Math.ceil(
            (platformLeaderboard?.leaderboard.length || 0) / 2
          )}
          headers={getHeader(platformLeaderboard?.format)}
          game={game}
          onMouseEnterTeamName={onMouseEnterTeamName}
          onMouseLeaveTeamName={onMouseLeaveTeamName}
          getTeamUrl={getTeamUrl}
          ruleset={ruleset}
          started={platformLeaderboard?.numberOfMatches !== 0}
          numberOfAdvanceTeams={selectedGroup?.advance}
        />
      </div>
      <div className={classes.tab}>
        <LeaderboardTable
          leaderboard={
            platformLeaderboard
              ? formatLeaderboardData(platformLeaderboard?.leaderboard)
              : []
          }
          headers={getHeader(platformLeaderboard?.format)}
          game={game}
          onMouseEnterTeamName={onMouseEnterTeamName}
          onMouseLeaveTeamName={onMouseLeaveTeamName}
          getTeamUrl={getTeamUrl}
          ruleset={ruleset}
          started={platformLeaderboard?.numberOfMatches !== 0}
          numberOfAdvanceTeams={selectedGroup?.advance}
        />
      </div>
    </div>
  );
};

export default LeaderboardTableContainer;
