import { URLS, ROOT_URLS } from "./utility/constants";

/**
 * Determines how many parts of the URL
 * after the language and game need to be
 * checked in order to determine scroll
 * to top trigger. If URL is not in this
 * object, scroll needs to be triggered
 * always.
 */
export const SCROLL_TO_TOP_URL_LENGTH = {
  [URLS.NEWS]: 2,
  [URLS.TOURNAMENTS]: 3,
  [ROOT_URLS.FANTANSY]: 3,
};