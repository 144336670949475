/* eslint-disable @typescript-eslint/no-this-alias */
export class TrieNode<T> {
  map: Map<string, TrieNode<T>>;
  constructor(public value: string, public endOfWord?: { payload: T }) {
    this.map = new Map();
  }

  add(value: string, endOfWord?: { payload: T }): TrieNode<T> {
    if (!this.map.has(value)) {
      this.map.set(value, new TrieNode(value, endOfWord));
    } else {
      const val = this.map.get(value);
      if (val && endOfWord !== val.endOfWord) {
        val.endOfWord = endOfWord;
      }
    }
    return this.map.get(value) as TrieNode<T>;
  }
}

export class Trie<T> {
  root: { [key: string]: T; } = {};
  search(value: string): T[] {
    const result: T[] = [];
    Object.keys(this.root).forEach(v => {
      if (v.toLowerCase().includes(value.toLowerCase())) {
        result.push(this.root[v]);
      }
    })
    return result;
  }
  insert(word: string, element: T): void {
    this.root[word] = element;
  }
}
