import { AnyAction } from "redux";
import { PgcPointsInterface } from "../types";

export const requested = (state: PgcPointsInterface): PgcPointsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    regions: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: PgcPointsInterface,
  action: AnyAction
): PgcPointsInterface => ({
  ...state,
  regions: action.regions,
  requestStates: {
    ...state.requestStates,
    regions: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: PgcPointsInterface,
  action: AnyAction
): PgcPointsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    regions: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: PgcPointsInterface): PgcPointsInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    regions: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
