import * as Yup from "yup";
// import { getSchema } from "@twire/utility/helpers";
import { TFunction } from "i18next";

const getSchema = (t: TFunction) => ({
  username: Yup.string()
    .min(3, t("sentences.shortUsername"))
    .max(30, t("sentences.longUsername"))
    .required(t("sentences.requiredUsername")),
  email: Yup.string().email(t("sentences.invalidEmail")),

  password: Yup.string()
    .min(8, t("sentences.shortPassword"))
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d](.){7,}$/, t("sentences.shortPassword"))
    .required(t("sentences.requiredPassword")),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    t("sentences.passwordsDontMatch")
  ),
  tos: Yup.bool().oneOf([true], t("sentences.tosRequired")),
  newsLetter: Yup.bool(),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const regFormSchema = (t: TFunction) =>
  Yup.object().shape({
    username: getSchema(t).username,
    email: getSchema(t).email,
    password: getSchema(t).password,
    confirmPassword: getSchema(t).confirmPassword,
    tos: getSchema(t).tos,
    newsLetter: getSchema(t).newsLetter,
  });

export const initialValues = {
  username: "",
  password: "",
  email: "",
  confirmPassword: "",
  tos: false,
  newsLetter: false,
};
