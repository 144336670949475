import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTeamRanking } from "@twire/redux/teamRanking/actions";
import { RootReducer } from "@twire/redux/types";
import { saveUserAction } from "@twire/redux/track/actions";
import { rankingTeamPageView } from "@twire/redux/track/userActions/userActions";
import Ranking from "./TeamRanking";
import { useGetTeamUrl } from "../../../../utility/hooks";

const ComponentContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    teamRanking: { teams, updatedAt, requestStates },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const getTeamUrl = useGetTeamUrl();

  useEffect(() => {
    dispatch(getTeamRanking());
    dispatch(saveUserAction(rankingTeamPageView("global")));
  }, [game]);

  return (
    <Ranking
      topTeams={teams.slice(0, 100)}
      loading={requestStates.teams.requesting && teams.length === 0}
      updatedAt={updatedAt}
      showError={
        requestStates.teams.failed &&
        teams.length === 0 &&
        !requestStates.teams.requesting
      }
      game={game}
      getTeamUrl={getTeamUrl}
    />
  );
};

export default ComponentContainer;
