import { all, put, takeLatest, select } from "@redux-saga/core/effects";
import { RootReducer } from "@twire/redux/types";
import { formatTournamentFilter, getGeneralTournamentFormat } from "@twire/utility/helpers";
import * as actionTypes from "./actionTypes";
import { getMatches, getTournament } from "./actions";

import { publicQuery } from "../../graphql/requestHelper";
import {
  tournamentById,
  platformLeaderboard,
  matchByTournament,
  teamStats,
  platformStats,
  tournamentStatsFilters,
} from "../../graphql/public/queries";
import { formatTournamentStatsFilters } from "./functions";
import { CACHE_TIME } from "../../graphql/cache";

function* fetchTournament({ id }: ReturnType<typeof getTournament>) {
  yield put({ type: actionTypes.TOURNAMENT_GET_TOURNAMENT_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const tournament: Generator<any, any> = yield publicQuery(
      tournamentById,
      {
        game: state.app.game,
        id,
      },
      CACHE_TIME.MINUTE * 3,
    );

    yield put({
      type: actionTypes.TOURNAMENT_GET_TOURNAMENT_SUCCESS,
      tournament: (tournament as any).data.tournamentById,
    });
  } catch (error) {
    yield put({ type: actionTypes.TOURNAMENT_GET_TOURNAMENT_FAILED, error });
  }
}

function* fetchLeaderboard() {
  yield put({ type: actionTypes.TOURNAMENT_GET_LEADERBOARD_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const tournament: Generator<any, any> = yield publicQuery(
      platformLeaderboard,
      {
        tournament: formatTournamentFilter(
          state.tournament.filter.shard,
          state.tournament.filter.parent,
          state.tournament.filter.child
        ),
        token: state.tournament.token,
        game: state.app.game,
      },
      state.tournament.token ? 0 : CACHE_TIME.MINUTE * 3,
    );

    const leaderboard = (tournament as any).data.platformLeaderboard;
    yield put({
      type: actionTypes.TOURNAMENT_GET_LEADERBOARD_SUCCESS,
      platformLeaderboard: {
        ...leaderboard,
        format: getGeneralTournamentFormat(leaderboard.format),
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.TOURNAMENT_GET_LEADERBOARD_FAILED, error });
  }
}

function* fetchMatches({ page }: ReturnType<typeof getMatches>) {
  if (page === 1) {
    yield put({ type: actionTypes.TOURNAMENT_GET_MATCHES_REQUESTED });
  }

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const tournament: Generator<any, any> = yield publicQuery(
      matchByTournament,
      {
        shardInfo: formatTournamentFilter(
          state.tournament.filter.shard,
          state.tournament.filter.parent,
          state.tournament.filter.child
        ),
        token: state.tournament.token,
        game: state.app.game,
        page: page,
      },
      state.tournament.token ? 0 : CACHE_TIME.MINUTE * 3,
    );

    yield put({
      type: actionTypes.TOURNAMENT_GET_MATCHES_SUCCESS,
      matchByTournament: (tournament as any).data.matchByTournament,
      page: page,
    });
  } catch (error) {
    yield put({ type: actionTypes.TOURNAMENT_GET_MATCHES_FAILED, error });
  }
}

function* fetchTeamStats() {
  yield put({ type: actionTypes.TOURNAMENT_GET_TEAM_STATS_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const tournament: Generator<any, any> = yield publicQuery(
      teamStats,
      {
        shardInfo: formatTournamentFilter(
          state.tournament.filter.shard,
          state.tournament.filter.parent,
          state.tournament.filter.child
        ),
        token: state.tournament.token,
        filters: formatTournamentStatsFilters(
          state.tournament.statsFilters
        ),
        game: state.app.game,
      },
      state.tournament.token ? 0 : CACHE_TIME.MINUTE * 3,
    );

    yield put({
      type: actionTypes.TOURNAMENT_GET_TEAM_STATS_SUCCESSFUL,
      teamStats: (tournament as any).data.teamStats,
    });
  } catch (error) {
    yield put({ type: actionTypes.TOURNAMENT_GET_TEAM_STATS_FAILED, error });
  }
}

function* fetchPlatformmStats() {
  yield put({ type: actionTypes.TOURNAMENT_GET_PLATFORM_STATS_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const tournament: Generator<any, any> = yield publicQuery(
      platformStats,
      {
        tournament: formatTournamentFilter(
          state.tournament.filter.shard,
          state.tournament.filter.parent,
          state.tournament.filter.child
        ),
        token: state.tournament.token,
        filters: formatTournamentStatsFilters(
          state.tournament.statsFilters
        ),
        game: state.app.game,
      },
      state.tournament.token ? 0 : CACHE_TIME.MINUTE * 3,
    );

    yield put({
      type: actionTypes.TOURNAMENT_GET_PLATFORM_STATS_SUCCESSFUL,
      platformStats: (tournament as any).data.platformStats,
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOURNAMENT_GET_PLATFORM_STATS_FAILED,
      error,
    });
  }
}

function* fetchStatsFilters() {
  yield put({ type: actionTypes.TOURNAMENT_GET_STATS_FILTERS_REQUESTED });

  const state: RootReducer = yield select((state: RootReducer) => state);

  try {
    const statsFilters: Generator<any, any> = yield publicQuery(
      tournamentStatsFilters,
      {
        shardInfo: formatTournamentFilter(
          state.tournament.filter.shard,
          state.tournament.filter.parent,
          state.tournament.filter.child
        ),
        game: state.app.game,
      },
      CACHE_TIME.MINUTE * 3,
    );

    yield put({
      type: actionTypes.TOURNAMENT_GET_STATS_FILTERS_SUCCESSFUL,
      statsFilters: (statsFilters as any).data.tournamentStatsFilters,
    });
  } catch (error) {
    yield put({ type: actionTypes.TOURNAMENT_GET_STATS_FILTERS_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.TOURNAMENT_GET_TOURNAMENT, fetchTournament),
    takeLatest(actionTypes.TOURNAMENT_GET_LEADERBOARD, fetchLeaderboard),
    takeLatest(actionTypes.TOURNAMENT_GET_MATCHES, fetchMatches),
    takeLatest(actionTypes.TOURNAMENT_GET_TEAM_STATS, fetchTeamStats),
    takeLatest(actionTypes.TOURNAMENT_GET_PLATFORM_STATS, fetchPlatformmStats),
    takeLatest(actionTypes.TOURNAMENT_GET_STATS_FILTERS, fetchStatsFilters),
  ]);
}
