import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { getScrims } from "@twire/redux/scrims/actions";
import { saveUserAction } from "@twire/redux/track/actions";
import { scrimsPageView } from "@twire/redux/track/userActions/userActions";
import { useDispatch } from "react-redux";
import Scrims from "./Scrims";

const ScrimsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  useEffect(() => {
    dispatch(getScrims());
    dispatch(saveUserAction(scrimsPageView()));
  }, []);
  return <Scrims url={url} />;
};

export default ScrimsContainer;
