import React from "react";
import { FeaturedTournamentsContext } from "../context";
import classes from "./Controller.module.scss";

const Controller: React.FC = () => (
  <FeaturedTournamentsContext.Consumer>
    {({ currentindex, featuredTournaments, setIndex }) => (
      <div className={classes.indicatorBox}>
        {featuredTournaments.map(({ name }, index) => (
          <div
            key={name}
            onClick={() => setIndex(index)}
            className={
              currentindex === index
                ? "background-twire-orange cursor-pointer"
                : "cursor-pointer"
            }
          />
        ))}
      </div>
    )}
  </FeaturedTournamentsContext.Consumer>
);

export default Controller;
