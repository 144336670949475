import React from "react";
import { mergeClasses } from "../../../../../utility/helpers";
import classes from "./Placeholder.module.scss";

const count = new Array(100).fill("a");

const Placeholder: React.FC = () => (
  <div className={classes.container}>
    {count.map((_, index) => (
      <div
        key={index}
        className={mergeClasses(classes.placeholderTile, "animate-pulse")}
      />
    ))}
  </div>
);

export default Placeholder;
