import { AnyAction, ActionCreator } from "redux";
import {
  // SetGlobalFilter,
  SetFilter,
  TournamentFilter,
  TimeType,
  GetPaginated,
} from "./types";
import * as actionTypes from "./actionTypes";

export const getOngoing = (): AnyAction => ({
  type: actionTypes.TOURNAMENTS_GET_ONGOING,
});

export const getPast = (): AnyAction => ({
  type: actionTypes.TOURNAMENTS_GET_PAST,
});

export const getUpcoming = (): AnyAction => ({
  type: actionTypes.TOURNAMENTS_GET_UPCOMING,
});

export const filterTournaments: ActionCreator<SetFilter> = (
  filter: TournamentFilter,
  key: TimeType
) => ({
  type: actionTypes.TOURNAMENTS_FILTER_TOURNAMENTS,
  filter,
  key,
});

export const getPaginated: ActionCreator<GetPaginated> = (
  key: TimeType,
  page: number
) => ({
  type: actionTypes.TOURNAMENTS_GET_PAGINATED,
  page,
  key,
});

// export const setGlobalFilter: ActionCreator<SetGlobalFilter> = (
//   filter: TournamentFilter
// ) => ({
//   type: actionTypes.TOURNAMENTS_GLOBAL_FILTER,
//   filter,
// });
