import { AnyAction } from "redux";
import { TeamRankingInterface } from "../types";

export const requested = (state: TeamRankingInterface): TeamRankingInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    teams: { failed: false, requesting: true, success: false },
  },
});

export const success = (
  state: TeamRankingInterface,
  action: AnyAction
): TeamRankingInterface => ({
  ...state,
  teams: action.topTeams,
  updatedAt: action.updatedAt,
  requestStates: {
    ...state.requestStates,
    teams: { failed: false, requesting: false, success: true },
  },
});

export const failed = (
  state: TeamRankingInterface,
  action: AnyAction
): TeamRankingInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    teams: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: TeamRankingInterface): TeamRankingInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    teams: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
