import { mergeClasses } from "@twire/utility/helpers";
import classes from "./MatchTile.module.scss";

const MatchTile: React.FC = () => (
  <div className={mergeClasses(classes.container, classes.placeholder)}>
    <div className="flex items-center w-full">
      <div
        className={mergeClasses(
          classes.logo,
          classes.placeholderLogo,
          "animate-pulse"
        )}
      />
      <div className={mergeClasses("pr-2 w-full")}>
        <div
          className={mergeClasses(
            "text-2xl font-bold text-twire-light animate-pulse",
            classes.placeholderTournamentName
          )}
        ></div>
        <div
          className={mergeClasses(
            "open-sans text-twire-light text-xs animate-pulse",
            classes.placeholderMatchName
          )}
        ></div>
      </div>
    </div>
    <div className={classes.actions}>
      <div
        className={mergeClasses(
          classes.actionIcon,
          "animate-pulse",
          "background-twire-placeholder-color"
        )}
      ></div>
      <div
        className={mergeClasses(classes.arrowSpanPlaceholder, "animate-pulse")}
      />
    </div>
  </div>
);

export default MatchTile;
