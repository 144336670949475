import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSignInUser } from "@twire/redux/user/actions";
import Dashboard from "./Dashboard";
import { RootReducer } from "@twire/redux/types";
import { AdsContext } from "./context";
import { saveUserAction } from "@twire/redux/track/actions";
import { adClick, adShown } from "@twire/redux/track/userActions/userActions";
import { AdProviders } from "@twire/utility/constants";

const DashboardContainer: FC = () => {
  const dispatch = useDispatch();
  const {
    user: {
      signedIn,
      userSubscription,
      requestStates: {
        userSubscription: userSubscriptionStates,
      }
    }
  } = useSelector((state: RootReducer) => state);

  const [showAds, setShowAds] = useState(true);

  useEffect(() => {
    dispatch(getSignInUser());
  }, [dispatch]);

  useEffect(() => {
    const urls = [
      "login",
      "register",
      "forgot-password",
      "verification",
    ];
    setShowAds(urls.every(u => !location.pathname.includes(u)));
  }, [location.pathname]);

  const onAdClick = () => {
    dispatch(saveUserAction(
      adClick(AdProviders.STAKE, undefined)
    ));
  };

  const onAdLoad = () => {
    dispatch(saveUserAction(
      adShown(AdProviders.STAKE, undefined)
    ));
  }


  const state = {
    showAds: showAds && (
      !signedIn ||
      !userSubscription.status && userSubscriptionStates.success
    ),
  }

  return (
    <AdsContext.Provider value={state}>
      <Dashboard
        onAdClick={onAdClick}
        onAdLoad={onAdLoad}    
      />
    </AdsContext.Provider>
  );
};

export default DashboardContainer;
