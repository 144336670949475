import { mergeClasses } from "@twire/utility/helpers";
import React from "react";
import classes from "./Placeholder.module.scss";

const AdvancedTable: React.FC = () => {
  const rowCount = Array(10).fill("");
  const colCount = Array(5).fill("");
  return (
    <div className={mergeClasses(classes.container, "appear")}>
      <div className={mergeClasses("flex h-full w-full")}>
        <div>
          <div
            className={mergeClasses(
              classes.bigCell,
              "background-twire-dark-ash",
              classes.centered,
              classes.cell,
              "pl-7",
              classes.start
            )}
          >
            <div className={classes.placeholder} />
          </div>
          {rowCount.map(() => (
            <div
              key={Math.random()}
              className={mergeClasses(
                classes.cell,
                classes.bigCell,
                classes.centered,
                "background-twire-dark-ash",
                "mt-1 justify-start pl-7 mr-1",
                classes.start
              )}
            >
              <div className={classes.placeholder} />
            </div>
          ))}
        </div>
        <div className={mergeClasses("w-full")}>
          <div className={classes.outerInner}>
            {/* <div> */}
            <div className={mergeClasses("w-full", classes.innerTable)}>
              {colCount.map(() => (
                <div key={Math.random()}>
                  <div
                    className={mergeClasses(
                      classes.tableHeading,
                      classes.cell,
                      classes.centered,
                      "background-twire-dark-ash cursor-pointer"
                    )}
                  >
                    <div className={classes.placeholder} />
                  </div>
                  {rowCount.map(() => (
                    <div
                      key={Math.random()}
                      className={mergeClasses(
                        classes.cell,
                        classes.centered,
                        "background-twire-grey",
                        "mt-1"
                      )}
                    >
                      <div className={classes.placeholder} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedTable;
