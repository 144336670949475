export const SCRIMS_GET_SCRIMS = "SCRIMS_GET_SCRIMS";
export const SCRIMS_GET_SCRIMS_REQUESTED = "SCRIMS_GET_SCRIMS_REQUESTED";
export const SCRIMS_GET_SCRIMS_SUCCESS = "SCRIMS_GET_SCRIMS_SUCCESS";
export const SCRIMS_GET_SCRIMS_FAILED = "SCRIMS_GET_SCRIMS_FAILED";
export const SCRIMS_GET_SCRIMS_CLEAR = "SCRIMS_GET_SCRIMS_CLEAR";

export const SCRIMS_FILTER_SCRIMS = "SCRIMS_FILTER_SCRIMS";
export const SCRIMS_FILTER_SCRIMS_REQUESTED = "SCRIMS_FILTER_SCRIMS_REQUESTED";
export const SCRIMS_FILTER_SCRIMS_SUCCESS = "SCRIMS_FILTER_SCRIMS_SUCCESS";
export const SCRIMS_FILTER_SCRIMS_FAILED = "SCRIMS_FILTER_SCRIMS_FAILED";
export const SCRIMS_FILTER_SCRIMS_CLEAR = "SCRIMS_FILTER_SCRIMS_CLEAR";

export const SCRIMS_GET_SCRIMS_PAGINATED = "SCRIMS_GET_SCRIMS_PAGINATED";
export const SCRIMS_GET_SCRIMS_REQUESTED_PAGINATED =
  "SCRIMS_GET_SCRIMS_REQUESTED_PAGINATED";
export const SCRIMS_GET_SCRIMS_SUCCESS_PAGINATED =
  "SCRIMS_GET_SCRIMS_SUCCESS_PAGINATED";
export const SCRIMS_GET_SCRIMS_FAILED_PAGINATED =
  "SCRIMS_GET_SCRIMS_FAILED_PAGINATED";
export const SCRIMS_GET_SCRIMS_CLEAR_PAGINATED =
  "SCRIMS_GET_SCRIMS_CLEAR_PAGINATED";
