import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import Tournaments from "./Tournaments";
import Placeholder from "./Placeholder";
import ComponentError from "@twire/common/ComponentError";
import { useUrl } from "@twire/utility/hooks";
import { TournamentsContainerProps } from "./types";
import classes from "./Tournaments.module.scss";

const TournamentsContainer: React.FC<TournamentsContainerProps> = ({
  loading,
  tournaments,
  error,
  onScroll,
  infinite,
}) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);
  const url = useUrl(`/${game}/tournaments/tournament/`);
  return loading ? (
    <Placeholder />
  ) : error ? (
    <ComponentError {...error} className={classes.error} />
  ) : (
    <Tournaments
      infinite={infinite}
      onScroll={onScroll}
      url={url}
      tournaments={tournaments}
    />
  );
};

export default TournamentsContainer;
