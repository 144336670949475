import Dropdown from "unop-react-dropdown";
import { PrizeTableContext } from "../context";

import { ReactComponent as ArrowTip } from "../../../../../../../../assets/svgs/arrowTip.svg";
import classes from "./PrizeTableFilter.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const PrizeTableFilterTrigger: React.FC = (props) => {
  return (
    <PrizeTableContext.Consumer>
      {({ shard, table }) => (
        <button className={classes.trigger} {...props}>
          {table.headers.find((val) => val.value === shard)?.title} <ArrowTip />
        </button>
      )}
    </PrizeTableContext.Consumer>
  );
};

const PrizeTableFilterDropdown = () => {
  return (
    <PrizeTableContext.Consumer>
      {({ table, shard, setShard }) => (
        <div className={classes.dropdownWrapper}>
          <Dropdown
            closeOnDropdownClicked
            closeOnClickOut
            trigger={<PrizeTableFilterTrigger />}
          >
            <div className={classes.dropdown}>
              {table.headers.map(({ value, title }) => (
                <button
                  key={value}
                  className={mergeClasses([value === shard, classes.active])}
                  onClick={() => {
                    setShard(value);
                  }}
                >
                  {title}
                </button>
              ))}
            </div>
          </Dropdown>
        </div>
      )}
    </PrizeTableContext.Consumer>
  );
};

const PrizeTableFilter: React.FC = () => {
  //   const { selectedRound, selectedGroup, changeGroup } = useTournament();
  return (
    <PrizeTableContext.Consumer>
      {() => (
        <div className={classes.container}>
          <PrizeTableFilterDropdown />
        </div>
      )}
    </PrizeTableContext.Consumer>
  );
};

export default PrizeTableFilter;
