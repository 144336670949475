import { mergeClasses } from "@twire/utility/helpers";
import { ReactComponent as ArrowTip } from "../../../../assets/svgs/arrowTip.svg";

import classes from "../DropDown.module.scss";

export const DropdownTrigger: React.FC<{
  onClick?: () => void;
  open?: boolean;
  className?: string;
}> = ({ onClick, open, children, className }) => {
  return (
    <button
      className={mergeClasses(
        "flex items-center",
        classes.trigger,
        [!!open, "background-twire-menu-grey"],
        [!!className, className as string]
      )}
      onClick={onClick}
    >
      {children}
      <ArrowTip className="ml-3" />
    </button>
  );
};

export default DropdownTrigger;
