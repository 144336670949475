import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "./index.css";
import "./styles/index.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import ErrorBoundary from "@twire/common/ErrorBoundary";
import ToastifyContainer from '@twire/common/ToastifyContainer';

let awsSettings;
if (process.env.REACT_APP_STAGE === "prod") {
  awsSettings = require("./cognito-config-prod.ts");
} else {
  awsSettings = require("./cognito-config.ts");
  console.log(awsSettings);
}

Amplify.configure({
  ...awsSettings.default,
  clientMetadata: {
    domain: document.location.hostname,
  },
});
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ErrorBoundary>
        <I18nextProvider i18n={i18next}>
          <ToastifyContainer />
          <App />
        </I18nextProvider>
      </ErrorBoundary>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
