import { mergeClasses } from "@twire/utility/helpers";

import Footer from "./Footer";
import Header from "./Header";
import HamburgerDropdown from "./HamburgerDropdown";
import Nav from "./Nav";
import classes from "./Layout.module.scss";
import "./LayoutStyles.scss";

const Layout: React.FC<{
  dropdownOpen: boolean;
  toggleDropdown: () => void;
  dropdownRef: any;
  dropdownHeight: number;
}> = ({ children, dropdownRef, dropdownHeight, ...rest }) => (
  <div
    className={mergeClasses("background-twire-dark mx-auto", classes.layout)}
  >
    <Header {...rest} />
    <div
      className={mergeClasses(
        classes.hamburgerDropdownWrapper,
        "background-twire-dark",
        [rest.dropdownOpen, classes.show]
      )}
      style={{ height: dropdownHeight }}
    >
      <div className="background-twire-dark" ref={dropdownRef}>
        <HamburgerDropdown
          toggleDropdown={rest.toggleDropdown}
        />
      </div>
    </div>
    <div className="flex">
      <Nav className={classes.desktopNav} />
      <div className={classes.right}>
        <div className={classes.mainSectionWrapper}>
          <section className={classes.mainSection}>{children}</section>
        </div>
        <Footer />
      </div>
    </div>
  </div>
);

export default Layout;
