import React, { useState, useEffect } from "react";
import { RootReducer } from "@twire/redux/types";
import { saveUserAction } from "@twire/redux/track/actions";
import { useLanguageChange } from "@twire/utility/hooks";
import { featuredTournamentPageView } from "@twire/redux/track/userActions/userActions";
import { useDispatch, useSelector } from "react-redux";

import TournamentContent from "./TournamentContent";
import { TournamentSocial } from "../../../../../../PublicAPI";

const TournamentContentContainer: React.FC = () => {
  const dispatch = useDispatch();
  const language = useLanguageChange();

  const {
    app: { game },
    featured: { featured },
  } = useSelector((state: RootReducer) => state);
  const [streams, setStreams] = useState<{[key: number]: TournamentSocial}>({});

  useEffect(() => {
    const featuredStreams: {[key: number]: TournamentSocial} = {};
    featured.forEach((t, i) => {
      if (t.live && t.tournamentSocials && t.tournamentSocials.length) {
        const twitch = t.tournamentSocials.find(s => s.type === "twitch");
        if (twitch) {
          featuredStreams[i] = twitch;
          return;
        }
        const youtube = t.tournamentSocials.find(s => s.type === "youtube");
        if (youtube) {
          featuredStreams[i] = youtube;
          return;
        }
      }
    });
    setStreams(featuredStreams);
  }, [featured]);

  const onTournamentViewClick = (currentIndex: number) => {
    dispatch(saveUserAction(featuredTournamentPageView(featured[currentIndex]?.name)));
  }

  return <TournamentContent
    lang={language}
    game={game}
    streams={streams}
    onTournamentViewClick={onTournamentViewClick}
  />;
};

export default TournamentContentContainer;
