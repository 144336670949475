import {
  mergeClasses,
  setTeamLogo,
  setDefaultTeamLogo,
} from "@twire/utility/helpers";
import { ReactComponent as ArrowTip } from "../../../assets/svgs/arrowTip.svg";
import { TileProps } from "./types";
import React from "react";
import classes from "./TeamTile.module.scss";

const TeamTile: React.FC<TileProps> = ({
  theme,
  name,
  pos,
  prevPosChange,
  previousPos,
  game,
}) => (
  <div
    className={mergeClasses(
      classes.container,
      theme ? `rank-tile-${theme.toLowerCase()}` : ""
    )}
  >
    <span className={classes.index}>#{pos}</span>
    <img
      src={setTeamLogo(game, name)}
      alt={name}
      className={classes.teamImage}
      onError={setDefaultTeamLogo}
    />
    <p className="text-twire-light truncate">{name}</p>
    {previousPos && !(previousPos === "NEW" || previousPos === "SAME") && (
      <div
        className={mergeClasses(
          "ml-auto flex items-center",
          classes.rankChange,
          [!!previousPos, classes[previousPos.toLowerCase()]]
        )}
      >
        <ArrowTip />
        {prevPosChange}
      </div>
    )}
  </div>
);

export default TeamTile;
