/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { mergeClasses } from "@twire/utility/helpers";
import { Link } from "react-router-dom";
import { t, urlFriendlyString } from "@twire/utility/helpers";
import { TWIRE_ASSETS_URL } from "@twire/utility/constants";
import { formatDate, formatter } from "@twire/utility/helpers";

import { ReactComponent as PlaySvg } from "../../../../../../assets/svgs/play.svg";
import { FeaturedTournamentsContext } from "../../context";

import classes from "./TournamentContent.module.scss";
import { featuredTournamentOpenStream } from "@twire/redux/track/userActions/userActions";
import { TournamentSocial } from "../../../../../../PublicAPI";

const TournamentContent: React.FC<{
  lang: string;
  game: string;
  streams: {[key: number]: TournamentSocial};
  onTournamentViewClick: (currentIndex: number) => void;
}> = ({ lang, game, onTournamentViewClick, streams, }) => (
  <FeaturedTournamentsContext.Consumer>
    {({ featuredTournaments, currentindex, isTransitioning, isAppearing }) => (
      <div
        className={mergeClasses(
          "flex relative",
          classes.container,
          [isTransitioning, classes.disappearing],
          isAppearing ? classes.appearing : ""
        )}
      >
        <div className={classes.tournamentContent}>
          <div className="flex align-center">
            <img
              src={`${TWIRE_ASSETS_URL}pubg/tournament-logos/${featuredTournaments[currentindex].badge}.png`}
              alt="img"
              className={mergeClasses(classes.badge, classes.animatable)}
            />
            <h2
              className={mergeClasses(
                classes.tournamentName,
                classes.animatable,
                "text-twire-light"
              )}
            >
              {featuredTournaments[currentindex].name}
            </h2>
          </div>
          <div className={classes.details}>
            <div>
              <div>{t("words.beginsOn")}</div>
              <div className={classes.animatable}>
                {formatDate(featuredTournaments[currentindex].time)}
              </div>
            </div>
            <div>
              <div>{t("words.tier")}</div>
              <div className={classes.animatable}>
                {t("words.tier")}{" "}
                {featuredTournaments[currentindex].tier}
              </div>
            </div>
            <div>
              <div>{t("words.region")}</div>
              <div className={classes.animatable}>
                {featuredTournaments[currentindex].location}
              </div>
            </div>
            <div>
              <div>{t("words.prize")}</div>
              <div className={classes.animatable}>
                {featuredTournaments[currentindex].prize
                  ? formatter(
                    featuredTournaments[currentindex].curency
                  )
                    .format(featuredTournaments[currentindex].prize)
                    .replace(/\.00/g, "")
                  : "N/A"}
              </div>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <Link
              to={`/${lang}/${game}/tournaments/tournament/${featuredTournaments[currentindex].id
                }/${urlFriendlyString(
                  featuredTournaments[currentindex].name || ""
                )}`}
            >
              <button
                className={mergeClasses(
                  classes.button,
                  "background-twire-dark-orange",
                  "border-twire-orange",
                  "text-twire-dark"
                )}
                onClick={() => onTournamentViewClick(currentindex)}
              >
                {t("words.viewTournament")}
              </button>
            </Link>
            {streams[currentindex] && <div className={classes.play}>
              <PlaySvg />
              <a
                href={streams[currentindex].value}
                target="_blank"
                rel="noreferrer"
                onClick={() => featuredTournamentOpenStream(featuredTournaments[currentindex].name)}
              >{t("words.watchOnStream")}</a>
            </div>}
          </div>
        </div>
        <img
          src="/images/featured/vietnam.png"
          alt="vietname"
          className={classes.charImg}
        />
      </div>
    )}
  </FeaturedTournamentsContext.Consumer>
);

export default TournamentContent;
