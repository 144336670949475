import React from "react";
import { NavLink } from "react-router-dom";
import { GlowDirection } from "./types";
import classes from "./MenuItem.module.scss";
import { mergeClasses } from "@twire/utility/helpers";

const MenuItem: React.FC<{
  dir?: GlowDirection;
  className?: string;
  active?: boolean;
  to: string;
  external?: boolean;
  exact?: boolean;
  isActive?: any;
  blue?: boolean;
}> = ({ dir, children, className, to, external, exact, isActive, blue }) =>
  !external ? (
    <NavLink
      exact={exact}
      activeClassName={classes.active}
      className={mergeClasses(
        dir ? classes[dir.toLowerCase()] : "",
        classes.navLink,
        className || "",
        blue ? classes.blue : ""
      )}
      isActive={isActive}
      to={to}
    >
      <div className={classes.navItem}>
        <div className={classes.indicatorWrapper}>
          <div className={classes.indicator} />
        </div>
        {children}
      </div>
    </NavLink>
  ) : (
    <a
      href={to}
      className={mergeClasses(
        dir ? classes[dir.toLowerCase()] : "",
        classes.navLink,
        className || ""
      )}
    >
      <div className={classes.navItem}>
        <div className={classes.indicatorWrapper}>
          <div className={classes.indicator} />
        </div>
        {children}
      </div>
    </a>
  );
export default MenuItem;
