import { mergeClasses } from "@twire/utility/helpers";
import { Link } from "react-router-dom";
import React from "react";
import classes from "./Footer.module.scss";
import { useT } from "@twire/utility/hooks";

import { ReactComponent as FacebookSvg } from "../../../../assets/svgs/facebook.svg";
import { ReactComponent as Twitter } from "../../../../assets/svgs/twitter.svg";
import { ReactComponent as Discord } from "../../../../assets/svgs/discord.svg";
import { ReactComponent as Instagram } from "../../../../assets/svgs/instagram.svg";
import { ReactComponent as Linkedin } from "../../../../assets/svgs/linkedin.svg";

const Footer: React.FC = () => (
  <div className={mergeClasses(classes.footerContainer, "w-full")}>
    <footer
      className={mergeClasses(
        "background-twire-grey h-full ml-auto",
        classes.footer
      )}
    >
      <div>
        <img src="/images/logo.png" alt="logo" className={classes.logo} />
        <p className={mergeClasses(classes.footerText, "text-twire-light")}>
          Twire © {new Date().getFullYear()} Twire d.o.o.
        </p>
      </div>
      <div className="ml-auto text-right">
        <ul className={classes.footerLinks}>
          <li>
            <a href={`mailto:info@twire.gg`}>{useT("words.contactUs")}</a>
          </li>
          <li>
            <a href={`mailto:patricija@twire.gg`}>
              {useT("words.advertiseWithUs")}
            </a>
          </li>
          <li>
            <a href={`https://analytics.twire.gg/en/terms/`}>
              {useT("words.tos")}
            </a>
          </li>
          <li>
            <a href={`https://analytics.twire.gg/en/privacy/`}>
              {useT("words.privacyPolicy")}
            </a>
          </li>
        </ul>
        <p className={mergeClasses(classes.footerText, "text-twire-light")}>
          Not affiliated with Steam or Valve Corp
        </p>
      </div>
    </footer>
    <footer
      className={mergeClasses(
        "background-twire-grey h-full ml-auto",
        classes.mobileFooter
      )}
    >
      <div className="flex">
        <img
          src="/images/logo.png"
          alt="logo"
          className={mergeClasses(classes.logo, "mr-auto")}
        />
        <div className="ml-auto">
          <Link to="/">{useT("words.contactUs")}</Link>
          <Link to="/">{useT("words.jobs")}</Link>
        </div>
        <div className="ml-3">
          <Link to="/">{useT("words.tos")}</Link>
          <Link to="/">{useT("words.privacyPolicy")}</Link>
        </div>
      </div>
      <div
        className={mergeClasses(classes.socialsWrapper, "flex items-center")}
      >
        <div className={mergeClasses(classes.socials, "flex")}>
          <a href="www.facebook.com">
            <FacebookSvg />
          </a>
          <a href="www.twitter.com">
            <Twitter />
          </a>
          <a href="www.discord.com">
            <Discord />
          </a>
          <a href="www.instagram.com">
            <Instagram />
          </a>
          <a href="www.linkedin.com">
            <Linkedin />
          </a>
        </div>
        <p className={classes.joinUs}>{useT("words.joinUs")}</p>
      </div>
      <div className={classes.disclaimer}>
        <p>Not affiliated with Steam or Valve Corp</p>
        <p className="ml-auto">
          Twire © {new Date().getFullYear()} Twire d.o.o.
        </p>
      </div>
    </footer>
  </div>
);

export default Footer;
