import * as Yup from "yup";
import { TFunction } from "i18next";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getResetPasswordFormSchema = (t: TFunction) => ({
  password: Yup.string()
    .min(8, t("sentences.shortPassword"))
    .required(t("sentences.requiredPassword")),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resetPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    password: getResetPasswordFormSchema(t).password,
    confirmPassword: getResetPasswordFormSchema(t).confirmPassword,
  });

export const initialValues = {
  password: "",
  confirmPassword: "",
};
