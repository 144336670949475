export const getRankClassName = (rank: number): string => {
  if (rank) {
    switch (rank) {
      case 1:
        return " rank-tile-orange";
      case 2:
        return " rank-tile-blue";
      case 3:
        return " rank-tile-red";
      default:
        return "";
    }
  } else {
    return "";
  }
};
