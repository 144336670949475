import { Reducer } from "redux";
import { getUrlStatsFilterParam, TwireUrl } from "@twire/utility/helpers";
import { defaultRequestState } from "../constants";
import { TournamentInterface, TournamentStatsFilterType } from "./types";
import * as actionTypes from "./actionTypes";
import * as tournamentHandlers from "./handlers/getTournament";
import * as filterHandlers from "./handlers/filterTournament";
import * as leaderboardHandlers from "./handlers/getLeaderboard";
import * as matchHandlers from "./handlers/getMatches";
import * as getTeamStatsHandlers from "./handlers/getTeamStats";
import * as getPlatformStatsHandlers from "./handlers/getPlatformStats";
import * as getStatsFiltersHandlers from "./handlers/getStatsFilters";
import * as statsFiltersHandlers from "./handlers/statsFilters";

const INITIAL_MAP_FILTERS = getUrlStatsFilterParam(
  TournamentStatsFilterType.MAPS
);

const INITIAL_MATCHES_FILTERS = getUrlStatsFilterParam(
  TournamentStatsFilterType.MATCHES
);

const INITIAL_STATE: TournamentInterface = {
  tournament: null,
  filter: {
    shard: "",
    parent: TwireUrl.get("round") || "",
    child: TwireUrl.get("group") || "",
    ruleset: null,
  },
  statsFilters: {
    all: !INITIAL_MAP_FILTERS.length 
      && !INITIAL_MATCHES_FILTERS.length,
    maps: INITIAL_MAP_FILTERS,
    matches: INITIAL_MATCHES_FILTERS,
  },
  token: TwireUrl.get("token") || "",
  platformLeaderboard: null,
  platformStatsLeaderboardTrie: null,
  matchByTournament: null,
  teamStats: null,
  platformStats: null,
  matchesPage: 1,
  isLastMatchesPage: false,
  requestStates: {
    filter: {
      ...defaultRequestState,
    },
    tournament: {
      ...defaultRequestState,
    },
    platformLeaderboard: {
      ...defaultRequestState,
    },
    matchByTournament: {
      ...defaultRequestState,
    },
    teamStats: {
      ...defaultRequestState,
    },
    platformStats: {
      ...defaultRequestState,
    },
    statsFilters: {
      ...defaultRequestState,
    },
  },
};

const tournamentsReducer: Reducer<TournamentInterface> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TOURNAMENT_GET_TOURNAMENT_REQUESTED:
      return tournamentHandlers.requested(state);
    case actionTypes.TOURNAMENT_GET_TOURNAMENT_FAILED:
      return tournamentHandlers.failed(state, action);
    case actionTypes.TOURNAMENT_GET_TOURNAMENT_SUCCESS:
      return tournamentHandlers.success(state, action);
    case actionTypes.TOURNAMENT_TOURNAMENT_CLEAR:
      return tournamentHandlers.clear(state);
    case actionTypes.TOURNAMENT_SET_FILTER:
      return filterHandlers.success(state, action);
    case actionTypes.TOURNAMENT_SET_FILTER_CLEAR:
      return filterHandlers.clear(state);
    case actionTypes.TOURNAMENT_GET_LEADERBOARD_REQUESTED:
      return leaderboardHandlers.requested(state);
    case actionTypes.TOURNAMENT_GET_LEADERBOARD_SUCCESS:
      return leaderboardHandlers.success(state, action);
    case actionTypes.TOURNAMENT_GET_LEADERBOARD_FAILED:
      return leaderboardHandlers.failed(state, action);
    case actionTypes.TOURNAMENT_GET_LEADERBOARD_CLEAR:
      return leaderboardHandlers.clear(state);
    case actionTypes.TOURNAMENT_GET_MATCHES_REQUESTED:
      return matchHandlers.requested(state);
    case actionTypes.TOURNAMENT_GET_MATCHES_SUCCESS:
      return matchHandlers.success(state, action);
    case actionTypes.TOURNAMENT_GET_MATCHES_FAILED:
      return matchHandlers.failed(state, action);
    case actionTypes.TOURNAMENT_GET_MATCHES_CLEAR:
      return matchHandlers.clear(state);
    case actionTypes.TOURNAMENT_GET_TEAM_STATS_REQUESTED:
      return getTeamStatsHandlers.requested(state);
    case actionTypes.TOURNAMENT_GET_TEAM_STATS_SUCCESSFUL:
      return getTeamStatsHandlers.success(state, action);
    case actionTypes.TOURNAMENT_GET_TEAM_STATS_FAILED:
      return getTeamStatsHandlers.failed(state, action);
    case actionTypes.TOURNAMENT_GET_TEAM_STATS_CLEAR:
      return getTeamStatsHandlers.clear(state);
    case actionTypes.TOURNAMENT_GET_PLATFORM_STATS_REQUESTED:
      return getPlatformStatsHandlers.requested(state);
    case actionTypes.TOURNAMENT_GET_PLATFORM_STATS_SUCCESSFUL:
      return getPlatformStatsHandlers.success(state, action);
    case actionTypes.TOURNAMENT_GET_PLATFORM_STATS_FAILED:
      return getPlatformStatsHandlers.failed(state, action);
    case actionTypes.TOURNAMENT_GET_PLATFORM_STATS_CLEAR:
      return getPlatformStatsHandlers.clear(state);
    case actionTypes.TOURNAMENT_GET_STATS_FILTERS_REQUESTED:
      return getStatsFiltersHandlers.requested(state);
    case actionTypes.TOURNAMENT_GET_STATS_FILTERS_SUCCESSFUL:
      return getStatsFiltersHandlers.success(state, action);
    case actionTypes.TOURNAMENT_GET_STATS_FILTERS_FAILED:
      return getStatsFiltersHandlers.failed(state, action);
    case actionTypes.TOURNAMENT_GET_STATS_FILTERS_CLEAR:
      return getStatsFiltersHandlers.clear(state);
      case actionTypes.TOURNAMENT_UNSET_STATS_FILTER:
        return getStatsFiltersHandlers.unset(state);
    case actionTypes.TOURNAMENT_SET_STATS_FILTER:
      return statsFiltersHandlers.success(state, action);
    case actionTypes.TOURNAMENT_SET_STATS_FILTER_CLEAR:
      return statsFiltersHandlers.clear(state);
    default:
      return state;
  }
};

export default tournamentsReducer;
