import { Trie } from "@twire/utility/classes";
import {
  RankedPlayer,
  RankedPlayermap,
  PlayerId,
  RankedPlayermapValue,
} from "@twire/redux/playerRanking/types";
import { OrderType, PlayerRankingSortType, PlayerStatKey } from "./types";

export const getTrie = (players: RankedPlayer[]): Trie<PlayerId> => {
  const trie = new Trie<PlayerId>();
  players.forEach((player) => {
    trie.insert(player.nickname.toLowerCase(), player.id);
  });
  return trie;
};

export const genRankedPlayerMap = (
  players: RankedPlayer[]
): RankedPlayermap => {
  return players.reduce((a, player, index) => {
    a[player.id] = {
      rank: index,
      player,
    };
    return a;
  }, {} as RankedPlayermap);
};

export const sortPlayers = (
  sortData: PlayerRankingSortType,
  data: RankedPlayermapValue[]
): PlayerId[] => {
  const [sortKey, sortBy] = sortData;
  const playersCopy = [...data];

  playersCopy.sort((a, b) => {
    if (a.player[sortKey] > b.player[sortKey]) {
      if (sortBy === "ASCENDING") {
        return 1;
      } else {
        return -1;
      }
    } else if (a.player[sortKey] < b.player[sortKey]) {
      if (sortBy === "DESCENDING") {
        return 1;
      } else {
        return -1;
      }
    } else {
      return 0;
    }
  });

  return playersCopy.map((p) => p.player.id);
};

export const determineOrder = (
  sortData: PlayerRankingSortType,
  newKey: PlayerStatKey
): OrderType => {
  if (sortData[0] === newKey) {
    return sortData[1] === "ASCENDING" ? "DESCENDING" : "ASCENDING";
  } else {
    return sortData[1];
  }
};
