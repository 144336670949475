import React from "react";
import { mergeClasses } from "@twire/utility/helpers";
// import Text from "@twire/common/Text";
import { useT } from "@twire/utility/hooks";
import { RequestStates } from "@twire/redux/types";
import Tournaments from "./Tournaments";
import Teams from "./Teams";
import { ReactComponent as SearchSVG } from "../../../../assets/svgs/search.svg";
import classes from "./Search.module.scss";
import Text from "../../../common/Text";

const Search: React.FC<{
  onChange: (value: string) => void;
  clearValue: () => void;
  count: number;
  searchRequestStates: RequestStates;
  searchRef: React.RefObject<HTMLInputElement>;
}> = ({ onChange, count, searchRequestStates, searchRef }) => (
  <div className={classes.container}>
    <h1 className={classes.heading}>Search</h1>
    <p className="text-twire-light text-center open-sans">
      <Text i18nKey="sentences.searchSubtitle">
        Search through hundreds of tournaments
      </Text>
    </p>
    <div
      className={mergeClasses("flex items-center relative", classes.searchBox)}
    >
      <SearchSVG />
      <input
        type="search"
        className={mergeClasses("ml-2 text-xs", classes.search)}
        placeholder={useT("sentences.searchPlaceholder")}
        onChange={(e) => onChange(e.target.value)}
        ref={searchRef}
      />
    </div>
    <hr className={classes.hr} />
    {searchRequestStates.requesting ? (
      <div className={mergeClasses("pt-8", classes.minSearchHeight)}>
        <img
          className="mx-auto appear"
          src="/images/animated/loader.gif"
          style={{ height: "25px" }}
          alt="loading"
        />
      </div>
    ) : searchRequestStates.success && count === 0 ? (
      <div
        className={mergeClasses(
          "text-twire-light opacity-70 open-sans font-bold text-xs text-center pt-8",
          classes.minSearchHeight
        )}
      >
        <Text i18nKey="words.searchNoResults">No Results found</Text>
      </div>
    ) : !searchRequestStates.requesting &&
      !searchRequestStates.success &&
      count === 0 ? (
      <div className={mergeClasses("pt-8", classes.minSearchHeight)}>
        <div className="text-twire-light opacity-70 open-sans font-bold text-xs text-center">
          <Text i18nKey="sentences.searchStart">
            Search by start typing into the search bar
          </Text>
        </div>
      </div>
    ) : (
      <div className={mergeClasses("appear", classes.minSearchHeight)}>
        <div>
          <p className="rift-bold text-twire-light my-4 text-lg">
            {count} <Text i18nKey="words.searchResults">results found</Text>
          </p>
        </div>
        <Tournaments />
        <Teams />
      </div>
    )}
  </div>
);

export default Search;
