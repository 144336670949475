import React from "react";
import { useSelector } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { mergeClasses } from "../../../utility/helpers";
import classes from "./Banner.module.scss";

const Banner: React.FC<{ className?: string }> = ({ className }) => {
  const {
    app: { banner },
  } = useSelector((state: RootReducer) => state);
  return (
    banner ?
    <div
      className={mergeClasses(classes.banner, [
        !!className,
        className as string,
        classes[banner.type],
      ])}
    >
      {banner.node}
    </div>
    :
    null
  );
};

export default Banner;
