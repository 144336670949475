// import { Table as TableClass, Column, Row } from "@twire/utility/classes";
import { mergeClasses, setDefaultTeamLogo } from "@twire/utility/helpers";

// import { ReactComponent as Multiple } from "../../../../../../../assets/svgs/multiple.svg";
// import { ReactComponent as User } from "../../../../../../../assets/svgs/user.svg";
import { ReactComponent as Play } from "../../../../../../../assets/svgs/play.svg";
import { MatchesInterfaceViewProps } from "./types";
import { MatchesContext } from "../context";
import classes from "./MatchesTable.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import Text from "@twire/common/Text";

const Table: React.FC<MatchesInterfaceViewProps> = ({
  matches,
  lang,
  game,
  onMatchReplayClick,
  onScroll,
  infinite,
  onMouseEnterMatchName,
  onMouseLeaveMatchName,
}) => {
  return (
    <InfiniteScroll
      dataLength={matches.length}
      next={onScroll}
      hasMore={infinite}
      loader={
        <div className="flex justify-center mt-5">
          <img
            src="/images/animated/loader.gif"
            style={{ height: "30px" }}
            alt="loading"
          />
        </div>
      }
    >
      <MatchesContext.Consumer>
        {({ time }) => (
          <div className={mergeClasses(classes.container, "appear")}>
            <div
              className={classes.wrapperGrid}
              style={{
                gridTemplateColumns:
                  time === "ongoing"
                    ? "max-content auto auto auto"
                    : "max-content auto auto auto auto",
              }}
            >
              <div className="w-full">
                <div
                  className={mergeClasses(
                    classes.grey1,
                    classes.row,
                    "mb-1 flex items-center font-bold",
                    classes.p30
                  )}
                >
                  <Text i18nKey="words.matchName">Match Name</Text>
                </div>
                <div>
                  {matches.map(({ name, gameCreatedAt }, index) => (
                    <div
                      className={mergeClasses(
                        classes.rank,
                        classes.grey2,
                        "mb-1 flex items-center text-xl whitespace-nowrap cursor-pointer",
                        classes.team,
                        classes.row,
                        classes.p30
                      )}
                      onMouseEnter={(e) => {
                        onMouseEnterMatchName(e, gameCreatedAt);
                      }}
                      onMouseLeave={onMouseLeaveMatchName}
                      key={index}
                    >
                      <span>{name}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-full">
                <div
                  className={mergeClasses(
                    classes.grey1,
                    classes.row,
                    "mb-1 flex items-center font-bold",
                    classes.p30
                  )}
                >
                  <Text i18nKey="words.map_">Map</Text>
                </div>
                {matches.map(({ map }, index) => (
                  <div
                    className={mergeClasses(
                      classes.rank,
                      classes.grey3,
                      classes.damage,
                      "mb-1 flex items-center text-xl whitespace-nowrap",
                      classes.row,
                      classes.p30
                    )}
                    key={index}
                  >
                    {map}
                  </div>
                ))}
              </div>

              {time === "upcoming" && (
                <div className="w-full">
                  <div
                    className={mergeClasses(
                      classes.grey1,
                      classes.row,
                      "mb-1 flex items-center font-bold",
                      classes.p30
                    )}
                  >
                    start
                  </div>
                  {matches.map((_, index) => (
                    <div
                      className={mergeClasses(
                        classes.rank,
                        classes.grey3,
                        classes.damage,
                        "mb-1 flex items-center font-bold whitespace-nowrap",
                        classes.row,
                        classes.p30
                      )}
                      key={index}
                    >
                      <span className="text-twire-orange">06/06/2021</span>
                      <span className="mx-1">-</span>
                      <span>3:00 PM</span>
                    </div>
                  ))}
                </div>
              )}

              {time === "upcoming" && (
                <div className="w-full">
                  <div
                    className={mergeClasses(
                      classes.grey1,
                      classes.row,
                      "mb-1 flex items-center font-bold",
                      classes.p30
                    )}
                  >
                    end
                  </div>
                  {matches.map((_, index) => (
                    <div
                      className={mergeClasses(
                        classes.rank,
                        classes.grey3,
                        classes.damage,
                        "mb-1 flex items-center font-bold whitespace-nowrap",
                        classes.row,
                        classes.p30
                      )}
                      key={index}
                    >
                      <span>06/06/2021</span>
                      <span className="mx-1">-</span>
                      <span>3:00 PM</span>
                    </div>
                  ))}
                </div>
              )}

              {time === "ongoing" && (
                <div className="w-full">
                  <div
                    className={mergeClasses(
                      classes.grey1,
                      classes.row,
                      "mb-1 flex items-center font-bold px-3"
                    )}
                  >
                    <Text i18nKey="words.winningTeam">Winning team</Text>
                  </div>
                  {matches.map(({ winner, logo }, index) => (
                    <div
                      className={mergeClasses(
                        classes.rank,
                        classes.grey3,
                        classes.kills,
                        "mb-1 flex items-center text-xl whitespace-nowrap pl-3",
                        classes.row
                      )}
                      key={index}
                    >
                      <img
                        src={logo}
                        className={classes.logo}
                        alt="logo"
                        onError={setDefaultTeamLogo}
                      />{" "}
                      {winner || "N/A"}
                    </div>
                  ))}
                </div>
              )}
              <div className="w-full">
                <div
                  className={mergeClasses(
                    classes.grey1,
                    classes.row,
                    "mb-1 flex items-center font-bold justify-center text-twire-orange px-3"
                  )}
                >
                  {/* Points */}
                </div>
                {matches.map(({ id }, index) => (
                  <div
                    className={mergeClasses(
                      classes.rank,
                      classes.grey3,
                      "mb-1 flex items-center font-bold text-twire-orange-i whitespace-nowrap justify-center",
                      classes.row,
                      classes.points
                    )}
                    key={index}
                  >
                    <div className={classes.actionsRow}>
                      {/* <button>
                        <Multiple />
                      </button>
                      <button>
                        <User />
                      </button> */}
                      <a
                        href={`https://analytics.twire.gg/${lang}/${game}/match/${id}`}
                        className="flex"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button>
                          <Play onClick={onMatchReplayClick} />
                        </button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </MatchesContext.Consumer>
    </InfiniteScroll>
  );
};

export default Table;
