import { FeaturedTournamentsContext } from "../context";

import TournamentContent from "./TournamentContent";
import Triangles from "./Triangles";

import classes from "./FeaturedTournament.module.scss";
import { mergeClasses } from "../../../../../utility/helpers";

const FeaturedTournament: React.FC = () => (
  <FeaturedTournamentsContext.Consumer>
    {({ featuredTournaments, currentindex, isAppearing }) => (
      <div className={classes.container}>
        <div className={classes.backgroundText}></div>
        <div
          className={mergeClasses(classes.text, [
            !isAppearing,
            classes.moveText,
          ])}
        >
          {featuredTournaments[currentindex].name}
        </div>
        <div className={classes.triangles}>
          <Triangles />
        </div>
        <TournamentContent />
      </div>
    )}
  </FeaturedTournamentsContext.Consumer>
);

export default FeaturedTournament;
