import React from "react";
import Triangles from "./Triangles";

const TrianglesContainer: React.FC = () => {
  const triangles = [
    [2, 2, 2, 2, 2, 1, 1, 1],
    [2, 2, 2, 2, 2, 1, 1, 1],
    [2, 2, 2, 2, 2, 1, 1, 1],
    [2, 2, 2, 2, 2, 1, 1, 1],
    [2, 2, 2, 2, 2, 1, 1, 1],
    [3, 3, 4, 5, 5, 3, 2, 1, 1],
  ];
  return <Triangles triangles={triangles} />;
};

export default TrianglesContainer;
