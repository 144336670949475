import { AnyAction } from "redux";
import { ArticlesInterface } from "../types";

export const requested = (
  state: ArticlesInterface,
  paginated?: boolean
): ArticlesInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    articles: { failed: false, requesting: true, success: false },
    paginated: paginated
      ? { failed: false, requesting: true, success: false }
      : state.requestStates.paginated,
  },
});

export const success = (
  state: ArticlesInterface,
  action: AnyAction,
  paginated?: boolean
): ArticlesInterface => ({
  ...state,
  // articles: action.articles,
  articles: paginated
    ? [...(state.articles || []), ...action.articles]
    : action.articles,
  page: paginated
    ? action.articles.length === 0
      ? action.page - 1
      : action.page
    : 1,
  isLastPage: action.articles.length === 0 ? true : state.isLastPage,
  game: action.game,
  requestStates: {
    ...state.requestStates,
    articles: { failed: false, requesting: false, success: true },
    paginated: paginated
      ? { failed: false, requesting: false, success: true }
      : state.requestStates.paginated,
  },
});

export const failed = (
  state: ArticlesInterface,
  action: AnyAction,
  paginated?: boolean
): ArticlesInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    articles: {
      failed: action.error,
      requesting: false,
      success: false,
    },
    paginated: paginated
      ? { failed: action.error, requesting: false, success: false }
      : state.requestStates.paginated,
  },
});

export const clear = (
  state: ArticlesInterface,
  paginated?: boolean
): ArticlesInterface => ({
  ...state,
  game: null,
  articles: [],
  page: 1,
  isLastPage: false,
  requestStates: {
    ...state.requestStates,
    articles: {
      failed: false,
      requesting: false,
      success: false,
    },
    paginated: paginated
      ? { failed: false, requesting: false, success: false }
      : state.requestStates.paginated,
  },
});
