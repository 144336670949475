import React, { useState, useEffect, useRef } from "react";
import { RootReducer } from "@twire/redux/types";
// import {
//   getPlatformStats,
//   clearPlatformStats,
// } from "@twire/redux/tournament/actions";
import {
  getElementYOffset,
  getElementXOffset,
} from "../../../../../../../utility/helpers";
import { showTooltip, hideTooltip } from "@twire/redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { HeaderType, ColHeadingType } from "./types";
import AdvancedTable from "./AdvancedTable";

const AdvancedTableContainer: React.FC<{
  headers: HeaderType;
  stats: any;
  colHeading: ColHeadingType;
  onResizeClick: () => void;
  fullscreen?: boolean;
}> = ({ headers, stats, colHeading, onResizeClick, fullscreen }) => {
  const dispatch = useDispatch();
  const [highToLow, setHighToLow] = useState(false);
  const [data, setData] = useState(stats);
  const [sortedBy, setSortedBy] = useState("twr");
  const dataOriginal = useRef(stats);
  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const {
    app: { showTooltip: shouldShowTooltip },
  } = useSelector((state: RootReducer) => state);

  const onMouseEnterStatHeader = (e: React.MouseEvent, description: string) => {
    if (description) {
      dispatch(
        showTooltip(
          getElementXOffset(e.currentTarget) + e.currentTarget.clientWidth / 2,
          getElementYOffset(e.currentTarget) - 15,
          <p style={{ maxWidth: "200px" }}>{description}</p>
        )
      );
    }
  };

  const onMouseLeaveStatHeader = () => {
    if (shouldShowTooltip) {
      dispatch(hideTooltip());
    }
  };

  useEffect(() => {
    setData(stats);
    dataOriginal.current = stats;
    sortByKey(sortedBy, true);
  }, [stats]);

  const sortByKey = (key: any, keepOrientation?: boolean) => {
    const dataCopy = [...dataOriginal.current];
    let order = highToLow;
    if (key === sortedBy && !keepOrientation) {
      setHighToLow(!highToLow);
      order = !highToLow;
    }
    dataCopy.sort((a: any, b: any) => {
      if (typeof a.data[key] === "number") {
        if (a.data[key] > b.data[key]) {
          return order ? 1 : -1;
        }
        if (a.data[key] < b.data[key]) {
          return order ? -1 : 1;
        }
        return 0;
      } else if (typeof a.data[key] === "string") {
        const aValue = a.data[key].toString().toLowerCase();
        const bValue = b.data[key].toString().toLowerCase();
        const arr = [aValue, bValue];
        arr.sort();
        if (aValue === arr[0]) {
          return order ? 1 : -1;
        }
        if (aValue !== arr[0]) {
          return order ? -1 : 1;
        }
        return 0;
      } else {
        return 0;
      }
    });
    setSortedBy(key);
    setData(dataCopy);
  };

  useEffect(() => {
    const distContstant = 12;
    const containerHeight = containerRef.current?.clientHeight || 0;
    const totalTableHeight =
      (tableRef.current?.clientHeight || 0) + data.length * 4;

    if (containerHeight - totalTableHeight > distContstant) {
      if (containerRef.current) {
        containerRef.current.style.height = `${
          totalTableHeight + distContstant
        }px`;
      }
    }
  }, []);

  return (
    <AdvancedTable
      colHeading={colHeading}
      headers={headers}
      data={data}
      sortByKey={sortByKey}
      sortedBy={sortedBy}
      highToLow={highToLow}
      containerRef={containerRef}
      tableRef={tableRef}
      onMouseEnterStatHeader={onMouseEnterStatHeader}
      onMouseLeaveStatHeader={onMouseLeaveStatHeader}
      onResizeClick={onResizeClick}
      fullscreen={!!fullscreen}
    />
  );
};

export default AdvancedTableContainer;
