import React from "react";
import { MatchTile } from "@twire/redux/matches/types";
import LastMatchTile from "./LastMatchTile";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../redux/types";

const LastMatchTileContainer: React.FC<MatchTile> = (props) => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  return <LastMatchTile {...props} game={game} />;
};

export default LastMatchTileContainer;
