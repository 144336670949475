import { ReactComponent as SuccessSVG } from "../../../assets/svgs/success.svg";
import Text from "../../common/Text";
// import TwireButton from "@twire/common/TwireButton";
// import TwireLink from "@twire/common/TwireLink";

const Success: React.FC = () => {
  return (
    <div className="flex flex-col items-center">
      <SuccessSVG className="mb-4" />
      <h1>
        <Text i18nKey="words.success">
          Success
        </Text>
      </h1>
      <h5 className="mb-0 open-sans text-sm text-center">
        <Text i18nKey="sentences.checkYourEmailRegistration">
          Check your email to finish registration
        </Text>
      </h5>
      {/* <TwireLink to="/" className="w-full">
      <TwireButton>Lets go!</TwireButton>
    </TwireLink> */}
    </div>
  );
};

export default Success;
