import { Switch, Route, Redirect } from "react-router-dom";
import NewsGrid from "./NewsGrid";
import Article from "./Article";
// import classes from './News.module.css'

const News: React.FC<{ url: string }> = ({ url }) => (
  <Switch>
    <Route path={`${url}/`} exact render={() => <NewsGrid />} />
    <Route path={`${url}/:id/:title?`} exact render={() => <Article />} />
    <Redirect to={`${url}/`} />
  </Switch>
);

export default News;
