import React from "react";
import { Field, ErrorMessage } from "formik";
import { InputProps } from "./types";
import classes from "./TwireInput.module.scss";
import { mergeClasses } from "../../../utility/helpers";

const TwireInput: React.FC<InputProps> = ({
  error,
  className,
  blue,
  Svg,
  ...rest
}) =>
  !Svg ? (
    <div className={classes.withoutSvg}>
      <ErrorMessage
        name={rest.name || ""}
        component="span"
        className={classes.errorMessage}
      />
      <Field
        className={mergeClasses(
          classes.input,
          className || "",
          error ? "border-twire-pink-red" : "",
          blue ? classes.blue : ""
        )}
        {...rest}
      />
    </div>
  ) : (
    <div className={classes.withSvg}>
      <ErrorMessage
        name={rest.name || ""}
        component="span"
        className={classes.errorMessage}
      />
      <div className={mergeClasses(classes.inputWrapper, className)}>
        {Svg && <Svg />}
        <Field
          className={mergeClasses(
            classes.input,
            error ? "border-twire-pink-red" : "",
            blue ? classes.blue : ""
          )}
          {...rest}
        />
      </div>
    </div>
  );

export default TwireInput;
