import { Auth } from "@aws-amplify/auth";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import paymentConfig from "../../payment-config";
import {
  SubscriptionCancelRequestQuery,
  SubscriptionCancelResponseBody,
  SubscriptionCheckoutRequestBody,
  SubscriptionCheckoutResponseBody,
} from "../../SubscriptionAPI";
import { toast } from "react-toastify";

export class Subscription {
  static URL = paymentConfig.url;
  static API = axios.create({
    baseURL: Subscription.URL,
    headers: {
      "Content-Type": "application/json",
    },
    validateStatus: (_status) => true,
  });

  private static request = async <T = any>(
    options: AxiosRequestConfig
  ): Promise<AxiosResponse<T> | string> => {
    try {
      const auth = await Auth.currentSession();
      const token = auth.getAccessToken().getJwtToken();

      const response = await Subscription.API.request<T>({
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response;
      } else {
        throw new Error((response.data as any).message);
      }
    } catch (e) {
      return e?.response?.data ? JSON.stringify(e.response.data) : e.message;
    }
  };

  static async checkout(body: SubscriptionCheckoutRequestBody) {
    const response =
      await Subscription.request<SubscriptionCheckoutResponseBody>({
        method: "POST",
        url: "/checkout",
        data: {
          ...body,
          cancelUrl: body.cancelUrl || window.location.href,
          successUrl:
            body.successUrl || `${window.location.origin}/en/pubg/settings`,
        } as SubscriptionCheckoutRequestBody,
      });

    if (typeof response !== "string") {
      toast.info(`Redirecting to checkout...`, {
        autoClose: 3000,
      });
      setTimeout(() => {
        window.location.href = response.data.sessionUrl;
      }, 3000);
    } else {
      toast.error(`${response}`);
    }
  }

  static async cancel() {
    const query: SubscriptionCancelRequestQuery = {
      type: "main",
    };

    const queryString = Object.keys(query)
      .map(
        (key) => `${key}=${query[key as keyof SubscriptionCancelRequestQuery]}`
      )
      .join("&");

    const response = await Subscription.request<SubscriptionCancelResponseBody>(
      {
        method: "GET",
        url: `/cancel?${queryString}`,
      }
    );

    if (typeof response !== "string") {
      toast.success(
        `You will not be charged anymore. Your subscription will end on ${new Date(
          response.data.cancelAt
        ).toLocaleDateString()}.`
      );

      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    } else {
      toast.error(`${response}`);
    }
  }
}
