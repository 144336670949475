import { useRouteMatch } from "react-router-dom";
import { t } from "@twire/utility/helpers";
// import { t } from "@twire/utility/helpers";

import { TournamentsNavItem } from "../../Tournaments/TournamentsNav/types";

export type NavLinksType = TournamentsNavItem[];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTournamentDataNavLinks = () => {
  const { url } = useRouteMatch();

  const navLinks: TournamentsNavItem[] = [
    // {
    //   url: `${url}/participants`,
    //   title: t("words.participants"),
    // },
    {
      url: `${url}/statistics`,
      title: t("words.teamStats"),
    },
    {
      url: `${url}/tournaments`,
      title: t("words.tournaments"),
    },
    {
      url: `${url}/matches`,
      title: t("words.matches"),
    },
  ];
  return {
    navLinks,
  };
};
