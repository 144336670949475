import { Reducer } from "redux";
import { defaultRequestState } from "../constants";
import { FantasyTournamentsInterface } from "./types";
import * as actionTypes from "./actionTypes";
import * as fantasyTournamentsHandlers from "./handlers/getFantasyTournaments";

const INITIAL_STATE: FantasyTournamentsInterface = {
  tournaments: [],
  requestStates: {
    tournaments: { ...defaultRequestState },
  },
};

const tournamentsReducer: Reducer<FantasyTournamentsInterface> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_SUCCESS:
      return fantasyTournamentsHandlers.success(state, action);
    case actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_FAILED:
      return fantasyTournamentsHandlers.failed(state, action);
    case actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_REQUESTED:
      return fantasyTournamentsHandlers.requested(state);
    case actionTypes.FANTASY_TOURNAMENTS_GET_TOURNAMENTS_CLEAR:
      return fantasyTournamentsHandlers.clear(state);
    default:
      return state;
  }
};

export default tournamentsReducer;
