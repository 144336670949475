import { useState } from "react";
import DropDown from "./DropDown";

const DropDownContainer: React.FC<{
  dropdownTrigger: any;
  className?: string;
}> = ({ children, ...rest }) => {
  const [open, setOpen] = useState(false);
  const onDisappear = () => {
    setOpen(false);
  };
  const onAppear = () => {
    setOpen(true);
  };
  return (
    <DropDown
      onDisappear={onDisappear}
      onAppear={onAppear}
      open={open}
      {...rest}
    >
      {children}
    </DropDown>
  );
};

export default DropDownContainer;
