import { createContext } from "react";

export type AdsContextType = {
  showAds: boolean;
};

const defaultValue: AdsContextType = {
  showAds: true,
};

export const AdsContext = createContext(defaultValue);
