import { Switch, Route, Redirect } from "react-router-dom";
import ComponentError from "@twire/common/ComponentError";
import FantasyBanner from "@twire/common/FantasyBanner";
import StreamEmbed from "@twire/common/StreamEmbed";
import TournamentFantasyBanner from "@twire/common/TournamentFantasyBanner";

import { ReactComponent as Trophy } from "../../../../../assets/svgs/trophy.svg";

import Leaderboard from "./Leaderboard";
import Matches from "./Matches";
import TeamStats from "./TeamStats";
import PlayerStats from "./PlayerStats";
import TournamentInfo from "./TournamentInfo";
import TournamentFilter from "./TournamentFilter";
import TournamentDataNavContainer from "./TournamentData/TournamentDataNav";
import { URLS } from "@twire/utility/constants";
import classes from "./Tournament.module.scss";
import { mergeClasses, getFullRoute, t } from "@twire/utility/helpers";
import { AdsContext } from "../../context";
import FantasyTournaments from "@twire/common/FantasyTournaments";
import PubgPageBanner from "@twire/common/PubgPageBanner";

const Tournament: React.FC<{
  failed: boolean;
  onErrorClicked: () => void;
}> = ({ failed, onErrorClicked }) =>
  failed ? (
    <ComponentError
      message={t("sentences.tournamentLoadFailed")}
      svg={Trophy}
      suggestion={t("sentences.backToTournamentsList")}
      className={mergeClasses(classes.error, "appear", "mt-5")}
      onActionClicked={() => onErrorClicked()}
      actionText={t("sentences.goToTournaments")}
    />
  ) : (
    <div className={mergeClasses(classes.container, "appear", "h-full")}>
      <div className={classes.left}>
        <div className="h-full flex flex-col">
          <div className={classes.tournamentInfoWrapper}>
            <TournamentInfo />
          </div>
          <AdsContext.Consumer>
            {({ showAds }) => (
              showAds && <div className="iframe-center" data-onpage="true" data-adpath="/339474670,22606297850/Twire/InContent"></div>
            )}
          </AdsContext.Consumer>

          <TournamentFantasyBanner className="mt-4 appear" />

          <div className="my-5">
            <TournamentFilter />
          </div>

          <div>
            <TournamentDataNavContainer />
          </div>

          {/* <div> */}
          <Switch>
            <Route
              path={getFullRoute(URLS.LEADERBOARD)}
              component={Leaderboard}
            />
            <Route path={getFullRoute(URLS.MATCHES)} component={Matches} />
            <Route path={getFullRoute(URLS.TEAM_STATS)} component={TeamStats} />
            <Route
              path={getFullRoute(URLS.PLAYER_STATS)}
              component={PlayerStats}
            />
            <Redirect to={getFullRoute(URLS.LEADERBOARD)} />
          </Switch>
          {/* </div> */}
        </div>
      </div>
      <div className={classes.right}>
        <div className="mb-4">
          <StreamEmbed />
          <AdsContext.Consumer>
            {({ showAds }) => (
              showAds && <div
                className="clever-core-ads"
                style={{ width: '300px' }}
              ></div>
            )}
          </AdsContext.Consumer>
          <FantasyBanner />
        </div>
        <FantasyTournaments />
        <PubgPageBanner />
      </div>
    </div>
  );

export default Tournament;
