import { AnyAction } from "redux";
import { UserStateInterface } from "../types";

export const requested = (state: UserStateInterface): UserStateInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    resendVerifyEmail: {
      failed: false,
      requesting: true,
      success: false,
    },
  },
});

export const success = (
  state: UserStateInterface,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: AnyAction
): UserStateInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    resendVerifyEmail: {
      failed: false,
      requesting: false,
      success: true,
    },
  },
});

export const failed = (
  state: UserStateInterface,
  action: AnyAction
): UserStateInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    resendVerifyEmail: {
      failed: action.error,
      requesting: false,
      success: false,
    },
  },
});

export const clear = (state: UserStateInterface): UserStateInterface => ({
  ...state,
  requestStates: {
    ...state.requestStates,
    resendVerifyEmail: {
      failed: false,
      requesting: false,
      success: false,
    },
  },
});
