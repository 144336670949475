import React from "react";
import { MatchTile } from "@twire/redux/matches/types";
import LastMatchTile from "../LastMatchTile";
import classes from "./LastMatches.module.scss";

const LastMatches: React.FC<{ matches: MatchTile[] }> = ({ matches }) => (
  <div className={classes.container}>
    {matches.map((match) => (
      <div key={match.id} className="my-1">
        <LastMatchTile {...match} />
      </div>
    ))}
  </div>
);

export default LastMatches;
