import {
  all,
  put,
  takeLatest,
  call,
} from "@redux-saga/core/effects";

import * as actionTypes from "./actionTypes";

function* fetchTopTeams() {
  yield put({ type: actionTypes.TEAMS_GET_PGC_POINTS_REQUESTED });

  try {

    const response: Generator<any, any> = yield call(
      fetch,
      "https://twire-assets.s3.eu-west-1.amazonaws.com/pubg/pgc/points-new.json"
    );
    const pgcPoints: Generator<any, any> = yield (
      response as any as Response
    ).json();

    yield put({
      type: actionTypes.TEAMS_GET_PGC_POINTS_SUCCESS,
      regions: pgcPoints,
    });
  } catch (error) {
    yield put({ type: actionTypes.TEAMS_GET_PGC_POINTS_FAILED, error });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([takeLatest(actionTypes.TEAMS_GET_PGC_POINTS, fetchTopTeams)]);
}
