import React from "react";
import ScrollableTableShade from "@twire/common/ScrollableTableShade";
import ScreenSizeButton from "@twire/common/ScreenSizeButton";
import { GeneralTournamentFormat } from "@twire/utility/constants";
import { LeaderboardStatsPerMatchProps } from "./types";

import {
  mergeClasses,
  setDefaultTeamLogo,
  setTeamLogo,
} from "../../../../../../../utility/helpers";
import { getColor, getVal } from "./functions";
import classes from "./LeaderboardStatsPerMatch.module.scss";

// const rounds = new Array(17).fill("");
// const rows = new Array(20).fill("");

const LeaderboardStatsPerMatch: React.FC<LeaderboardStatsPerMatchProps> = ({
  game,
  leaderboard,
  ruleset,
  tableRef,
  containerRef,
  onMouseEnterTeamName,
  onMouseLeaveTeamName,
  fullscreen,
  toggleFullScreen,
  showResizeButton,
}) => (
  <div className={mergeClasses([fullscreen, "fullscreen"])}>
    <div className="relative mt-5 appear">
      {(fullscreen || showResizeButton) && (
        <div className={classes.resizeButton}>
          <ScreenSizeButton onClick={toggleFullScreen} alt={fullscreen} />
        </div>
      )}
      <ScrollableTableShade scrollableRef={containerRef} tableRef={tableRef} />
      <div className={mergeClasses(classes.container)} ref={containerRef}>
        <table className={mergeClasses(classes.table)} ref={tableRef}>
          <tbody>
            <tr
              className={mergeClasses(
                classes.th,
                classes.cell,
                classes.stickyTop
              )}
            >
              <td
                className={mergeClasses(
                  classes.pl30,
                  classes.rank,
                  classes.stickyLeft,
                  classes.indexSticky,
                  classes.alignLeftI
                )}
              >
                <span
                  className={mergeClasses([!fullscreen, classes.paddedSpan])}
                >
                  Rank
                </span>
              </td>
              <td
                className={mergeClasses(
                  classes.stickyLeft,
                  classes.name,
                  classes.teamSticky,
                  classes.teamTitle,
                  classes.alignLeftI
                )}
              >
                <span
                  className={mergeClasses(
                    classes.paddedSpan,
                    classes.teamTitle,
                    classes.alignLeftI
                  )}
                >
                  Team
                </span>
              </td>
              <td
                colSpan={2}
                className={mergeClasses(
                  classes.stickyLeft,
                  classes.total1Sticky
                )}
              >
                Total
              </td>
              {(leaderboard[0] && leaderboard[0].statsPerMatch
                ? leaderboard[0].statsPerMatch
                : []
              ).map((_, i) => (
                <>
                  <td
                    className={mergeClasses([i === 0, classes.pl30])}
                    colSpan={ruleset == GeneralTournamentFormat.MOST_CHICKEN ? 2 : 3}
                    key={i}
                  >
                    {" "}
                    <span
                      className={mergeClasses([i === 0, classes.smallPaddedSpan])}
                    >{`Match ${i + 1}`}</span>
                  </td>
                  <td>&nbsp;</td>
                </>
              ))}
            </tr>
            <tr
              className={mergeClasses(classes.subHeading, classes.stickyTop40)}
            >
              <td
                colSpan={2}
                className={mergeClasses(
                  classes.stickyLeft,
                  classes.indexSticky,
                  "background-twire-dark"
                )}
              ></td>
              <td
                className={mergeClasses(
                  classes.stickyLeft,
                  classes.total1Sticky,
                  "background-twire-dark"
                )}
              >
                K
              </td>
              <td
                className={mergeClasses(
                  classes.stickyLeft,
                  classes.total2Sticky,
                  "background-twire-dark"
                )}
              >
                {ruleset == GeneralTournamentFormat.MOST_CHICKEN ? "W" : "P"}
              </td>
              {(leaderboard[0] && leaderboard[0].statsPerMatch
                ? leaderboard[0].statsPerMatch
                : []
              ).map((_, i) => (
                <React.Fragment key={i}>
                  <td
                    style={{ transform: "translateX(5px)" }}
                    className={mergeClasses([i === 0, classes.pl30])}
                  >
                    <span
                      className={mergeClasses([
                        i === 0,
                        classes.smallPaddedSpan,
                      ])}
                    >
                      K
                    </span>
                  </td>
                  <td>PL</td>
                  {ruleset == GeneralTournamentFormat.MOST_CHICKEN
                    ? null
                    : <td>PO</td>}
                  <td>&nbsp;</td>
                </React.Fragment>
              ))}
            </tr>
            {leaderboard.map((leaderboardRow, ii) => (
              <tr
                className={classes.fieldRow}
                key={ii}
                style={{ transform: `translateY(${ii * 4}px)` }}
              >
                <td
                  className={mergeClasses(
                    "background-twire-dark-ash",
                    classes.pl30,
                    classes.stickyLeft,
                    classes.indexSticky,
                    classes.alignLeftI
                  )}
                >
                  <span className={mergeClasses(
                    "font-bold text-xl",
                    classes.paddedSpan,
                  )}>#{ii + 1}</span>
                </td>
                <td
                  className={mergeClasses(
                    "background-twire-dark-ash cursor-pointer",
                    classes.stickyLeft,
                    classes.name,
                    classes.teamSticky,
                    classes.alignLeftI
                  )}
                  onMouseEnter={(e) => {
                    onMouseEnterTeamName(e, leaderboardRow.players);
                  }}
                  onMouseLeave={onMouseLeaveTeamName}
                >
                  <table>
                    <tr>
                      <td>
                        <img
                          src={setTeamLogo(game, leaderboardRow.team)}
                          onError={setDefaultTeamLogo}
                          alt="img"
                        />
                      </td>
                      <td>
                        <span className={mergeClasses(
                          "text-xl",
                          classes.alignLeftI
                        )}>
                          {leaderboardRow.team}
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
                <td
                  className={mergeClasses(
                    "text-twire-orange background-twire-dark-ash text-xl",
                    classes.stickyLeft,
                    classes.dataCell,
                    classes.total1Sticky,
                    classes.smallCell
                  )}
                >
                  {leaderboardRow.kills}
                </td>
                <td
                  className={mergeClasses(
                    "text-twire-orange background-twire-dark-ash text-xl",
                    classes.stickyLeft,
                    classes.dataCell,
                    classes.total2Sticky
                  )}
                >
                  {ruleset == GeneralTournamentFormat.MOST_CHICKEN
                    ? leaderboardRow.wins
                    : leaderboardRow.totalPoints}
                </td>
                {(leaderboard[ii] && leaderboard[ii].statsPerMatch
                  ? leaderboard[ii].statsPerMatch
                  : []
                ).map((stat, statindex) => (
                  <React.Fragment key={statindex}>
                    <td
                      className={mergeClasses(
                        "text-twire-opaque-white background-twire-grey text-twire-light text-xl",
                        [statindex === 0, classes.pl30],
                        classes.dataCell,
                        classes.smallDataCell
                      )}
                      style={{
                        transform: `translateX( ${
                          statindex === 0 ? (statindex + 1) * 4 : 0
                        }px)`,
                      }}
                    >
                      <span
                        className={mergeClasses(
                          [statindex === 0, classes.smallPaddedSpan],
                          "text-twire-light",
                          typeof getVal(ruleset, stat) !== "number"
                            ? classes.grey
                            : "text-twire-light-i"
                        )}
                        style={{ paddingLeft: "10px" }}
                      >
                        {typeof stat.kills === "number" ? stat.kills : "N/A"}
                      </span>
                    </td>
                    <td
                      className={mergeClasses(
                        "text-twire-opaque-white background-twire-grey text-twire-light text-xl",
                        `text-twire-${getColor(stat.rank)}-i`,
                        classes.dataCellNormal,
                        typeof stat.rank !== "number"
                          ? classes.grey
                          : "text-twire-light-i",
                        [ruleset == GeneralTournamentFormat.MOST_CHICKEN && stat.rank === 1]
                      )}
                    >
                      {typeof stat.rank === "number"
                        ? stat.rank
                        : "N/A"}
                    </td>
                    {ruleset == GeneralTournamentFormat.MOST_CHICKEN
                      ? null
                      : <td
                        className={mergeClasses(
                          "text-twire-opaque-white background-twire-grey text-twire-light text-xl",
                          [statindex === 0, classes.pl30],
                          classes.dataCell,
                          classes.smallDataCell
                        )}
                      >
                        <span
                          className={mergeClasses(
                            "text-twire-light",
                            typeof stat.points !== "number"
                              ? classes.grey
                              : "text-twire-light-i"
                          )}
                        >
                          {typeof stat.points === "number"
                            ? stat.points
                            : "N/A"}
                        </span>
                      </td>}
                    <td>&nbsp;</td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default LeaderboardStatsPerMatch;
