import { useSelector } from "react-redux";
import { useUrl } from "@twire/utility/hooks";
import { RootReducer } from "@twire/redux/types";
import { t } from "@twire/utility/helpers";

import { TournamentsNavItem } from "./types";

export type NavLinksType = TournamentsNavItem[];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTournamentsNavLinks = () => {
  const {
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const navLinks: TournamentsNavItem[] = [
    {
      url: useUrl(`/${game}/tournaments/ongoing`),
      title: t("words.ongoingTournaments"),
    },
    {
      url: useUrl(`/${game}/tournaments/past`),
      title: t("words.pastTournaments"),
    },
    {
      url: useUrl(`/${game}/tournaments/upcoming`),
      title: t("words.upcomingTournaments"),
    },
  ];
  return {
    navLinks,
  };
};
