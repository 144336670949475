import React from "react";
import { mergeClasses } from "../../../../../../utility/helpers";
import classes from "./Placeholder.module.scss";

const Placeholder: React.FC = () => (
  <div className={classes.container}>
    <div className={mergeClasses(classes.time, "animate-pulse")}></div>
    <div className={mergeClasses(classes.header, "animate-pulse")}></div>
    <div className={mergeClasses(classes.short, "animate-pulse")}></div>
    <div className={mergeClasses(classes.bigImage, "animate-pulse")}></div>
    {/* <div className={classes.shortParagraph}></div> */}
    <div className={classes.longParagraph1}>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
    </div>
    <div className={mergeClasses(classes.bigImage, "animate-pulse")}></div>

    <div className={classes.longParagraph2}>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
      <div className={mergeClasses("animate-pulse")}></div>
    </div>
  </div>
);

export default Placeholder;
