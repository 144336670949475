import React from "react";
import classes from "./Placeholder.module.scss";

const Placeholder: React.FC = () => (
  <div className={classes.container}>
    <div className={classes.caption} />
    <div className={classes.content}>
      <div className={classes.contentCol}>
        <div />
        <div />
      </div>
      <div className={classes.contentCol}>
        <div />
        <div />
      </div>
      <div className={classes.contentCol}>
        <div />
        <div />
      </div>
      <div className={classes.contentCol}>
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default Placeholder;
