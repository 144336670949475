import React from "react";
import { Link } from "react-router-dom";
import TeamTile from "@twire/common/TeamTile";
import { RankingTeam } from "@twire/redux/teamRanking/types";
import { useGetTeamUrl } from "@twire/utility/hooks";
import { getTheme } from "@twire/utility/helpers";
import { Games } from "@twire/utility/constants";
// import classes from "./TopTeams.module.css";

const TopTeams: React.FC<{
  topTeams: RankingTeam[];
  game: Games;
  getTeamUrl: ReturnType<typeof useGetTeamUrl>;
}> = ({ topTeams, game, getTeamUrl }) => (
  <>
    {topTeams.map((team, index) => (
      <Link
        to={getTeamUrl(team.id || 0, team.name)}
        className="block my-1"
        key={team.id}
      >
        <TeamTile
          pos={index + 1}
          theme={getTheme(index)}
          game={game}
          {...team}
        />
      </Link>
    ))}
  </>
);

export default TopTeams;
