import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "@twire/redux/types";
import { getPlayerRanking } from "@twire/redux/playerRanking/actions";
import Ranking from "./PlayerRanking";
import { useGetTeamUrl } from "../../../../utility/hooks";
import {
  PlayerId,
  PlayerStats,
  RankedPlayermap,
} from "@twire/redux/playerRanking/types";

import {
  genRankedPlayerMap,
  getTrie,
  sortPlayers,
  determineOrder,
} from "./functions";
import { PlayerRankingSortType, PlayersTrie } from "./types";

const ComponentContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    playerRanking: { data, currentFilter, requestStates, updatedAt },
    app: { game },
  } = useSelector((state: RootReducer) => state);

  const [sortData, setSortby] = useState<PlayerRankingSortType>([
    "overallScore",
    "DESCENDING",
  ]);

  const [players, setPlayers] = useState<PlayerId[]>([]);

  const [avgPlayerStats, setAvgPlayerStats] = useState<PlayerStats | null>(
    null
  );

  const [showAverageStats, setShowAverageStats] = useState(false);

  const [playersTrie, setPlayersTrie] = useState<PlayersTrie>(getTrie([]));

  const [playersData, setPlayersData] = useState<RankedPlayermap>({});

  const getTeamUrl = useGetTeamUrl();

  const sort = (key: keyof PlayerStats) => {
    const newSortData: PlayerRankingSortType = [
      key,
      determineOrder(sortData, key),
    ];
    setSortby(newSortData);
    const sorted = sortPlayers(newSortData, Object.values(playersData));
    setPlayers(sorted);
  };

  useEffect(() => {
    dispatch(getPlayerRanking());
  }, [game]);

  useEffect(() => {
    if (data && currentFilter) {
      const pl = data[currentFilter.year][currentFilter.contest];
      const rankedPlayerMap = genRankedPlayerMap(pl.players);
      setPlayersData(rankedPlayerMap);
      setAvgPlayerStats(pl.averageScores);
      setPlayersTrie(getTrie(pl.players));
      const playerIds = pl.players.map((p) => p.id);
      setSortby(["overallScore", "DESCENDING"]);
      setPlayers(playerIds);
    }
  }, [data, currentFilter]);

  const averageStatsToggle = () => {
    if (avgPlayerStats) {
      if (showAverageStats) {
        setShowAverageStats(false);
      } else {
        setShowAverageStats(true);
      }
    } else {
      if (avgPlayerStats) {
        setShowAverageStats(false);
      }
    }
  };
  return (
    <Ranking
      setSortby={sort}
      players={players}
      loading={requestStates.rankedPlayers.requesting}
      updatedAt={updatedAt}
      showError={
        requestStates.rankedPlayers.failed &&
        !requestStates.rankedPlayers.requesting
      }
      game={game}
      getTeamUrl={getTeamUrl}
      sortedBy={sortData}
      playersTrie={playersTrie}
      rankedPlayermap={playersData}
      averageStats={avgPlayerStats}
      showAverageStats={showAverageStats}
      averageStatsToggle={averageStatsToggle}
    />
  );
};

export default ComponentContainer;
