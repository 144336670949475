import { mergeClasses, t, urlFriendlyString } from "@twire/utility/helpers";
import { URLS } from "@twire/utility/constants";
import ArrowSpan from "@twire/common/ArrowSpan";
import { ReactComponent as Play } from "../../../../../../assets/svgs/play.svg";
import { MatchTileViewProps } from "./types";
import classes from "./MatchTile.module.scss";

const MatchTile: React.FC<MatchTileViewProps> = ({
  lang,
  game,
  matchId,
  matchName,
  tournamentName,
  tournamentLogo,
  tournamentId,
  className,
}) => (
  <div className={mergeClasses(classes.container, className || "")}>
    <div className="flex items-center">
      <img src={tournamentLogo} alt="vietnam" className={classes.logo} />
      <div className={mergeClasses("pr-2")}>
        <div className="text-2xl font-bold text-twire-light">
          {tournamentName}
        </div>
        <div className="open-sans text-twire-light text-xs opacity-70">
          {matchName}
        </div>
      </div>
    </div>
    <div className={classes.actions}>
      <div className="flex">
        <a
          href={`https://analytics.twire.gg/${lang}/${game}/match/${matchId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Play className={classes.actionIcon} />
        </a>
      </div>
      <a
        href={`/${lang}/${game}${URLS.TOURNAMENT.replace(
          ":id",
          tournamentId.toString()
        ).replace(":name?", urlFriendlyString(tournamentName))}` +
        `/team-stats?matches=${matchId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ArrowSpan>{t("words.details")}</ArrowSpan>
      </a>
    </div>
  </div>
);

export default MatchTile;
