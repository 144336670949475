import { ReactComponent as SuccessSVG } from "../../../assets/svgs/success.svg";
import TwireButton from "@twire/common/TwireButton";
import { useDispatch } from "react-redux";
import { clearForgotPassword } from "@twire/redux/user/actions";
import Text from "@twire/common/Text";
// import TwireLink from "@twire/common/TwireLink";

const Success: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col items-center">
      <SuccessSVG className="mb-4" />
      <h1><Text i18nKey="words.success">Success</Text></h1>
      <h5 className="mb-0 open-sans text-sm text-center">
        <Text i18nKey="sentences.forgotPasswordSuccess">
          Email with reset password link was sent to you
        </Text>
      </h5>
      <TwireButton
        className="mt-4"
        onClick={() => dispatch(clearForgotPassword())}
      >
        <Text i18nKey="words.resendLink">Resend Link</Text>
      </TwireButton>
    </div>
  );
};

export default Success;
