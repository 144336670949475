import React from "react";
import { NavLink } from "react-router-dom";
import { mergeClasses } from "@twire/utility/helpers";

import classes from "./NavigationLinks.module.scss";

import { NavigationLinksProps } from "./types";

const NavigationLinks: React.FC<NavigationLinksProps> = ({
  navigationLinks,
}) => (
  <nav className={classes.nav}>
    {navigationLinks.map(
      ({ url, svg: Svg, title, external, exact, isActive, blue }) =>
        !external ? (
          <NavLink
            to={url}
            key={title}
            className={classes.navLink}
            activeClassName={classes.active}
            exact={!!exact}
            isActive={isActive}
          >
            <div
              className={mergeClasses(
                classes.navItem,
                blue ? classes.blue : ""
              )}
            >
              <div className={classes.indicatorWrapper}>
                <div className={classes.indicator} />
              </div>
              <Svg />
              <p>{title}</p>
            </div>
          </NavLink>
        ) : (
          <a
            href={url}
            key={title}
            className={classes.navLink}
            target="_blank"
            rel="noreferrer"
            // activeClassName={classes.active}
            // exact={!!exact}
          >
            <div className={classes.navItem}>
              <div className={classes.indicatorWrapper}>
                <div className={classes.indicator} />
              </div>
              <Svg />
              <p>{title}</p>
            </div>
          </a>
        )
    )}
  </nav>
);

export default NavigationLinks;
