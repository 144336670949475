import ComponentError, {
  ComponentErrorInterface,
} from "@twire/common/ComponentError";
import { FeaturedTournamentsContext } from "./context";
import Controller from "./Controller";
import FeaturedTournament from "./FeaturedTournament";
import Placeholder from "./Placeholder";
import classes from "./FeaturedTournaments.module.scss";

const FeaturedTournaments: React.FC<{
  isLoading: boolean;
  failed: boolean;
  errorObj: ComponentErrorInterface;
}> = ({ isLoading, failed, errorObj }) => (
  <FeaturedTournamentsContext.Consumer>
    {({ featuredTournaments }) => (
      featuredTournaments &&
      featuredTournaments.length === 0 &&
      !isLoading?
      null
      :
      <div className={classes.container}>
        {featuredTournaments &&
          featuredTournaments.length === 0 &&
          isLoading && <Placeholder />}
        {featuredTournaments &&
          featuredTournaments.length > 0 &&
          !failed && <FeaturedTournament />}
        {failed && (
          <ComponentError className={classes.errorComponent} {...errorObj} />
        )}
        <Controller />
        <div className={classes.line} />
      </div>
    )}
  </FeaturedTournamentsContext.Consumer>
);

export default FeaturedTournaments;
